import React, {
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Divider,
	Flex,
	Row,
	Space,
	Timeline,
	Typography,
	Upload,
} from 'antd';
import {
	CloseOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLink,
	faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import {
	AttachmentTypes,
	DATE_AND_TIME,
	ExpenseActions,
	AccountingItemApprovalStates,
	FixtureCounterpartyTypes,
	HireInvoiceItemStates,
} from '@shared/utils/constants';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import type FixtureCounterparty from '@api/models/fixture-counterparty';
import type Attachment from '@api/models/attachment';
import { formatDate } from '@client/utils/formatDate';
import Button from '@client/components/Button';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import {
	addAttachment,
	deleteAttachment,
	submitExpense,
	markExpensePaid,
	markExpenseUnpaid,
	unsubmitExpense,
	updateVoyageExpense,
	getSuppliers,
	updateVoyageExpenseReceivable,
	getCounterparties,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import asyncWrapper from '@client/utils/asyncWrapper';
import AttachmentViewer from '@client/components/AttachmentViewer';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import ExpensePaymentsTable from '@client/screens/financials/ExpensesScreen/ExpensePaymentsTable';
import styles from './ExpenseDetailsCard.module.css';
import ExpenseActionButtons from './private/ExpenseActionButtons';
import getSelectedExpenseItems from './private/getSelectedExpenseItems';
import getReceivableItems from './private/getReceivableItems';
import { getActionDetails } from './private/helpers/getActionDetails';

type ExpenseDetailsCardProps = {
	expense: TransformedExpenses & { charterer?: FixtureCounterparty};
	refreshExpenses: () => void;
	readOnly?: boolean;
}

const ExpenseDetailsCard = ({
	expense,
	refreshExpenses,
	readOnly = false,
}: ExpenseDetailsCardProps) => {
	const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
	const [suppliers] = useFetchedState(getSuppliers);
	const [charterers] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTEREROWNER),
	);

	const selectedExpenseItems = getSelectedExpenseItems(expense, suppliers ?? []);

	const saveDetails = asyncWrapper(async (values) => {
		const newValues = {
			...values,
			...(values.amount != null ? {
				amount: values.amount.value,
				currency: values.amount.currency,
				exchangeRate: values.amount.exchangeRate,
			} : { amount: undefined }),
		};

		await updateVoyageExpense(expense.id, newValues);
		await refreshExpenses();
	}, 'Could not save expense details');

	const saveReceivableDetails = asyncWrapper(async (values) => {
		if (expense.voyageId != null && expense.receivable != null) {
			await updateVoyageExpenseReceivable(expense.voyageId, expense.receivable.id, values, []);
		}

		await refreshExpenses();
	}, 'Could not save receivable details');

	const onSubmitExpense = asyncWrapper(async () => {
		await submitExpense(expense.id);
		await refreshExpenses();
	}, 'Could not submit expense for approval');

	const onUnsubmitExpense = asyncWrapper(async () => {
		await unsubmitExpense(expense.id);
		await refreshExpenses();
	}, 'Could not undo submission');

	const onMarkAsPaid = asyncWrapper(async () => {
		await markExpensePaid(expense.id);
		await refreshExpenses();
	}, 'Could not mark expense as paid');

	const onMarkAsUnpaid = asyncWrapper(async () => {
		await markExpenseUnpaid(expense.id);
		await refreshExpenses();
	}, 'Could not mark expense as unpaid');

	const onRemoveAttachment = async (attachmentId: number) => {
		try {
			await deleteAttachment(attachmentId);
			await refreshExpenses();
		} catch (e) {
			showErrorNotification('Could not remove attachment', e as Error);
		}
	};

	const onAddAttachment = async (file: File) => {
		try {
			await addAttachment(AttachmentTypes.VOYAGE_EXPENSE, expense.id, file);
			await refreshExpenses();
		} catch (e) {
			showErrorNotification('Could not add attachment', e as Error);
		}
	};

	const receivableCharterer = useMemo(() => {
		if (expense?.receivable == null || expense?.receivable?.chartererId == null) {
			return null;
		}

		return charterers?.find((c) => c.id === expense.receivable?.chartererId);
	}, [charterers, expense]);

	const getReceivableStr = () => {
		if (expense.charterer != null) {
			return expense.charterer;
		}

		if (receivableCharterer != null) {
			return receivableCharterer.name;
		}

		return '';
	};

	return (
		<>
			<Space direction="vertical" className={styles.card}>
				<ExpensePaymentsTable
					expenseId={expense.id}
					currency={expense.currency}
					refreshExpenses={refreshExpenses}
				/>
				<Flex gap={10} vertical>
					<ExpenseActionButtons
						expense={expense}
						onSubmitExpense={onSubmitExpense}
						onUnsubmitExpense={onUnsubmitExpense}
						onMarkAsPaid={onMarkAsPaid}
						onMarkAsUnpaid={onMarkAsUnpaid}
					/>
					<Typography.Title level={5}>Attachments</Typography.Title>
					<Space className={styles.attachments} direction="vertical">
						{readOnly && expense.attachments?.length === 0 && (
							<em>No attachments</em>
						)}
						{expense.attachments?.map((attachment) => (
							<div className={styles.attachmentButton} key={attachment.id}>
								<Button
									className={styles.noPaddingButton}
									onClick={() => setSelectedAttachment(attachment)}
									icon={(
										<FontAwesomeIcon
											icon={faLink as Icon}
											className={styles.icon}
										/>
									)}
									type="link"
								>
									{attachment.name}
								</Button>
								{!readOnly && (
									<Button
										type="link"
										danger
										icon={(<CloseOutlined />)}
										confirmTitle={`Remove ${attachment.name}?`}
										onClick={() => onRemoveAttachment(attachment.id)}
									/>
								)}
							</div>
						))}
						{!readOnly && (
							<Upload
								beforeUpload={(file) => onAddAttachment(file)}
								showUploadList={false}
							>
								<Button
									type="link"
									icon={(<PlusOutlined />)}
									padding={0}
								>
									Add file
								</Button>
							</Upload>
						)}
					</Space>
				</Flex>
				<Row gutter={[16, 24]}>
					<Col xs={16}>
						<EditableDetails
							// @ts-ignore
							title={(expense.supplierName != null ?
								(
									<>
										Expense from:
										{' '}
										{' '}
										{expense.supplierName}
									</>

								) :
								'Expense Details'
							)}
							onSave={saveDetails}
							saveButtonProps={{
								confirmTitle: expense.state !== AccountingItemApprovalStates.PENDING ?
									'This expense has been submitted for approval or is approved. Editing it will unsubmit it and approval will be needed again' : null,
							}}
							// @ts-ignore
							items={selectedExpenseItems}
						/>
					</Col>
					{expense.receivable != null && (
						<Col xxl={10} xs={12}>
							<EditableDetails
								// @ts-ignore
								title={(
									<>
										Receivable from:
										{' '}
										{' '}
										{getReceivableStr()}
									</>
								)}
								onSave={saveReceivableDetails}
								editButtonProps={{
									disabled: readOnly || expense.receivable.state !== HireInvoiceItemStates.PENDING,
									disabledTooltip: (readOnly ?
										'Cannot edit receivable details while approving' :
										'Receivable has already been invoiced'
									),
								}}
								// getReceivableItems is not typed
								// @ts-ignore
								items={getReceivableItems(expense)}
							/>
						</Col>
					)}
					<Divider />
					<Col span={24}>
						<Typography.Title level={4}>
							Timeline
						</Typography.Title>
					</Col>
					<Col span={24}>
						<Timeline mode="left">
							{expense.actions
								.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
								.map((action) => {
									const {
										icon,
										iconColor,
										label,
									} = getActionDetails(action.action);

									return (
										<Timeline.Item
											key={action.id}
											className={styles.timelineItem}
											label={formatDate(action.createdAt, DATE_AND_TIME)}
											dot={icon}
											color={iconColor}
										>
											{label}
											<br />
											<div className={styles.actionCreatedBy}>
												{`by ${action.createdByUser}`}
												{
													action.description != null && (
														<>
															<br />
															{action.action === ExpenseActions.REJECTED ?
																'reason: ' : 'note: ' }
															{action.description}
														</>
													)
												}
											</div>
										</Timeline.Item>
									);
								})}
							<Timeline.Item
								label={formatDate(expense.createdAt, DATE_AND_TIME)}
								className={styles.timelineItem}
								dot={(<FontAwesomeIcon icon={faPlus as Icon} />)}
								color="green"
							>
								Expense created
								<br />
								<div className={styles.actionCreatedBy}>
									{`by ${expense?.createdByUserName}`}
								</div>
							</Timeline.Item>
						</Timeline>
					</Col>
				</Row>
				<AttachmentViewer
					attachment={selectedAttachment}
					modal
					onCloseModal={() => setSelectedAttachment(null)}
				/>
			</Space>
		</>
	);
};

export default ExpenseDetailsCard;
