import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import type { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import Button from '@client/components/Button';
import AddButton from '@client/components/AddButton';
import { NullableBunkerRecord } from '@client/screens/estimates/details/helpers/types';

export type RobTableProps = {
	label: string;
	dataSource: NullableBunkerRecord[];
	onChange: (records: NullableBunkerRecord[]) => void;
	onDelete: (record: NullableBunkerRecord) => void;
	fuelOptions: Array<{ label: string; value: string }>;
	fixtureCurrency: Values<typeof Currencies>;
	showPricePerTon: boolean;
};

const RobTable = ({
	label,
	dataSource,
	onChange,
	onDelete,
	fuelOptions,
	showPricePerTon = false,
	fixtureCurrency,
}: RobTableProps) => {
	const columns = [
		{
			title: 'Fuel grade',
			dataIndex: 'fuelGrade',
			editable: true,
			width: 100,
			type: 'select',
			inputProps: {
				defaultValue: fuelOptions[0]?.value,
				options: fuelOptions,
			},
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			editable: true,
			type: 'number',
			transformData: {
				in: (v: number) => v?.toFixed(3),
			},
			inputProps: {
				addonAfter: 'MT',
			},
		},
		...(showPricePerTon ? [{
			title: 'Price',
			dataIndex: 'pricePerTon',
			editable: true,
			type: 'currency',
			inputProps: {
				currency: fixtureCurrency,
			},
		}] : []),
		{
			title: (
				<AddButton
					onClick={() => onChange([
						...dataSource,
						{
							fuelGrade: fuelOptions[0]?.value as Values<typeof FuelTypes>,
							quantity: 0,
							pricePerTon: 0,
						},
					])}
				/>
			),
			dataIndex: 'addEntry',
			render: (record: NullableBunkerRecord) => (
				<Button
					onClick={() => onDelete(record)}
					type="text"
					confirmTitle="Are you sure you want to delete this row?"
					danger
					icon={(<DeleteOutlined />)}
				/>
			),
		},
	];

	return (
		<Form.Item label={label}>
			<EditableCellTableRedux<NullableBunkerRecord>
				dataSource={dataSource}
				size="small"
				pagination={false}
				onChange={onChange}
				emptyText="Empty"
				// @ts-ignore
				columns={columns}
			/>
		</Form.Item>
	);
};

export default RobTable;
