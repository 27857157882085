import React, {
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Flex,
} from 'antd';
import classNames from 'classnames';
import {
	CloseOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { PortActionTypes } from '@shared/utils/constants';
import type { Port } from '@api/utils/ports';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import {
	deleteItineraryEntry,
	updatePortCall,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import CountryFlag from '@client/components/CountryFlag';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import { useItinerary } from './ItineraryTab/ItineraryProvider';
import styles from './ItineraryTab/tabs/styles/SummaryTab.module.css';

const PortCallSelector = () => {
	const [editingPort, setEditingPort] = useState(false);
	const [saving, setSaving] = useState(false);
	const {
		expandedEntry,
		ports,
		refreshItinerary,
		setItineraryShouldUpdate,
		onSelectEntry,
	} = useItinerary();
	const {
		refreshDetails,
	} = useVoyage();

	const selectedEntry = expandedEntry as ItineraryPortCallDto;

	const actions = selectedEntry?.actions ?? [];
	const actionStr = actions.map((a) => a.action);
	const isCommencement = actions.some((a) => a.action === PortActionTypes.COMMENCEMENT);
	const isLoadingOrDischarge =
            actionStr.includes(PortActionTypes.LOADING) ||
            actionStr.includes(PortActionTypes.DISCHARGING);

	const disabledDeleteTooltip = useMemo(() => {
		if (isLoadingOrDischarge) {
			return 'You cannot delete port calls with loading or discharging actions';
		}

		if (isCommencement) {
			return 'You cannot delete the commencement port call';
		}

		return '';
	}, [isCommencement, isLoadingOrDischarge]);

	const {
		arrivalDate,
		departureDate,
		requiredArrivalDate,
		estimatedArrivalDate,
	} = selectedEntry;

	const isActual = departureDate != null || arrivalDate != null;

	const hasDaExpenses = selectedEntry.voyageExpenses.length > 0;

	const deletePortCall = async () => {
		try {
			await deleteItineraryEntry(selectedEntry.vesselId, selectedEntry.id);
			await refreshDetails();
			await refreshItinerary();
			await setItineraryShouldUpdate(true);
		} catch (e) {
			showErrorNotification('Could not delete port call', e as Error);
		}
	};

	const updateEntry = async (
		attributes: {
			port?: Port;
		},
	) => {
		setSaving(true);

		try {
			onSelectEntry(undefined);
			await updatePortCall(selectedEntry.vesselId, selectedEntry.id, attributes, true);
			await refreshDetails();
			await refreshItinerary();
			await setItineraryShouldUpdate(true);
		} catch (e) {
			showErrorNotification('Could not update port call', e as Error);
		} finally {
			setSaving(false);
		}
	};

	const portOptions = useMemo(() => (ports != null ? ports?.map((p) => {
		return {
			label: (
				<div key={p.id} className={styles.portSelector}>
					<CountryFlag
						countryCode={typeof p?.countryCode === 'string' ? p.countryCode : undefined}
					/>
					<p>{p.name}</p>
				</div>
			),
			value: p.id,
			searchValue: p.name,
		};
	}).sort((a, b) => a.searchValue.localeCompare(b.searchValue)) : []), [ports]);

	const handlePortSelect = async (id: number | null) => {
		if (id == null) {
			return;
		}

		const newPort = ports?.find((p) => p.id === id);

		if (newPort == null) {
			return;
		}

		await updateEntry({
			port: newPort,
		});
		await refreshDetails();
		setEditingPort(false);
	};

	const etaAfterRequiredAlert = useMemo(() => {
		if (requiredArrivalDate?.isBefore(estimatedArrivalDate) && arrivalDate == null) {
			return (
				<Alert type="warning" message="Required arrival date is before estimated arrival date" />
			);
		}

		return null;
	}, [arrivalDate, estimatedArrivalDate, requiredArrivalDate]);

	return (
		<Flex>
			<div
				className={classNames(styles.flagAndNameRow)}
			>
				{editingPort ? (
					<Select
						showSearch
						className={styles.selectPort}
						size="large"
						value={selectedEntry.port.id}
						optionFilterProp="searchValue"
						options={portOptions}
						onSelect={handlePortSelect}
						onBlur={() => setEditingPort(false)}
					/>
				) : (
					<>
						<CountryFlag
							countryCode={selectedEntry?.port.countryCode}
							className={styles.portFlag}
							size={35}
						/>
						{selectedEntry.port.name}
					</>
				)}
			</div>
			<div
				className={styles.topButtonRow}
			>
				{editingPort ? (
					<Button
						type="link"
						className={styles.closeIcon}
						onClick={() => setEditingPort(false)}
						icon={(
							<CloseOutlined />
						)}
					/>
				) : (
					<div className={styles.buttonContainer}>
						{!selectedEntry.isCanalTransit ? (
							<div className={styles.innerBtnContainer}>
								<Button
									type="link"
									loading={saving}
									onClick={() => setEditingPort(true)}
									disabled={isActual}
									confirmTitle={selectedEntry.actions.length > 0 ? 'This port will also be changed in the cargo and the estimate' : undefined}
									icon={(
										<EditOutlined />
									)}
								>
									Change port
								</Button>
								{etaAfterRequiredAlert}
							</div>
						) : (<div />)}
						{(arrivalDate == null && departureDate == null) && (
							<Button
								type="primary"
								danger
								disabled={isCommencement || isLoadingOrDischarge}
								onClick={() => deletePortCall()}
								confirmTitle={`Are you sure you want to delete this port call? ${hasDaExpenses != null && 'Port DA\'s attached to this port call will be deleted'}`}
								disabledTooltip={disabledDeleteTooltip}
							>
								Delete port call
							</Button>
						)}
					</div>
				)}
			</div>
		</Flex>
	);
};

export default PortCallSelector;
