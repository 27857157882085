import React, { useState } from 'react';
import {
	Card,
	Empty,
	Grid,
	Modal,
	Space,
} from 'antd';
import Button from '@client/components/Button';
import Table from '@client/components/Table/Table';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import CargoDetailsModal from '@client/screens/cargos/components/CargoDetailsModal/CargoDetailsModal';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import getImportCargoColumns from '../helpers/importCargoColumns';
import { getCargoCardColumns } from '../helpers/cargoCardColumns';
import { useCargoProvider } from '../context/CargoContext';
import styles from './styles/CargosCard.module.css';

const CargosCard = () => {
	const {
		estimate,
		estimateLoading,
		cargos,
		allCargos,
		onUpdateCargo,
		onCreateNewCargoMutator,
		onImportCargoMutator,
		onDeleteCargoMutator,
		chartererOptions,
		portOptions,
		refreshEverything,
	} = useCargoProvider();

	const [showModal, setShowModal] = useState(false);
	const [editingCargoId, setEditingCargoId] = useState<number | null>(null);

	const screens = Grid.useBreakpoint();

	const getModalWidth = () => {
		if (screens.xxl) {
			return '60%';
		}

		if (screens.xs) {
			return '100%';
		}

		return '75%';
	};

	const handleImportCargo = async (cargoId: number) => {
		onImportCargoMutator.mutate(cargoId, {
			onSuccess: () => {
				showSuccessNotification('Cargo added to estimate');
				setShowModal(false);
			},
			onError: (e: Error) => {
				showErrorNotification('Failed to add cargo to estimate', e as Error);
			},
		});
	};

	const columns = getImportCargoColumns({
		onImportCargo: handleImportCargo,
		cargosOnEstimate: estimate?.cargos ?? [],
		chartererOptions,
	});

	const cargoCardColumns = getCargoCardColumns({
		onDeleteCargoMutator,
		onUpdateCargo,
		setEditingCargoId,
		ports: portOptions,
		isTceLocked: estimate?.isTceLocked ?? false,
	});

	return (
		<>
			<Card
				styles={{ body: { padding: 0 } }}
				size="small"
				extra={(
					<Space size="small">
						<Button disabled size="small">Intake Calculator</Button>
						<Button onClick={() => setShowModal(true)} size="small">Import from cargo-book</Button>
						<Button
							onClick={() => onCreateNewCargoMutator.mutate()}
							size="small"
							type="primary"
						>
							New Cargo
						</Button>
					</Space>
				)}
			>
				{cargos.length === 0 ? (
					<div style={{ padding: 16 }}>
						<Empty
							description="Create or import a cargo to estimate the voyage"
							image={manOnBoat}
						/>
					</div>
				) : (
					<Table
						size="small"
						className={styles.table}
						pagination={false}
						columns={cargoCardColumns}
						dataSource={cargos}
						emptyText="No cargos"
					/>
				)}
			</Card>
			<Modal
				title="Add existing cargo"
				open={showModal}
				width={getModalWidth()}
				onCancel={() => setShowModal(false)}
				footer={false}
			>
				<Table
					size="small"
					loading={estimateLoading}
					pagination={false}
					columns={columns}
					dataSource={allCargos.filter((c) => !c.ghost)}
				/>
			</Modal>
			<CargoDetailsModal
				cargoId={editingCargoId}
				setEditingCargoId={setEditingCargoId}
				visible={editingCargoId != null}
				isTceLocked={false}
				refresh={refreshEverything}
			/>
		</>
	);
};

export default CargosCard;
