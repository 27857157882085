import { Moment } from 'moment';
import HIIVoyageExpense from '@shared/hireInvoice/HIIVoyageExpense';
import HIIBunker from '@shared/hireInvoice/HIIBunker';
import HIIFreight from '@shared/hireInvoice/HIIFreight';
import HIIOffHirePeriod from '@shared/hireInvoice/HIIOffHirePeriod';
import HIIOffHireBunker from '@shared/hireInvoice/HIIOffHireBunker';
import HIIOffHireExpenseSubjectToHireDays from '@shared/hireInvoice/HIIOffHireExpenseSubjectToHireDays';
import HIIOffHireAddressCommission from '@shared/hireInvoice/HIIOffHireAddressCommission';
import HIIOffHireBrokerCommission from '@shared/hireInvoice/HIIOffHireBrokerCommission';
import HireInvoiceItem, { TemplateItemChildren } from '@shared/hireInvoice/HireInvoiceItem';
import HIIFreightAddressCommission from '@shared/hireInvoice/HIIFreightAddressCommission';
import HIIFreightBrokerCommission from '@shared/hireInvoice/HIIFreightBrokerCommission';
import HIIDeadfreight from '@shared/hireInvoice/HIIDeadfreight';
import HIIOverage from '@shared/hireInvoice/HIIOverage';
import HIIDespatch from '@shared/hireInvoice/HIIDespatch';
import HIIDemurrage from '@shared/hireInvoice/HIIDemurrage';
import { getCargoPortLaytimeStats } from '@shared/utils/getCargoPortLaytimeStats';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { formatCurrency } from '@shared/utils/currency';
import { getLaytimeInvoiceLineDescriptions } from '@shared/utils/getLaytimeInvoiceLineDescriptions';
import HIIDemurrageBrokerCommission from '@shared/hireInvoice/HIIDemurrageBrokerCommission';
import HIIDemurrageAddressCommission from '@shared/hireInvoice/HIIDemurrageAddressCommission';
import HIIRevenueItem from '@shared/hireInvoice/HIIRevenueItem';
import HIIBbItem from '@shared/hireInvoice/HIIBbItem';
import HIIBbItemAddressCommission from '@shared/hireInvoice/HIIBbItemAddressCommission';
import HIIBbItemBrokerCommission from '@shared/hireInvoice/HIIBbItemBrokerCommission';
import type { HireInvoiceProps } from '@api/models/hire-invoice';
import type { VoyageBunkerProps } from '@api/models/voyage-bunker';
import type { BunkerProps } from '@api/models/bunker';
import type { VoyageOffHirePeriodProps } from '@api/models/voyage-off-hire-period';
import type { ExpenseSubjectToHireDaysProps } from '@api/models/expense-subject-to-hire-days';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureType } from '@api/models/spot-fixture';
import type { CargoStats } from '@api/features/laytime/getCargosAndLaytimes';
import type { VoyageOffHireBunkerProps } from '@api/models/voyage-off-hire-bunker';
import type { Port } from '@api/utils/ports';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import { RevenueItemProps } from '@api/models/revenue-item';
import { OffHirePeriodProps } from '@api/models/off-hire-period';
import { VoyageExpenseReceivableProps } from '@api/models/voyage-expense-receivable';
import { BbItemProps } from '@api/models/bb-item';
import Cargo from '@api/models/cargo';
import Broker from '@api/models/broker';
import BrokerInCargo from '@api/models/broker-in-cargo';
import { calculateTotal } from './math';
import type { Values } from './objectEnums';
import {
	AccountTypes,
	CalculationMethodForExpensesSubjectToHireDays,
	Currencies,
	LaytimeIntervals,
	LaytimeTerms,
} from './constants';

export type ChartererBroker = {
	id: number;
	name: string;
	commission: number;
};

type DndLaytimeProps = {
	getId: () => number;
	cargo: GetFixtureDetailsResponse<SpotFixtureType>['cargos'][number];
	fixtureCurrency: Values<typeof Currencies>;
	laytimeStats: CargoStats;
	hireInvoices: VoyageInvoice[];
	chartererId: number;
	items: (HIIDemurrage | HIIDespatch)[];
};

export const getItemsFromHireInvoices = (hireInvoices: Array<HireInvoiceProps & { items: HireInvoiceItem[] }>) => {
	if (hireInvoices == null) {
		return [];
	}

	const hireInvoiceItems = hireInvoices.reduce<HireInvoiceItem[]>((arr, h) => [...arr, ...h.items], []);

	return hireInvoiceItems;
};

export const flattenHireInvoiceItems = (items: HireInvoiceItem[]) => items?.reduce<HireInvoiceItem[]>((arr, i) => [
	...arr,
	i,
	...(i.children || []),
], []) || [];

export const getFullHireInvoiceItemType = (hireInvoiceItem: HireInvoiceItem, items: HireInvoiceItem[]) => {
	const parent = hireInvoiceItem.getParent(items);

	if (hireInvoiceItem.parentId == null || parent == null) {
		return hireInvoiceItem.itemTypeLabel;
	}

	return `${parent.itemTypeLabel} ➝ ${hireInvoiceItem.itemTypeLabel}`;
};

export const calculateItemsTotal = (items: HireInvoiceItem[], includeChildren = true) => (
	calculateTotal(items, (i) => i.getTotal(items, includeChildren))
);

export const getExpenseHireInvoiceItem = (
	getId: () => number,
	expense: VoyageExpenseReceivableProps,
	fixtureCurrency: Values<typeof Currencies>,
) => (
	new HIIVoyageExpense(getId(), true, {
		voyageExpenseReceivableId: expense.id,
		amount: expense.amount,
		invoiceDescription: expense.itemDescription,
		account: expense.account,
		currency: expense.currency,
		exchangeRate: expense.exchangeRate,
		fixtureCurrency,
		unit: expense.unit,
		quantity: expense.quantity,
		unitPrice: expense.unitPrice,
	})
);

export const getBunkerHireInvoiceItem = (
	getId: () => number,
	bunker: VoyageBunkerProps & BunkerProps,
	fixtureCurrency: Values<typeof Currencies>,
) => (
	new HIIBunker(getId(), true, {
		bunkerId: bunker.id,
		quantity: bunker.quantity,
		pricePerTon: bunker.pricePerTon,
		currency: bunker.currency,
		exchangeRate: bunker.exchangeRate,
		fuelGrade: bunker.fuelGrade,
		type: bunker.type,
		note: bunker.note,
		fixtureCurrency,
	})
);
//
export const getFreightHireInvoiceItem = (
	getId: () => number,
	cargo: GetFixtureDetailsResponse<SpotFixtureType>['cargos'][number],
	fixtureCurrency: Values<typeof Currencies>,
	params: {
		percentage: number;
		blQuantity: number;
		addressCommissionInvoicablePercentage: number;
		brokerCommissionInvoicablePercentage: number;
		maxAddressCommissionPercentage: number;
		maxBrokerCommissionPercentage: number;
	},
) => {
	const freightItem = new HIIFreight(getId(), true, {
		cargoId: cargo.id,
		quantity: params.blQuantity,
		cargoType: cargo.type,
		freightRate: cargo.freightRate,
		freightType: cargo.freightType,
		charterer: cargo.charterer,
		exchangeRate: cargo.exchangeRate,
		currency: cargo.currency,
		unit: cargo.unit,
		fixtureCurrency,
		addressCommission: cargo.addressCommission,
		brokerCommission: cargo.brokerCommission,
		maxPercentage: params.percentage ?? 100,
		percentage: params.percentage ?? 100,
		note: '',
	});

	freightItem.children.push(
		...(cargo.addressCommission > 0 ? [new HIIFreightAddressCommission(getId(), true, {
			cargoId: cargo.id,
			percentage: cargo.addressCommission,
			maxPercentage: params.maxAddressCommissionPercentage,
			invoiceablePercentage: params.addressCommissionInvoicablePercentage,
			fixtureCurrency,
		})] : []),
		...cargo.Brokers
			.filter((b) => {
				return b.BrokerInCargo.paidBy === AccountTypes.CHARTERER;
			})
			.map((c) => new HIIFreightBrokerCommission(getId(), true, {
				cargoId: cargo.id,
				brokerId: c.id,
				brokerName: c.name,
				percentage: c.BrokerInCargo.commission,
				invoiceablePercentage: params.brokerCommissionInvoicablePercentage,
				maxPercentage: params.maxBrokerCommissionPercentage,
				fixtureCurrency,
			})),
	);

	freightItem.children.forEach((c) => {
		c.parentId = freightItem.id;
	});

	return freightItem;
};

export const getDeadfreightOrOverageHireInvoiceItem = (
	getId: () => number,
	cargo: Cargo & { Brokers: Array<Broker & { BrokerInCargo: BrokerInCargo }> },
	fixtureCurrency: Values<typeof Currencies>,
	params: {
		percentage: number;
		quantity: number;
		type: 'deadfreight' | 'overage';
		addressCommissionInvoicablePercentage: number;
		brokerCommissionInvoicablePercentage: number;
		maxAddressCommissionPercentage: number;
		maxBrokerCommissionPercentage: number;
	},
) => {
	const { type } = params;

	if ((type === 'deadfreight' && cargo.deadfreightAmount == null) ||
		(type === 'overage' && cargo.overageAmount == null)
	) {
		return [];
	}

	const attributes = {
		cargoId: cargo.id,
		quantity: params.quantity,
		cargoType: cargo.type,
		freightRate: type === 'deadfreight' ? (cargo.deadfreightAmount ?? 0) : (cargo.overageAmount ?? 0),
		freightType: cargo.freightType,
		charterer: cargo.charterer,
		exchangeRate: cargo.exchangeRate,
		currency: cargo.currency,
		unit: cargo.unit,
		fixtureCurrency,
		addressCommission: cargo.addressCommission,
		brokerCommission: cargo.brokerCommission,
		maxPercentage: params.percentage ?? 100,
		percentage: params.percentage ?? 100,
		note: '',
	};

	let item: HIIDeadfreight | HIIOverage;

	if (type === 'deadfreight') {
		item = new HIIDeadfreight(getId(), true, attributes);
	} else {
		item = new HIIOverage(getId(), true, attributes);
	}

	// Using freight for commissions until issues arise
	item.children.push(
		...(cargo.addressCommission > 0 ? [new HIIFreightAddressCommission(getId(), true, {
			cargoId: cargo.id,
			percentage: cargo.addressCommission,
			invoiceablePercentage: params.addressCommissionInvoicablePercentage,
			maxPercentage: params.maxAddressCommissionPercentage,
			fixtureCurrency,
		})] : []),
		...cargo.Brokers
			.filter((b) => {
				return b.BrokerInCargo.paidBy === AccountTypes.CHARTERER;
			})
			.map((c) => new HIIFreightBrokerCommission(getId(), true, {
				cargoId: cargo.id,
				brokerId: c.id,
				brokerName: c.name,
				percentage: c.BrokerInCargo.commission,
				invoiceablePercentage: params.brokerCommissionInvoicablePercentage,
				maxPercentage: params.maxBrokerCommissionPercentage,
				fixtureCurrency,
			})),
	);

	item.children.forEach((c) => {
		c.parentId = item.id;
	});

	item.children.forEach((c) => {
		c.parentId = item.id;
	});

	return item;
};

type CheckProps = {
	cargo: GetFixtureDetailsResponse<SpotFixtureType>['cargos'][number];
	hireInvoices: VoyageInvoice[];
	chartererId: number;
	cargoPortId?: number;
	type?: 'loading' | 'discharging';
};

const checkIfInvoiced = ({
	cargo,
	hireInvoices,
	chartererId,
	cargoPortId,
	type,
}: CheckProps) => {
	const invoiced = hireInvoices
		.filter((h) => h.chartererId === chartererId)
		.reduce((
			arr: HireInvoiceItem[],
			invoice: VoyageInvoice,
		) => [...arr, ...invoice.items], [])
		.filter(
			(i): i is (HIIDemurrage | HIIDespatch) => i instanceof HIIDemurrage || i instanceof HIIDespatch,
		);

	switch (cargo.laytimeTerms) {
		case LaytimeTerms.REVERSIBLE: {
			return invoiced.length > 0;
		}

		case LaytimeTerms.NON_REVERSIBLE: {
			const demOrDes = invoiced.filter((i) => i.cargoPortId === cargoPortId);

			return demOrDes.length > 0;
		}

		case LaytimeTerms.AVERAGED: {
			const demOrDes = invoiced.filter((i) => i.type === type);

			return demOrDes.length > 0;
		}

		default:
			break;
	}

	return false;
};

const getReversibleDndItems = ({
	getId,
	cargo,
	hireInvoices,
	chartererId,
	items,
	laytimeStats,
	fixtureCurrency,
}: DndLaytimeProps) => {
	if (checkIfInvoiced({ cargo, hireInvoices, chartererId })) {
		return;
	}

	if (laytimeStats.totalReceivable! > 0) {
		const item = getDemurrageInvoiceItem(getId, cargo, fixtureCurrency, {
			action: null,
			amount: laytimeStats.totalReceivable!,
			rate: cargo.demurrage ?? 0,
			hours: Math.abs(laytimeStats.totalTimeDiff) / 60,
			port: null,
			laytimeDetails: getLaytimeInvoiceLineDescriptions(cargo),
		}) as HIIDemurrage;

		items.push(item);
	} else {
		const item = getDespatchInvoiceItem(getId, cargo, fixtureCurrency, {
			action: null,
			amount: laytimeStats.totalReceivable!,
			rate: cargo.despatch ?? 0,
			hours: Math.abs(laytimeStats.totalTimeDiff) / 60,
			port: null,
			laytimeDetails: getLaytimeInvoiceLineDescriptions(cargo),
		}) as HIIDemurrage;

		items.push(item);
	}
};

const getNonReversibleDndItems = ({
	getId,
	cargo,
	hireInvoices,
	chartererId,
	items,

	fixtureCurrency,
}: DndLaytimeProps) => {
	cargo.CargoPorts.forEach((cp) => {
		let item: HIIDemurrage | HIIDespatch | null = null;

		if (checkIfInvoiced({ cargo, hireInvoices, chartererId, cargoPortId: cp.id })) {
			return;
		}

		const {
			totalDemurrage,
			totalDespatch,
			timeGainedOrLost,
			isDem,
		} = getCargoPortLaytimeStats(cp);

		const { action } = splitActionKey(cp.portAndActionKey);

		const laytimeDetails = [{
			section: 'Rate',
			columns: [
				`${formatCurrency(
					isDem ? (cp.demurrage ?? 0) : (cp.despatch ?? 0), cargo.currency,
				)} per ${cp.laytimeInterval === LaytimeIntervals.DAY ? 'day' : 'hours'}`,
			],
		}];

		if (isDem && totalDemurrage > 0) {
			item = getDemurrageInvoiceItem(getId, cargo, fixtureCurrency, {
				action,
				amount: totalDemurrage,
				rate: cp.demurrage ?? 0,
				hours: timeGainedOrLost !== 0 ? timeGainedOrLost / 60 : timeGainedOrLost,
				port: cp.port,
				laytimeDetails,
				cargoPortId: cp.id,
			}) as HIIDemurrage;
		} else if (totalDespatch < 0) {
			item = getDespatchInvoiceItem(getId, cargo, fixtureCurrency, {
				action,
				amount: totalDespatch,
				rate: cp.despatch ?? 0,
				hours: timeGainedOrLost !== 0 ? timeGainedOrLost / 60 : timeGainedOrLost,
				port: cp.port,
				laytimeDetails,
				cargoPortId: cp.id,
			}) as HIIDespatch;
		}

		if (item != null) {
			items.push(item);
		}
	});
};

const getAveragedDndItems = ({
	getId,
	cargo,
	hireInvoices,
	chartererId,
	items,
	laytimeStats,
	fixtureCurrency,
}: DndLaytimeProps) => {
	let loadingItem: HIIDemurrage | HIIDespatch | null = null;
	let dischargingItem: HIIDemurrage | HIIDespatch | null = null;

	const {
		totalDemurrageLoading,
		totalDespatchLoading,
		totalDemurrageDischarging,
		totalDespatchDischarging,
		totalTimeLoading,
		totalTimeDischarging,
		totalReceivable,
	} = laytimeStats;

	// If sum is zero, don't create invoice items
	if (totalReceivable === 0) {
		return;
	}

	const loadingCargo = {
		...cargo,
		CargoPorts: cargo.CargoPorts.filter((c) => {
			const { action } = splitActionKey(c.portAndActionKey);

			return action === 'loading';
		}),
	};

	const dischargingCargo = {
		...cargo,
		CargoPorts: cargo.CargoPorts.filter((c) => {
			const { action } = splitActionKey(c.portAndActionKey);

			return action === 'discharging';
		}),
	};

	if (
		(totalDemurrageLoading ?? 0) > (totalDespatchLoading ?? 0) &&
		!checkIfInvoiced({ cargo, hireInvoices, chartererId, type: 'loading' })
	) {
		const amount = (laytimeStats.totalDemurrageLoading ?? 0) -
			(laytimeStats.totalDespatchLoading ?? 0);

		loadingItem = getDemurrageInvoiceItem(getId, cargo, fixtureCurrency, {
			action: 'loading',
			amount,
			rate: cargo.demurrageLoading ?? 0,
			hours: (totalTimeLoading ?? 0) / 60,
			port: null,
			laytimeDetails: getLaytimeInvoiceLineDescriptions(loadingCargo),
		}) as HIIDemurrage;
		items.push(loadingItem);
	} else if (!checkIfInvoiced({ cargo, hireInvoices, chartererId, type: 'loading' })) {
		// Make despatch negative
		const amount = ((laytimeStats.totalDespatchLoading ?? 0) -
			(laytimeStats.totalDemurrageLoading ?? 0)) * -1;

		loadingItem = getDespatchInvoiceItem(getId, cargo, fixtureCurrency, {
			action: 'loading',
			amount,
			rate: cargo.despatchLoading ?? 0,
			hours: (totalTimeLoading ?? 0) / 60,
			port: null,
			laytimeDetails: getLaytimeInvoiceLineDescriptions(loadingCargo),
		}) as HIIDespatch;
		items.push(loadingItem);
	}

	if (
		(totalDemurrageDischarging ?? 0) > (totalDespatchDischarging ?? 0) &&
		!checkIfInvoiced({ cargo, hireInvoices, chartererId, type: 'discharging' })
	) {
		const amount = (laytimeStats.totalDemurrageDischarging ?? 0) -
			(laytimeStats.totalDespatchDischarging ?? 0);

		dischargingItem = getDemurrageInvoiceItem(getId, cargo, fixtureCurrency, {
			action: 'discharging',
			amount,
			rate: cargo.demurrageDischarge ?? 0,
			hours: (totalTimeDischarging ?? 0) / 60,
			port: null,
			laytimeDetails: getLaytimeInvoiceLineDescriptions(dischargingCargo),
		}) as HIIDemurrage;
		items.push(dischargingItem);
	} else if (!checkIfInvoiced({ cargo, hireInvoices, chartererId, type: 'discharging' })) {
		const amount = ((laytimeStats.totalDespatchDischarging ?? 0) -
			(laytimeStats.totalDemurrageDischarging ?? 0)) * -1;

		dischargingItem = getDespatchInvoiceItem(getId, cargo, fixtureCurrency, {
			action: 'discharging',
			amount,
			rate: cargo.despatchDischarge ?? 0,
			hours: (totalTimeDischarging ?? 0) / 60,
			port: null,
			laytimeDetails: getLaytimeInvoiceLineDescriptions(dischargingCargo),
		}) as HIIDespatch;
		items.push(dischargingItem);
	}
};

export const getDndInvoiceItems = (
	getId: () => number,
	cargo: GetFixtureDetailsResponse<SpotFixtureType>['cargos'][number],
	fixtureCurrency: Values<typeof Currencies>,
	laytimeStats: CargoStats,
	hireInvoices: VoyageInvoice[],
	chartererId: number,
) => {
	const items: (HIIDemurrage | HIIDespatch)[] = [];

	if (laytimeStats.totalReceivable == null || laytimeStats.totalReceivable === 0) {
		return items;
	}

	switch (cargo.laytimeTerms) {
		case LaytimeTerms.NON_REVERSIBLE: {
			getNonReversibleDndItems({
				cargo,
				chartererId,
				fixtureCurrency,
				items,
				hireInvoices,
				getId,
				laytimeStats,
			});
			break;
		}

		case LaytimeTerms.REVERSIBLE: {
			getReversibleDndItems({
				cargo,
				chartererId,
				fixtureCurrency,
				items,
				hireInvoices,
				getId,
				laytimeStats,
			});
			break;
		}

		case LaytimeTerms.AVERAGED: {
			getAveragedDndItems({
				cargo,
				chartererId,
				fixtureCurrency,
				items,
				hireInvoices,
				getId,
				laytimeStats,
			});
			break;
		}

		default:
			break;
	}

	return items;
};

export const getDemurrageInvoiceItem = (
	getId: () => number,
	cargo: Cargo & { Brokers: Array<Broker & { BrokerInCargo: BrokerInCargo }> },
	fixtureCurrency: Values<typeof Currencies>,
	params: {
		action: 'discharging' | 'loading' | null;
		rate: number;
		hours: number;
		amount: number;
		port: Port | null;
		laytimeDetails: TemplateItemChildren | null;
		cargoPortId?: number;
	},
) => {
	const hiiDemurrage = new HIIDemurrage(getId(), true, {
		cargoId: cargo.id,
		cargoPortId: params.cargoPortId ?? null,
		currency: cargo.currency,
		fixtureCurrency,
		charterer: cargo.charterer,
		terms: cargo.laytimeTerms!,
		hours: params.hours,
		rate: params.rate,
		laytimeDetails: params.laytimeDetails,
		amount: params.amount,
		type: params.action,
		port: params.port,
	});

	hiiDemurrage.children.push(
		...(cargo.addressCommission > 0 ? [new HIIDemurrageAddressCommission(getId(), true, {
			percentage: cargo.addressCommission,
			fixtureCurrency,
			cargoId: cargo.id,
		})] : []),
		...cargo.Brokers
			.filter((b) => {
				return b.BrokerInCargo.paidBy === AccountTypes.CHARTERER;
			})
			.map((c) => new HIIDemurrageBrokerCommission(getId(), true, {
				cargoId: cargo.id,
				brokerId: c.id,
				brokerName: c.name,
				fixtureCurrency,
				percentage: c.BrokerInCargo.commission,
			})),
	);

	hiiDemurrage.children.forEach((c) => {
		c.parentId = hiiDemurrage.id;
	});

	return hiiDemurrage;
};

export const getDespatchInvoiceItem = (
	getId: () => number,
	cargo: Cargo & { Brokers: Array<Broker & { BrokerInCargo: BrokerInCargo }> },
	fixtureCurrency: Values<typeof Currencies>,
	params: {
		action: 'discharging' | 'loading' | null;
		rate: number;
		hours: number;
		amount: number;
		port: Port | null;
		laytimeDetails: TemplateItemChildren | null;
		cargoPortId?: number;
	},
) => {
	return new HIIDespatch(getId(), true, {
		cargoId: cargo.id,
		cargoPortId: params.cargoPortId ?? null,
		currency: cargo.currency,
		fixtureCurrency,
		charterer: cargo.charterer,
		terms: cargo.laytimeTerms!,
		hours: params.hours,
		rate: params.rate,
		laytimeDetails: params.laytimeDetails,
		amount: params.amount,
		type: params.action,
		port: params.port,
	});
};

export const getOffHireHireInvoiceItem = (
	getId: () => number,
	offHire: VoyageOffHirePeriodProps & OffHirePeriodProps & {
		bunkers: Array<VoyageOffHireBunkerProps & BunkerProps>;
	},
	fixtureCurrency: Values<typeof Currencies>,
	params: {
		addressCommission: number | null;
		brokers: ChartererBroker[];
		expensesSubjectToHireDays: ExpenseSubjectToHireDaysProps[];
		calculationMethodForExpensesSubjectToHireDays: Values<typeof CalculationMethodForExpensesSubjectToHireDays>;
		hireDaysDecimals: number;
	},
) => {
	const offHireItem = new HIIOffHirePeriod(getId(), true, {
		voyageOffHirePeriodId: offHire.id,
		hirePerDay: offHire.hirePerDay,
		startTime: offHire.startTime,
		endTime: offHire.endTime,
		invoiceNote: offHire.invoiceNote,
		type: offHire.type,
		percentageForOwnersAccount: offHire.percentageForOwnersAccount,
		fixtureCurrency,
		hireDaysDecimals: params.hireDaysDecimals,
	});

	offHireItem.children.push(
		...(params.addressCommission ? [new HIIOffHireAddressCommission(getId(), true, {
			percentage: params.addressCommission,
			fixtureCurrency,
		})] : []),
		...params.brokers.map((c) => new HIIOffHireBrokerCommission(getId(), true, {
			brokerId: c.id,
			brokerName: c.name,
			percentage: c.commission,
			fixtureCurrency,
		})),
		...params.expensesSubjectToHireDays.map((e) => new HIIOffHireExpenseSubjectToHireDays(getId(), true, {
			fixtureCurrency,
			name: e.name,
			amount: e.amount,
			interval: e.interval,
			calculationMethodForExpensesSubjectToHireDays: params.calculationMethodForExpensesSubjectToHireDays,
		})),
		...offHire.bunkers.map((bunker) => new HIIOffHireBunker(getId(), true, {
			bunkerId: bunker.id,
			quantity: bunker.quantity,
			pricePerTon: bunker.pricePerTon,
			fuelGrade: bunker.fuelGrade,
			currency: bunker.currency,
			exchangeRate: bunker.exchangeRate,
			fixtureCurrency,
		})),
	);

	offHireItem.children.forEach((c) => {
		c.parentId = offHireItem.id;
	});

	return offHireItem;
};

export const getRevenueItemHireInvoiceItem = (
	getId: () => number,
	revenueItem: RevenueItemProps,
	cargo: GetFixtureDetailsResponse<SpotFixtureType>['cargos'][number],
	fixtureCurrency: Values<typeof Currencies>,
) => {
	const item = new HIIRevenueItem(getId(), true, {
		cargoId: revenueItem.cargoId,
		revenueItemId: revenueItem.id,
		voyageId: revenueItem.voyageId,
		amount: revenueItem.amount,
		itemDescription: revenueItem.itemDescription,
		currency: revenueItem.currency,
		exchangeRate: revenueItem.exchangeRate,
		note: revenueItem.note || null,
		chartererId: revenueItem.chartererId,
		subjectToCommissions: revenueItem.subjectToCommissions,
		fixtureCurrency,
	});

	if (item.subjectToCommissions) {
		item.children.push(
			...(cargo.addressCommission > 0 ? [new HIIDemurrageAddressCommission(getId(), true, {
				cargoId: cargo.id,
				percentage: cargo.addressCommission,
				fixtureCurrency,
			})] : []),
			...cargo.Brokers
				.filter((b) => {
					return b.BrokerInCargo.paidBy === AccountTypes.CHARTERER;
				})
				.map((c) => new HIIDemurrageBrokerCommission(getId(), true, {
					cargoId: cargo.id,
					brokerId: c.id,
					brokerName: c.name,
					percentage: c.BrokerInCargo.commission,
					fixtureCurrency,
				})),
		);

		item.children.forEach((c) => {
			c.parentId = item.id;
		});
	}

	return item;
};

export const getBbHireInvoiceItem = (
	getId: () => number,
	bbItem: BbItemProps,
	fixtureCurrency: Values<typeof Currencies>,
	from: string | Moment,
	to: string | Moment,
	params: {
		addressCommission: number | null;
		daysDecimals: number;
		brokers: ChartererBroker[];
	},
	useUTC?: boolean,
) => {
	if (!bbItem.id) {
		throw new Error('bbItem.id is null or undefined');
	}

	const item = new HIIBbItem(getId(), true, {
		bbItemId: bbItem.id,
		amount: bbItem.amount,
		pcm: bbItem.pcm,
		fixtureCurrency,
		brokerPaidByChartererIds: bbItem.brokerPaidByChartererIds,
		brokerPaidByChartererPercentages: bbItem.brokerPaidByChartererPercentages,
		brokerPaidByOwnerIds: bbItem.brokerPaidByOwnerIds,
		brokerPaidByOwnerPercentages: bbItem.brokerPaidByOwnerPercentages,
		addComm: bbItem.addComm,
		type: bbItem.type,
		from,
		to,
		useUTC,
		daysDecimals: params.daysDecimals,
	});

	item.children.push(
		...(params.addressCommission && params.addressCommission > 0 ? [new HIIBbItemAddressCommission(getId(), true, {
			bbItemId: bbItem.id,
			fixtureCurrency,
			percentage: params.addressCommission,
		})] : []),
		...params.brokers.map((c) => new HIIBbItemBrokerCommission(getId(), true, {
			bbItemId: bbItem.id,
			brokerId: c.id,
			brokerName: c.name,
			fixtureCurrency,
			percentage: c.commission,
		})),
	);

	item.children.forEach((c) => {
		c.parentId = item.id;
	});

	return item;
};
