import React, {
	useCallback,
	useMemo,
} from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { faInbox } from '@fortawesome/pro-light-svg-icons';
import { Moment } from 'moment';
import {
	Tag,
	Grid,
} from 'antd';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Currencies,
	DATE,
	EstimateStatus,
	FixtureTypes,
} from '@shared/utils/constants';
import {
	formatDate,
	toMoment,
} from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import { round } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	copyEstimate,
	createEstimate,
	getEstimateGroups,
	getVessels,
} from '@client/lib/api';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';
import { FormPopoverProps } from '@client/components/FormPopover';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import Button from '@client/components/Button';
import VesselDescription from '@client/components/VesselDescription';
import getVesselOptions from '@client/utils/getVesselOptions';
import { stringSetToSortedList } from '@client/utils/stringSetToSortedList';
import styles from './EstimatesListPage.module.css';

type EstimateOverviewTableRow = {
	id: number;
	estimateGroupId: number;
	firstEstimateId: number;
	name: string;
	vesselName: string | null;
	vesselFlag: string | null;
	createdAt: Moment;
	tce: number | null;
	currency: Values<typeof Currencies>;
	firstLoadPortName: string | null;
	lastDischargePortName: string | null;
	commenceDate: Moment | null;
	charters: string | null;
	brokers: string | null;
};

type CreateEstimateFormData = {
	name: string;
	currency: Values<typeof Currencies>;
	vesselId?: number;
}

const EstimatesListPage: React.FC = () => {
	const [estimateGroups, refresh, error, loading] = useFetchedState(getEstimateGroups);

	const [vessels] = useFetchedState(getVessels);

	const screens = Grid.useBreakpoint();

	const filterMaps = useMemo(() => {
		const loadPorts = new Set<string>();
		const charters = new Set<string>();
		const vesselsInEstimate = new Set<string>();

		estimateGroups?.forEach((item) => {
			if (item.firstLoadPortName != null) {
				loadPorts.add(item.firstLoadPortName);
			}

			if (item.charters != null) {
				charters.add(item.charters);
			}

			if (item.vesselName != null) {
				vesselsInEstimate.add(item.vesselName);
			}
		});

		return {
			loadPorts: stringSetToSortedList<string>(loadPorts),
			charters: stringSetToSortedList<string>(charters),
			vesselsInEstimate: stringSetToSortedList<string>(vesselsInEstimate),
		};
	}, [estimateGroups]);

	const estimateRows: EstimateOverviewTableRow[] = useMemo(() => (
		estimateGroups?.map((e) => ({
			id: e.id,
			firstEstimateId: e.firstEstimateId,
			estimateGroupId: e.id,
			name: e.groupName,
			vesselName: e.vesselName ?? null,
			vesselFlag: e.vesselFlag,
			createdAt: toMoment(e.createdAt),
			tce: e?.tce ?? null,
			currency: e.currency ?? null,
			status: e.status ?? null,
			firstLoadPortName: e.firstLoadPortName ?? null,
			lastDischargePortName: e.lastDischargePortName ?? null,
			commenceDate: e.commenceDate ?? null,
			completeDate: e.completeDate ?? null,
			charters: e.charters ?? null,
			brokers: e.brokers ?? null,
		})) ?? []
	), [estimateGroups]);

	const getStatusColor = (status: EstimateStatus) => {
		if (status === EstimateStatus.Available) {
			return 'green';
		}

		if (status === EstimateStatus.Working) {
			return 'blue';
		}

		if (status === EstimateStatus.Subjects) {
			return 'orange';
		}

		return 'red';
	};

	const columns: ColumnsType<EstimateOverviewTableRow> = useMemo(() => [
		// Estimate ID
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: 60,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Tag color={getStatusColor(status)}>
					{EstimateStatus[status]}
				</Tag>
			),
			width: 120,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Vessel',
			dataIndex: 'vesselName',
			key: 'vesselName',
			sorter: standardSort('vesselName'),
			filterMultiple: true,
			filters: filterMaps.vesselsInEstimate.map((vessel) => ({
				text: capitalize(vessel),
				value: vessel,
			})),
			render: (_, est) => (
				<VesselDescription
					name={est.vesselName ?? '—'}
					flag={est.vesselFlag}
				/>
			),
		},
		{
			title: '1st Loadport',
			dataIndex: 'firstLoadPortName',
			key: 'firstLoadPortName',
			filterMultiple: true,
			filters: filterMaps.loadPorts.filter((p) => p !== '-').map((port) => ({
				text: capitalize(port),
				value: port,
			})),
			onFilter: (value, record) => record.firstLoadPortName === value,
			width: 200,
		},
		{
			title: 'Last Disport',
			dataIndex: 'lastDischargePortName',
			key: 'lastDischargePortName',
			width: 200,
		},
		{
			title: 'Commence Date',
			dataIndex: 'commenceDate',
			key: 'commenceDate',
			render: (date: Moment) => (date == null ? '-' : formatDate(date, DATE, false)),
			width: 150,
		},
		{
			title: 'Complete Date',
			dataIndex: 'completeDate',
			key: 'completeDate',
			render: (date: Moment) => (date == null ? '-' : formatDate(date, DATE, false)),
			width: 150,
		},
		{
			title: 'Charterers',
			dataIndex: 'charters',
			key: 'charters',
			filterMultiple: true,
			filters: filterMaps.charters.filter((p) => p !== '-').map((charter) => ({
				text: capitalize(charter),
				value: charter,
			})),
			onFilter: (value, record) => record.charters === value,
			width: 150,
		},
		{
			title: 'Brokers',
			dataIndex: 'brokers',
			key: 'brokers',
			width: 150,
		},
		{
			title: 'Creation Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			sorter: standardSort('createdAt'),
			render: renderDate(DATE),
			width: 150,
		},
		{
			title: 'TCE (per day)',
			dataIndex: 'tce',
			key: 'tce',
			sorter: standardSort('tce'),
			render: (value, row) => {
				if (value == null) {
					return '—';
				}

				const roundedValue = round(value, 0);

				if (row.currency == null) {
					return roundedValue;
				}

				return formatCurrency(roundedValue, row.currency);
			},
			width: 150,
		},
		{
			title: 'Action',
			render: (_, row) => (
				<Button
					onClick={async (e: any) => {
						e.stopPropagation();
						await copyEstimate(row.id);
						await refresh();
					}}
					icon={(
						<FontAwesomeIcon
							className={styles.icon}
							icon={faCopy as IconProp}
						/>
					)}
				>
					<b>Copy</b>
				</Button>
			),
			width: 70,
		},
	], [filterMaps.loadPorts, filterMaps.charters, filterMaps.vesselsInEstimate, refresh]);

	const submitCreateEstimateForm = useCallback(async (values: CreateEstimateFormData) => {
		const estimate = await createEstimate(values);
		showSuccessNotification(
			'Estimate created',
			'The estimate was successfully created',
		);

		return estimate;
	}, []);

	const formFields: FormPopoverProps<CreateEstimateFormData>['fields'] = useMemo(() => [
		{
			label: 'Name',
			name: 'name',
			type: 'text',
			required: true,
		},
		{
			label: 'Vessel',
			name: 'vesselId',
			type: 'select',
			required: false,
			options: (vessels != null ? [
				{
					label: 'No vessel',
					value: -1,
				},
				{
					label: 'My vessels',
					options: getVesselOptions(vessels, 'myVessels'),
				},
				{
					label: 'Market vessels',
					options: getVesselOptions(vessels, 'marketVessels'),
				},
			] : []),
			inputProps: {
				showSearch: true,
			},
		},
		{
			label: 'Currency',
			name: 'currency',
			type: 'select',
			required: true,
			options: Object.values(Currencies).map((c) => ({
				label: c,
				value: c,
			})).sort(standardSort('label')),
			inputProps: {
				showSearch: true,
			},
		},
	], [vessels]);

	return (
		<ListTableScreen<EstimateOverviewTableRow, CreateEstimateFormData>
			title="VC-Out Estimates"
			rootPageTitle="Estimate - VC"
			columns={columns}
			loading={loading}
			emptyTitle="No estimates found"
			emptyIcon={faInbox}
			error={error}
			data={estimateRows}
			tableProps={{ size: 'small', useCards: screens.xs, scroll: { x: 'max-content', y: 'max-content' } }}
			formFields={formFields}
			formProps={{
				initialValues: {
					currency: Currencies.USD,
				},
			}}
			formButtonText="Create estimate"
			onFormSubmit={submitCreateEstimateForm}
			getRowHref={
				(row) => Links.Fixtures[FixtureTypes.SPOT].Details.get(
					row.estimateGroupId,
					row.firstEstimateId ?? row.id,
				)
			}
			redirectOnSubmit
			showEmptyArrow
		/>
	);
};

export default EstimatesListPage;

