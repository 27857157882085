import React, {
	useEffect,
	useState,
} from 'react';
import {
	CapexOpexInput,
	ContractNamingConventions,
} from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getUserData,
	updateOrganizationDetails,
} from '@client/lib/api';
import styles from './GeneralSettings.module.css';

const GeneralSettings = () => {
	const [userData, refreshUserData] = useFetchedState(getUserData);
	const [warning, setWarning] = useState(userData?.capexOpexInput);

	const ConventionDescriptions = {
		[ContractNamingConventions.LEGACY]: 'ex. ATH-01',
		[ContractNamingConventions.DEFAULT]: 'ex. ATH-0001',
		[ContractNamingConventions.FREE]: 'Anything you want',
	} as const;

	useEffect(() => {
		if (userData?.capexOpexInput != null && warning == null) {
			setWarning(userData.capexOpexInput);
		}
	}, [userData, warning]);

	return (
		<EditableDetails
			className={styles.details}
			labelWidth={300}
			items={[
				{
					key: 'hireDaysDecimals',
					label: 'Maximum hire days decimals',
					editable: true,
					type: 'number',
					value: userData?.hireDaysDecimals ?? 6,
				},
				{
					label: 'Capex & Opex input',
					key: 'capexOpexInput',
					value: userData?.capexOpexInput ?? null,
					editable: true,
					// @ts-ignore
					type: 'select',
					onChange: (value: CapexOpexInput) => setWarning(value),
					options: Object.values(CapexOpexInput).map((value) => ({
						label: capitalize(value),
						value,
					})),
				},
				{
					label: 'Global Accrual Mapping',
					key: 'globalAccrualMapping',
					// @ts-ignore
					render: (value: boolean) => (value ? 'Enabled' : 'Disabled'),
					value: userData?.globalAccrualMapping ?? true,
					editable: true,
					type: 'switch',
				},
				{
					label: 'Contract naming convention',
					key: 'contractNamingConvention',
					value: userData?.contractNamingConvention ?? null,
					editable: true,
					// @ts-ignore
					type: 'select',
					options: Object.values(ContractNamingConventions).map((value) => ({
						label: `${capitalize(value)} (${ConventionDescriptions[value]})`,
						value,
					})),
				},
				{
					label: 'Enable Mediterranean ECA',
					key: 'useMedEca',
					render: () => (userData?.useMedEca ? 'Enabled' : 'Disabled'),
					value: userData?.useMedEca ?? true,
					editable: true,
					type: 'switch',
				},
			]}
			onSave={async (values) => {
				try {
					await updateOrganizationDetails(values);
					await refreshUserData();
				} catch (e) {
					showErrorNotification('Cannot update settings');
				}
			}}
			confirmTitle={warning === userData?.capexOpexInput ? null : 'All capex & opex figures for all vessels will be deleted and will need to be re-entered'}
		/>
	);
};

export default GeneralSettings;
