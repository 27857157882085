import HIIFreight from '@shared/hireInvoice/HIIFreight';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import HIIDeadfreight from '@shared/hireInvoice/HIIDeadfreight';
import getFDDNumbers from '@shared/utils/getFDDNumbers';
import HIIOverage from '@shared/hireInvoice/HIIOverage';
import getLaytimeStats from '@shared/utils/laytimeStats/getLaytimeStats';
import HIIDemurrage from '@shared/hireInvoice/HIIDemurrage';
import HIIDespatch from '@shared/hireInvoice/HIIDespatch';
import HIIVoyageExpense from '@shared/hireInvoice/HIIVoyageExpense';
import HIIRevenueItem from '@shared/hireInvoice/HIIRevenueItem';
import HIIFreightAddressCommission from '@shared/hireInvoice/HIIFreightAddressCommission';
import HIIFreightBrokerCommission from '@shared/hireInvoice/HIIFreightBrokerCommission';
import { BankAccountProps } from '@api/models/bank-account';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureType } from '@api/models/spot-fixture';
import { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import { VoyageExpenseReceivableProps } from '@api/models/voyage-expense-receivable';
import { RevenueItemProps } from '@api/models/revenue-item';
import { Values } from './objectEnums';
import { calculateTotal } from './math';
import {
	AccountTypes,
	FixtureTypes,
	HireInvoiceItemStates,
} from './constants';
import {
	getFreightHireInvoiceItem,
	getDeadfreightOrOverageHireInvoiceItem,
	getDndInvoiceItems,
	getExpenseHireInvoiceItem,
	getRevenueItemHireInvoiceItem,
} from './hireInvoice';
import filterHireInvoiceItemsByState from './filterHireInvoiceItemsByState';

let _id = -1;

const getId = () => {
	const newId = _id;

	_id -= 1;

	return newId;
};

export type WithHIIState<T> = T & { state: Values<typeof HireInvoiceItemStates> };

export type SpotParams = {
	hireInvoices: VoyageInvoice[];
	addressCommission: number | null;
	brokers: Array<{
		id: number;
		name: string;
		commission: number;
		paidBy: Values<typeof AccountTypes>;
	}>;
	cargos: GetFixtureDetailsResponse<SpotFixtureType>['cargos'];
	selectedBankAccount: BankAccountProps;
	voyageExpenseReceivables: WithHIIState<VoyageExpenseReceivableProps>[];
	revenueItems: WithHIIState<RevenueItemProps>[];
	chartererId: number;
	type: typeof FixtureTypes.SPOT;
};

const getAvailableFreightInvoiceItems = (params: SpotParams) => {
	const {
		hireInvoices,
		selectedBankAccount,
		cargos,
		chartererId,
		voyageExpenseReceivables,
		revenueItems,
	} = params;

	const cargoResults: (
		HIIFreight | HIIDeadfreight | HIIOverage | HIIDemurrage | HIIDespatch | HIIVoyageExpense | HIIRevenueItem
	)[] = [];

	if (!Array.isArray(hireInvoices)) {
		return cargoResults;
	}

	const invoicedFreights = hireInvoices
		.reduce((
			arr: HireInvoiceItem[],
			invoice: VoyageInvoice,
		) => [...arr, ...invoice.items], [])
		.filter((i): i is HIIFreight => i instanceof HIIFreight);

	const invoicedDeadfreights = hireInvoices
		.reduce((
			arr: HireInvoiceItem[],
			invoice: VoyageInvoice,
		) => [...arr, ...invoice.items], [])
		.filter((i): i is HIIDeadfreight => i instanceof HIIDeadfreight);

	const invoicedOverages = hireInvoices
		.reduce((
			arr: HireInvoiceItem[],
			invoice: VoyageInvoice,
		) => [...arr, ...invoice.items], [])
		.filter((i): i is HIIOverage => i instanceof HIIOverage);

	filterHireInvoiceItemsByState(voyageExpenseReceivables, HireInvoiceItemStates.PENDING)
		.filter((c) => c.chartererId === chartererId && !c.estimated)
		.forEach((expense) => cargoResults.push(getExpenseHireInvoiceItem(
			getId,
			expense,
			selectedBankAccount.currency,
		)));

	filterHireInvoiceItemsByState(revenueItems, HireInvoiceItemStates.PENDING)
		.filter((c) => c.chartererId === chartererId && !c.estimated)
		.forEach((revenueItem) => {
			const cargo = cargos.filter((c) => c.id === revenueItem.cargoId)[0];
			cargoResults.push(getRevenueItemHireInvoiceItem(
				getId,
				revenueItem,
				cargo,
				selectedBankAccount.currency,
			));
		});

	cargos
		.filter((c) => c.charterer === chartererId)
		.forEach((cargo) => {
			const fddNumbers = getFDDNumbers(cargo);
			const laytimeStats = getLaytimeStats(cargo);
			let freight;
			let deadfreight;
			let overage;

			const invoicedFreightPercentage = calculateTotal(
				invoicedFreights.filter((i) => i.cargoId === cargo.id),
				(i) => i.percentage,
			);

			const relevantFreight = invoicedFreights.filter((i) => i.cargoId === cargo.id);

			// Freight
			const relevantFreightAddressCommission = relevantFreight.map((i) => i.children.filter(
				(v) => v instanceof HIIFreightAddressCommission,
			))
				.flat() as HIIFreightAddressCommission[];

			const invoicedFreightAddressCommission = calculateTotal(
				relevantFreightAddressCommission, (i) => i.invoiceablePercentage,
			);

			const relevantFreightBrokerCommission = relevantFreight
				.map((i) => i.children.filter((v) => v instanceof HIIFreightBrokerCommission))
				.flat() as unknown as HIIFreightAddressCommission[];

			const invoicedFreightBrokerCommission = calculateTotal(
				relevantFreightBrokerCommission, (i) => i.invoiceablePercentage,
			);

			// Deadfreight
			const relevantDeadFreight = invoicedDeadfreights.filter((i) => i.cargoId === cargo.id);
			const relevantDeadfreightBrokerCommission = relevantDeadFreight
				.map((i) => i.children.filter((v) => v instanceof HIIFreightBrokerCommission))
				.flat() as unknown as HIIFreightAddressCommission[];

			const invoicedDeadfreightBrokerCommission = calculateTotal(
				relevantDeadfreightBrokerCommission, (i) => i.invoiceablePercentage,
			);

			const relevantDeadfreightAddressCommission = relevantDeadFreight.map((i) => i.children.filter(
				(v) => v instanceof HIIFreightAddressCommission,
			))
				.flat() as HIIFreightAddressCommission[];

			const invoicedDeadfreightAddressCommission = calculateTotal(
				relevantDeadfreightAddressCommission, (i) => i.invoiceablePercentage,
			);

			// Overage
			const relevantOverage = invoicedOverages.filter((i) => i.cargoId === cargo.id);
			const relevantOverageBrokerCommission = relevantOverage
				.map((i) => i.children.filter((v) => v instanceof HIIFreightBrokerCommission))
				.flat() as unknown as HIIFreightAddressCommission[];

			const invoicedOverageBrokerCommission = calculateTotal(
				relevantOverageBrokerCommission, (i) => i.invoiceablePercentage,
			);

			const relevantOverageAddressCommission = relevantOverage.map((i) => i.children.filter(
				(v) => v instanceof HIIFreightAddressCommission,
			))
				.flat() as HIIFreightAddressCommission[];

			const invoicedOverageAddressCommission = calculateTotal(
				relevantOverageAddressCommission, (i) => i.invoiceablePercentage,
			);

			const invoicedDeadfreightPercentage = calculateTotal(
				invoicedDeadfreights.filter((i) => i.cargoId === cargo.id),
				(i) => i.percentage,
			);

			const invoicedOveragePercentage = calculateTotal(
				invoicedOverages.filter((i) => i.cargoId === cargo.id),
				(i) => i.percentage,
			);

			if ((100 - invoicedFreightPercentage) > 0) {
				freight = getFreightHireInvoiceItem(getId, cargo, selectedBankAccount.currency, {
					percentage: 100 - invoicedFreightPercentage,
					blQuantity: fddNumbers.totalFreightAmount,
					addressCommissionInvoicablePercentage: 100 - invoicedFreightAddressCommission,
					brokerCommissionInvoicablePercentage: 100 - invoicedFreightBrokerCommission,
					maxAddressCommissionPercentage: 100 - invoicedFreightAddressCommission,
					maxBrokerCommissionPercentage: 100 - invoicedFreightBrokerCommission,
				}) as HIIFreight;
			}

			if ((100 - invoicedDeadfreightPercentage) > 0) {
				deadfreight = getDeadfreightOrOverageHireInvoiceItem(getId, cargo, selectedBankAccount.currency, {
					percentage: 100 - invoicedDeadfreightPercentage,
					quantity: fddNumbers.totalDeadfreightAmount,
					addressCommissionInvoicablePercentage: 100 - invoicedDeadfreightAddressCommission,
					brokerCommissionInvoicablePercentage: 100 - invoicedDeadfreightBrokerCommission,
					maxAddressCommissionPercentage: 100 - invoicedDeadfreightAddressCommission,
					maxBrokerCommissionPercentage: 100 - invoicedDeadfreightBrokerCommission,
					type: 'deadfreight',
				}) as HIIDeadfreight;
			}

			if ((100 - invoicedOveragePercentage) > 0) {
				overage = getDeadfreightOrOverageHireInvoiceItem(getId, cargo, selectedBankAccount.currency, {
					percentage: 100 - invoicedOveragePercentage,
					quantity: fddNumbers.totalOverageAmount,
					addressCommissionInvoicablePercentage: 100 - invoicedOverageAddressCommission,
					brokerCommissionInvoicablePercentage: 100 - invoicedOverageBrokerCommission,
					maxAddressCommissionPercentage: 100 - invoicedOverageAddressCommission,
					maxBrokerCommissionPercentage: 100 - invoicedOverageBrokerCommission,
					type: 'overage',
				}) as HIIOverage;
			}

			const dnd = getDndInvoiceItems(
				getId,
				cargo,
				selectedBankAccount.currency,
				laytimeStats,
				hireInvoices,
				chartererId,
			);

			cargoResults.push(
				...(freight != null ? [freight] : []),
				...(deadfreight != null ? [deadfreight] : []),
				...(overage != null ? [overage] : []),
				...(dnd != null ? dnd : []),
			);
		});

	return cargoResults;
};

export default getAvailableFreightInvoiceItems;
