export const CANAL_TRANSIT_REGEX = /isCanalTransit=(true|false)/;
export const ACTUAL_DATE_REGEX = /isActualDate=(true|false)/;

export const extractBooleanFromKey = (key: string | undefined, pattern: RegExp): boolean => {
	const match = key?.match(pattern);

	return match ? match[1] === 'true' : false;
};

export const sortByFuelGrade = <T extends { fuelGrade: string }>(
	items: T[],
	prioritizeVLSFO: boolean = true,
): T[] => [...items].sort((a, b) => {
	if (prioritizeVLSFO) {
		if (a.fuelGrade === 'VLSFO') {
			return -1;
		}

		if (b.fuelGrade === 'VLSFO') {
			return 1;
		}
	}

	return a.fuelGrade.localeCompare(b.fuelGrade);
});
