import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	faClock,
	faContactBook,
	faFileAlt,
	faGlobe,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Form } from 'antd';
import { Values } from '@shared/utils/objectEnums';
import {
	AccountingSystemProviders,
	AccountingSystemStrategies,
} from '@shared/utils/constants';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	disconnectAccountingSystem,
	getConnectionAndMapping,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import CounterpartyMappingTab from './CounterpartyMappingTab';
import SupplierMappingTab from './SupplierMappingTab';
import PeriodCloseMappingTab from './PeriodCloseMappingTab';
import AccrualTypeMappingTab from './AccrualTypeMappingTab';
import CodatConnectionDetails from './CodatConnectionDetails';
import ConnectionDetails from './ConnectionDetails';

const MappingDetails = ({
	strategy,
	provider,
	refreshItems,
	selectedItemId,
}: {
	strategy: Values<typeof AccountingSystemStrategies>;
	provider: Values<typeof AccountingSystemProviders>;
	refreshItems: () => void;
	selectedItemId?: number | null;
}) => {
	const [form] = Form.useForm();
	const [connection, refreshConnection] = useFetchedState(async () => {
		if (strategy == null || selectedItemId == null) {
			return undefined;
		}

		const result = await getConnectionAndMapping(strategy, selectedItemId);

		if (typeof result === 'string') {
			return undefined;
		}

		return result;
	}, [strategy, selectedItemId]);

	const onRefresh = useCallback(async () => {
		refreshItems();
		refreshConnection();
	}, [refreshItems, refreshConnection]);

	const onDisconnect = useCallback(async () => {
		const connectionId = connection?.id;

		if (connectionId == null) {
			return;
		}

		try {
			await disconnectAccountingSystem(connectionId);
			await onRefresh();
			showSuccessNotification('Accounting system disconnected');
			form.resetFields();
		} catch (e) {
			showErrorNotification('Could not disconnect accounting system', e as Error);
		}
	}, [connection, onRefresh, form]);

	const tabs = useMemo(() => {
		return [

			{
				title: 'Connection',
				key: 'connection',
				icon: faGlobe as IconProp,
				render: () => (
					provider === AccountingSystemProviders.CODAT ?
						(
							<CodatConnectionDetails
								selectedItemId={selectedItemId}
								connection={connection}
								onDisconnect={onDisconnect}
								refreshConnection={onRefresh}
							/>
						) : (
							<ConnectionDetails
								selectedItemId={selectedItemId}
								connection={connection}
								onDisconnect={onDisconnect}
								form={form}
								refreshConnection={onRefresh}
							/>
						)),
			},
			{
				disabled: connection == null,
				title: 'Counterparties',
				key: 'counterparties',
				icon: faContactBook as IconProp,
				render: () => (
					<CounterpartyMappingTab
						refreshConnection={refreshConnection}
						connection={connection}
					/>
				),
			},
			{
				disabled: connection == null,
				title: 'Suppliers',
				key: 'suppliers',
				icon: faContactBook as IconProp,
				render: () => (
					<SupplierMappingTab connection={connection} refreshConnection={refreshConnection} />
				),
			},
			{
				disabled: connection == null,
				title: 'Period Close',
				key: 'periodClose',
				icon: faClock as IconProp,
				render: () => (
					<PeriodCloseMappingTab connection={connection} refreshConnection={refreshConnection} />
				),
			}, {
				disabled: connection == null,
				title: 'Accruals',
				key: 'accruals',
				icon: faFileAlt as IconProp,
				render: () => (
					<AccrualTypeMappingTab connection={connection} refreshConnection={refreshConnection} />
				),
			}];
	}, [refreshConnection, onRefresh, selectedItemId, form, provider, onDisconnect, connection]);

	return (
		<DetailsTabScreen
			rootPageTitle="Accounting Mapping"
			tabs={tabs}
			defaultTabKey="connection"
		/>
	);
};

export default MappingDetails;
