import { FixtureStates } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

export const colors = [
	'#eb2f96',
	'#f5222d',
	'#fa541c',
	'#fa8c16',
	'#faad14',
	'#a0d911',
	'#52c41a',
	'#13c2c2',
	'#1677ff',
	'#2f54eb',
	'#722ed1',
];

export const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

export const getStringModuloColor = (str: string) => {
	const value = str.split('').reduce((acc, _char, i) => acc + str.charCodeAt(i), 0);
	const colorIndex = value % colors.length;

	return colors[colorIndex];
};

export const getFixtureStateColor = (fixtureState: Values<typeof FixtureStates>) => {
	switch (fixtureState) {
		case FixtureStates.PENDING:
			return 'magenta';
		case FixtureStates.FIXED:
			return 'cyan';
		case FixtureStates.COMMENCED:
			return 'geekblue';
		case FixtureStates.COMPLETED:
			return 'black';

		default:
			return 'black';
	}
};
