import {
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import { formatPercentage } from '@shared/utils/formatPercentage';
import calculateTotalFreight from '@shared/utils/calculateTotalFreight';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

class HIIFreightBrokerCommission extends HireInvoiceItem {
	static get itemType() {
		return 'freightBrokerCommission';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.brokerId = params.brokerId;
		this.cargoId = params.cargoId;
		this.brokerName = params.brokerName;
		this.percentage = params.percentage;
		this.invoiceablePercentage = params.invoiceablePercentage;
		this.maxPercentage = params.maxPercentage;

		this._showTotal = true;
		this.itemTypeLabel = 'Broker Commission';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;
		this.pnlGroup = PnlGroups.BROKER_COMMISSIONS;

		this._fields = {};
	}

	_calculateTotal(invoiceItems, convertCurrency) {
		const freight = this.getParent(invoiceItems);

		const finalPercentage = this.percentage * (this.invoiceablePercentage / 100);

		const totalFreight = calculateTotalFreight(freight, this.fixtureCurrency, convertCurrency);

		const total = totalFreight * (finalPercentage / 100);

		return -1 * total;
	}

	getDescription(invoiceItems) {
		const parent = this.getParent(invoiceItems);

		if (parent?.children.filter((c) => c instanceof HIIFreightBrokerCommission).length > 1) {
			return `${formatPercentage(this.percentage, 2)}, ${this.brokerName}`;
		}

		return `${formatPercentage(this.percentage, 2)}`;
	}

	static async _getClassParams(model, parentModel) {
		const broker = await model.getBroker(parentModel);

		return {
			brokerId: model.brokerId,
			brokerName: broker.name,
			percentage: broker.commission,
			invoiceablePercentage: model.invoiceablePercentage,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.brokerId = this.brokerId;
		model.cargoId = this.cargoId;

		model.invoiceablePercentage = this.invoiceablePercentage;

		await model.save();
	}

	_getTemplateItemParams(invoiceItems) {
		const cargo = this.getParent(invoiceItems);

		const formattedQuantity = cargo.quantity?.toLocaleString() ?? 0;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Broker commission', `${this.getDescription(invoiceItems)}, ${formattedQuantity} MT  @ (${this.invoiceablePercentage}%)`],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Broker commission',
					`${formatPercentage(this.percentage, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(cargo.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIFreightBrokerCommission);

export default HIIFreightBrokerCommission;
