import React, {
	useMemo,
	useCallback,
	useState,
} from 'react';
import {
	useParams,
	useHistory,
} from 'react-router-dom';
import {
	faChartPieAlt,
	faDollarSign,
	faFileAlt,
	faTachometerAltSlow,
	faCalendarAlt,
	faUniversity,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Typography } from 'antd';
import { VesselOwnershipTypes } from '@shared/utils/constants';
import type { CrewReportsWithTypes } from '@api/utils/sequelize/getAllCrewReports';
import type { VesselProps } from '@api/models/vessel';
import {
	getVesselDetails,
	updateVessel,
	getVesselsWithPositions,
	deleteVessel,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Button from '@client/components/Button';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import asyncWrapper from '@client/utils/asyncWrapper';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import { Links } from '@client/utils/links';
import { VesselBankingTab } from '@client/screens/fleet/VesselDetailsScreen/tabs/VesselBankingTab';
import VesselDescription from '@client/components/VesselDescription';
import showErrorNotification from '@client/utils/showErrorNotification';
import VesselConsumptionTab from '@client/screens/fleet/VesselDetailsScreen/tabs/VesselConsumptionTab';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import { CrewReportDrawer } from './components/CrewReportDrawer/CrewReportDrawer';
import { VesselSummaryTab } from './tabs/VesselSummaryTab';
import { VesselDetailsTab } from './tabs/VesselDetailsTab';
import { VesselFinanceTab } from './tabs/VesselFinanceTab';
import { VesselDocumentsTab } from './tabs/VesselDocumentsTab';
import { VesselCrewReportsTab } from './tabs/VesselCrewReportsTab';
import VesselOffHireTab from './tabs/VesselOffHireTab';

const VesselDetailsScreen = () => {
	const params = useParams<{id: string}>();
	const id = Number(params.id);
	const history = useHistory();
	const [reportToDisplay, setReportToDisplay] = useState<CrewReportsWithTypes | null>(null);
	const [blockNavigation, setBlockNavigation] = useState(false);
	const { useBlocker } = useNavigationBlock();
	useBlocker(blockNavigation);

	const [
		vesselData,
		refreshVessel,
		error,
		loadingVessels,
	] = useFetchedState(
		() => getVesselDetails(id),
		[],
		{ showNotification: false },
	);

	const [
		vesselsWithPositions,
		_refreshPositions,
		_positionsError,
		loadingPositions,
	] = useFetchedState(
		getVesselsWithPositions,
		[],
		{ showNotification: false },
	);

	const vesselPosition = useMemo(() => {
		let vesselWithPosition = null;

		if (vesselsWithPositions != null && vesselData != null) {
			vesselWithPosition = vesselsWithPositions.find((v) => v.id === vesselData.id);
		}

		if (loadingPositions || vesselWithPosition == null) {
			return null;
		}

		return vesselWithPosition.position;
	}, [vesselsWithPositions, vesselData, loadingPositions]);

	const saveEdit = useCallback(async (editValues: Partial<VesselProps>) => {
		if (Object.keys(editValues).length === 0) {
			return;
		}

		try {
			await updateVessel(id, {
				...editValues,
				...(Object.keys(editValues).includes('flag') && !editValues.flag ? { flag: null } : {}),
			});
			showSuccessNotification('Vessel details saved');
		} catch (e) {
			showErrorNotification('Could not save details', e as Error);
		}

		await refreshVessel();
	}, [id, refreshVessel]);

	const retireVessel = asyncWrapper(async () => {
		if (vesselData?.id != null) {
			await deleteVessel(vesselData.id);
			showSuccessNotification('Vessel retired successfully');
			history.push(Links.Vessels.get());
		}
	}, 'Unable to delete vessel');

	const openReportDrawer = (report: CrewReportsWithTypes) => {
		setReportToDisplay(report);
	};

	const closeReportDrawer = () => {
		setReportToDisplay(null);
	};

	const tabs = [
		...(vesselData?.ownershipType === VesselOwnershipTypes.MARKET ? [{
			key: 'details',
			title: 'Details',
			icon: faFileAlt as IconProp,
			render: () => (
				<VesselDetailsTab
					vessel={vesselData}
					saveEdit={saveEdit}
					vesselId={id}
					refreshVessel={refreshVessel}
				/>
			),
		}, {
			key: 'performance',
			title: 'Consumption',
			icon: faTachometerAltSlow as IconProp,
			render: () => (
				<VesselConsumptionTab
					vessel={vesselData}
					refreshVessel={refreshVessel}
					setBlockNavigation={setBlockNavigation}
				/>
			),
		}] : [{
			key: 'summary',
			icon: faChartPieAlt as IconProp,
			title: 'Summary',
			render: () => (
				<VesselSummaryTab
					vessel={vesselData}
					position={vesselPosition}
					openReportDrawer={openReportDrawer}
				/>
			),
		},
		{
			key: 'details',
			title: 'Details',
			icon: faFileAlt as IconProp,
			render: () => (
				<VesselDetailsTab
					vessel={vesselData}
					saveEdit={saveEdit}
					vesselId={id}
					refreshVessel={refreshVessel}
				/>
			),
		},
		{
			key: 'offHire',
			title: 'Off-hire',
			icon: faFileAlt as IconProp,
			render: () => (
				<VesselOffHireTab refreshVessel={refreshVessel} vessel={vesselData} />
			),
		},
		...(vesselData?.ownershipType === VesselOwnershipTypes.OWNED ? [{
			key: 'finance',
			title: 'Financial',
			icon: faDollarSign as IconProp,
			render: () => (
				<VesselFinanceTab
					vessel={vesselData}
					refreshVessel={refreshVessel}
					vesselId={id}
				/>
			),
		}] : []),
		{
			key: 'banking',
			title: 'Banking',
			icon: faUniversity as IconProp,
			render: () => (vesselData != null ? (
				<VesselBankingTab
					vessel={vesselData}
					refreshVessel={refreshVessel}
				/>
			) : null),

		},
		...(vesselData != null ? [{
			key: 'performance',
			title: 'Consumption',
			icon: faTachometerAltSlow as IconProp,
			render: () => (
				<VesselConsumptionTab
					vessel={vesselData}
					refreshVessel={refreshVessel}
					setBlockNavigation={setBlockNavigation}
				/>
			),
		}] : []),
		{
			key: 'reports',
			title: 'Reports',
			icon: faCalendarAlt as IconProp,
			render: () => (
				<VesselCrewReportsTab
					openReportDrawer={openReportDrawer}
					vesselId={id}
					vessel={vesselData}
					refreshVessel={refreshVessel}
				/>
			),
		},
		{
			key: 'documents',
			title: 'Documents',
			icon: faFileAlt as IconProp,
			render: () => (
				<VesselDocumentsTab
					vesselId={id}
					refreshVessel={refreshVessel}
					vessel={vesselData}
				/>
			),
		}]),

	];

	return (
		<>
			<DetailsTabScreen
				rootPageTitle="Vessels"
				loading={loadingVessels}
				title={(
					<Typography.Title level={4}>
						<VesselDescription
							name={vesselData?.name}
							flag={vesselData?.flag}
						/>
					</Typography.Title>
				)}
				breadcrumbs={
					[[
						'Vessels',
						Links.Vessels.get(),
					]]
				}
				tabs={tabs}
				defaultTabKey={vesselData?.ownershipType === VesselOwnershipTypes.MARKET ? 'details' : 'summary'}
				canGoBack
				extra={(
					<Button
						confirmTitle="Are you sure you want to retire this vessel?"
						onClick={retireVessel}
						disabled={(vesselData?.fixtureCount ?? 0) > 0}
						disabledTooltip="Vessel cannot be retired after being assigned to one or more estimates"
						type="primary"
						danger
					>
						Retire Vessel
					</Button>
				)}
				error={error ?? undefined}

			/>
			<CrewReportDrawer
				report={reportToDisplay}
				onClose={closeReportDrawer}
			/>
		</>
	);
};

export default VesselDetailsScreen;
