import { Values } from '@shared/utils/objectEnums';
import {
	AccountTypes,
	FixtureTypes,
	Currencies,
	CargoUnitTypes,
	CargoUnitLabels,
	CargoUnitSingularLabels,
} from './constants';
import { formatCurrency } from './currency';
import { round } from './math';

export const isNumber = (s: string) => (
	s !== '' &&
	(
		stringToValidNumericString(s) === s ||
		stringToValidNumericString(s, { separateThousands: true }) === s
	)
);

export const capitalize = (s: string | null, restToLowercase = false) => {
	const string = (s || '').toString();

	const firstLetter = string.charAt(0).toUpperCase();
	let rest = string.slice(1);

	if (restToLowercase) {
		rest = rest.toLowerCase();
	}

	return firstLetter + rest;
};

export const formatQuantity = (
	quantity: number,
	unit: Values<typeof CargoUnitTypes>,
	description?: string,
) => {
	let string = `${stringToValidNumericString(`${round(quantity, 3)}`, { separateThousands: true })}`;

	if (unit != null && CargoUnitLabels[unit] != null) {
		if (quantity === 1) {
			string += ` ${CargoUnitSingularLabels[unit]}`;
		} else {
			string += ` ${CargoUnitLabels[unit]}`;
		}
	}

	if (description != null) {
		string += ` ${description}`;
	}

	return string;
};

export const fixtureTypeToPascalCase = (type: Values<typeof FixtureTypes>) => {
	switch (type) {
		case FixtureTypes.BB_OUT:
			return 'BB-Out';

		case FixtureTypes.TC_IN:
			return 'TC-In';

		case FixtureTypes.TC_OUT:
			return 'TC-Out';

		case FixtureTypes.SPOT:
			return 'VC-Out';
		default:
			return '';
	}
};

export const parsePercentage = (string: string) => Number(string.replace('%', ''));

export const trimString = (string: string | null, length: number | null, addDots = false) => {
	if (length == null || string == null) {
		return string;
	}

	let newString = string.substring(0, length);

	if (addDots && string.length > length) {
		newString = `${newString}...`;
	}

	return newString;
};

export const trimCurrency = (number: number, currency: Values<typeof Currencies>) => {
	if (number < 10000) {
		return formatCurrency(number, currency);
	}

	if (number < 1_000_000) {
		return `${formatCurrency(Math.round(number / 1000), currency)}K`;
	}

	return `${formatCurrency(round(number / 1_000_000, 2), currency)}M`;
};

export type StringToToValidNumericStringOptions = {
	allowNegative?: boolean;
	integersOnly?: boolean;
	separateThousands?: boolean;
};

export const stringToValidNumericString = (string: string, options?: StringToToValidNumericStringOptions) => {
	const {
		allowNegative = true,
		integersOnly = false,
		separateThousands = false,
	} = options ?? {};

	if (string == null) {
		return null;
	}

	let str = string;
	// Remove all non-valid characters
	str = str.replace(/[^0-9.,-]/g, '');
	// Remove all '.' that doesn't come after a digit
	str = str.replace(/([^0-9.]|^)\.+/g, '$1');

	// Remove all but the first '.'
	let dotIndex = str.indexOf('.');

	if (dotIndex > -1) {
		str = str.slice(0, dotIndex + 1) + str.slice(dotIndex + 1).replace(/\./g, '');
	}

	// Remove all ',' to the right of a '.'
	dotIndex = str.indexOf('.');
	if (dotIndex > -1) {
		str = str.slice(0, dotIndex + 1) + str.slice(dotIndex + 1).replace(/,/g, '');
	}

	// Remove all ',' unless it's the last character
	str = str.replace(/,(?!$)/g, '');
	// Remove all ',' if it's not after a digit
	str = str.replace(/([^0-9.]|^),+/g, '$1');
	// Remove all '-' unless it's the first character
	str = str.replace(/(?!^)-/g, '');
	// Remove all leading zeroes
	str = str.replace(/^0+(?=[1-9])/g, '');

	if (separateThousands) {
		// Insert thousand-separators
		// See: https://stackoverflow.com/a/10899795/4688606
		const parts = str.split('.');
		str = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] != null ? `.${parts[1]}` : '');
	} else {
		str = str.replace(/,/g, '');
	}

	if (integersOnly) {
		str = str.replace(/\..*/g, ''); // Remove anything after the decimal point
	}

	if (!allowNegative) {
		str = str.replace(/-/g, ''); // Remove the minus symbol
	}

	return str;
};

export const stringToNumber = (
	string: string | null,
	options?: { allowNegative?: boolean; integersOnly?: boolean },
) => {
	if (string == null) {
		return 0;
	}

	const {
		allowNegative = true,
		integersOnly = false,
	} = options ?? {};

	const str = stringToValidNumericString(string, {
		allowNegative,
		integersOnly,
	});

	if (str == null || str === '') {
		return 0;
	}

	const num = Number(str);

	if (Number.isNaN(num)) {
		return 0;
	}

	return num;
};

export function combineUrlParts(...parts: Array<string | null | undefined>) {
	return parts.reduce<string>((acc, part) => {
		if (part == null) {
			return acc;
		}

		if (acc.endsWith('/') && part.startsWith('/')) {
			return acc + part.slice(1);
		}

		if (acc !== '' && !acc.endsWith('/') && !part.startsWith('/')) {
			return `${acc}/${part}`;
		}

		return acc + part;
	}, '');
}

export const toTitleCase = (str: string) => str.replace(
	/\w\S*/g,
	(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

const expenseReceivableAccountTypes = {
	...AccountTypes,
	BOTH: 'payable_and_receivable',
};

export const getPayerString = (
	payer: Values<typeof expenseReceivableAccountTypes>,
	fixtureType: Values<typeof FixtureTypes>,
) => {
	if (fixtureType === FixtureTypes.SPOT) {
		return payer === AccountTypes.OWNER ? 'Not reimbursable' : 'Reimbursable';
	}

	if (payer === expenseReceivableAccountTypes.BOTH) {
		return 'Payable & Receivable';
	}

	return payer === AccountTypes.OWNER ? 'Owner\'s expense' : 'Charterer\'s expense';
};

export const formatHumanReadable = (string: string | null) => {
	if (string == null) {
		return null;
	}

	let output = string;

	output = output.replace(/([a-z])([A-Z])/g, '$1 $2');

	// Multiple uppercase letters in a row should be separated right before the last one
	output = output.replace(/([A-Z])([A-Z])([a-z])/g, '$1 $2$3');

	return capitalize(output);
};

export const formatBoolean = (bool: boolean) => (bool ? 'Yes' : 'No');

export const getNameInitials = (fullName: string) => {
	// given a name this function finds and returns the first letter of the first and last name
	const initials = fullName.match(/\b\w/g) || [];

	return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

