import React, {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import { DatePicker as AntDatePicker } from 'antd';
import {
	isMoment,
	Moment,
} from 'moment';
import {
	DatePickerProps as AntDatePickerProps,
	RangePickerProps as AntRangePickerProps,
} from 'antd/lib/date-picker';
import {
	nowMoment,
	stringifyIfMoment,
	toMoment,
} from '@shared/utils/date';
import {
	DateFormats,
	UtcOffsets,
} from '@shared/utils/constants';
import { range as getRange } from '@shared/utils/array';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import Select from '@client/components/Select';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import { useAuth } from '@client/lib/auth';
import styles from './styles/DatePicker.module.css';

const truncateMoment = (value: Date | Moment) => toMoment(value)
	.seconds(0)
	.milliseconds(0);

type _DatePickerValue = Date | Moment | null | undefined;
export type DatePickerValue = _DatePickerValue | [_DatePickerValue, _DatePickerValue];
type InternalTime = Moment | undefined | [Moment | undefined, Moment | undefined];

const valueToInternalTime = (value: DatePickerValue): InternalTime => {
	if (value == null) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return [
			valueToInternalTime(value[0]) as Moment | undefined,
			valueToInternalTime(value[1]) as Moment | undefined,
		];
	}

	return toMoment(value).local(true);
};

const valueToInternalOffset = (value: DatePickerValue): number | null => {
	if (value == null) {
		return null;
	}

	if (Array.isArray(value) && value[0] != null) {
		return toMoment(value[0]).utcOffset();
	}

	if (!Array.isArray(value)) {
		return toMoment(value).utcOffset();
	}

	return null;
};

const internalsToValue = (
	internalTime: InternalTime,
	internalOffset: number | undefined,
	requireOffset: boolean,
): DatePickerValue => {
	if (internalTime === undefined || (internalOffset === undefined && requireOffset)) {
		return undefined;
	}

	if (internalTime === null) {
		return null;
	}

	if (Array.isArray(internalTime)) {
		return [
			internalsToValue(internalTime[0], internalOffset, requireOffset) as _DatePickerValue,
			internalsToValue(internalTime[1], internalOffset, requireOffset) as _DatePickerValue,
		];
	}

	return truncateMoment(internalTime)
		.utcOffset(internalOffset ?? 0, true);
};

const getTimeProps = (
	valueMoment: Moment | undefined,
	defaultValueMoment: Moment | undefined,
	isRange: boolean,
): {
	minuteStep: number;
	showTime: boolean | { defaultValue: Moment };
} => {
	let showTime;

	if (!isRange) {
		const hours = Number(valueMoment?.format('HH') ?? defaultValueMoment?.format('HH') ?? 12);
		const minutes = Number(valueMoment?.format('mm') ?? defaultValueMoment?.format('mm') ?? 0);

		const showTimeDefaultValue = nowMoment()
			.hours(hours)
			.minutes(minutes);

		showTime = {
			defaultValue: showTimeDefaultValue,
		};
	} else {
		showTime = true;
	}

	return {
		showTime,
		minuteStep: 1,
	};
};

const valueToMoment = (
	value: DatePickerValue,
): Moment | [Moment | undefined, Moment | undefined] | undefined => {
	if (value == null) {
		return undefined;
	}

	if (Array.isArray(value)) {
		return [
			value[0] != null ? toMoment(value[0]) : undefined,
			value[1] != null ? toMoment(value[1]) : undefined,
		];
	}

	return toMoment(value);
};

type PickerComponentProps = (
	| ({ range?: false } & AntDatePickerProps)
	| ({ range?: true } & AntRangePickerProps)
);

export type DatePickerProps = ExtendUnique<{
	fullWidth?: boolean;
	rangeHorizontal?: boolean;
	time?: boolean;
	minDate?: Moment;
	maxDate?: Moment;
	onTimezoneChange?: (newOffset: number) => void;
	defaultPickerValue?: Moment;
	disableTimezone?: boolean;
	showTimezone?: boolean;
	defaultUtcOffset?: number;
	showNow?: boolean;
	onBlur?: () => void;
	onChange?: (newValue: DatePickerValue) => void;
	allowClear?: boolean;
	stopPropagation?: boolean;
	forceUtcUpdate?: boolean;
	staticTimezonePicker?: boolean;
	forceOnChange?: boolean;
}, PickerComponentProps>;

const DatePicker = forwardRef<HTMLElement, DatePickerProps>(({
	value,
	onChange,
	fullWidth,
	range = false,
	rangeHorizontal = false,
	time = false,
	className,
	defaultValue,
	inputReadOnly = false,
	minDate: minDateProp,
	maxDate: maxDateProp,
	onTimezoneChange,
	disableTimezone = true,
	stopPropagation = false,
	showTimezone = true,
	onBlur,
	onOpenChange,
	defaultUtcOffset,
	defaultPickerValue,
	forceUtcUpdate = false,
	staticTimezonePicker = false,
	showNow: showNowProp = true,
	forceOnChange = false,
	...props
}: DatePickerProps, ref: any) => {
	const { userInfo } = useAuth();
	const immediateTimeValue = useRef<Moment | [Moment, Moment] | undefined | null>(undefined);
	const internalValue = useRef<DatePickerValue>(undefined);

	const [internalTime, setInternalTime] = useState<InternalTime>(
		valueToInternalTime(value),
	);
	const [internalOffset, setInternalOffset] = useState(
		valueToInternalOffset(value) ?? defaultUtcOffset,
	);
	const [prevInternalOffset, setPrevInternalOffset] = useState<number | undefined>();
	const [allowSaveOnEnter, setAllowSaveOnEnter] = useState(false);

	const valueAsString = stringifyIfMoment(value);
	const defaultValueAsString = stringifyIfMoment(defaultValue);
	const internalTimeAsString = stringifyIfMoment(internalTime);

	const defaultValueMoment = useMemo(
		() => valueToMoment(defaultValue),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[defaultValueAsString],
	);

	const valueMoment = useMemo(
		() => valueToMoment(value),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[valueAsString],
	);

	const timeProps = getTimeProps(valueMoment as any, defaultValueMoment as any, range);

	useEffect(() => {
		if (internalOffset == null || forceUtcUpdate) {
			setInternalOffset(defaultUtcOffset);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultUtcOffset]);

	useEffect(() => {
		immediateTimeValue.current = undefined;

		const isNow = isMoment(internalTime) && nowMoment().diff(internalTime, 'seconds') === 0;

		const visible = (
			prevInternalOffset === internalOffset &&
			!isNow &&
			!range &&
			time &&
			internalTime !== null
		);

		setPrevInternalOffset(internalOffset);

		const newValue = internalsToValue(internalTime, internalOffset, time && !disableTimezone);

		const _old = stringifyIfMoment(value ?? internalValue.current) ?? null;
		const _new = stringifyIfMoment(newValue) ?? null;

		if (_old === _new) {
			return;
		}

		internalValue.current = newValue;

		visibleChange(visible);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalTimeAsString, internalOffset]);

	useEffect(() => {
		const newInternalTime = valueToInternalTime(valueMoment);
		const newInternalOffset = valueToInternalOffset(valueMoment) ?? defaultUtcOffset;

		setInternalTime(newInternalTime);
		setInternalOffset(newInternalOffset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueAsString]);

	useEffect(() => {
		if (allowSaveOnEnter && internalValue.current != null && typeof onChange === 'function') {
			onChange(internalValue.current);
			setAllowSaveOnEnter(false);
		}
	}, [allowSaveOnEnter, onChange]);

	const visibleChange = (visible: boolean) => {
		// Only update value when closing and value has changed
		if (
			!visible &&
			internalValue.current !== undefined &&
			typeof onChange === 'function'
		) {
			const newValue = internalValue.current;
			const oldValueString = stringifyIfMoment(value);
			const newValueString = stringifyIfMoment(newValue);

			if ((newValueString !== oldValueString || newValue == null) && !forceOnChange) {
				onChange(newValue);
			}
		}

		if (typeof onOpenChange === 'function') {
			if (immediateTimeValue.current === undefined) {
				onOpenChange(visible);
			}
		}

		// Handle onBlur ourselves every time the datepicker is closed
		if (!visible && typeof onBlur === 'function') {
			onBlur();
		}
	};

	const onOffsetChange = (newOffset: number) => {
		if (typeof onTimezoneChange === 'function') {
			onTimezoneChange(newOffset);
		}

		setInternalOffset(newOffset);
	};

	const minDate = useMemo(
		() => (minDateProp != null ? truncateMoment(minDateProp) : undefined),
		[minDateProp],
	);
	const maxDate = useMemo(
		() => (maxDateProp != null ? truncateMoment(maxDateProp) : undefined),
		[maxDateProp],
	);

	const disabledDate = useCallback((d: Moment) => {
		if (d == null) {
			return null;
		}

		return (
			(minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
			(maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
		);
	}, [minDate, maxDate]);

	const disabledTime = useCallback((d: Moment | null) => {
		if (d == null) {
			return null;
		}

		if (minDate != null && d.isSame(minDate, 'day')) {
			return {
				disabledHours: () => (minDate.hour() > 0 ? getRange(0, minDate.hour() - 1) : []),
				disabledMinutes: (hour: number) => (hour === minDate.hour() && minDate.minutes() > 0 ?
					getRange(0, minDate.minutes() - 1) :
					[]
				),
			};
		}

		if (maxDate != null && d.isSame(maxDate, 'day')) {
			return {
				disabledHours: () => getRange(maxDate.hour() + 1, 24),
				disabledMinutes: (hour: number) => (hour === maxDate.hour() ?
					getRange(maxDate.minutes() + 1, 60) :
					[]
				),
			};
		}

		return null;
	}, [minDate, maxDate]);

	const dateFormat = useMemo(() => {
		const preferredDateFormat = (userInfo as UserData).dateFormat;
		switch (props.picker) {
			case 'year':
				return 'YYYY';

			case 'month':
				if (preferredDateFormat === DateFormats.YMD) {
					return 'YYYY-MM';
				}

				return 'MM-YYYY';

			default:
				return time ? preferredDateFormat : preferredDateFormat.slice(0, 10);
		}
	}, [props.picker, time, userInfo]);

	const smartEnter = (input: string) => {
		// Datepicker defaults (with "-")
		if (input.length === 16 || input.length === 10) {
			setAllowSaveOnEnter(true);

			return;
		}

		let d = null;
		let m = null;
		let y = null;
		// Currently time is last for all formats
		const t = input.substring(9);

		let newValue = null;

		switch (dateFormat) {
			case DateFormats.DMY:
			case DateFormats.DMY.slice(0, 10):
				d = input.substring(0, 2);
				m = input.substring(2, 4);
				y = input.substring(4, 8);

				break;

			case DateFormats.MDY:
			case DateFormats.MDY.slice(0, 10):
				d = input.substring(2, 4);
				m = input.substring(0, 2);
				y = input.substring(4, 8);

				break;

			case DateFormats.YMD:
			case DateFormats.YMD.slice(0, 10):
				y = input.substring(0, 4);
				m = input.substring(4, 6);
				d = input.substring(6, 8);

				if (input.length === 4) {
					m = input.substring(0, 2);
					d = input.substring(2, 4);
				}

				break;

			default:
				break;
		}

		if (input.length === 14) {
			newValue = toMoment(`${y}-${m}-${d}T${t}:00Z`);

			validateDate(newValue);
		}

		if (input.length === 8) {
			newValue = toMoment(`${y}-${m}-${d}`);

			validateDate(newValue);
		}

		// if user inputs shorthand date, autocomplete with current year
		if (input.length === 4) {
			newValue = toMoment(`${nowMoment().year()}-${m}-${d}`);

			validateDate(newValue);
		}
	};

	const validateDate = (date: Moment) => {
		if (date.isValid()) {
			setInternalTime(date as any);
			setAllowSaveOnEnter(true);
		}
	};

	const isNowValidDate = (
		(minDate == null || nowMoment().isAfter(minDate)) &&
		(maxDate == null || nowMoment().isBefore(maxDate))
	);

	const showNow = showNowProp != null ?
		showNowProp !== false && isNowValidDate :
		undefined;

	const PickerComponent = (range ? AntDatePicker.RangePicker : AntDatePicker) as React.ElementType;

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.fullWidth]: fullWidth,
			})}
		>
			{(time && showTimezone) && (
				<Select
					variant={props.variant ?? undefined}
					key={internalOffset}
					className={classNames({
						[styles.timezonePicker]: !staticTimezonePicker,
						[styles.staticTimezonePicker]: staticTimezonePicker,
					})}
					disabled={disableTimezone}
					suffixIcon={disableTimezone ? null : undefined} // Use suffixIcon to hide/show the arrow
					options={UtcOffsets.map((o) => ({
						label: o.offset === 0 && !disableTimezone ? 'UTC+00:00' : o.name,
						value: o.offset,
					}))}
					value={internalOffset ?? 0}
					onChange={(newValue) => onOffsetChange(newValue as number)}
					popupMatchSelectWidth={false}
				/>
			)}
			<PickerComponent
				defaultPickerValue={valueToInternalTime(defaultPickerValue) as any}
				ref={ref}
				defaultValue={defaultValueMoment as any}
				value={internalTime as any}
				onChange={(newValue: InternalTime) => {
					if (forceOnChange && onChange != null && typeof onChange === 'function') {
						onChange(newValue as any);
					}

					immediateTimeValue.current = newValue as any;
					setInternalTime(newValue as any);
					internalValue.current = internalsToValue(
						newValue, internalOffset, time && !disableTimezone,
					);
				}}
				onSelect={(newValue: InternalTime) => {
					immediateTimeValue.current = newValue as any;
					setInternalTime(newValue);
					internalValue.current = internalsToValue(
						newValue, internalOffset, time && !disableTimezone,
					);
				}}
				onOpenChange={visibleChange}
				inputReadOnly={inputReadOnly}
				className={classNames(styles.datePicker, className, {
					[styles.range]: range,
					[styles.vertical]: range && !rangeHorizontal,
				})}
				onKeyDown={(e: KeyboardEvent) => {
					if (e.key === 'Enter' && typeof onChange === 'function') {
						if (!range) {
							smartEnter((e.target as HTMLInputElement).value);
						}
					}
				}}
				disabledDate={disabledDate}
				disabledTime={disabledTime}
				showNow={showNow}
				showToday={showNow}
				// Used in rare cases, where the date selector panel might be rendered inside
				// something else with a onClick handler, which we don't want to trigger
				panelRender={
					stopPropagation ?
						(panelNode: React.ReactElement) => React.cloneElement(panelNode, {
							onClick: (e: React.MouseEvent<HTMLElement>) => {
								e.stopPropagation();
							},
						}) :
						undefined
				}
				suffixIcon={null}
				format={dateFormat}
				{...(time ? timeProps : {})}
				{...props}
			/>
		</div>
	);
});

export default DatePicker;
