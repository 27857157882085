import { CargoUnitLabels } from '@shared/utils/constants';
import type { EditableCargo } from '@api/features/cargos/updateCargo';

const getIntakeCargoFields = ({
	cargoDetails,

}: {
	cargoDetails: EditableCargo;
}) => {
	return [
		{
			key: 'stowageFactor',
			label: 'Cargo stowage factor',
			type: 'number',
			value: cargoDetails.stowageFactor,
			editable: true,
			inputProps: { suffix: 'cbft/ton' },
		},
		{
			key: 'quantity',
			label: 'CP quantity',
			type: 'number',
			value: cargoDetails.quantity,
			inputProps: { suffix: `${CargoUnitLabels[cargoDetails.unit]}` },
			editable: true,
		},
		{
			key: 'quantityTolerance',
			label: 'Tolerance',
			value: cargoDetails.quantityTolerance,
			type: 'percentage',
			editable: true,
		},
	];
};

export default getIntakeCargoFields;
