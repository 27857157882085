import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import type { FixtureCounterpartyProps } from '@api/models/fixture-counterparty';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import { formatDate } from '@client/utils/formatDate';
import StateTag from '@client/components/StateTag';

const getReceivableItems = (
	expense: TransformedExpenses & { charterer?: FixtureCounterpartyProps | undefined },
) => (expense.receivable == null ? [] : [
	{
		label: 'Rebillable',
		key: '',
		type: 'text',
		render: () => (
			<StateTag
				state={expense.receivable?.state ?? 'Not created'}
			/>
		),
	},
	{
		key: 'amount',
		label: 'Amount',
		value: expense.receivable.amount,
		inputProps: {
			currency: expense.receivable.currency,
		},
		render: (
			c: any,
		) => formatCurrency(c.value, expense.receivable!.currency),
		type: 'currency',
		editable: true,
	},
	{
		label: 'Description',
		key: 'itemDescription',
		type: 'text',
		value: expense.receivable.itemDescription,
		editable: true,
	},
	{
		label: 'Created At',
		type: 'text',
		value: formatDate(expense.receivable.createdAt),
	},
	{
		key: 'note',
		label: 'Note',
		value: expense.receivable.note,
		editable: true,
		type: 'textarea',
	},
]);

export default getReceivableItems;
