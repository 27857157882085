import React, {
	useRef,
	useMemo,
} from 'react';
import { Typography } from 'antd';
import {
	faBalanceScale,
	faBooks,
	faChartPieAlt,
	faDollar,
	faDonate,
	faFileAlt,
	faFileInvoiceDollar,
	faGasPump,
	faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EditOutlined } from '@ant-design/icons';
import {
	AccountTypes,
	AttachmentTypes,
	ContractNamingConventions,
	Currencies,
	FixtureTypes,
} from '@shared/utils/constants';
import { fixtureTypeToPascalCase } from '@shared/utils/string';
import { sortByDates } from '@shared/utils/sortByDates';
import { Values } from '@shared/utils/objectEnums';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import {
	getUserData,
	renameIdentifier,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import VesselDescription from '@client/components/VesselDescription';
import { Links } from '@client/utils/links';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import FormPopover from '@client/components/FormPopover';
import Button from '@client/components/Button';
import HeaderAction from './components/HeaderAction';
import TcExpensesTab from './components/TcExpensesTab';
import InvoicesTab from './components/InvoicesTab';
import CumulativeComparison from './components/CumulativeComparison';
import Recap from './components/tabs/Recap';
import TcSummaryTab from './components/TcSummaryTab';
import CommissionsTab from './components/CommissionsTab/CommissionsTab';
import BunkerExpenditureTab from './tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import { ItineraryProvider } from './components/ItineraryTab/ItineraryProvider';
import TcPnlTab from './components/ProfitAndLossTab/TcPnlTab';

const TcContractDetailsScreen = ({
	voyageDetails,
	fixtureDetails,
	refreshVoyageDetails,
	refreshFixtureDetails,
	currency,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshVoyageDetails: () => void;
	refreshFixtureDetails: () => void;
	currency: Values<typeof Currencies>;
}) => {
	const tabRef = useRef<HTMLDivElement>(null);
	const [userInfo] = useFetchedState(getUserData);

	const refreshDetails = async () => {
		refreshVoyageDetails();
		refreshFixtureDetails();
	};

	const updateIdentifier = async (identifier?: string) => {
		try {
			await renameIdentifier(fixtureDetails.id, identifier);
			refreshDetails();
			showSuccessNotification('Identifier updated');
		} catch (e) {
			showErrorNotification('Could not update identifier', e as Error);
		}
	};

	const expenses = useMemo(() => {
		if (!voyageDetails?.id) {
			return [];
		}

		const sortedByDate = sortByDates(voyageDetails.voyageExpenseReceivables, 'createdAt');

		return sortedByDate.sort((a, b) => a.account.localeCompare(b.account));
	}, [voyageDetails]);

	const bunkersHasCurrencyDiff = voyageDetails.voyageBunkers.some((e) => e.currency !== currency);
	const isTcIn = voyageDetails?.fixture?.type === FixtureTypes.TC_IN;

	const tabs = [
		{
			key: 'summary',
			title: 'Summary',
			icon: faChartPieAlt as IconProp,
			render: () => (
				<ItineraryProvider
					refreshVoyageDetails={refreshVoyageDetails}
					voyageDetails={voyageDetails}
				>
					<TcSummaryTab
						voyageDetails={voyageDetails}
						fixtureDetails={fixtureDetails}
						refreshVoyageDetails={refreshVoyageDetails}
						refreshFixtureDetails={refreshFixtureDetails}
					/>
				</ItineraryProvider>
			),
		},
		{
			key: 'recap',
			title: 'Recap',
			icon: faFileAlt as IconProp,
			render: () => (
				<Recap
					voyageDetails={voyageDetails}
					fixtureDetails={fixtureDetails}
				/>
			),
		},
		{
			key: 'expenses',
			title: 'Expenses',
			forceRender: true,
			icon: faDonate as IconProp,
			render: () => (
				<TcExpensesTab
					voyageDetails={voyageDetails}
					fixtureCurrency={currency}
					refreshDetails={refreshDetails}
					expenses={expenses}
					bunkersHasCurrencyDiff={bunkersHasCurrencyDiff}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		{
			key: 'invoices',
			title: 'Invoices',
			icon: faFileInvoiceDollar as IconProp,
			render: () => (
				<InvoicesTab
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		...(fixtureDetails.brokers?.some((b) => {
			if (fixtureDetails.type === FixtureTypes.TC_OUT ||
				fixtureDetails.type === FixtureTypes.BB_OUT) {
				return b.paidBy === AccountTypes.OWNER;
			}

			if (fixtureDetails.type === FixtureTypes.TC_IN) {
				return b.paidBy === AccountTypes.CHARTERER;
			}

			return false;
		}) ? [{
				key: 'commissions',
				title: 'Commissions',
				icon: faUserTie as IconProp,
				render: () => (
					<CommissionsTab
						fixtureDetails={fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>}
						voyageDetails={voyageDetails}
						refreshDetails={refreshDetails}
					/>
				),
			}] : []),
		...(fixtureDetails.type === FixtureTypes.TC_OUT ? [{
			key: 'pnl',
			title: 'P&L',
			icon: faDollar as IconProp,
			render: () => (
				<TcPnlTab
					currency={currency}
					id={fixtureDetails.voyageId}
					vesselOwnershipType={fixtureDetails.vessel.ownershipType}
				/>
			),
		}] : []),
		{
			key: 'reconciliation',
			title: 'SoA',
			icon: faBalanceScale as IconProp,
			render: ({ active }: {active: boolean}) => (
				<CumulativeComparison
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					focused={active}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		...(isTcIn ? [] : [
			{
				key: 'bunkers',
				title: 'Bunkers',
				icon: faGasPump as IconProp,
				render: () => (
					<BunkerExpenditureTab
						vessel={voyageDetails.vessel}
						voyageDetails={voyageDetails}
						refreshVoyageDetails={refreshDetails}
						fixtureDetails={fixtureDetails}
					/>
				),
			},
		]),
		{
			key: 'documents',
			title: 'Documents',
			icon: faBooks as IconProp,
			render: () => (
				<AttachmentLibrary
					attachments={voyageDetails.attachments}
					refreshAttachments={refreshDetails}
					type={AttachmentTypes.VOYAGE}
					attachToId={voyageDetails.id}
				/>
			),
		},
	];

	return (
		<DetailsTabScreen
			rootPageTitle="Contract - TC"
			tabContainerRef={tabRef}
			defaultTabKey="summary"
			canGoBack
			tabsProps={{ destroyInactiveTabPane: true }}
			tabs={tabs}
			breadcrumbs={
				[[
					`Contracts - ${fixtureDetails == null ? 'N/A' : fixtureTypeToPascalCase(fixtureDetails.type)}`,
					Links.Voyages.get(),
				]]
			}
			title={(
				<Typography.Title level={4}>
					<VesselDescription
						name={voyageDetails?.vesselName}
						flag={voyageDetails?.vesselFlag}
					/>
					{' | '}
					{voyageDetails?.identifier}
					{userInfo?.contractNamingConvention === ContractNamingConventions.FREE && (
						<FormPopover
							title="Contract identifier"
							onSubmit={(values) => updateIdentifier(
								values.identifier ?? 'Unnamed',
							)}
							content="Hi"
							fields={[
								{
									label: 'Identifier',
									name: 'identifier',
									type: 'text',
									required: false,
								},
							]}
						>
							<Button type="link" icon={(<EditOutlined />)} />
						</FormPopover>
					)}
				</Typography.Title>
			)}
			extra={(
				<HeaderAction
					fixtureDetails={fixtureDetails}
					details={voyageDetails}
					refreshDetails={refreshDetails}
				/>
			)}
		/>
	);
};

export default TcContractDetailsScreen;

