import React from 'react';
import {
	InputNumber,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
	faBoxes,
	faEdit,
	faUpDown,
} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { Moment } from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import {
	getUtcOffset,
	toMoment,
} from '@shared/utils/date';
import {
	MetersPer,
	PortActionTypeLabels,
	PortCallTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { filterUnique } from '@shared/utils/array';
import type PortCallAction from '@api/models/port-call-action';
import type CargoPort from '@api/models/cargo-port';
import type Cargo from '@api/models/cargo';
import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';
import DatePicker from '@client/components/DatePicker';
import Button from '@client/components/Button';
import styles from '../components/styles/itineraryColumns.module.css';
import DateBadge from '../components/DateBadge';

const getItineraryColumns = ({
	selectedTimeFormat,
	portCalls,
	onUpdateRob,
	onSelectEntry,
	seaPassages,
	onUpdatePortCall,
	deletePortCall,
	viewMode,
}: {
	selectedTimeFormat: 'utc' | 'localTime';
	portCalls: ItineraryPortCallDto[];
	onUpdateRob: (key: string, portCallId: number, newQuantity: number | null) => void;
	onSelectEntry: (entry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined) => void;
	seaPassages: ItinerarySeaPassageDto[];
	onUpdatePortCall: (field: string, value: any, newRow: ItineraryPortCallDto) => void;
	deletePortCall: (portCall: ItineraryPortCallDto) => void;
	viewMode: 'default' | 'map' | 'bunker';
}) => {
	const uniqueFuelTypes = filterUnique(
		portCalls.flatMap((event) => (
			event.Robs.flatMap((rob) => rob.RobBunkers.map((bunker) => bunker.fuelGrade))
		)),
		(fuelGrade) => fuelGrade,
	).sort();

	const arrivalColumns = uniqueFuelTypes.map((fuelType) => ({
		key: `${fuelType}-arrival`,
		title: `${fuelType}`,
		dataIndex: `${fuelType}-arrival`,
		render: (value: number | undefined, row: ItineraryPortCallDto) => (
			row.type === PortCallTypes.COMMENCEMENT ? (<div style={{ width: '100%', textAlign: 'center' }}>—</div>) : (
				<InputNumber
					key={value}
					disabled={row.arrivalDate == null || row.type === PortCallTypes.COMMENCEMENT}
					className={styles.fullWidth}
					defaultValue={value}
					variant="borderless"
					addonAfter="MT"
					onChange={(newQuantity: number | null) => onUpdateRob(`${fuelType}-arrival`, row.id, newQuantity)}
				/>
			)),
	}));

	const departureColumns = uniqueFuelTypes.map((fuelType) => ({
		key: `${fuelType}-departure`,
		title: `${fuelType}`,
		dataIndex: `${fuelType}-departure`,
		render: (value: number | undefined, row: ItineraryPortCallDto) => (
			<InputNumber
				key={value}
				disabled={
					row.departureDate == null ||
					row.type === PortCallTypes.COMMENCEMENT ||
					row.type === PortCallTypes.COMPLETION
				}
				className={styles.fullWidth}
				variant="borderless"
				defaultValue={value}
				addonAfter="MT"
				onChange={(newQuantity: number | null) => onUpdateRob(`${fuelType}-departure`, row.id, newQuantity)}
			/>
		),
	}));

	const bunkerColumns = [
		...(arrivalColumns.length > 0 ? [{
			title: 'ROB on Arrival',
			children: arrivalColumns,
		}] : []),
		...(departureColumns.length > 0 ? [{
			title: 'ROB on Departure',
			children: departureColumns,
		}] : []
		),
	];

	return [
		{
			key: 'actions',
			align: 'center' as const,
			width: 30,
			render: (row: ItineraryPortCallDto) => (
				<FontAwesomeIcon
					className={styles.cursor}
					onClick={() => onSelectEntry(row)}
					icon={faEdit}
				/>
			),
		},
		{
			title: 'Port',
			dataIndex: ['port', 'name'],
			key: 'port',
			width: 250,
			render: (port: string, row: ItineraryPortCallDto) => {
				if (row.type === PortCallTypes.COMPLETION) {
					return port;
				}

				const seaPassage = seaPassages.find((sp) => sp.id === row.nextSeaPassageId);

				if (seaPassage == null) {
					return port;
				}

				const nextPortCall = seaPassage == null ? undefined : portCalls.find((pc) => (
					pc.id === seaPassage.nextPortCallId
				));

				const { departureDate } = row;
				const arrivalDate = nextPortCall != null ? nextPortCall.arrivalDate : undefined;

				const estimatedDepartureDate = row.departureDate ?? row.estimatedDepartureDate;
				const estimatedArrivalDate = nextPortCall?.estimatedArrivalDate;

				const estimatedDuration = (estimatedArrivalDate != null && estimatedArrivalDate != null) ? round(estimatedArrivalDate.diff(estimatedDepartureDate, 'days', true), 2) : undefined;

				const distance = seaPassage.route == null ? 0 : Math.round(
					seaPassage.route.properties.distance / MetersPer.NAUTICAL_MILE,
				);

				const isSeaPassageActual = (
					row.departureDate != null && nextPortCall?.arrivalDate != null
				);

				const actualDuration = arrivalDate != null ? round(arrivalDate.diff(departureDate, 'days', true), 2) : undefined;

				return (
					<div style={{ width: '100%', height: '100%', position: 'relative' }}>
						{port}
						<div
							role="button"
							onClick={() => seaPassage && onSelectEntry(seaPassage)}
							onKeyPress={(e) => {
								if (e.key === 'Enter' && seaPassage) {
									onSelectEntry(seaPassage);
								}
							}}
							tabIndex={0}
							className={classNames(styles.seaPassagePill, {
								[styles.estimated]: !isSeaPassageActual,
								[styles.actual]: isSeaPassageActual,
							})}
						>
							<Space>
								<FontAwesomeIcon icon={faUpDown} />
								{!isSeaPassageActual ? `Est. ${distance} nm | ${estimatedDuration} days` : `${actualDuration ?? estimatedDuration} days`}
							</Space>
						</div>
					</div>
				);
			},
		},
		...(viewMode === 'default' ? [
			{
				title: 'Arrival',
				dataIndex: 'estimatedArrivalDate',
				key: 'estimatedArrivalDate',
				width: selectedTimeFormat === 'localTime' ? 315 : 225,
				render: (date: Moment, row: ItineraryPortCallDto) => {
					if (row.type === PortCallTypes.COMMENCEMENT) {
						return (<div style={{ width: '100%', textAlign: 'center' }}>—</div>);
					}

					const utcOffset = getUtcOffset(row.port);

					const calculatedDate = row.arrivalDate ?? row.mastersEta ?? row.estimatedArrivalDate;
					const formattedDate = calculatedDate == null ? undefined : (selectedTimeFormat === 'localTime' ? calculatedDate : toMoment(calculatedDate).utc());

					return (
						<Space>
							<DateBadge entry={row} type="arrival" />
							<DatePicker
								time
								showTimezone={selectedTimeFormat === 'localTime'}
								variant="borderless"
								className={styles.fullWidth}
								allowClear={row.arrivalDate != null}
								defaultValue={formattedDate}
								onChange={(d) => onUpdatePortCall('arrivalDate', d, row)}
								defaultUtcOffset={selectedTimeFormat === 'localTime' ? utcOffset : 0}
								forceUtcUpdate
								forceOnChange
							/>
						</Space>
					);
				},
			},
			{
				title: 'Departure',
				dataIndex: 'estimatedDepartureDate',
				key: 'estimatedDepartureDate',
				width: selectedTimeFormat === 'localTime' ? 315 : 225,
				render: (date: Moment, row: ItineraryPortCallDto) => {
					const calculatedDate = row.departureDate ?? row.estimatedDepartureDate;
					const formattedDate = calculatedDate == null ? undefined : (selectedTimeFormat === 'localTime' ? calculatedDate : toMoment(calculatedDate).utc());

					const utcOffset = getUtcOffset(row.port);

					return (
						<Space>
							<DateBadge entry={row} type="departure" />
							<DatePicker
								time
								showTimezone={selectedTimeFormat === 'localTime'}
								variant="borderless"
								className={styles.fullWidth}
								allowClear={row.departureDate != null}
								defaultValue={formattedDate}
								onChange={(d) => onUpdatePortCall('departureDate', d, row)}
								defaultUtcOffset={selectedTimeFormat === 'localTime' ? utcOffset : 0}
								forceUtcUpdate
								forceOnChange
							/>
						</Space>
					);
				},
			},
		] : []),
		...(viewMode === 'bunker' ?
			[
				...bunkerColumns,
			] : [
				{
					title: 'Actions',
					dataIndex: 'actions',
					key: 'actions',
					render: (actions: PortCallAction[], row: ItineraryPortCallDto) => (
						<>
							{(actions.length === 0 && row.type !== PortCallTypes.COMMENCEMENT) && ' — '}
							{row.type === PortCallTypes.COMMENCEMENT && `Commencement${actions.length > 0 ? ', ' : ''}`}
							{actions?.map((action) => PortActionTypeLabels[action.action]).join(', ')}
						</>
					),
				},
				{
					title: 'Cargo',
					dataIndex: ['actions'],
					key: 'cargo',
					render: (actions: Array<PortCallAction & {
						CargoPort: CargoPort & { Cargo: Cargo };
					}>) => actions?.map((action) => {
						const cargo = action?.CargoPort?.Cargo;

						if (cargo == null) {
							return undefined;
						}

						return (
							<Link target="_blank" to={`/cargos/${cargo.id}`}>
								<Space>
									<FontAwesomeIcon icon={faBoxes} />
									{cargo.type}
								</Space>
							</Link>
						);
					}),
				},
				{
					key: 'kebab',
					title: '',
					width: 25,
					render: (row: ItineraryPortCallDto) => (
						<Button type="link" danger icon={(<DeleteOutlined />)} onClick={() => deletePortCall(row)} />
					),
				},
			]),
	];
};

export default getItineraryColumns;
