import React, { useCallback } from 'react';
import {
	Divider,
	Empty,
} from 'antd';
import type
{
	CargoDetails,
	GetCargoDetailsResponse,
} from '@api/features/cargos/getCargoDetails';
import type { CargoProps } from '@api/models/cargo';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import Card from '@client/components/Card/Card';
import { useEstimate } from '@client/screens/estimates/details/context/EstimatorContext';
import { updateVessel } from '@client/lib/api';
import getIntakeCargoFields from '../helpers/getIntakeCargoFields';
import getIntakeVesselFields from '../helpers/getIntakeVesselFields';
import styles from './CargoDetailsScreen.module.css';

const IntakeCalculatorKeyDetails = ({
	cargoDetails,
	handleChange,
	handleUpdate,
	refreshAll,
}: {
	cargoDetails?: GetCargoDetailsResponse | null;
	handleChange: (id: number, attributes: Partial<CargoProps>) => Promise<void> | void;
	handleUpdate: (values: Record<string, any>) => Promise<void>;
	refreshAll: () => void;
}) => {
	const {
		estimate,
		vessel,
	} = useEstimate();

	const handleCombinedUpdate = useCallback(
		async (values: Record<string, any>) => {
			const [key, value] = Object.entries(values)[0];
			const estimateKeys = ['summerDraftOverride', 'tpcOverride', 'constantsOverride', 'vesselCapacityType', 'dwtOverride'];
			const cargoKeys = ['stowageFactor', 'quantity', 'quantityTolerance'];
			const vesselKeys = ['grainCapacity', 'baleCapacity'];

			if (estimateKeys.includes(key)) {
				if (!estimate?.id) {
					return;
				}

				if ((estimate as any)[key] === value) {
					return;
				}

				handleUpdate(values);
				refreshAll();
			} else if (vesselKeys.includes(key)) {
				if (!vessel?.id) {
					return;
				}

				if (vessel[key as keyof typeof vessel] === value) {
					return;
				}

				await updateVessel(vessel.id, { [key]: value });
				refreshAll();
			} else if (cargoKeys.includes(key)) {
				if (cargoDetails?.id == null) {
					return;
				}

				if (cargoDetails[key as keyof CargoDetails] === value) {
					return;
				}

				handleChange(cargoDetails.id, values);
			}
		},
		[
			estimate,
			vessel,
			cargoDetails,
			handleChange,
			handleUpdate,
			refreshAll,
		],
	);

	if (cargoDetails == null) {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description="No data"
			/>
		);
	}

	return (
		<>
			<Card>
				<Divider>
					Vessel details
				</Divider>
				<EditableDetails
					className={styles.keyDetailsTable}
					title="Vessel details"
					// @ts-ignore
					items={getIntakeVesselFields({
						estimate,
					})}
					// @ts-ignore
					onEditValuesChange={handleCombinedUpdate}
					editing
					hideHeader
				/>
				<Divider>
					Cargo details
				</Divider>
				<EditableDetails
					className={styles.keyDetailsTable}
					title="Cargo details"
					// @ts-ignore
					items={getIntakeCargoFields({
						cargoDetails,
					})}
					// @ts-ignore
					onEditValuesChange={handleCombinedUpdate}
					editing
					hideHeader
				/>
			</Card>
		</>
	);
};

export default IntakeCalculatorKeyDetails;
