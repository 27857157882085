import React from 'react';
import { Form } from 'antd';
import Select from '@client/components/Select';

const CompanySelector = ({ companies }: {companies: {id: string; name: string}[]}) => {
	return (
		<Form.Item name="companyId" label="Company Code / ID">
			<Select
				virtual
				placeholder="Select Company"
				showSearch
				options={(companies ?? []).map((company) => ({
					label: company.name,
					value: company.id,
				}))}
			/>
		</Form.Item>
	);
};

export default CompanySelector;
