import React, {
	useState,
	useMemo,
} from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faPlus,
	faMinus,
} from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Row,
	Col,
	Divider,
	Space,
} from 'antd';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import getVcPnlItems from '../ProfitAndLossTab/helpers/getVcPnlItems';
import getPnlTableColumns from '../ProfitAndLossTab/helpers/pnlTableColumns';
import { TablePnlEntry } from '../ProfitAndLossTab/VcPnlTab';
import TcInCostAllocation from '../TcInCostAllocation';
import styles from './VcPnl.module.css';

type Props = {
	pnl: any;
	voyageDetails: GetVoyageDetailsResponse;
	fixtureCurrency: Values<typeof Currencies>;
	columnsToHide?: ('actual' | 'estimated' | 'invoiced' | 'variance')[];
	showTcInAllocation?: boolean;
	spanOverride?: {
		xs?: number;
		sm?: number;
		xl?: number;
		xxl?: number;
	};
	slim?: boolean;
	hideHeaders?: boolean;
}

const VcPnl = ({
	pnl,
	fixtureCurrency,
	voyageDetails,
	columnsToHide = [],
	showTcInAllocation = true,
	spanOverride = {},
	slim,
	hideHeaders,
}: Props) => {
	const [activeTab, setActiveTab] = useState('pnl');
	const showActual = !columnsToHide.includes('actual');
	const showEstimated = !columnsToHide.includes('estimated');
	const showInvoiced = !columnsToHide.includes('invoiced');
	const columns = getPnlTableColumns(fixtureCurrency, columnsToHide);

	const { revenueItems, expenseItems, resultsItems } = useMemo(() => {
		if (pnl == null) {
			return {
				revenueItems: [],
				expenseItems: [],
				resultsItems: [],
			};
		}

		const items = getVcPnlItems(pnl, voyageDetails.vesselCostType);

		return Object.entries(items).reduce((acc, [key, value]) => {
			const mapped = value.map((v) => {
				return {
					item: v.item,
					// @ts-ignore
					number: v?.number,
					// @ts-ignore
					bold: v?.bold,
					...(showEstimated ? { estimated: v.estimated } : []),
					...(showActual ? { actual: v.actual } : []),
					...(showInvoiced ? { invoiced: v.invoiced } : []),
				};
			});

			return {
				...acc,
				[key]: mapped,
			};
		}, {
			revenueItems: [],
			expenseItems: [],
			resultsItems: [],
		});
	}, [pnl, voyageDetails, showActual, showEstimated, showInvoiced]);

	return (
		<Row align="middle" justify="center">
			<Col
				xs={spanOverride.xs ?? 24}
				sm={spanOverride.sm ?? 24}
				xl={spanOverride.xl ?? 20}
				xxl={activeTab === 'tcIn' ? 18 : spanOverride.xxl ?? 12}
			>
				<Card
					slim={slim}
					bodyStyle={{ paddingTop: 0 }}
					title={hideHeaders ? null : 'P&L Sheet'}
					tabList={showTcInAllocation ? [
						{ label: 'P&L', key: 'pnl' },
						{ label: 'TC-In Allocation', key: 'tcIn' },
					] : undefined}
					onTabChange={(key) => setActiveTab(key)}
				>
					{activeTab === 'tcIn' ? (
						<TcInCostAllocation />
					) : (
						<>
							{!hideHeaders && (
								<Divider>
									<Space>
										<FontAwesomeIcon icon={faPlus as IconProp} />
										Revenue
									</Space>
								</Divider>
							)}
							<Table<TablePnlEntry>
								size="small"
								pagination={false}
								columns={columns}
								dataSource={revenueItems}
								className={styles.table}
								bordered
								showHeader={!hideHeaders}
							/>
							{!hideHeaders && (
								<Divider>
									<Space>
										<FontAwesomeIcon icon={faMinus as IconProp} />
										Expenses
									</Space>
								</Divider>
							)}
							<Table<TablePnlEntry>
								size="small"
								pagination={false}
								columns={columns}
								dataSource={expenseItems}
								className={styles.table}
								bordered
								showHeader={!hideHeaders}
							/>
							{!hideHeaders && (
								<Divider>Results</Divider>
							)}
							<Table<TablePnlEntry>
								size="small"
								pagination={false}
								columns={columns}
								dataSource={resultsItems}
								className={styles.table}
								bordered
								showHeader={!hideHeaders}
							/>
						</>
					)}
				</Card>
			</Col>
		</Row>
	);
};

export default VcPnl;
