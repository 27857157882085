import React, {
	useMemo,
	useState,
} from 'react';
import { Space } from 'antd';
import {
	FixtureTypes,
	PortActionTypes,
	VcContractCompletionTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import isItemPortCall from '@shared/utils/isItemPortCall';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import { capitalize } from '@shared/utils/string';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import Select from '@client/components/Select';
import CountryFlag from '@client/components/CountryFlag';
import DatePicker from '@client/components/DatePicker';
import {
	selectPortCallAction,
	updateVoyage,
} from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import Details from '@client/components/Details';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import { useItinerary } from './ItineraryTab/ItineraryProvider';
import styles from './styles/SelectItineraryEntryAndDates.module.css';

const SelectItineraryEntryAndDates = () => {
	const [updateLoading, setUpdateLoading] = useState(false);
	const {
		itinerary,
		itineraryLoading: loading,
		setItineraryShouldUpdate,
		refreshItinerary,
	} = useItinerary();

	const {
		voyageDetails,
		refreshVoyageDetails,
		fixtureDetails,
		updateVoyageField,
		portOptions,
	} = useVoyage();

	const [completionType, setCompletionType] = useState<
		VcContractCompletionTypes | null
	>(voyageDetails.completionType);

	const portCalls = useMemo(() => {
		return itinerary.filter((entry) => (
			isItemPortCall(entry) && !entry.isCanalTransit
		)) as ItineraryPortCallDto[];
	}, [itinerary]);

	const onSelectEntry = async (
		type: Values<typeof PortActionTypes>,
		portCallId: number | null,
	) => {
		setUpdateLoading(true);

		try {
			await selectPortCallAction(type, voyageDetails.id, portCallId);
			showSuccessNotification(`${capitalize(type)} selected`);
			await refreshVoyageDetails();
			await refreshItinerary();
			setItineraryShouldUpdate(true);
		} catch (e) {
			showErrorNotification('Could not update delivery/redelivery', e as Error);
		} finally {
			setUpdateLoading(false);
		}
	};

	const matchActionWithEntry = useMemo(() => {
		const delivery = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.DELIVERING)
		));

		const redelivery = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.REDELIVERING)
		));

		const commencement = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.COMMENCEMENT)
		));

		const completion = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.COMPLETION)
		));

		return {
			commencement,
			delivery,
			redelivery,
			completion,
		};
	}, [portCalls]);

	const completionEntry = useMemo(() => {
		if (fixtureDetails.type === FixtureTypes.SPOT) {
			return matchActionWithEntry.completion;
		}

		return matchActionWithEntry.redelivery;
	}, [matchActionWithEntry, fixtureDetails]);

	const isSpot = fixtureDetails.type === FixtureTypes.SPOT;
	const isTcInVoyage = fixtureDetails.type === FixtureTypes.TC_IN;
	const isTcInVessel = voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN;

	const onChangeCompletionType = async (type: VcContractCompletionTypes | null) => {
		const currentType = completionType;
		setCompletionType(type);

		try {
			await updateVoyage(voyageDetails.id, { completionType: type });
			showSuccessNotification('Completion type updated');
			await refreshVoyageDetails();
		} catch (e) {
			setCompletionType(currentType);
			showErrorNotification('Could not update completion type', e as Error);
		}
	};

	const commencementItems = [
		...(isTcInVoyage ? [] : [
			{
				key: '10',
				label: 'Commencement',
				value: (
					<Select
						placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
						showSearch
						loading={loading || updateLoading}
						key={matchActionWithEntry.commencement?.id}
						defaultValue={matchActionWithEntry.commencement?.id}
						allowClear
						disabled
						className={styles.fullWidth}
						onChange={(value) => onSelectEntry(PortActionTypes.COMMENCEMENT, value)}
						optionRender={(option) => (
							<Space direction="vertical" size={0}>
								<Space>
									<CountryFlag countryCode={option.data.country} />
									{option.label}
								</Space>
								{option.data.departureDate && (
									<div style={{ fontSize: 11 }}>
										Departure:
										{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
									</div>
								)}
							</Space>
						)}
						options={portCalls.map((pc) => ({
							value: pc.id,
							label: pc.port.name,
							departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
							country: pc.port.countryCode,
							disabled: matchActionWithEntry.commencement?.id === pc.id,
						}))}
					/>
				),
			},
			{
				key: '1',
				label: 'Commencement date',
				value: (
					<DatePicker
						placeholder="Commencement date"
						className={styles.fullWidth}
						value={voyageDetails?.commencementDate}
						onChange={(date) => updateVoyageField('commencementDate', date)}
						time
						disabled={!isTcInVoyage}
					/>
				),
			},
		]),
	];

	const tcInRedelivery = voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY;

	const deliveryItems = [
		...(isTcInVoyage ? [{
			key: 'deliveryPort',
			label: 'Delivery Port',
			value: (
				<Select
					showSearch
					placeholder="Select Delivery Port"
					options={portOptions}
					onChange={(port) => updateVoyageField('deliveryPort', port)}
					defaultValue={voyageDetails.deliveryPort?.id}
				/>
			),
		}] : [
			{
				key: '0',
				label: 'Delivery',
				value: (
					<Select
						placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
						showSearch
						loading={loading || updateLoading}
						key={matchActionWithEntry.delivery?.id}
						defaultValue={matchActionWithEntry.delivery?.id}
						allowClear
						disabled={portCalls.length === 0}
						className={styles.fullWidth}
						onChange={(value) => onSelectEntry(PortActionTypes.DELIVERING, value)}
						optionRender={(option) => (
							<Space direction="vertical" size={0}>
								<Space>
									<CountryFlag countryCode={option.data.country} />
									{option.label}
								</Space>
								{option.data.departureDate && (
									<div style={{ fontSize: 11 }}>
										Departure:
										{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
									</div>
								)}
							</Space>
						)}
						options={portCalls.map((pc) => ({
							value: pc.id,
							label: pc.port.name,
							departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
							country: pc.port.countryCode,
							disabled: matchActionWithEntry.delivery?.id === pc.id,
						}))}
					/>
				),
			}]),
		{
			key: '1',
			label: 'Delivery date',
			value: (
				<DatePicker
					placeholder="Delivery date"
					allowClear
					className={styles.fullWidth}
					defaultValue={voyageDetails?.deliveryDate}
					onChange={(date) => updateVoyageField('deliveryDate', date)}
					time
					forceOnChange
				/>
			),
		},
	];

	const redeliveryItems = [
		...(isTcInVoyage ? [{
			key: 'redeliveryPort',
			label: 'Redelivery Port',
			value: (
				<Select
					showSearch
					placeholder="Select Redelivery Port"
					options={portOptions}
					onChange={(port) => updateVoyageField('redeliveryPort', port)}
					defaultValue={voyageDetails.redeliveryPort?.id}
				/>
			),
		}] : [
			{
				key: '-1',
				label: 'Redelivery',
				value: (
					<Select
						placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
						defaultValue={matchActionWithEntry.redelivery?.id}
						onChange={(value) => onSelectEntry(PortActionTypes.REDELIVERING, value)}
						key={matchActionWithEntry.redelivery?.id}
						className={styles.fullWidth}
						disabled={
							portCalls.length === 0
						}
						loading={loading || updateLoading}
						allowClear
						showSearch
						optionRender={(option) => (
							<Space direction="vertical" size={0}>
								<Space>
									<CountryFlag countryCode={option.data.country} />
									{option.label}
								</Space>
								{option.data.departureDate && (
									<div style={{ fontSize: 11 }}>
										Departure:
										{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
									</div>
								)}
							</Space>
						)}
						options={portCalls.map((pc) => ({
							value: pc.id,
							label: pc.port.name,
							departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
							country: pc.port.countryCode,
							disabled: matchActionWithEntry.redelivery?.id === pc.id,
						}))}
					/>
				),
			},
		]
		),
		{
			key: '2',
			label: `Redelivery date ${tcInRedelivery ? '(TC-Out)' : ''}`,
			value: (
				<DatePicker
					placeholder="Redelivery date"
					className={styles.fullWidth}
					allowClear
					defaultValue={voyageDetails?.redeliveryDate}
					onChange={(date) => updateVoyageField('redeliveryDate', date)}
					time
					forceOnChange
				/>
			),
		},
	];

	const completionItems = [
		...(isTcInVoyage ? [] : [
			...(isTcInVessel ? [
				{
					key: 'completionType',
					label: 'Redeliver the vessel on TC IN to the owner',
					value: (
						<Select
							value={completionType}
							key={completionType}
							onChange={onChangeCompletionType}
							options={[
								{
									label: 'Redeliver the vessel',
									value: VcContractCompletionTypes.TC_IN_DELIVERY,
									disabled: voyageDetails.linkedTcInVoyageId == null,
								},
								{ label: 'No', value: VcContractCompletionTypes.NEXT_CONTRACT },
							]}
						/>
					),
				},
			] : []),
			...(isSpot ? [{
				key: '11',
				label: 'Completion',
				value: (
					<Select
						placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
						defaultValue={completionEntry?.id}
						onChange={(value) => onSelectEntry(PortActionTypes.COMPLETION, value)}
						key={completionEntry?.id}
						className={styles.fullWidth}
						disabled={
							portCalls.length === 0 ||
							fixtureDetails.type !== FixtureTypes.SPOT ||
							voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY
						}
						loading={loading || updateLoading}
						showSearch
						allowClear
						optionRender={(option) => (
							<Space direction="vertical" size={0}>
								<Space>
									<CountryFlag countryCode={option.data.country} />
									{option.label}
								</Space>
								{option.data.departureDate && (
									<div style={{ fontSize: 11 }}>
										Departure:
										{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
									</div>
								)}
							</Space>
						)}
						options={portCalls.map((pc) => ({
							value: pc.id,
							label: pc.port.name,
							departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
							country: pc.port.countryCode,
							disabled: matchActionWithEntry.completion?.id === pc.id,
						}))}
					/>
				),
			},
			{
				key: voyageDetails.completionDate?.toISOString() ?? 'completionDate',
				label: 'Completion date',
				value: (
					<DatePicker
						key={`CD-${voyageDetails?.completionDate?.toLocaleString() ?? nowMoment().toLocaleString()}`}
						placeholder="Completion date"
						className={styles.fullWidth}
						disabled
						value={voyageDetails?.completionDate}
						onChange={(date) => updateVoyageField('completionDate', date)}
						time
					/>
				),
			}] : []),
		]),
	];

	const items = [
		...commencementItems,
		...(isSpot ? [] : [
			// only show delivery and redelivery items when not VCO
			...deliveryItems,
			...redeliveryItems,
		]),
		...completionItems,
	];

	return (
		<Details
			title={null}
			items={items}
			className={styles.details}
		/>
	);
};

export default SelectItineraryEntryAndDates;
