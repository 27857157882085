import {
	CargoUnitTypes,
	conversionRates,
	VesselCapacityType,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type Vessel from '@api/models/vessel';
import type { LocalPortRotationWithKey } from '@client/screens/estimates/details/context/hooks/usePortRotationHandlers';

export type MaxCargoCalculation = {
	portMax: number | string;
	limitingFactor: string;
};

const calculateMaxCargoForPort = (
	port: LocalPortRotationWithKey,
	rowIndex: number,
	vessel: Vessel,
	estimateResult: GetEstimateDetailsResponse | null | undefined,
): MaxCargoCalculation => {
	if (rowIndex === 0) {
		return { portMax: '-', limitingFactor: '-' };
	}

	const summerDraftValue = estimateResult?.summerDraftOverride ?? vessel.summerDraft ?? 99;
	const constantsValue = estimateResult?.constantsOverride ?? vessel.constants ?? 0;
	const dwt = estimateResult?.dwtOverride ?? vessel.summerDWT ?? 0;
	const portDraft = port.draft ?? 99;

	const totalPotentialCargo = dwt - constantsValue;

	const lostDraft =
		portDraft != null && portDraft > 0 ?
			Math.max(0, summerDraftValue - portDraft) :
			summerDraftValue;

	const tpcValue = estimateResult?.tpcOverride ?? vessel.TPC ?? 0;

	const selectedUnit =
    (estimateResult?.cargos && estimateResult.cargos.length > 0 && estimateResult.cargos[0].unit) ||
    CargoUnitTypes.CBM;

	const conversionFactor = conversionRates[selectedUnit] || 1;

	const portMaxByWeightCalcRaw = (totalPotentialCargo - (lostDraft * tpcValue * 100)) /
		conversionFactor;

	const portMaxByWeightCalc = Math.max(0, portMaxByWeightCalcRaw);

	let vesselCapacityCBM = 0;

	if (estimateResult?.vesselCapacityType === VesselCapacityType.Bale) {
		if (vessel.baleCapacity != null) {
			vesselCapacityCBM = vessel.baleCapacity;
		}
	} else if (vessel.grainCapacity != null) {
		vesselCapacityCBM = vessel.grainCapacity;
	}

	let stowageFactor = 0;

	if (estimateResult?.cargos && estimateResult.cargos.length > 0) {
		stowageFactor = estimateResult.cargos[0].stowageFactor ?? 0;
	}

	let portMaxByVolumeCalcCBM = 0;

	if (stowageFactor > 0) {
		portMaxByVolumeCalcCBM = vesselCapacityCBM / stowageFactor;
	}

	const portMaxByWeightCalcConverted = portMaxByWeightCalc * conversionFactor;
	const portMaxByVolumeCalcConverted = portMaxByVolumeCalcCBM * conversionFactor;

	const calculatedPortMax = Math.min(portMaxByWeightCalcConverted, portMaxByVolumeCalcConverted);
	const limitingFactor =
    portMaxByWeightCalcConverted < portMaxByVolumeCalcConverted ? 'Weight' : 'Volume';

	return { portMax: round(calculatedPortMax), limitingFactor };
};

export default calculateMaxCargoForPort;
