import React, {
	useEffect,
	useState,
} from 'react';
import { Form } from 'antd';
import { Moment } from 'moment';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
	SofActions,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { ensureNegative } from '@shared/utils/math';
import type { RobProps } from '@api/models/rob';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { Port } from '@api/utils/ports';
import {
	createRobs,
	updateRob,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import { EditValues } from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import {
	ItineraryPortCallDto,
	NullableBunkerRecord,
} from '@client/screens/estimates/details/helpers/types';
import { useItinerary } from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/ItineraryProvider';
import { sortByFuelGrade } from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/utils/helpers';
import RobEntryFormItems from './RobEntryFormItems';

export type BunkerRecord = {
	id: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number;
	adjustment?: number;
};

type RobEntryFormProps = {
	hideMetaFields?: boolean;
	onClose: () => void;
	fixtureCurrency: Values<typeof Currencies>;
	vesselId: number;
	editing: EditValues | null;
	voyageDetails: GetVoyageDetailsResponse;
	acceptableFuels: Array<Values<typeof FuelTypes>>;
};

const RobEntryForm = ({
	onClose,
	fixtureCurrency,
	vesselId,
	editing,
	voyageDetails,
	acceptableFuels,
}: RobEntryFormProps) => {
	const [form] = Form.useForm<{
		date: Moment;
		event: Values<typeof CrewReportTypes>;
		port: Port | string;
	}>();
	const [eventType, setEventType] = useState<
		null | Values<typeof CrewReportTypes> | SofActions>(null);
	const [remainingOnBoard, setRemainingOnBoard] = useState<NullableBunkerRecord[]>([]);
	const [bunkersReceived, setBunkersReceived] = useState<NullableBunkerRecord[]>([]);
	const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
	const itinerary = useItinerary();

	const { expandedEntry, expandedPortCallAction } = itinerary || {};
	const selectedPortCall = expandedEntry as ItineraryPortCallDto;
	useEffect(() => {
		if (expandedPortCallAction != null && selectedPortCall != null) {
			form.setFieldValue('date', expandedPortCallAction.actionDate);
			form.setFieldValue('event', expandedPortCallAction.action);
			form.setFieldValue('port', selectedPortCall.port.id);

			setSelectedDate(expandedPortCallAction.actionDate);
			// @ts-ignore
			setEventType(expandedPortCallAction?.action);
		}
	}, [expandedPortCallAction, selectedPortCall, form]);

	useEffect(() => {
		if (editing != null) {
			if (editing.robs != null) {
				const vals = editing.robs.map((r) => ({
					id: r.id,
					fuelGrade: r.fuelGrade,
					quantity: r.totalQuantity,
					pricePerTon: r.fuelQueue?.[0]?.pricePerTon,
				}));
				setRemainingOnBoard(vals);
			}

			if (editing.event != null) {
				setEventType(editing.event);
			}

			if (editing.quantity != null && editing.fuelGrade != null) {
				setRemainingOnBoard([{ quantity: editing.quantity, fuelGrade: editing.fuelGrade }]);
			}

			if (editing.VesselBunkers != null) {
				setBunkersReceived(editing.VesselBunkers.map((vb) => vb.Bunker));
			}
		} else {
			const fuelTypeToUse = acceptableFuels?.[0] ?? FuelTypes.VLSFO;
			setRemainingOnBoard([{ quantity: null, fuelGrade: fuelTypeToUse, pricePerTon: 0 }]);
			setBunkersReceived([{ quantity: null, fuelGrade: fuelTypeToUse, pricePerTon: 0 }]);
		}
	}, [acceptableFuels, editing]);

	const deleteEntry = (rob: boolean, record: NullableBunkerRecord): void => {
		const arr: NullableBunkerRecord[] = rob ? remainingOnBoard : bunkersReceived;
		const index = arr.findIndex(
			(entry) => entry.fuelGrade === record.fuelGrade &&
				entry.quantity === record.quantity &&
				entry.pricePerTon === record.pricePerTon,
		);

		if (index !== -1) {
			if (rob) {
				const newArr = [...remainingOnBoard];
				newArr.splice(index, 1);
				setRemainingOnBoard(newArr);
			} else {
				const newArr = [...bunkersReceived];
				newArr.splice(index, 1);
				setBunkersReceived(newArr);
			}
		}
	};

	const onAddNewBunkerRob = async (values: Pick<RobProps, 'event' | 'port' | 'date'>) => {
		const { event, port, date } = values;
		const filteredEntries = remainingOnBoard.filter(
			(entry): entry is BunkerRecord => entry.quantity !== null,
		);
		const filteredBunkersReceived = bunkersReceived.filter(
			(entry): entry is BunkerRecord => entry.quantity !== null,
		);

		await createRobs({
			vesselId,
			voyageId: voyageDetails.id,
			event: event ?? editing?.event,
			port: port ?? editing?.port ?? null,
			date: date ?? editing?.date,
			currency: fixtureCurrency,
			robs: filteredEntries,
			bunkersReceived: filteredBunkersReceived,
			portCallActionId: expandedPortCallAction?.id,
		});
	};

	const onSave = async () => {
		if (editing != null && editing.robId != null) {
			let transformedBunkersReceived = bunkersReceived.filter((b) => b.quantity != null);

			if (editing.event === CrewReportTypes.REDELIVERY) {
				transformedBunkersReceived = bunkersReceived.map((b) => ({
					...b,
					quantity: ensureNegative(b.quantity ?? 0),
				}));
			}

			const attributes = {
				currency: fixtureCurrency,
				bunkersReceived: transformedBunkersReceived,
				remainingOnBoard:
					remainingOnBoard.length > 1 ?
						(remainingOnBoard as BunkerRecord[]) :
						(remainingOnBoard[0] as BunkerRecord),
			};

			await updateRob({
				robId: editing.robId,
				bunkerId: editing.bunkerId,
				robBunkerId: editing.robBunkerId,
				vesselId,
				voyageId: voyageDetails.id,
				attributes,
				currency: fixtureCurrency,
				date: selectedDate ?? undefined,
				port: form.getFieldValue('port'),
				event: editing.event,
				deleteMissingEntries: true,
				resetQueue: editing.event === CrewReportTypes.COMMENCEMENT,
			});
			await onClose();

			return;
		}

		try {
			if (editing?.event !== CrewReportTypes.COMMENCEMENT) {
				await form.validateFields();
			}

			const values = form.getFieldsValue();
			await onAddNewBunkerRob({ ...values, port: values.port as Port });
			await onClose();
		} catch (e) {
			showErrorNotification('Could not create ROB entry', e as Error);
		}
	};

	useEffect(() => {
		if (editing != null) {
			const transformedValues = {
				event: editing.event,
				port: editing?.port?.name,
				date: editing.date,
			};
			setSelectedDate(editing.date ?? null);
			form.setFieldsValue(transformedValues);
		}
	}, [editing, form]);

	return (
		<Form
			layout="vertical"
			form={form}
			onFieldsChange={(fields) => {
				const field = fields[0];

				if (field.name[0] === 'date') {
					setSelectedDate(field.value);
				}

				if (field.name[0] === 'event') {
					setEventType(field.value);
				}
			}}
		>
			<RobEntryFormItems
				fixtureCurrency={fixtureCurrency}
				eventType={eventType}
				onSaveRob={onSave}
				onDeleteRob={deleteEntry}
				newRobs={sortByFuelGrade(remainingOnBoard, true)}
				setNewRobs={setRemainingOnBoard}
				bunkersReceived={bunkersReceived}
				setBunkersReceived={setBunkersReceived}
				selectedDate={selectedDate}
				acceptableFuels={acceptableFuels}
				voyageDetails={voyageDetails}
			/>
		</Form>
	);
};

export default RobEntryForm;
