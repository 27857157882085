import React, { useMemo } from 'react';
import {
	Col,
	Row,
	Flex,
	Grid,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	FixtureTypeLabels,
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import formatTcAndBbFixtureDuration from '@shared/utils/formatTcAndBbFixtureDuration';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getPorts,
	getVesselDetails,
	getVoyages,
	updateVoyage,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import Card from '@client/components/Card/Card';
import Details from '@client/components/Details';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import DatePicker from '@client/components/DatePicker';
import Select from '@client/components/Select';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import TooltipIcon from '@client/components/TooltipIcon';
import EstimatedDates from './EstimatedDates/EstimatedDates';
import LinkedVoyages from './LinkedVoyages/LinkedVoyages';
import ItineraryTab from './ItineraryTab/ItineraryTab';
import VoyageCompletionDetails from './VoyageCompletionDetails';
import styles from './styles/TcSummaryTab.module.css';

const TcSummaryTab = ({
	voyageDetails,
	fixtureDetails,
	refreshVoyageDetails,
	refreshFixtureDetails,
}: {
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps | SpotFixtureProps>;
	voyageDetails: GetVoyageDetailsResponse;
	refreshVoyageDetails: () => Promise<void> | void;
	refreshFixtureDetails: () => void;
}) => {
	const [voyages] = useFetchedState(getVoyages);
	const screens = Grid.useBreakpoint();
	const filteredVoyages = useMemo(() => {
		if (voyages == null) {
			return [];
		}

		return voyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, voyages]);

	const [vessel, _refresh, _error, _loadingVesselDetails] = useFetchedState(
		() => getVesselDetails(voyageDetails.vesselId),
		[voyageDetails.vesselId],
	);

	const [ports] = useFetchedState(getPorts);
	const portOptions = getPortOptions(ports ?? []);

	const updateVoyageField = async (field: string, value: any, contractId?: number | null) => {
		try {
			await updateVoyage(contractId ?? voyageDetails.id, { [field]: value });
			await refreshVoyageDetails();
			refreshFixtureDetails();
			showSuccessNotification('Contract updated');
		} catch (e) {
			if (value !== undefined) {
				showErrorNotification('Could not update contract', e as Error);
			}
		}
	};

	const setAlert = () => {
		if (voyageDetails.id != null && fixtureDetails.type !== FixtureTypes.SPOT) {
			if (!voyageDetails.deliveryDate && !voyageDetails.deliveryPort) {
				return 'Missing delivery port and date';
			}

			if (!voyageDetails.deliveryDate) {
				return 'Missing delivery date';
			}

			if (!voyageDetails.deliveryPort) {
				return 'Missing delivery port';
			}
		}

		return false;
	};

	const isAlert = setAlert();
	const alert = isAlert && (
		<span>{isAlert}</span>
	);

	if (voyageDetails == null) {
		return null;
	}

	const getDeliveryRedeliveryItems = () => ([
		{
			key: '1',
			label: (
				<Flex>
					<span>Delivery date</span>
					{alert && (
						<TooltipIcon
							Icon={ExclamationCircleOutlined}
						>
							{alert}
						</TooltipIcon>
					)}
				</Flex>
			),
			value: (
				<DatePicker
					className={styles.fullWidth}
					value={voyageDetails?.deliveryDate}
					onChange={(date) => updateVoyageField('deliveryDate', date)}
					time
				/>
			),
		},
		{
			key: '2',
			label: 'Redelivery date',
			value: (
				<DatePicker
					className={styles.fullWidth}
					value={voyageDetails?.completionDate}
					onChange={(date) => updateVoyageField('completionDate', date)}
					time
				/>
			),
		},
		{
			key: '3',
			label: 'Delivery port',
			value: (
				<Select
					value={voyageDetails?.deliveryPort?.id}
					onChange={(value) => updateVoyageField('deliveryPort', value)}
					options={portOptions}
					showSearch
					allowClear
					onClear={() => updateVoyageField('deliveryPort', null)}
				/>
			),
		},
		{
			key: '4',
			label: 'Redelivery port',
			value: (
				<Select
					value={voyageDetails?.completionPort?.id}
					placeholder="Select port"
					onChange={(value) => updateVoyageField('completionPort', value)}
					options={portOptions}
					showSearch
					allowClear
					onClear={() => updateVoyageField('completionPort', null)}
				/>
			),
		},
	]);

	const getOtherItems = () => ([
		{
			type: 'text',
			key: '1',
			label: 'Duration',
			value: formatTcAndBbFixtureDuration(fixtureDetails as TcFixtureProps),
		},
		{
			key: '2',
			label: 'Charterer',
			value: (
				<>
					{FixtureTypeLabels[fixtureDetails.type]}
					{fixtureDetails.type === FixtureTypes.TC_IN ? ' from ' : ' to '}
					{fixtureDetails.counterpartyName}
				</>
			),
		},
	]);

	return (
		<Row gutter={[16, 16]}>
			<Col sm={24} xxl={7}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card slim>
							<LinkedVoyages
								voyageDetails={voyageDetails}
								voyages={filteredVoyages}
								refreshVoyageDetails={refreshVoyageDetails}
								ownershipPeriods={vessel?.ownershipPeriods ?? []}
							/>
							{(
								voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN &&
								voyageDetails.fixture.type !== FixtureTypes.TC_IN
							) && (
								<VoyageCompletionDetails
									voyageDetails={voyageDetails}
									voyages={filteredVoyages}
									updateVoyageField={updateVoyageField}
								/>
							)							}
						</Card>
					</Col>
					<Col span={24}>
						{screens.xl && !screens.xxl ? (
							<Row gutter={[16, 16]}>
								<Col flex="auto" span={12}>
									<Card size="small" slim>
										<EstimatedDates
											voyageDetails={voyageDetails}
											updateVoyageField={updateVoyageField}
											portOptions={portOptions}
										/>
									</Card>
								</Col>
								<Col span={12}>
									<Card size="small" slim>
										<Details title={null} items={getOtherItems()} />
										<Details title={null} items={getDeliveryRedeliveryItems()} />
									</Card>
								</Col>
							</Row>
						) : (
							<Card slim>
								<Flex vertical gap={10}>
									<EstimatedDates
										voyageDetails={voyageDetails}
										updateVoyageField={updateVoyageField}
										portOptions={portOptions}
									/>
									<Details title={null} items={getDeliveryRedeliveryItems()} />
									<Details title={null} items={getOtherItems()} />
								</Flex>
							</Card>
						)}
					</Col>
				</Row>
			</Col>
			{fixtureDetails.type !== FixtureTypes.TC_IN && (
				<Col sm={24} xxl={17}>
					<ItineraryTab />
				</Col>
			)}
		</Row>
	);
};

export default TcSummaryTab;
