// components/VendorExpensePaymentStateTag.tsx

import React from 'react';
import { Tag } from 'antd';
import { Moment } from 'moment';
import { capitalize } from '@shared/utils/string';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { ExpenseStatus } from '@shared/utils/constants';

type VendorExpensePaymentStateTagProps = {
	amount?: number;
	dueDate?: string | null | undefined | Moment;
	voyageExpensePayments?: Array<{ amount: number }>;
	state?: ExpenseStatus;
	className?: string;
};

const getStateColor = (status: ExpenseStatus): string => {
	switch (status) {
		case ExpenseStatus.PENDING:
			return 'orange';
		case ExpenseStatus.PARTIAL:
			return 'cyan';
		case ExpenseStatus.OVERDUE:
			return 'red';
		case ExpenseStatus.PAID:
			return 'green';
		case ExpenseStatus.OVERPAID:
			return 'red';
		default:
			return 'grey';
	}
};

const VendorExpensePaymentStateTag = ({
	amount = 0,
	dueDate,
	voyageExpensePayments: VoyageExpensePayments = [],
	state,
	className,
}: VendorExpensePaymentStateTagProps) => {
	let determinedStatus: ExpenseStatus;

	if (state) {
		determinedStatus = state;
	} else {
		const totalPayment = calculateTotalPayment(VoyageExpensePayments);
		determinedStatus = getExpenseStatus(totalPayment, amount, dueDate);
	}

	return (
		<Tag className={className} color={getStateColor(determinedStatus)}>
			{capitalize(determinedStatus)}
		</Tag>
	);
};

export default VendorExpensePaymentStateTag;

export const calculateTotalPayment = (
	VoyageExpensePayments: Array<
	{ amount: number }
	> | undefined,
): number => {
	if (!VoyageExpensePayments || !Array.isArray(VoyageExpensePayments)) {
		return 0;
	}

	return VoyageExpensePayments.reduce((sum, payment) => {
		const amt = Number(payment.amount);

		return sum + (Number.isNaN(amt) ? 0 : amt);
	}, 0);
};

export const getExpenseStatus = (
	totalPayment: number,
	expenseAmount: number,
	dueDate: string | null | undefined | Moment,
): ExpenseStatus => {
	const today = nowMoment();
	const expenseDueDate = dueDate ? toMoment(dueDate) : null;

	if (totalPayment === 0) {
		return ExpenseStatus.PENDING;
	}

	if (totalPayment < expenseAmount) {
		if (expenseDueDate && today.isAfter(expenseDueDate, 'day')) {
			return ExpenseStatus.OVERDUE;
		}

		return ExpenseStatus.PARTIAL;
	}

	if (totalPayment === expenseAmount) {
		return ExpenseStatus.PAID;
	}

	return ExpenseStatus.OVERPAID;
};
