import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { Moment } from 'moment';
import { toMoment } from '@shared/utils/date';
import { NavigareAccountingCodes } from '@shared/utils/constants';
import type { SupplierProps } from '@api/models/supplier';
import type {
	TransformedExpenses,
	VoyageWithFixture,
} from '@api/utils/getTransformedVoyageExpenses';
import type FixtureCounterpartyProps from '@api/models/fixture-counterparty';
import type { PortCallProps } from '@api/models/port-call';
import { formatDate } from '@client/utils/formatDate';
import { Links } from '@client/utils/links';
import CountryFlag from '@client/components/CountryFlag';
import VendorExpensePaymentStateTag from '@client/components/VendorExpensePaymentStateTag';
import ApprovalStateTag from '@client/components/ApprovalStateTag';
import styles from './styles/SelectedExpenseItems.module.css';

const getSelectedExpenseItems = (
	selectedExpense: TransformedExpenses & {
		charterer?: FixtureCounterpartyProps | undefined;
		voyage?: VoyageWithFixture | undefined;
	},
	suppliers: Array<SupplierProps>,
	isNavigare?: boolean,
) => {
	if (selectedExpense == null) {
		return [];
	}

	let vessel;
	let charterer;
	let cpDate;

	if (selectedExpense.voyage != null) {
		vessel = selectedExpense.voyage.Vessel;
		charterer = selectedExpense.charterer;
		cpDate = selectedExpense.voyage.Fixture.cpDate;
	}

	return [
		{
			label: 'Payment',
			key: '',
			value: (
				<VendorExpensePaymentStateTag
					amount={selectedExpense.amount}
					dueDate={selectedExpense.dueDate}
					voyageExpensePayments={selectedExpense.VoyageExpensePayments}
					className={styles.statusTag}
				/>
			),
		},
		{
			label: 'Approval',
			key: '',
			render: () => (
				<ApprovalStateTag
					state={selectedExpense.state}
				/>
			),
		},
		{
			label: 'Invoice ID',
			key: 'customInvoiceId',
			value: selectedExpense.customInvoiceId,
			editable: true,
		},
		{
			key: 'amount',
			label: 'Amount',
			value: {
				value: selectedExpense.amount,
				currency: selectedExpense.currency,
				exchangeRate: selectedExpense.exchangeRate,
			},
			editable: true,
			type: 'multiCurrency',
			inputProps: {
				baseCurrency: selectedExpense.fixtureCurrency,
			},
		},
		{
			label: 'Description',
			key: 'itemDescription',
			value: selectedExpense.itemDescription,
			editable: true,
		},
		{
			label: 'Contract',
			value: (
				<Space direction="vertical" size={1}>
					<b>
						<Link to={Links.Voyage.get(selectedExpense.voyageId!)}>
							{selectedExpense.voyageIdentifier}
						</Link>
					</b>
					{charterer != null && (
						<span className={styles.contractSubSection}>
							<>
								Charterer:
								{' '}
								{charterer}
							</>
						</span>
					)}
					{vessel != null && (
						<span className={styles.contractSubSection}>
							Vessel:
							{' '}
							<Link to={Links.Vessel.get(vessel.id)}>
								{vessel.name}
							</Link>
						</span>
					)}
					{cpDate != null && (
						<span className={styles.contractSubSection}>
							<>
								CP Date:
								{' '}
								{cpDate}
							</>
						</span>
					)}
				</Space>
			),
		},
		{
			label: 'Supplier',
			key: 'supplierId',
			value: selectedExpense.supplierId,
			editable: true,
			type: 'select',
			inputProps: {
				showSearch: true,
			},
			options: suppliers.map((s: SupplierProps) => ({
				label: s.name,
				value: s.id,
			})),
		},
		...(selectedExpense.portCall != null ? [{
			key: 'portCall',
			label: 'Port Call',
			editable: false,
			value: selectedExpense.portCall,
			render: (entry: {value: PortCallProps}) => {
				const pc = entry.value;

				if (pc == null) {
					return (<em>-</em>);
				}

				return (
					<Link
						to={`${Links.Voyage.get(pc.voyageId!)}/#/itinerary`}
					>
						<Space>
							<CountryFlag countryCode={pc.port?.countryCode} />
							{`${pc.port?.name}`}
						</Space>
					</Link>

				);
			},
		}] : []),
		{
			key: 'invoiceDate',
			label: 'Invoice Date',
			editable: true,
			type: 'date',
			value: selectedExpense.invoiceDate && toMoment(selectedExpense.invoiceDate),
			render: selectedExpense.invoiceDate && ((entry: {value: Moment}) => formatDate(entry.value)),
		},
		{
			key: 'dueDate',
			label: 'Due Date',
			editable: true,
			type: 'date',
			value: selectedExpense.dueDate && toMoment(selectedExpense.dueDate),
			render: selectedExpense.dueDate && ((entry: {value: Moment}) => formatDate(entry.value)),
		},
		...(isNavigare ? [{
			key: 'accountCodeOverride',
			label: 'Account',
			editable: true,
			type: 'select',
			value: selectedExpense.accountCodeOverride,
			options: Object.keys(NavigareAccountingCodes).map((code) => ({
				label: `${NavigareAccountingCodes[code]} (${code})`,
				value: Number(code),
			})),
			render: (entry: {value?: number | null}) => (
				entry.value == null ? '-' :
					`${NavigareAccountingCodes[entry.value]} (${entry.value})`
			),
		}] : []),
		{
			label: 'Created By',
			value: selectedExpense.createdByUserName,
		},
		{
			key: 'note',
			label: 'Note',
			value: selectedExpense.note,
			editable: true,
			type: 'textarea',
		},
	];
};

export default getSelectedExpenseItems;
