/* eslint-disable max-len */
import React, {
	useMemo,
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import {
	Alert,
	Divider,
	Input,
	Space,
	Typography,
} from 'antd';
import classNames from 'classnames';
import TextArea from 'antd/es/input/TextArea';
import { Moment } from 'moment';
import {
	formatDate,
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { round } from '@shared/utils/math';
import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';
import { formatCurrency } from '@shared/utils/currency';
import { groupByProperty } from '@shared/utils/array';
import getRateForDate from '@shared/utils/getRateForDate';
import getAvailableHIIsFromTcDetails from '@shared/utils/getAvailableHIIsFromTcDetails';
import {
	currencySymbols,
	CustomFieldParentTypes,
	CustomFieldType,
	DATE_AND_TIME,
	FixtureTypes,
	HireTypes,
	HireUnit,
	NULL_STRING,
} from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import HIIExpenseSubjectToHireDays from '@shared/hireInvoice/HIIExpenseSubjectToHireDays';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import HIIRevenueSubjectToDays from '@shared/hireInvoice/HIIRevenueSubjectToDays';
import HIIOffHirePeriod from '@shared/hireInvoice/HIIOffHirePeriod';
import getAvailableBbInvoiceItems from '@shared/utils/getAvailableBbInvoiceItems';
import HIIBbItem from '@shared/hireInvoice/HIIBbItem';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import Select from '@client/components/Select';
import {
	createHireInvoice,
	getCustomFields,
	getHireInvoicePreview,
	updateCustomField,
} from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import DatePicker from '@client/components/DatePicker';
import TooltipIcon from '@client/components/TooltipIcon';
import showErrorNotification from '@client/utils/showErrorNotification';
import PdfGenerator from '@client/components/PdfGenerator/PdfGenerator';
import CurrencyInput from '@client/components/CurrencyInput';
import NumericInput from '@client/components/NumericInput';
import Button from '@client/components/Button';
import { SettingsProps } from '@client/components/PdfGenerator/private/Settings';
import useHirePeriodSplit from '@client/screens/fleet/VoyageDetailsScreen/helpers/useHirePeriodSplit';
import Table from '@client/components/Table/Table';
import useBbItemPeriodSplit from '@client/screens/fleet/VoyageDetailsScreen/helpers/useBbItemPeriodSplit';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import styles from '../styles/HireInvoiceForm.module.css';
import { updateHIIAttribute } from './helpers/invoiceFunctions';

export type Items = HireInvoiceItem[];

type HireInvoiceFormProps = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps
	>;
	open: boolean | object;
	cumulative: boolean;
	readOnly: boolean;
	onClose: () => void;
	onInvoiceCreated: () => void;
}

const HireInvoiceForm: React.FC<HireInvoiceFormProps> = ({
	voyageDetails: details,
	fixtureDetails,
	open,
	cumulative,
	readOnly,
	onClose,
	onInvoiceCreated,
}) => {
	const {
		id: voyageId,
		deliveryDate,
		readyForCumulative,
		completionDate,
	} = details;

	const { userInfo } = useAuth();
	const hirePeriodEndPickerRef = useRef<HTMLElement>(null);

	const [items, setItems] = useState<Items | []>([]);

	const [showPreviousItems, setShowPreviousItems] = useState<boolean>(false);
	const [showPaymentTerms, setShowPaymentTerms] = useState(true);
	const [groupHirePeriods, setGroupHirePeriods] = useState<boolean>(false);
	const [invoiceTitle, setInvoiceTitle] = useState('Hire Invoice');
	const [includeHirePeriod, setIncludeHirePeriod] = useState<boolean>(!cumulative);
	const [invoiceDate, setInvoiceDate] = useState<Moment>(nowMoment());
	const [isCreditNote, setIsCreditNote] = useState(false);
	const [customFieldChange, setCustomFieldChange] = useState(false);

	const invoices = details.hireInvoices;

	const isHireInvoice = useMemo(() => {
		if (typeof open === 'object' && 'includeHire' in open) {
			return open.includeHire as boolean;
		}

		return true;
	}, [open]);

	// If the details are reloaded
	// Refresh the default items
	// But not if the drawer is open
	useEffect(() => {
		if (open || details.id == null || fixtureDetails.id == null) {
			return;
		}

		let newItems = [];

		if (fixtureDetails.type === FixtureTypes.BB_OUT) {
			newItems = getAvailableBbInvoiceItems({
				hireInvoices: invoices ?? [],
				bankAccount: fixtureDetails.bankAccount,
				bbItems: fixtureDetails.BbItems,
				brokers: fixtureDetails.brokers,
				voyageExpenseReceivables: details.voyageExpenseReceivables,
				fromDate: deliveryDate,
				readyForCumulative,
				completionDate: completionDate!,
				useUTC: fixtureDetails.useUTC,
				voyageBunkers: details.voyageBunkers,
				daysDecimals: fixtureDetails.hireDaysDecimals,
			});
		} else {
			newItems = getAvailableHIIsFromTcDetails(
				details,
				fixtureDetails,
				invoices ?? [],
				isHireInvoice,
			);
		}

		setItems(newItems);
	}, [
		open,
		details,
		fixtureDetails,
		isHireInvoice,
		invoices,
		deliveryDate,
		readyForCumulative,
		completionDate,
	]);

	useEffect(() => {
		if (typeof open === 'boolean') {
			return;
		}

		const presetOptions = {
			title: setInvoiceTitle,
			showPrevious: setShowPreviousItems,
			includeHire: setIncludeHirePeriod,
			isCreditNote: setIsCreditNote,
		};

		Object.entries(open).forEach(([option, value]) => {
			const setOption = presetOptions[option as keyof typeof presetOptions];

			if (setOption == null || value == null) {
				return;
			}

			setOption(value);
		});
	}, [open]);

	useEffect(() => {
		if (!cumulative) {
			return;
		}

		setInvoiceTitle('Cumulative Statement');
	}, [cumulative]);

	const HIIBbItems = useMemo(
		() => items.filter((i) => i.getItemType() === HIIBbItem.itemType) as HIIBbItem[],
		[items],
	);

	const otherItems = useMemo(
		() => items.filter((i) => i.getItemType() !== HIIBbItem.itemType) as HireInvoiceItem[],
		[items],
	);

	const [
		itemsWithHIIBbItems,
		updateHIIBbItemProperty,
	] = useBbItemPeriodSplit(HIIBbItems);

	const HIIBbItemsByType = useMemo(() => {
		const groupedItems: Record<string, HIIBbItem> = {};
		HIIBbItems.forEach((item) => {
			if (item.type) {
				groupedItems[item.type] = item;
			}
		});

		return groupedItems;
	}, [HIIBbItems]);

	const [
		allHirePeriodItems,
		[primaryHirePeriod, secondaryHirePeriod],
		hirePeriodSplitDate,
		setHirePeriodSplitDate,
		updateHirePeriodProperty,
		updateHirePeriodEnd,
		updateExpenseSubjectToHireDays,
	] = useHirePeriodSplit(otherItems);

	const allItems = useMemo(() => [
		...itemsWithHIIBbItems,
		...allHirePeriodItems,
	], [itemsWithHIIBbItems, allHirePeriodItems]);

	const hirePeriodStart = primaryHirePeriod?.from;
	const hirePeriodEnd = (secondaryHirePeriod || primaryHirePeriod)?.to;

	const initialItems = useMemo(() => (cumulative ? [] : allItems.filter((i) => (
		!i.addManually &&
		// Remove all hire periods if includeHirePeriod is false
		(includeHirePeriod || !(i instanceof HIIHirePeriod))
	))), [cumulative, includeHirePeriod, allItems]);

	const getAddedItems = useCallback((addedItemIds: Array<number | string>) => [
		...initialItems,
		...allItems.filter((i) => addedItemIds.includes(i.id)),
	], [initialItems, allItems]);

	const save = async (addedItemIds: (number | string)[]) => {
		try {
			await createHireInvoice(
				voyageId,
				getAddedItems(addedItemIds),
				{
					chartererId: fixtureDetails.counterpartyId,
					cumulative,
					showPreviousItems,
					invoiceTitle,
					invoiceDate,
					groupHirePeriods,
					showPaymentTerms,
					isCreditNote,
				},
			);
			await onInvoiceCreated();
			onClose();
		} catch (e) {
			showErrorNotification('Could not save hire invoice', e as Error);
		}
	};

	const onUpdateInvoice = useCallback((addedItemIds: (number | string)[]) => {
		const invoiceTotal = getAddedItems(addedItemIds).reduce((acc, item) => (
			acc + item.getTotal(allItems, true)
		), 0);

		if (invoiceTotal < 0) {
			setIsCreditNote(true);
		} else {
			setIsCreditNote(false);
		}
	}, [getAddedItems, allItems]);

	const fetchPreview = useCallback((
		previewItemIds: Array<number | string>,
	) => getHireInvoicePreview(
		details.id,
		getAddedItems(previewItemIds),
		{
			showPreviousItems: showPreviousItems || cumulative,
			showPaymentTerms,
			paymentTerm: fixtureDetails.paymentTerm,
			paymentTermValue: fixtureDetails.paymentTermValue,
			groupHirePeriods,
			invoiceTitle,
			invoiceDate,
			isCreditNote,
		},
	), [details.id, getAddedItems, groupHirePeriods, fixtureDetails, showPreviousItems, cumulative, invoiceTitle, invoiceDate, showPaymentTerms, isCreditNote]);

	const sections = groupByProperty(
		allItems,
		(i) => (i.addManually ? i.templateSection : NULL_STRING),
	);

	const getConfirmStatus = (addedItems: any[]): (null | [null | 'error' | 'warning', string]) => {
		if (cumulative && items.length > addedItems.length) {
			return ['warning', 'Not all items have been added to the invoice. Continue?'];
		}

		if (
			(includeHirePeriod && primaryHirePeriod?.hireRate === 0) ||
			(includeHirePeriod && secondaryHirePeriod != null && secondaryHirePeriod.hireRate === 0)
		) {
			return ['warning', `Hire per day is set to ${currencySymbols[fixtureCurrency as keyof typeof currencySymbols] || `${fixtureCurrency} `}0. Continue?`];
		}

		return null;
	};

	const fixtureCurrency = details.bankAccount == null ?
		userInfo.baseCurrency :
		details.bankAccount.currency;

	const getExpensesSubjectToHireDaysSettings = () => {
		const hirePeriods = allItems.filter((item) => item instanceof HIIHirePeriod);
		const [filteredHirePeriod] = hirePeriods.map((hirePeriod) => {
			return {
				...hirePeriod,
				children: hirePeriod.children.filter(
					(child) => child instanceof HIIExpenseSubjectToHireDays,
				) as HIIExpenseSubjectToHireDays[],
			};
		});

		if (filteredHirePeriod == null) {
			return [];
		}

		return [
			...filteredHirePeriod.children
				.map((expense) => ({
					label: expense.name,
					content: (
						<CurrencyInput
							currency={fixtureCurrency}
							value={expense.getTotal(allItems) ?? 0}
							onChange={(amount) => updateExpenseSubjectToHireDays(
								filteredHirePeriod.id,
								expense.id,
								amount,
								true,
							)}
						/>
					),
				})),
		];
	};

	const getHirePeriodSettings = (
		primary: boolean,
	) => {
		const hireItem = (primary ? primaryHirePeriod : secondaryHirePeriod) || {};
		const rateScheduleItem = getRateForDate(hireItem.from, fixtureDetails.rateSchedule);

		const overlapWarning = (
			fixtureDetails.useRateSchedule &&
			rateScheduleItem &&
			rateScheduleItem.to
		) ? toMoment(hireItem.to).isAfter(rateScheduleItem.to) : false;

		const hasError = (fixtureDetails.useRateSchedule &&
			rateScheduleItem != null &&
			rateScheduleItem.rate !== hireItem.hireRate);

		return [
			...(fixtureDetails.useRateSchedule && rateScheduleItem == null ? [{
				hide: !includeHirePeriod,
				label: '',
				content: (
					<Alert
						showIcon
						type="warning"
						message="No rate schedule found"
						description={`The contract specifies a rate schedule, but no rate period corresponding to this hire period (beginning ${formatDate(hireItem.from, DATE_AND_TIME)}) has been found.`}
					/>
				),
			}] : []),
			...((!fixtureDetails.useRateSchedule || rateScheduleItem == null) ? [] : [{
				hide: !includeHirePeriod,
				label: '',
				content: (
					<>
						{overlapWarning && (
							<Alert
								type="warning"
								showIcon
								message="Period is overlapping rates"
								description="Be aware that the end of the of the hire period, is potentially overlapping multiple rate schedule periods"
							/>
						)}
						<Typography.Title level={4}>
							Rate schedule
						</Typography.Title>
						<Table
							pagination={false}
							dataSource={fixtureDetails.rateSchedule}
							size="small"
							rowClassName={(row) => classNames({
								[styles.bold]: rateScheduleItem.id === row.id,
							})}
							columns={[
								{
									dataIndex: 'from',
									title: 'Start',
									render: (c: number) => (c == null ? (
										<em>Empty</em>
									) : formatDate(c, DATE_AND_TIME)),
								},
								{
									dataIndex: 'to',
									title: 'End',
									render: (c: number) => (c == null ? (
										<em>Empty</em>
									) : formatDate(c, DATE_AND_TIME)),
								},
								{
									dataIndex: 'rate',
									title: 'Rate',
									render: (c: number) => (c == null ? (
										<em>Empty</em>
									) : formatCurrency(c, fixtureCurrency)),
								},
							]}
						/>
					</>
				),
			}]),
			{
				hide: !includeHirePeriod,
				label: 'Hire Type',
				content: (
					<Select
						value={hireItem.hireType}
						onChange={(v) => updateHirePeriodProperty(primary, 'hireType', v)}
						options={Object.values(HireTypes).map((v) => ({
							label: capitalize(v),
							value: v,
						}))}
					/>
				),
			},
			{
				hide: !includeHirePeriod,
				label: 'Hire',
				error: hasError || overlapWarning,
				content: (
					<Space direction="vertical">
						<CurrencyInput
							currency={fixtureCurrency}
							className={classNames(styles.settingsInput, {
								[styles.missingHire]: includeHirePeriod && hireItem.hireRate === 0,
							})}
							value={hireItem.hireRate}
							onChange={(v) => updateHirePeriodProperty(primary, 'hireRate', v)}
							addonAfter={
								fixtureDetails.hireUnit === HireUnit.MONTHS ?
									'/ month' :
									'/ day'
							}
						/>
						{hasError && (
							<Alert showIcon type="error" message="Hire per day does not match rate schedule" />
						)}
					</Space>
				),
			},
			{
				hide: !includeHirePeriod,
				label: 'Hire description',
				content: (
					<Input
						placeholder={fixtureDetails.hireDescription ?? ''}
						className={styles.settingsInput}
						value={hireItem.hireDescription}
						onChange={(e) => updateHirePeriodProperty(primary, 'hireDescription', e.target.value)}
					/>
				),
			},
		];
	};

	const [fields] = useFetchedState(async () => {
		return await getCustomFields({
			parentId: fixtureDetails?.organizationId!,
			parentType: CustomFieldParentTypes.ORG,
		});
	}, [fixtureDetails.organizationId, customFieldChange]);

	const renderItemContent = (item: HireInvoiceItem) => {
		switch (item.getItemType()) {
			case HIIRevenueSubjectToDays.itemType: {
				const relevantItem = item as HIIRevenueSubjectToDays;

				return (
					<div className={styles.revenueSubjectToDaysContainer}>
						<p className={styles.revenueDescription} style={{ margin: 0 }}>
							{formatCurrency(relevantItem.amount, relevantItem.fixtureCurrency)}
							{' '}
							per day
						</p>
						<div className={styles.revenueDateContainer}>
							<div>
								<b className={styles.revenueDatesLabel}>Start</b>
								<DatePicker
									defaultValue={isHireInvoice ? relevantItem.startDate : undefined}
									time
									stopPropagation
									allowClear={false}
									maxDate={relevantItem.endDate}
									onClick={(e) => {
										e.stopPropagation();
									}}
									onChange={(v) => {
										updateHIIAttribute(relevantItem, 'startDate', v, setItems);
									}}
								/>
							</div>
						</div>
						<div className={styles.revenueDateContainer}>
							<div>
								<b className={styles.revenueDatesLabel}>End</b>
								<DatePicker
									defaultValue={isHireInvoice ? relevantItem.endDate : undefined}
									time
									stopPropagation
									allowClear={false}
									minDate={relevantItem.startDate}
									onClick={(e) => {
										e.stopPropagation();
									}}
									onChange={(v) => {
										updateHIIAttribute(relevantItem, 'endDate', v, setItems);
									}}
								/>
							</div>
						</div>
						<div>
							<b>Total:</b>
							{' '}
							{formatCurrency(relevantItem.calculateAmount(), relevantItem.fixtureCurrency)}
						</div>
					</div>
				);
			}

			case HIIOffHirePeriod.itemType: {
				const { type } = (item as HIIOffHirePeriod);

				return (
					<>
						{(type != null) && (
							<>
								<span>
									<span style={{ fontWeight: 'bold' }}>Type: </span>
									{type}
								</span>

							</>
						)}
						<p className={styles.itemDescription}>{item.getDescription(items)}</p>
						<span>
							{formatCurrency(Math.abs(item.getTotal(items, true)), fixtureCurrency)}
						</span>
					</>
				);
			}

			default:
				return (
					<>
						<p className={styles.itemDescription}>{item.getDescription(items)}</p>
						<span>
							{formatCurrency(Math.abs(item.getTotal(items, true)), fixtureCurrency)}
						</span>
					</>
				);
		}
	};

	const settingsSections: SettingsProps['settings'] = {
		General: [
			{
				hide: cumulative,
				label: 'Title',
				content: (
					<Input
						value={invoiceTitle}
						onChange={(e) => setInvoiceTitle(e.target.value)}
					/>
				),
			},
			{
				hide: cumulative,
				label: 'Invoice Date',
				content: (
					<DatePicker
						allowClear={false}
						showNow={false}
						className={styles.settingsInput}
						value={invoiceDate}
						onChange={(date) => setInvoiceDate(date as Moment)}
						range={false}
					/>
				),
			},
			{
				hide: cumulative,
				label: 'Include hire period',
				content: (
					<Select
						value={includeHirePeriod}
						onChange={(v) => setIncludeHirePeriod(v as boolean)}
						options={[
							{ label: 'Include', value: true },
							{ label: 'Do not include', value: false },
						]}
					/>
				),
			},
			{
				hide: cumulative || !includeHirePeriod,
				label: 'Hire period end',
				content: (
					<>
						<DatePicker
							ref={hirePeriodEndPickerRef}
							allowClear={false}
							time
							showNow={false}
							className={styles.settingsInput}
							dropdownClassName={styles.hireEndDatePicker}
							value={hirePeriodEnd}
							onChange={(v) => updateHirePeriodEnd(v as Moment)}
							minDate={hirePeriodSplitDate || hirePeriodStart}
							maxDate={
								details.completionDate != null ?
									toMoment(details.completionDate) :
									undefined
							}
							range={false}
							renderExtraFooter={() => (
								<>
									<Button
										type="link"
										size="small"
										disabled={details.completionDate == null}
										onClick={() => {
											if (details.completionDate == null) {
												return;
											}

											updateHirePeriodEnd(toMoment(details.completionDate).utc());
											hirePeriodEndPickerRef?.current?.blur();
										}}
									>
										Add remaining hire
									</Button>
									{details.completionDate == null && (
										<TooltipIcon>
											Select a Redelivery Date to enable this option
										</TooltipIcon>
									)}
								</>
							)}
						/>
						<Divider className={styles.hireEndDivider}>or</Divider>
						<NumericInput
							value={(hirePeriodStart == null || hirePeriodEnd == null ?
								undefined :
								round(toMoment(hirePeriodEnd).diff(hirePeriodStart, 'days', true))
							)}
							onChange={(value) => {
								let newDate;

								if (value == null) {
									newDate = toMoment(hirePeriodStart);
								} else if (Number.isInteger(value)) {
									newDate = toMoment(hirePeriodStart).add(value, 'days');
								} else {
									newDate = toMoment(hirePeriodStart).add(value * 24, 'hours');
								}

								updateHirePeriodEnd(newDate);
							}}
							addonAfter="Days"
							returnNullOnEmpty
							allowNegative={false}
						/>
					</>
				),
			},
			{
				hide: !includeHirePeriod,
				label: (
					<>
						Split Hire Period
						<TooltipIcon>
							If the hire invoice should include multiple separate hire rates,
							choose a date to split the hire period.
						</TooltipIcon>
					</>
				),
				content: (
					<DatePicker
						allowClear
						time
						value={hirePeriodSplitDate}
						onChange={(v) => setHirePeriodSplitDate(v as Moment)}
						minDate={primaryHirePeriod?.from}
						maxDate={(secondaryHirePeriod || primaryHirePeriod)?.to}
						range={false}
					/>
				),
			},
			{
				hide: cumulative,
				label: 'Include previous items and payments',
				content: (
					<Select
						value={showPreviousItems}
						onChange={(v) => typeof v === 'boolean' && setShowPreviousItems(v)}
						options={[
							{ label: 'Do not include', value: false },
							{ label: 'Include', value: true },
						]}
					/>
				),
			},
			{
				hide: false,
				label: 'Group Hire Periods',
				content: (
					<Select
						value={groupHirePeriods}
						onChange={(v) => typeof v === 'boolean' && setGroupHirePeriods(v)}
						options={[
							{ label: 'Yes', value: true },
							{ label: 'No', value: false },
						]}
					/>
				),
			},
			{
				hide: cumulative,
				label: 'Include payment term',
				content: (
					<Select
						value={showPaymentTerms}
						onChange={(v) => typeof v === 'boolean' && setShowPaymentTerms(v)}
						options={[
							{ label: 'Include', value: true },
							{ label: 'Do not include', value: false },
						]}
					/>
				),
			},
			{
				hide: false,
				label: 'Credit note',
				content: (
					<Select
						value={isCreditNote}
						onChange={(v) => typeof v === 'boolean' && setIsCreditNote(v)}
						options={[
							{ label: 'Yes', value: true },
							{ label: 'No', value: false },
						]}
					/>
				),
			},
		],
		...(includeHirePeriod ? (
			hirePeriodSplitDate == null ? {
				'Hire Rate': getHirePeriodSettings(true),
			} : {
				'Hire Rate (1)': getHirePeriodSettings(true),
				'Hire Rate (2)': getHirePeriodSettings(false),
			}
		) : {}),
		...(fixtureDetails.expensesSubjectToHireDays && fixtureDetails.expensesSubjectToHireDays.length > 0 ? { 'Expenses Subject to Hire Days': getExpensesSubjectToHireDaysSettings() } : {}),
	};

	const createBbItemSettings = (type: string) => [
		{
			label: 'Start',
			content: (
				<DatePicker
					allowClear={false}
					showNow={false}
					time
					className={styles.settingsInput}
					value={HIIBbItemsByType[type]?.from}
					onChange={(date) => {
						if (date && HIIBbItemsByType[type]) {
							updateHIIBbItemProperty(HIIBbItemsByType[type].id, 'from', date);
						}
					}}
					range={false}
				/>
			),
		},
		{
			label: 'End',
			content: (
				<DatePicker
					allowClear={false}
					showNow={false}
					time
					className={styles.settingsInput}
					value={HIIBbItemsByType[type]?.to}
					onChange={(date) => {
						if (date && HIIBbItemsByType[type]) {
							updateHIIBbItemProperty(HIIBbItemsByType[type].id, 'to', date);
						}
					}}
					range={false}
				/>
			),
		},
		{
			label: 'Rate',
			content: (
				<CurrencyInput
					currency={fixtureCurrency}
					className={styles.settingsInput}
					value={HIIBbItemsByType[type]?.amount}
					onChange={(value) => {
						if (HIIBbItemsByType[type]) {
							updateHIIBbItemProperty(HIIBbItemsByType[type].id, 'amount', value);
						}
					}}
					addonAfter={
						fixtureDetails.hireUnit === HireUnit.MONTHS ? '/ month' : '/ day'
					}
				/>
			),
		},
	];

	if (HIIBbItemsByType.Drydock) {
		settingsSections.Drydock = createBbItemSettings('Drydock');
	}

	if (HIIBbItemsByType.Capex) {
		settingsSections.Capex = createBbItemSettings('Capex');
	}

	if (HIIBbItemsByType.Opex) {
		settingsSections.Opex = createBbItemSettings('Opex');
	}

	if (HIIBbItemsByType['TC Hire']) {
		settingsSections['TC Hire'] = createBbItemSettings('TC Hire');
	}

	const settings = settingsSections;

	if (fields != null) {
		const handleUpdate = async (
			id: number,
			type: CustomFieldType,
			label: string,
			value: string | number | Date,
		) => {
			await updateCustomField({
				id,
				type,
				label,
				value,
			}).then(() => setCustomFieldChange(!customFieldChange));
		};

		fields.sort((a, b) => a.id - b.id).forEach((f) => {
			let field = null;

			switch (f.type) {
				case CustomFieldType.TEXT:
					field = (
						<TextArea
							defaultValue={f.value.toString()}
							onChange={(async (e) => handleUpdate(f.id, f.type, f.label, e.currentTarget.value))}
						/>
					);
					break;
				case CustomFieldType.NUMBER:
					field = (
						<Input
							defaultValue={f.value.toString()}
							onChange={(async (e) => handleUpdate(f.id, f.type, f.label, e.currentTarget.value))}
						/>
					);
					break;
				case CustomFieldType.DATEPICKER:
					field = (
						<DatePicker
							defaultValue={f.value !== '' ? (f.value as unknown as Moment) : null}
							time
							rangeHorizontal
							allowClear
							onCalendarChange={(newValue: Moment | Moment[]) => handleUpdate(f.id, f.type, f.label, newValue != null ? (newValue as Moment).toDate() : '')}
						/>
					);
					break;
				default:
					field = (<TextArea />);
			}

			const entry = {
				hide: false,
				label: f.label,
				content: field,
			};

			(settings as any).General.push(entry);
		});
	}

	return (
		<PdfGenerator
			title={(
				<>
					{(cumulative ?
						'Generate cumulative hire statement' :
						'Generate hire invoice'
					)}
					<TooltipIcon>
						To add items to the hire invoice, just drag-and-drop
						them from the left onto the PDF invoice on the right
					</TooltipIcon>
				</>
			)}
			open={open}
			onClose={onClose}
			settings={settings}
			settingsDefaultOpen={fixtureDetails.hireRate == null || cumulative}
			sections={sections}
			renderItem={(item: Items[number]) => (
				<div className={styles.item}>
					{renderItemContent(item)}
				</div>
			)}
			readOnly={readOnly}
			getConfirmStatus={getConfirmStatus}
			onUpdateInvoice={onUpdateInvoice}
			onSave={save}
			getPreview={fetchPreview}
		/>
	);
};

export default HireInvoiceForm;

