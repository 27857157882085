import React from 'react';
import {
	Col,
	Row,
	Flex,
	Grid,
	Badge,
	Space,
} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import {
	FeatureFlags,
	FixtureTypeLabels,
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import formatTcAndBbFixtureDuration from '@shared/utils/formatTcAndBbFixtureDuration';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import Card from '@client/components/Card/Card';
import Details from '@client/components/Details';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import Button from '@client/components/Button';
import styles from './styles/SelectItineraryEntryAndDates.module.css';
import LinkedVoyages from './LinkedVoyages/LinkedVoyages';
import ItineraryTab from './ItineraryTab/ItineraryTab';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import SelectItineraryEntryAndDates from './SelectItineraryEntryAndDates';
import TcInSelector from './TcInSelector';
import ProjectView from './ProjectView/ProjectView';

const TcSummaryTab = () => {
	const {
		voyageDetails,
		tcFixtureDetails: fixtureDetails,
		linkTheShip,
	} = useVoyage();

	const usesTheShip = useFeatureFlag(FeatureFlags.THE_SHIP);

	const screens = Grid.useBreakpoint();

	if (voyageDetails == null) {
		return null;
	}

	const getOtherItems = () => ([
		{
			type: 'text',
			key: '1',
			label: 'Duration',
			value: formatTcAndBbFixtureDuration(fixtureDetails as TcFixtureProps),
		},
		{
			key: '2',
			label: 'Charterer',
			value: (
				<>
					{FixtureTypeLabels[fixtureDetails.type]}
					{fixtureDetails.type === FixtureTypes.TC_IN ? ' from ' : ' to '}
					{fixtureDetails.counterpartyName}
				</>
			),
		},
		...(!usesTheShip ? [] : [{
			key: '3',
			label: 'TheShip Integration',
			value: voyageDetails.vesselExternalId ? (
				<Badge status="success" text="Connected" />
			) : (
				<Space>
					<Badge status="error" text="Not connected" />
					<Button
						onClick={linkTheShip}
						type="link"
						icon={(<LinkOutlined />)}
					>
						Connect
					</Button>
				</Space>
			),
		}]),
	]);

	return (
		<Row gutter={[16, 16]}>
			<Col sm={24} xxl={7}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card slim>
							{(
								voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN &&
								voyageDetails.fixture.type !== FixtureTypes.TC_IN
							) && (
								<div style={{ padding: 8 }}>
									<TcInSelector />
								</div>
							)}
							<LinkedVoyages />
						</Card>
					</Col>
					<Col span={24}>
						{screens.xl && !screens.xxl ? (
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Card size="small" slim>
										<Details className={styles.details} title={null} items={getOtherItems()} />
										<SelectItineraryEntryAndDates />
									</Card>
								</Col>
							</Row>
						) : (
							<Card slim>
								<Flex vertical gap={10}>
									<SelectItineraryEntryAndDates />
									<Details className={styles.details} title={null} items={getOtherItems()} />
								</Flex>
							</Card>
						)}
					</Col>
				</Row>
			</Col>
			{fixtureDetails.type === FixtureTypes.TC_IN && (
				<Col sm={24} xxl={17}>
					<ProjectView />
				</Col>
			)}
			{fixtureDetails.type !== FixtureTypes.TC_IN && (
				<Col sm={24} xxl={17}>
					<ItineraryTab />
				</Col>
			)}
		</Row>
	);
};

export default TcSummaryTab;
