import React, {
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Col,
	Divider,
	Empty,
	Row,
	Space,
} from 'antd';
import {
	CargoUnitLabels,
	CargoUnitSingularLabels,
	CargoUnitTypes,
	FreightRateType,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { splitActionKey } from '@shared/utils/splitActionKey';
import {
	capitalize,
	formatQuantity,
} from '@shared/utils/string';
import { formatNumber } from '@shared/utils/formatNumber';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import Table from '@client/components/Table/Table';
import StatisticCard from '@client/components/StatisticCard';
import EditableTable from '@client/components/EditableTable';
import CreditNoteCheckbox from '@client/components/CreditNoteCheckbox';
import styles from './FreightDemurrageDespatchTab.module.css';

const FreightTablesTab = ({
	selectedCargo,
	onQuantityChange,
	loading,
}: {
	loading: boolean;
	selectedCargo: GetCargosAndLaytimesResponse[number] | undefined;
	onQuantityChange: (value: number | null, cp: { id: number }, creditNote: boolean) => void;
}) => {
	const [createCreditNote, setCreateCreditNote] = useState(false);

	const freightTableData = useMemo(() => {
		if (selectedCargo == null) {
			return [];
		}

		return [...(selectedCargo.totalActuallyLoaded !== 0 ? [{
			type: 'Freight',
			cargo: selectedCargo.totalFreightAmount,
			rate: selectedCargo.freightRate,
			rateType: selectedCargo.freightType,
			unit: selectedCargo.unit,
			invoiced: selectedCargo.totalFreightInvoiced,
		}] : []),
		...((selectedCargo.totalDeadfreightAmount !== 0 && selectedCargo.totalActuallyLoaded !== 0) ? [{
			type: 'Deadfreight',
			cargo: selectedCargo.totalDeadfreightAmount,
			rate: selectedCargo.deadfreightAmount ?? 0,
			unit: selectedCargo.unit,
			rateType: selectedCargo.freightType,
			invoiced: selectedCargo.totalDeadfreightInvoiced,
		}] : []),
		...(selectedCargo.totalOverageAmount !== 0 ? [{
			type: 'Overage',
			cargo: selectedCargo.totalOverageAmount,
			unit: selectedCargo.unit,
			rate: selectedCargo.overageAmount ?? 0,
			rateType: selectedCargo.freightType,
			invoiced: selectedCargo.totalOverageInvoiced,
		}] : [])];
	}, [selectedCargo]);

	const quantityTableData = useMemo(() => {
		if (selectedCargo == null) {
			return [];
		}

		return selectedCargo.CargoPorts.map((cp) => ({
			id: cp.id,
			port: cp.port.name,
			cpQuantity: cp.cpQuantity,
			blQuantity: cp.blQuantity,
			key: cp.portAndActionKey,
			action: capitalize(splitActionKey(cp.portAndActionKey).action),
		})).sort((a, b) => b.action.localeCompare(a.action) || a.port.localeCompare(b.port));
	}, [selectedCargo]);

	if (selectedCargo == null) {
		return (<Empty />);
	}

	const minQuantityAllowed = (
		(100 - (selectedCargo?.quantityTolerance ?? 0)) / 100
	) * selectedCargo?.quantity;

	const maxQuantityAllowed = (
		(100 + (selectedCargo?.quantityTolerance ?? 0)) / 100
	) * selectedCargo?.quantity;

	const handleBLQuantityChange = async (rowKey: string, value: number | null) => {
		const cargoPort = quantityTableData.find((cp) => cp.key === rowKey);

		if (cargoPort != null) {
			await onQuantityChange(value, cargoPort, createCreditNote);
		}
	};

	return (
		<Row gutter={[16, 16]}>
			<Col span={4}>
				<StatisticCard items={[{
					label: 'CP Quantity',
					children: formatQuantity(selectedCargo.quantity, selectedCargo.unit),
				}]}
				/>
			</Col>
			<Col span={4}>
				<StatisticCard items={[{ label: 'QTY Tolerance', children: `${selectedCargo?.quantityTolerance ?? 0}%` }]} />
			</Col>
			<Col span={4}>
				<StatisticCard items={[{
					label: 'Freight Rate',
					children: `${formatCurrency(
						selectedCargo.freightRate,
						selectedCargo.currency,
					)}
						${selectedCargo.freightType === FreightRateType.LUMPSUM ? '' : `/ ${CargoUnitLabels[selectedCargo.unit]}`}`,
				}]}
				/>
			</Col>
			<Col span={4}>
				<StatisticCard items={[{ label: 'Overage Rate', children: formatCurrency(selectedCargo.overageAmount ?? 0, selectedCargo.currency) }]} />
			</Col>
			<Col span={4}>
				<StatisticCard items={[{ label: 'Deadfreight Rate', children: formatCurrency(selectedCargo.deadfreightAmount ?? 0, selectedCargo.currency) }]} />
			</Col>
			<Col xs={24} xxl={12}>
				{selectedCargo.totalActuallyLoaded !== selectedCargo.totalActuallyDischarged && (
					<Alert
						className={styles.alert}
						type="warning"
						showIcon
						message="Total amount loaded does not match with total amount discharged"
					/>
				)}
				<EditableTable
					pagination={false}
					keyDataIndex="key"
					bordered
					saveOnEnter={selectedCargo.HIIFreights.length === 0}
					loading={loading}
					onSave={(rowKey, value) => handleBLQuantityChange(rowKey, value.blQuantity)}
					dataSource={quantityTableData}
					iconButtons
					confirmTitle={selectedCargo.HIIFreights.length === 0 ? undefined : (
						<CreditNoteCheckbox
							description="Since freight has already been invoiced, you might want to generate a credit note"
							createCreditNote={createCreditNote}
							setCreateCreditNote={setCreateCreditNote}
							showCumulativeAlert={false}
						/>
					)}
					columns={[
						{ title: 'Port', key: 'port', dataIndex: 'port', width: '33%' },
						{ title: 'Action', key: 'action', dataIndex: 'action', width: '33%' },
						{ title: 'BL Quantity',
							width: '33%',
							key: 'quantity',
							dataIndex: 'blQuantity',
							editingProps: {
								type: 'number',
								inputProps: {
									addonAfter: selectedCargo?.unit != null && CargoUnitTypes[selectedCargo.unit],
									separateThousands: true,
									allowClear: true,
								},
							},
							render: (q: number | null) => (
								<div style={q != null ? {} : { opacity: 0.65 }}>
									<Space>
										{formatNumber(
											q != null ? q : 0, { separateThousands: true },
										)}
										{selectedCargo?.unit != null && CargoUnitTypes[selectedCargo.unit]}
									</Space>
								</div>
							),
							editable: true },
					]}
				/>
			</Col>
			<Divider />
			<Col span={6}>
				<StatisticCard items={[{ label: 'Total Actually Loaded', children: formatQuantity(selectedCargo.totalActuallyLoaded ?? 0, selectedCargo.unit) }]} />
			</Col>
			<Col span={6}>
				<StatisticCard items={[{ label: 'Min. Allowed', children: formatQuantity(minQuantityAllowed, selectedCargo.unit) }]} />
			</Col>
			<Col span={6}>
				<StatisticCard items={[{ label: 'Max. Allowed', children: formatQuantity(maxQuantityAllowed, selectedCargo.unit) }]} />
			</Col>
			<Col span={6}>
				<StatisticCard items={[{
					label: selectedCargo.quantity - (selectedCargo.totalActuallyLoaded ?? 0) < 0 ? 'Over CP' : 'Under CP',
					children: formatQuantity(
						(selectedCargo.totalActuallyLoaded ?? 0) - selectedCargo.quantity,
						selectedCargo.unit,
					),
				}]}
				/>
			</Col>
			<Col span={24}>
				<Table
					className={styles.table}
					pagination={false}
					bordered
					dataSource={freightTableData}
					emptyText="Insert a BL Quantity for loading port to start calculating"
					columns={[
						{ title: 'Type', key: 'type', dataIndex: 'type' },
						{ title: 'Cargo', key: 'cargo', dataIndex: 'cargo', render: (c, cargo) => formatQuantity(c, cargo.unit) },
						{ title: 'Rate',
							key: 'rate',
							dataIndex: 'rate',
							render: (v, c) => (`
								${formatCurrency(v, selectedCargo.currency)} 
								${c.rateType === FreightRateType.LUMPSUM ? ' Lumpsum' : `per ${c.unit != null ? CargoUnitSingularLabels[c.unit] ?? 'Unit' : 'Unit'}`}
							`) },
						{ title: 'Gross',
							key: 'gross',
							dataIndex: 'gross',
							render: (_v, c) => {
								let amount = 0;

								if (c.cargo != null) {
									amount = c.cargo * (c?.rate ?? 0);
								}

								if (c.rateType === FreightRateType.LUMPSUM) {
									amount = c.rate;
								}

								return formatCurrency(amount, selectedCargo.currency);
							} },
						{ title: 'Invoiced', key: 'invoiced', dataIndex: 'invoiced', render: (_v, c) => formatCurrency(c.invoiced == null ? 0 : c.invoiced, selectedCargo.currency) },
					]}
				/>
			</Col>
		</Row>
	);
};

export default FreightTablesTab;
