import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Flex,
	Modal,
	Space,
	Tabs,
} from 'antd';
import { useHistory } from 'react-router';
import {
	CompassOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import { EstimateStatus } from '@shared/utils/constants';
import { sortByDates } from '@shared/utils/sortByDates';
import Button from '@client/components/Button';
import Table from '@client/components/Table/Table';
import {
	createContractFromEstimate,
	deleteFixture,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import { Links } from '@client/utils/links';
import SavingIndicator from '@client/components/SavingIndicator';
import { useEstimate } from '../context/EstimatorContext';
import { useCentralStore } from '../context/CentralStoreContext';
import { useCargoProvider } from '../context/CargoContext';
import styles from './styles/TopBar.module.css';

const TopBar = () => {
	const [bunkerPricesModalOpen, setBunkerPricesModalOpen] = useState(false);
	const history = useHistory();
	const {
		estimate,
		onUpdateEstimate,
		fixtureHasInvoices,
	} = useEstimate();

	const {
		estimates,
		selectedEstimateId,
		setSelectedEstimateId,
		handleCreateSubEstimate,
		onDeleteEstimate,
		loading,
		refreshEverything,
	} = useCentralStore();

	const {
		isReadyToFix,
		isReadyToFixMessage,
	} = useCargoProvider();

	const fixEstimate = async () => {
		if (estimate != null) {
			try {
				const id = await createContractFromEstimate(estimate.id);

				showSuccessNotification('Contract created');
				history.push(Links.Voyage.get(id));
			} catch (e) {
				showErrorNotification('Could not create contract', e as Error);
			}
		}
	};

	const isReadyToUnfix = useMemo(() => {
		let ready = true;
		const messages = [];

		if (fixtureHasInvoices) {
			ready = false;
			messages.push('An estimate cannot be fixed, if the associated contract has an invoice raised');
		}

		const messageJSX = (
			<ul>
				{messages.map((msg, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={index}>{msg}</li>
				))}
			</ul>
		);

		return {
			ready,
			message: messageJSX,
		};
	}, [fixtureHasInvoices]);

	const onUnfixEstimate = useCallback(async () => {
		if (estimate?.fixtureId == null) {
			return;
		}

		if (fixtureHasInvoices) {
			showErrorNotification(
				'Could not unfix estimate',
				new Error('An estimate cannot be unfixed, if the associated contract has an invoice raised'),
			);

			return;
		}

		await deleteFixture(estimate.fixtureId);
		await onUpdateEstimate({ status: EstimateStatus.Available }, true);
		await refreshEverything();
	}, [onUpdateEstimate, refreshEverything, estimate, fixtureHasInvoices]);

	const isFixed = useMemo(
		() => !(estimate?.fixtureId == null || !estimate.fixtureId),
		[estimate],
	);

	const items = sortByDates(estimates, 'createdAt')
		.map((e) => ({
			label: e.name,
			key: e.id.toString(),
		}));

	return (
		<>
			<Card className={styles.card}>
				<Flex justify="space-between">
					<div
						className={styles.tabContainer}
					>
						<Tabs
							className={styles.tabs}
							items={items}
							onTabClick={(key) => setSelectedEstimateId(Number(key))}
							activeKey={selectedEstimateId?.toString()}
						/>
					</div>
					<Space>

						<SavingIndicator spinning={loading} />
						{isFixed && estimate?.fixtureId != null && (
							<Button
								type="link"
								onClick={() => {
									if (estimate.voyage != null) {
										history.push(Links.Voyage.get(estimate?.voyage.id));
									}
								}}
								icon={(<CompassOutlined />)}
							>
								Go to contract
							</Button>
						)}
						<Button
							onClick={onDeleteEstimate}
							confirmTitle="Are you sure you want to delete this estimate?"
							danger
							icon={(<DeleteOutlined />)}
						>
							Delete estimate
						</Button>
						<Button
							onClick={handleCreateSubEstimate}
						>
							Create new sub-estimate
						</Button>
						{isFixed ? (
							<Button
								disabled={!isReadyToUnfix.ready}
								disabledTooltip={isReadyToUnfix.message}
								onClick={onUnfixEstimate}
							>
								Unfix Estimate
							</Button>
						) : (
							<Button
								disabled={!isReadyToFix}
								disabledTooltip={isReadyToFixMessage}
								onClick={fixEstimate}
								type="primary"
							>
								Create Contract
							</Button>
						)}

					</Space>
				</Flex>
			</Card>
			<Modal
				open={bunkerPricesModalOpen}
				onCancel={() => setBunkerPricesModalOpen(false)}
			>
				<Table
					size="small"
					columns={[
						{ title: 'Port', dataIndex: 'port' },
						{ title: 'Grade', dataIndex: 'grade' },
						{ title: 'Price', dataIndex: 'price' },
					]}
					showSearch
					dataSource={[]}
				/>
			</Modal>
		</>
	);
};

export default TopBar;
