import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import PaymentsTable from '@client/screens/fleet/VoyageDetailsScreen/components/PaymentsTable';
import FreightInvoicesTable from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceTables/FreightInvoicesTable';

const FreightInvoices = () => {
	return (
		<Row gutter={[16, 16]}>
			<Col xl={12} xxl={8}>
				<PaymentsTable />
			</Col>
			<Col xl={24} xxl={16}>
				<FreightInvoicesTable />
			</Col>
		</Row>
	);
};

export default FreightInvoices;
