import React from 'react';
import { Moment } from 'moment';
import { TimeFormat } from '@shared/utils/date';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { PortActionTypes } from '@shared/utils/constants';
import type { Port } from '@api/utils/ports';
import type { GetVoyageItineraryResponse } from '@api/features/voyages/getVoyageItinerary';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@client/screens/estimates/details/helpers/types';
import PortCallSummaryTab from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/PortCallSummaryTab';
import SeaPassageSummary from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/SeaPassageSummary';
import ConditionTab from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/ConditionTab';
import ConsumptionContainer from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/ConsumptionContainer';
import { updateSeaPassage } from '@client/lib/api';
import { PortCallBunkersSection } from './PortCallBunkersSection';
import PortCallDeliveryRedeliveryTab from './PortCallDeliveryRedeliveryTab';
import TheShipReportsTab from './TheShipReportsTab';

type Props = {
	itinerary: GetVoyageItineraryResponse | undefined;
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined;
	voyageDetails: GetVoyageDetailsResponse;
	refreshDetails: () => void;
	refreshVessel: () => Promise<void> | void;
	setActiveTab: (tab: string) => void;
	selectedTimeFormat: TimeFormat;
	ports: Port[] | undefined;
	latestActualDate: Moment | null;
	latestEstimatedDate: Moment | null;
	isPreviousActual: boolean;
	vessel: GetVesselDetailsResponse | undefined;
	isConsMissingForSpeed: boolean;
	usesTheShip: boolean;
};

const getTabList = ({
	selectedEntry,
	usesTheShip,
}: {
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto;
	usesTheShip: boolean;
	isConsMissingForSpeed: boolean;
}) => [{
	tab: 'Summary',
	key: 'summary',
},
...(isItemPortCall(selectedEntry) ?
	[
		{
			tab: 'Bunkers',
			key: 'bunkers',
		},
		{
			tab: 'Condition',
			key: 'condition',
		},
	] :
	[]),
...(usesTheShip ?
	[
		{
			tab: 'Reports (TheShip)',
			key: 'tsReports',
		},
	] :
	[]),
...(!isItemPortCall(selectedEntry) &&
	(selectedEntry.estimated ||
		(selectedEntry as ItinerarySeaPassageDto).inProgress) ?
	[
		{
			tab: <>Condition / Consumption</>,
			key: 'condition',
		},
	] :
	[]),
...(isItemPortCall(selectedEntry) &&
	selectedEntry.actions.some((a) => a.action === PortActionTypes.DELIVERING) ?
	[
		{
			tab: 'Delivery (TC-Out)',
			key: 'delivery',
		},
	] :
	[]),
...(isItemPortCall(selectedEntry) &&
	selectedEntry.actions.some((a) => a.action === PortActionTypes.REDELIVERING) ?
	[
		{
			tab: 'Redelivery (TC-Out)',
			key: 'redelivery',
		},
	] :
	[])];

const getTabContent = ({
	selectedEntry,
	refreshDetails,
	selectedTimeFormat,
	latestActualDate,
	latestEstimatedDate,
	ports,
	isPreviousActual,
	vessel,
	refreshVessel,
	isConsMissingForSpeed,
}: Omit<Props, 'selectedEntry' | 'usesTheShip'> & {
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto;
}) => {
	if (isItemPortCall(selectedEntry)) {
		return {
			summary: (
				<PortCallSummaryTab
					key={selectedEntry.id}
					selectedEntry={selectedEntry}
					refreshDetails={refreshDetails}
					ports={ports}
					selectedTimeFormat={selectedTimeFormat}
					allowDelete
					latestActualDate={latestActualDate}
					latestEstimatedDate={latestEstimatedDate}
					isPreviousActual={isPreviousActual}
				/>
			),
			bunkers: (<PortCallBunkersSection />),
			condition: (
				<ConsumptionContainer vessel={vessel} refreshVessel={refreshVessel} />
			),
			tsReports: (<TheShipReportsTab selectedEntry={selectedEntry} />),
			delivery: (<PortCallDeliveryRedeliveryTab />),
			redelivery: (<PortCallDeliveryRedeliveryTab />),
		};
	}

	const makePrimary = async (id: number) => {
		await updateSeaPassage(vessel?.id!, selectedEntry.id, {
			activeConsumptionSetId: id,
		});
		await refreshDetails();
	};

	return {
		summary: (
			<SeaPassageSummary
				selectedEntry={selectedEntry}
				refreshDetails={refreshDetails}
				consumptionSets={vessel?.consumptionSets}
			/>
		),
		condition: (selectedEntry.estimated || selectedEntry.inProgress) && (
			<ConditionTab
				selectedEntry={selectedEntry}
				refreshDetails={refreshDetails}
				vessel={vessel}
				refreshVessel={refreshVessel}
				isConsMissingForSpeed={isConsMissingForSpeed}
				makePrimary={makePrimary}
			/>
		),
		tsReports: (<TheShipReportsTab selectedEntry={selectedEntry} />),
	};
};

export const getTabs = ({
	selectedEntry,
	isConsMissingForSpeed,
	usesTheShip,
	...rest
}: Props) => {
	if (selectedEntry == null) {
		return {
			tabList: [],
			tabContent: [],
		};
	}

	const tabContent = getTabContent({
		selectedEntry,
		isConsMissingForSpeed,
		...rest,
	});

	const tabList = getTabList({
		selectedEntry,
		isConsMissingForSpeed,
		usesTheShip,
	});

	return {
		tabContent,
		tabList,
	};
};
