// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KoqDfyGTXGIv4iJMdOkg {\n\tborder-radius: 5px;\n\twidth: auto;\n\tmin-width: 40px;\n\tpadding-left: 8px;\n\tpadding-right: 8px;\n\ttext-align: center;\n}\n\n.v7TgaJibgpxnlDCYnFSd {\n\tbackground: rgba(219, 216, 205, 0.5);\n}\n\n.wIgnrtbLzesU_4rSsnqt {\n\tbackground: rgba(255, 219, 88, 0.5);\n}\n\n.HalsKIOTvaP_6tuEGEF3 {\n\tbackground: rgba(88, 202, 255, 0.5);\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/components/styles/DateBadge.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,WAAW;CACX,eAAe;CACf,iBAAiB;CACjB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,mCAAmC;AACpC;;AAEA;CACC,mCAAmC;AACpC","sourcesContent":[".badge {\n\tborder-radius: 5px;\n\twidth: auto;\n\tmin-width: 40px;\n\tpadding-left: 8px;\n\tpadding-right: 8px;\n\ttext-align: center;\n}\n\n.actual {\n\tbackground: rgba(219, 216, 205, 0.5);\n}\n\n.estimated {\n\tbackground: rgba(255, 219, 88, 0.5);\n}\n\n.master {\n\tbackground: rgba(88, 202, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "KoqDfyGTXGIv4iJMdOkg",
	"actual": "v7TgaJibgpxnlDCYnFSd",
	"estimated": "wIgnrtbLzesU_4rSsnqt",
	"master": "HalsKIOTvaP_6tuEGEF3"
};
export default ___CSS_LOADER_EXPORT___;
