// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VWj3vUZYgArZJvOUG4V9 {\n\tfont-size: 12px;\n}\n\n.YFviC0CSvMHn3624Lg9g {\n\tfont-size: 12px;\n\tdisplay: flex;\n\twidth: 80px;\n\tjustify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/ExpenseDetailsCard/private/styles/SelectedExpenseItems.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,aAAa;CACb,WAAW;CACX,uBAAuB;AACxB","sourcesContent":[".contractSubSection {\n\tfont-size: 12px;\n}\n\n.statusTag {\n\tfont-size: 12px;\n\tdisplay: flex;\n\twidth: 80px;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contractSubSection": "VWj3vUZYgArZJvOUG4V9",
	"statusTag": "YFviC0CSvMHn3624Lg9g"
};
export default ___CSS_LOADER_EXPORT___;
