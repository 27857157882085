import React from 'react';
import { Drawer } from 'antd';
import type { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import type { RobBunkerType } from '@api/models/rob-bunker';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { EditValues } from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import RobEntryForm from './RobEntryForm';

export type BunkerRecord = {
	id: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number;
	adjustment?: number;
}

type Props = {
	open: boolean;
	setRobDrawerOpen: (state: boolean) => void;
	fixtureCurrency: Values<typeof Currencies>;
	refreshData: () => void;
	vesselId: number;
	editing: EditValues | null;
	voyageDetails: GetVoyageDetailsResponse;
	setEditing: (record: RobBunkerType | null) => void;
	acceptableFuels: Array<Values<typeof FuelTypes>>;
};

const RobEntryDrawer = ({
	open,
	setRobDrawerOpen,
	fixtureCurrency,
	vesselId,
	editing,
	setEditing,
	refreshData,
	voyageDetails,
	acceptableFuels,
}: Props) => {
	const onClose = async () => {
		await refreshData();
		setRobDrawerOpen(false);
	};

	return (
		<Drawer
			open={open || editing != null}
			title={editing == null ? 'New ROB Entry' : 'Update entry'}
			width={500}
			onClose={() => {
				setEditing(null);
				setRobDrawerOpen(false);
			}}
		>
			<RobEntryForm
				onClose={onClose}
				fixtureCurrency={fixtureCurrency}
				vesselId={vesselId}
				editing={editing}
				voyageDetails={voyageDetails}
				acceptableFuels={acceptableFuels}
			/>
		</Drawer>
	);
};

export default RobEntryDrawer;
