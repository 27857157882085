import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	useQuery,
	useQueryClient,
} from 'react-query';
import {
	Flex,
	Typography,
} from 'antd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-thin-svg-icons';
import { Link } from 'react-router-dom';
import {
	DATE_AND_TIME,
	FixtureTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import { fixtureTypeToName } from '@shared/utils/fixtureUtils';
import { formatDate } from '@shared/utils/date';
import type { VoyageData } from '@api/features/voyages/project/getProject';
import {
	deleteCacheEntry,
	getProject,
} from '@client/lib/api';
import MatrixTable from '@client/components/Table/MatrixTable';
import Button from '@client/components/Button';
import LoadingIndicator from '@client/components/LoadingIndicator';
import Card from '@client/components/Card/Card';
import { Links } from '@client/utils/links';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import styles from './ProjectView.module.css';

const VC_REVENUE_KEYS = ['freight', 'demurrage', 'despatch'];
const TC_REVENUE_KEYS = ['hire'];

const ProjectView = () => {
	const {
		voyageId,
	} = useVoyage();

	const queryClient = useQueryClient();

	const {
		data,
		isLoading,
		isFetching,
	} = useQuery(
		['project', voyageId],
		() => getProject(voyageId),
		{
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			enabled: voyageId != null,
		},
	);

	const getHeader = useCallback((voyageData: VoyageData) => {
		if (voyageData.id === -1) {
			return (
				<div
					className={classNames(styles.headerContainer, styles.netHeaderContainer)}
				>
					<Typography.Title level={4} className={styles.netColumn}>
						Net
					</Typography.Title>
				</div>
			);
		}

		if (voyageData.voyage == null) {
			return <></>;
		}

		const fixtureName = voyageData.fixture?.type != null ?
			`${fixtureTypeToName(voyageData.fixture.type)}` : null;

		const date = voyageData.date != null ?	formatDate(voyageData.date, 'DD/MM/YYYY') : '';
		const owner = voyageData.counterpartyName != null ? voyageData.counterpartyName : '';
		let formattedOwner = owner;

		if (formattedOwner.length > 16) {
			formattedOwner = `${owner.slice(0, 16)} [...]`;
		}

		const { name } = voyageData;

		return (
			<div className={styles.headerContainer}>
				<Typography.Text className={styles.headerLabel}>
					{fixtureName}
				</Typography.Text>
				<Link to={Links.Voyage.get(voyageData.voyage.id)}>{name}</Link>
				<Typography.Text className={styles.headerLabel}>
					{date}
				</Typography.Text>
				<Typography.Text strong>
					{formattedOwner}
				</Typography.Text>
			</div>
		);
	}, []);

	const transformedData = useMemo(() => {
		if (data == null) {
			return [];
		}

		return data.data.map((d) => ({
			...d,
			width: 180,
			label: getHeader(d),
			sticky: d.id === -1,
		}));
	}, [data, getHeader]);

	const renderOutCell = useCallback((
		value: number,
		col: VoyageData,
		keys: string[],
		type: 'currency' | 'days' = 'currency',
		isNetRow: boolean = false,
	) => {
		const [key] = keys.slice(-1);

		if (isNetRow && col.id !== -1) {
			return '-';
		}

		const fixtureType = col?.fixture?.type;

		if (fixtureType === FixtureTypes.TC_IN) {
			return '-';
		}

		if (
			fixtureType !== FixtureTypes.SPOT &&
			(VC_REVENUE_KEYS.includes(key)) && col.id !== -1
		) {
			return '-';
		}

		if (
			fixtureType !== FixtureTypes.TC_OUT &&
			(TC_REVENUE_KEYS.includes(key)) && col.id !== -1
		) {
			return '-';
		}

		if (type === 'days') {
			return `${round(value, 2)} Days`;
		}

		if (col.currency != null) {
			return formatCurrency(value, col.currency, { forceDecimals: true });
		}

		return '';
	}, []);

	const renderHireCostCell = useCallback((value: number, col: VoyageData) => {
		if (col?.fixture?.type !== FixtureTypes.TC_IN && col.id !== -1) {
			return '-';
		}

		if (col.currency != null) {
			return formatCurrency(value, col.currency, { forceDecimals: true });
		}

		return '';
	}, []);

	const refresh = async () => {
		await deleteCacheEntry(`/api/voyages/project/${voyageId}?voyageId=${voyageId}`);
		await queryClient.invalidateQueries('project');
	};

	if (isLoading || isFetching || data == null) {
		return (
			<Flex vertical justify="center" align="center" gap={5}>
				<LoadingIndicator fullHeight size="large" />
				<Typography.Title level={3}>Loading project view..</Typography.Title>
			</Flex>
		);
	}

	return (
		<Card padding={12} className={styles.card}>
			<Flex
				vertical
				className={styles.refreshContainer}
			>
				<Typography.Text className={styles.smallText} type="secondary">
					Last updated:
					{' '}
					{data?.lastUpdated?.local().format(DATE_AND_TIME)}
				</Typography.Text>
				<Button
					onClick={refresh}
					className={classNames([styles.activeEntry, styles.refreshBtn])}
					icon={(
						<FontAwesomeIcon icon={faArrowsRotate} />
					)}
				>
					Refresh data
				</Button>
			</Flex>
			<div className={styles.container}>
				<MatrixTable
					tableWidth="100%"
					groupTitleColumnWidth={100}
					titleColumnWidth={165}
					data={transformedData}
					groups={[
						{
							title: (<Typography.Text strong>Revenue</Typography.Text>),
							children: [
								{ title: 'Freight', key: ['pnl', 'revenue', 'freight'], render: renderOutCell },
								{ title: 'Demurrage', key: ['pnl', 'revenue', 'demurrage'], render: renderOutCell },
								{ title: 'Despatch', key: ['pnl', 'revenue', 'despatch'], render: renderOutCell },
								{ title: 'Hire', key: ['pnl', 'revenue', 'hire'], render: renderOutCell },
								{ title: 'Other Revenue', key: ['pnl', 'revenue', 'otherRevenue'], render: renderOutCell },
								{ title: 'Address Commission', key: ['pnl', 'revenue', 'addComm'], render: renderOutCell },
								{ title: 'Brokers Commission', key: ['pnl', 'revenue', 'brokersComm'], render: renderOutCell },
								{ title: 'Net Revenue', key: ['pnl', 'revenue', 'netRevenue'], render: renderOutCell },
							],
						},
						{
							title: (<Typography.Text strong>Expenses</Typography.Text>),
							children: [
								{ title: 'Port Expenses', key: ['pnl', 'expenses', 'portExpenses'], render: renderOutCell },
								{ title: 'Bunker Expenditure', key: ['pnl', 'expenses', 'bunkerExpenditure'], render: renderOutCell },
								{ title: 'Other Expenses', key: ['pnl', 'expenses', 'otherExpenses'], render: renderOutCell },
								{ title: 'Off-Hire', key: ['pnl', 'expenses', 'offHire'], render: renderOutCell },
								{ title: 'Total Expenses', key: ['pnl', 'expenses', 'totalExpenses'], render: renderOutCell },
							],
						},
						{
							title: (<Typography.Text strong>Voy. Result</Typography.Text>),
							children: [
								{ title: 'TCE Earnings', key: ['pnl', 'voyageResult', 'tce'], render: renderOutCell },
								{
									title: 'Total Days',
									key: ['pnl', 'voyageResult', 'totalDays'],
									render: (value, col, key) => renderOutCell(value, col, key, 'days'),
								},
								{ title: 'TCE / Day', key: ['pnl', 'voyageResult', 'tcePerDay'], render: renderOutCell },
							],
						},
						{
							title: (<Typography.Text strong>Hire Cost</Typography.Text>),
							children: [
								{ title: 'Gross Hire Cost', key: ['pnl', 'hireCosts', 'grossHireCost'], render: renderHireCostCell },
								{ title: 'Other Costs', key: ['pnl', 'hireCosts', 'otherCosts'], render: renderHireCostCell },
								{ title: 'Address Commission', key: ['pnl', 'hireCosts', 'addComm'], render: renderHireCostCell },
								{ title: 'Broker Commission', key: ['pnl', 'hireCosts', 'brokersComm'], render: renderHireCostCell },
								{ title: 'Off-Hire', key: ['pnl', 'hireCosts', 'offHire'], render: renderHireCostCell },
								{ title: 'Total Hire Cost', key: ['pnl', 'hireCosts', 'totalHireCost'], render: renderHireCostCell },
							],
						},
						{
							title: (<Typography.Text strong>Net Result</Typography.Text>),
							children: [
								{
									title: 'Net Result',
									key: ['pnl', 'netResult', 'netResult'],
									render: (value, col, key) => renderOutCell(value, col, key, 'currency', true),
								},
								{
									title: 'Net Result / Day',
									key: ['pnl', 'netResult', 'netResultPerDay'],
									render: (value, col, key) => renderOutCell(value, col, key, 'currency', true),
								},
							],
						},
					]}
				/>
			</div>
		</Card>

	);
};

export default ProjectView;
