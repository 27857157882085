import React, {
	createContext,
	useContext,
	ReactNode,
	useState,
	useMemo,
	useEffect,
	useCallback,
} from 'react';
import { Moment } from 'moment';
import debounce from 'lodash.debounce';
import { arrayMove } from '@dnd-kit/sortable';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetVoyageItineraryResponse } from '@api/features/voyages/getVoyageItinerary';
import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';
import type { Port } from '@api/utils/ports';
import {
	createItineraryEntry,
	deleteItineraryEntry,
	getPorts,
	getVoyageItinerary,
	reorderPortCall,
	updatePortCall,
	updateRob,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';
import getPortAndRangeOptions from '@client/utils/getPortAndRangeOptions';
import getItineraryColumns from './utils/getItineraryColumns';

type ItineraryContextType = {
	itinerary: GetVoyageItineraryResponse;
	refreshItinerary: () => void;
	itineraryError: Error | null;
	itineraryLoading: boolean;
	setEndDate: (date: string) => void;
	setStartDate: (date: string) => void;
	deletePortCall: (portCall: ItineraryPortCallDto) => void;
	portCalls: ItineraryPortCallDto[];
	seaPassages: ItinerarySeaPassageDto[];
	localItinerary: (ItineraryPortCallDto | ItinerarySeaPassageDto)[];
	setLocalItinerary: (itinerary: (ItineraryPortCallDto | ItinerarySeaPassageDto)[]) => void;
	portOptions: ReturnType<typeof getPortAndRangeOptions>;
	viewMode: 'map' | 'bunker' | 'default';
	setViewMode: (type: 'map' | 'bunker' | 'default') => void;
	ports: Port[] | undefined;
	selectedTimeFormat: 'utc' | 'localTime';
	setSelectedTimeFormat: (format: 'utc' | 'localTime') => void;
	addItineraryEntry: ({
		portId,
		estimatedDepartureDate,
	}: { portId: number; estimatedDepartureDate?: Moment }) => void;
	expandedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined;
	onSelectEntry: (entry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined) => void;
	voyageDetails: GetVoyageDetailsResponse;
	onUpdateRob: (key: string, portCallId: number, newQuantity: number | null) => void;
	onUpdatePortCall: (field: string, value: any, newRow: ItineraryPortCallDto) => void;
	movePortCall: (pcId: number, overId: number) => void;
	columns: ReturnType<typeof getItineraryColumns>;
	refreshVoyageDetails: () => void;
}

interface ItineraryProviderProps {
	children: ReactNode;
	voyageDetails: GetVoyageDetailsResponse;
	refreshVoyageDetails: () => void;
}

export const ItineraryProvider = ({
	children,
	voyageDetails,
	refreshVoyageDetails,
}: ItineraryProviderProps) => {
	const [startDate, setStartDate] = useState<string | null>(
		toMoment(voyageDetails?.commencementDate ?? nowMoment()).subtract(1, 'week').toISOString() || null,
	);
	const [endDate, setEndDate] = useState<string | null>(
		toMoment(voyageDetails?.completionDate ?? nowMoment()).toISOString() || null,
	);
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

	const [viewMode, setViewMode] = useState<'default' | 'map' | 'bunker'>('default');
	const [selectedTimeFormat, setSelectedTimeFormat] = useState<'utc' | 'localTime'>(voyageDetails.itineraryUseUTC ? 'utc' : 'localTime');

	const [
		serverItinerary,
		refreshItinerary,
		itineraryError,
		itineraryLoading,
	] = useFetchedState(async () => {
		if (voyageDetails == null) {
			return [];
		}

		const result = await getVoyageItinerary(Number(voyageDetails.id));

		if (result == null) {
			return [];
		}

		return result;
	}, [startDate, endDate, voyageDetails], { initialState: [] });

	const itinerary = serverItinerary as GetVoyageItineraryResponse;

	const [ports] = useFetchedState(getPorts);
	const portOptions = useMemo(() => getPortAndRangeOptions(ports ?? []), [ports]);

	// eslint-disable-next-line max-len
	const [localItinerary, setLocalItinerary] = useState<(ItineraryPortCallDto | ItinerarySeaPassageDto)[]>(itinerary);

	const rawPortCalls = useMemo(
		() => localItinerary.filter((e): e is ItineraryPortCallDto => isItemPortCall(e)),
		[localItinerary],
	);

	const seaPassages = useMemo(
		() => localItinerary.filter((e): e is ItinerarySeaPassageDto => !isItemPortCall(e)),
		[localItinerary],
	);

	// Creates columns like VLSFO-arrival & VLSFO-departure for each unique fuel type
	const portCalls = rawPortCalls.map((pc) => {
		const transformed = pc.Robs.reduce((acc: Record<string, number>, item) => {
			let eventKey = item.event.toLowerCase();

			// This allows us to see (but not edit) commencement and completion bunkers in table
			if (eventKey === 'commencement') {
				eventKey = 'departure';
			}

			if (eventKey === 'completion') {
				eventKey = 'departure';
			}

			item.RobBunkers.forEach((bunker) => {
				const fuelKey = bunker.fuelGrade;
				const key = `${fuelKey}-${eventKey}`;

				if (acc[key]) {
					acc[key] += bunker.totalQuantity;
				} else {
					acc[key] = bunker.totalQuantity;
				}
			});

			return acc;
		}, {});

		return {
			...transformed,
			...pc,
		};
	});

	const expandedEntry = useMemo(() => {
		if (selectedIndex == null) {
			return undefined;
		}

		const selectedEntry = itinerary[selectedIndex];

		if (selectedEntry == null) {
			return undefined;
		}

		return selectedEntry;
	}, [selectedIndex, itinerary]);

	useEffect(() => {
		if (itinerary != null) {
			setLocalItinerary(itinerary);
		}
	}, [itinerary]);

	const deletePortCall = useCallback(async (portCall: ItineraryPortCallDto) => {
		try {
			await deleteItineraryEntry(portCall.vesselId, portCall.id);
			await refreshItinerary();
		} catch (e) {
			showErrorNotification('Could not delete port call', e as Error);
		}
	}, [refreshItinerary]);

	const addItineraryEntry = async ({
		portId,
		estimatedDepartureDate,
	}: {
		portId: number;
		estimatedDepartureDate?: Moment;
	}) => {
		await createItineraryEntry({
			vesselId: Number(voyageDetails.vesselId),
			portId,
			savingIndicator: true,
			voyageId: voyageDetails.id,
			estimatedDepartureDate,
		});

		await refreshItinerary();
	};

	const onUpdateRob = useCallback(async (
		key: string,
		portCallId: number,
		newQuantity: number | null,
	) => {
		const [fuelType, event] = key.split('-');

		const portCall = portCalls.find((pc) => pc.id === portCallId);

		if (portCall == null) {
			return;
		}

		const rob = portCall.Robs.find((r) => r.event.toLowerCase() === event);

		if (rob == null) {
			return;
		}

		const robBunker = rob.RobBunkers.find((b) => b.fuelGrade === fuelType);

		// Find an update the relevant RobBunker to the updated quantity, then update localItinerary
		const updatedLocalItinerary = localItinerary.map((entry) => {
			if (entry.id !== portCallId || !isItemPortCall(entry)) {
				return entry;
			}

			const updatedRobs = entry.Robs.map((robItem) => {
				if (robItem.id !== rob.id) {
					return robItem;
				}

				const updatedRobBunkers = robItem.RobBunkers.map((bunker) => {
					if (bunker.id !== robBunker?.id) {
						return bunker;
					}

					return {
						...bunker,
						totalQuantity: newQuantity ?? 0,
					};
				});

				return {
					...robItem,
					RobBunkers: updatedRobBunkers,
				};
			});

			// Return the updated entry with updated Robs
			return {
				...entry,
				Robs: updatedRobs,
			};
		});

		// @ts-ignore, this works
		setLocalItinerary(updatedLocalItinerary);

		if (portCall.voyageId == null) {
			return;
		}

		if (robBunker == null && fuelType != null) {
			await updateRob({
				vesselId: portCall.vesselId,
				voyageId: portCall.voyageId,
				robId: rob.id,
				currency: voyageDetails?.bankAccount?.currency ?? Currencies.USD,
				attributes: {
					remainingOnBoard: [{
						fuelGrade: fuelType as Values<typeof FuelTypes>,
						quantity: newQuantity ?? 0,
						pricePerTon: 0,
					}],
				},
				deleteMissingEntries: false,
			});
		} else if (robBunker != null) {
			await updateRob({
				vesselId: portCall.vesselId,
				voyageId: portCall.voyageId,
				robId: rob.id,
				currency: voyageDetails?.bankAccount?.currency ?? Currencies.USD,
				attributes: {
					remainingOnBoard: {
						id: robBunker.id,
						quantity: newQuantity ?? 0,
						fuelGrade: robBunker.fuelGrade,
					},
				},
			});
		}

		await refreshItinerary();
	}, [portCalls, localItinerary, refreshItinerary, voyageDetails]);

	const debounceUpdateRob = debounce(onUpdateRob, 500);

	const onUpdatePortCall = useCallback(async (
		field: string,
		value: any,
		newRow: ItineraryPortCallDto,
	) => {
		// update port call in localItinerary
		const newLocalItinerary = localItinerary.map((entry) => {
			if (entry.id === newRow.id && isItemPortCall(entry)) {
				return {
					...entry,
					[field]: value,
				};
			}

			return entry;
		});
		setLocalItinerary(newLocalItinerary);
		await updatePortCall(newRow.vesselId, newRow.id, { [field]: value }, true);
		await refreshItinerary();
	}, [refreshItinerary, localItinerary]);

	const movePortCall = useCallback(async (pcId: number, overId: number) => {
		const overEntry = portCalls.find((p) => p.id === overId);
		const activeEntry = portCalls.find((p) => p.id === pcId);
		const voyageId = activeEntry?.voyageId;

		if (pcId !== overId && overEntry != null && activeEntry != null && voyageId != null) {
			const activeIndex = portCalls.findIndex((i) => i.id === pcId);
			const overIndex = portCalls.findIndex((i) => i.id === overId);

			const oldItinerary = [...localItinerary];
			const updated = arrayMove(portCalls, activeIndex, overIndex);
			setLocalItinerary(updated);

			try {
				await reorderPortCall({
					vesselId: Number(activeEntry?.vesselId),
					draggedId: activeEntry.id,
					droppedOverId: overEntry.id,
					voyageId,
				});
				refreshItinerary();
			} catch (e) {
				setLocalItinerary(oldItinerary);
				showErrorNotification('Could not move port call', e as Error);
			}
		}
	}, [localItinerary, portCalls, refreshItinerary]);

	const onSelectEntry = useCallback((
		entry: ItineraryPortCallDto |ItinerarySeaPassageDto | undefined,
	) => {
		if (entry == null) {
			setSelectedIndex(undefined);

			return;
		}

		const isPortCall = isItemPortCall(entry);
		const index = itinerary.findIndex((e) => (
			e.id === entry.id && isItemPortCall(e) === isPortCall
		));
		setSelectedIndex(index);
	}, [itinerary]);

	const columns = useMemo(() => getItineraryColumns({
		deletePortCall,
		onUpdateRob: debounceUpdateRob,
		onSelectEntry,
		portCalls,
		seaPassages,
		onUpdatePortCall,
		selectedTimeFormat,
		viewMode,
	}), [
		portCalls,
		seaPassages,
		selectedTimeFormat,
		viewMode,
		deletePortCall,
		debounceUpdateRob,
		onSelectEntry,
		onUpdatePortCall,
	]);

	return (
		<ItineraryContext.Provider value={{
			itinerary,
			localItinerary,
			refreshItinerary,
			itineraryError,
			itineraryLoading,
			setEndDate,
			setStartDate,
			deletePortCall,
			portCalls,
			seaPassages,
			setLocalItinerary,
			portOptions,
			setViewMode,
			viewMode,
			ports,
			selectedTimeFormat,
			setSelectedTimeFormat,
			addItineraryEntry,
			expandedEntry,
			voyageDetails,
			onSelectEntry,
			onUpdateRob: debounceUpdateRob,
			onUpdatePortCall,
			movePortCall,
			columns,
			refreshVoyageDetails,
		}}
		>
			{children}
		</ItineraryContext.Provider>
	);
};

const ItineraryContext = createContext<ItineraryContextType>(undefined!);
export const useItinerary = () => useContext(ItineraryContext);

