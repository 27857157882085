/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
	ReactElement,
	ReactNode,
} from 'react';
import classNames from 'classnames';
import { round } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import { arraysWithIdsAreEqual } from '@shared/utils/array';
import styles from './MatrixTable.module.css';

type NestedKeyOf<T> = {
	[K in keyof T]: T[K] extends object ? [K, ...NestedKeyOf<T[K]>] : [K];
}[keyof T];

type Group<DataType extends BaseDataType> = {
	title: string | ReactElement;
	children: Array<
	{
		key?: keyof DataType | NestedKeyOf<DataType>;
		title: string | ReactElement;
		inputProps?: {
			addOnAfter?: string;
		};
		break?: boolean;
		render?: (
			value: any,
			entry: DataType,
			key: any,
		) => ReactNode;
		calculated?: boolean;
	} & (NormalCategory | CurrencyCategory)
	>;
}

type NormalCategory = {
	type?: 'number' | 'string';
}

type CurrencyCategory = {
	type: 'currency';
}

type BaseDataType = {
	label: string | ReactElement;
	id: number;
	sticky?: boolean;
	currency?: Values<typeof Currencies> | null;
	width?: number;
}

const MatrixTable = <DataType extends BaseDataType>({
	groups,
	data,
	compare = false,
	tableWidth = '100%',
	groupTitleColumnWidth,
	titleColumnWidth,
}: {
	groups: Group<DataType>[];
	data: DataType[];
	compare?: boolean;
	tableWidth?: number | string;
	groupTitleColumnWidth?: number | string;
	titleColumnWidth?: number | string;
}) => {
	const baseline = data[0];

	let globalRowCounter = 0;

	return (
		<table style={{ borderCollapse: 'separate', width: tableWidth, height: '100%', borderSpacing: 0 }}>
			<tbody>
				<tr>
					<th colSpan={1} className={styles.label} style={{ zIndex: 20 }} />
					<th
						colSpan={1}
						className={styles.label}
						style={{ zIndex: 20, borderRight: '2px solid rgb(220, 220, 220)' }}
					/>
					{data.map((d) => {
						let extraStyles = {};

						if (d.sticky) {
							extraStyles = {
								position: 'sticky',
								right: 0,
								zIndex: 1,
								backgroundColor: '#FAFDFF',
								borderLeft: '2px solid rgb(220, 220, 220)',
							};
						}

						return (
							<th
								colSpan={1}
								className={styles.label}
								style={{ ...extraStyles }}
							>
								{d.label}
							</th>
						);
					})}
				</tr>
				{groups.map((group) => {
					return group.children.map((category, index) => {
						globalRowCounter += 1;

						return (
							<tr
								className={classNames({
									[styles.lastRow]: index === group.children.length - 1,
									[styles.alternatingRow]: globalRowCounter % 2 === 1,
								})}
							>
								<th
									colSpan={1}
									className={styles.headerRow}
									style={{
										width: groupTitleColumnWidth,
										minWidth: groupTitleColumnWidth,
									}}
								>
									{index === 0 ? group.title : ''}
								</th>
								<th
									colSpan={1}
									className={styles.headerRow}
									style={{
										textAlign: 'left',
										paddingLeft: 8,
										width: titleColumnWidth,
										minWidth: titleColumnWidth,
										borderRight: '2px solid rgb(220, 220, 220)',
									}}
								>
									{category.title}
								</th>
								{data.map((row) => {
									let value: any;

									if (category.key != null) {
										if (Array.isArray(category.key)) {
											// Traverse the object for nested keys
											// @ts-ignore
											value = category.key.reduce(
												// @ts-ignore
												(acc, key) => (acc != null ? acc[key] : undefined),
												row,
											);
										} else {
											value = row[category.key];
										}
									}

									let formattedValue: any = value;

									if (category.type === 'currency') {
										if (row.currency == null) {
											throw new Error(
												'A currency must be set on a currency category',
											);
										}

										formattedValue = formatCurrency(
											value as unknown as number ?? 0,
											row.currency,
										);
									}

									if (category.type === 'number') {
										formattedValue = round(value as unknown as number ?? 0, 2);
									}

									if (category.render != null && typeof category.render === 'function') {
										formattedValue = category.render(value ?? 0, row, category.key);
									}

									// Compare with baseline
									const baselineValue = category.key ?
										Array.isArray(category.key) ?
											// @ts-ignore
											category.key.reduce(
												// @ts-ignore
												(acc, key) => (acc != null ? acc[key] : undefined),
												baseline,
											) :
											baseline[category.key] :
										undefined;

									let isDifferent = false;

									if (Array.isArray(value) && Array.isArray(baselineValue)) {
										isDifferent = !arraysWithIdsAreEqual(value, baselineValue);
									} else {
										isDifferent = value !== baselineValue;
									}

									let extraStyles = {};

									if (row.sticky) {
										extraStyles = {
											position: 'sticky',
											right: 0,
											zIndex: 1,
											backgroundColor: globalRowCounter % 2 === 1 ? '#F5F6F7' : '#FCFEFF',
											fontWeight: 700,
											borderLeft: '2px solid rgb(220, 220, 220)',
										};
									}

									return (
										<td
											colSpan={1}
											className={classNames(
												styles.content,
												styles.alignRight,
												{
													[styles.innerShadowHighlight]: isDifferent && compare,
													[styles.calculated]: !isDifferent && category.calculated,
												},
											)}
											style={{
												width: row.width ?? undefined,
												minWidth: row.width ?? undefined,
												...extraStyles,
											}}
										>
											{formattedValue}
											{category.inputProps?.addOnAfter != null && ` ${category.inputProps.addOnAfter}`}
										</td>
									);
								})}
							</tr>
						);
					});
				})}
			</tbody>
		</table>
	);
};

export default MatrixTable;

