// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pjR2jRP1EjVNkhzSH851 {\n\twidth: 100%;\n}\n\n.lRyuAFnrPWzixBLfWgT0 {\n\tcursor: pointer;\n\tcolor: #1677ff;\n}\n\n.MLeGfEj8NUmIU9BxrnDE {\n\tposition: absolute;\n\tfont-size: 10px;\n\tcolor: black;\n\tborder-radius: 5px;\n\ttop: 25px;\n\tpadding-left: 6px;\n\tpadding-right: 3px;\n\tz-index: 50;\n}\n\n.pBUxRvFE3sgaWhU58hTA {\n\tfont-size: 16px;\n}\n\n.MLeGfEj8NUmIU9BxrnDE:hover {\n\tcursor: pointer !important;\n}\n\n.Hwr5hksw2jqgjxRyrO0c {\n\tbackground: rgba(219, 216, 205, 0.5);\n}\n\n.Hwr5hksw2jqgjxRyrO0c:hover {\n\tbackground: rgba(219, 216, 205, 0.8);\n}\n\n.Gej1dthUDB9aWCkfGaEn {\n\tbackground: rgba(255, 219, 88, 0.5);\n}\n\n.Gej1dthUDB9aWCkfGaEn:hover {\n\tbackground: rgba(255, 219, 88, 0.8);\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/components/styles/itineraryColumns.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,eAAe;CACf,YAAY;CACZ,kBAAkB;CAClB,SAAS;CACT,iBAAiB;CACjB,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,oCAAoC;AACrC;;AAEA;CACC,mCAAmC;AACpC;;AAEA;CACC,mCAAmC;AACpC","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n\n.cursor {\n\tcursor: pointer;\n\tcolor: #1677ff;\n}\n\n.seaPassagePill {\n\tposition: absolute;\n\tfont-size: 10px;\n\tcolor: black;\n\tborder-radius: 5px;\n\ttop: 25px;\n\tpadding-left: 6px;\n\tpadding-right: 3px;\n\tz-index: 50;\n}\n\n.kebabMenu {\n\tfont-size: 16px;\n}\n\n.seaPassagePill:hover {\n\tcursor: pointer !important;\n}\n\n.actual {\n\tbackground: rgba(219, 216, 205, 0.5);\n}\n\n.actual:hover {\n\tbackground: rgba(219, 216, 205, 0.8);\n}\n\n.estimated {\n\tbackground: rgba(255, 219, 88, 0.5);\n}\n\n.estimated:hover {\n\tbackground: rgba(255, 219, 88, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "pjR2jRP1EjVNkhzSH851",
	"cursor": "lRyuAFnrPWzixBLfWgT0",
	"seaPassagePill": "MLeGfEj8NUmIU9BxrnDE",
	"kebabMenu": "pBUxRvFE3sgaWhU58hTA",
	"actual": "Hwr5hksw2jqgjxRyrO0c",
	"estimated": "Gej1dthUDB9aWCkfGaEn"
};
export default ___CSS_LOADER_EXPORT___;
