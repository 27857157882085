import React from 'react';
import {
	faBoxes,
	faEdit,
	faLink,
	faUpDown,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Flex,
	Space,
} from 'antd';
import classNames from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import {
	PortCallTypes,
	MetersPer,
	PortActionTypeLabels,
	FuelTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import {
	getUtcOffset,
	toMoment,
} from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@client/screens/estimates/details/helpers/types';
import DatePicker from '@client/components/DatePicker';
import Button from '@client/components/Button';
import { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import styles from '../components/styles/itineraryColumns.module.css';
import DateBadge from '../components/DateBadge';

const getItineraryColumns = ({
	selectedTimeFormat,
	portCalls,
	onSelectEntry,
	seaPassages,
	onUpdatePortCall,
	deletePortCall,
	viewMode,
	setShowCommencementLinking,
	setEditingCargoId,
	setEditingCargoPortId,
	uniqueFuelTypes = [],
}: {
	selectedTimeFormat: 'utc' | 'localTime';
	portCalls: ItineraryPortCallDto[];
	onSelectEntry: (entry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined) => void;
	seaPassages: ItinerarySeaPassageDto[];
	onUpdatePortCall: (field: string, value: any, newRow: ItineraryPortCallDto) => void;
	deletePortCall: (portCall: ItineraryPortCallDto) => void;
	viewMode: 'default' | 'map' | 'bunker';
	setShowCommencementLinking: (show: boolean) => void;
	setEditingCargoId: React.Dispatch<React.SetStateAction<number | null>>;
	setEditingCargoPortId: React.Dispatch<React.SetStateAction<number | null>>;
	uniqueFuelTypes: Array<Values<typeof FuelTypes>>;
}) => {
	// @ts-ignore
	const arrivalColumns: EditableColumns<ItineraryPortCallDto> = uniqueFuelTypes
		.map((fuelType) => ({
			key: `${fuelType}-arrival`,
			title: `${fuelType}`,
			dataIndex: `${fuelType}-arrival`,
			editable: true,
			type: 'number' as 'number',
			inputProps: {
				addonAfter: 'MT',
			},
			inputPropsWithRow: (row: ItineraryPortCallDto) => {
				const disabled = row.arrivalDate == null || row.type === PortCallTypes.COMMENCEMENT;

				return ({
					disabled,
					valueOverride: disabled ? '-' : undefined,
				});
			},
		}));

	// @ts-ignore
	const departureColumns: EditableColumns<ItineraryPortCallDto> = uniqueFuelTypes
		.map((fuelType) => ({
			key: `${fuelType}-departure`,
			title: `${fuelType}`,
			dataIndex: `${fuelType}-departure`,
			editable: true,
			type: 'number' as 'number',
			inputProps: {
				addonAfter: 'MT',
			},
			inputPropsWithRow: (row: ItineraryPortCallDto) => {
				const disabled = row.departureDate == null;

				const isCommencementOrCompletion = (
					row.type === PortCallTypes.COMMENCEMENT ||
					row.type === PortCallTypes.COMPLETION);

				return ({
					disabled: disabled || isCommencementOrCompletion,
					valueOverride: disabled ? '-' : undefined,
				});
			},
		}));

	const bunkerColumns = [
		...(arrivalColumns.length > 0 ? [{
			title: 'ROB on Arrival',
			children: arrivalColumns,
			editable: true,
		}] : []),
		...(departureColumns.length > 0 ? [{
			title: 'ROB on Departure',
			children: departureColumns,
			editable: true,
		}] : []
		),
	] as const;

	return [
		{
			key: 'actions',
			align: 'center' as const,
			width: 30,
			render: (row: ItineraryPortCallDto) => (
				<FontAwesomeIcon
					className={styles.cursor}
					onClick={() => onSelectEntry(row)}
					icon={faEdit}
				/>
			),
		},
		{
			title: 'Port',
			dataIndex: 'port',
			width: 250,
			render: (row: ItineraryPortCallDto) => {
				const { port } = row;

				if (port == null) {
					return null;
				}

				if (row.type === PortCallTypes.COMPLETION) {
					return port.name;
				}

				const seaPassage = seaPassages.find((sp) => sp.id === row.nextSeaPassageId);

				if (seaPassage == null) {
					return port.name;
				}

				const nextPortCall = seaPassage == null ? undefined : portCalls.find((pc) => (
					pc.id === seaPassage.nextPortCallId
				));

				const { departureDate } = row;
				const arrivalDate = nextPortCall != null ? nextPortCall.arrivalDate : undefined;

				const estimatedDepartureDate = row.departureDate ?? row.estimatedDepartureDate;
				const estimatedArrivalDate = nextPortCall?.estimatedArrivalDate;

				const estimatedDuration = (estimatedArrivalDate != null && estimatedArrivalDate != null) ? round(estimatedArrivalDate.diff(estimatedDepartureDate, 'days', true), 2) : undefined;

				const distance = seaPassage.route == null ? 0 : Math.round(
					seaPassage.route.properties.distance / MetersPer.NAUTICAL_MILE,
				);

				const isSeaPassageActual = (
					row.departureDate != null && nextPortCall?.arrivalDate != null
				);

				const actualDuration = arrivalDate != null ? round(arrivalDate.diff(departureDate, 'days', true), 2) : undefined;

				return (
					<div style={{ width: '100%', height: '100%', position: 'relative' }}>
						{port.name}
						<div
							role="button"
							onClick={() => seaPassage && onSelectEntry(seaPassage)}
							onKeyPress={(e) => {
								if (e.key === 'Enter' && seaPassage) {
									onSelectEntry(seaPassage);
								}
							}}
							tabIndex={0}
							className={classNames(styles.seaPassagePill, {
								[styles.estimated]: !isSeaPassageActual,
								[styles.actual]: isSeaPassageActual,
							})}
						>
							<Space>
								<FontAwesomeIcon icon={faUpDown} />
								{!isSeaPassageActual ? `Est. ${distance} nm | ${estimatedDuration} days` : `${actualDuration ?? estimatedDuration} days`}
							</Space>
						</div>
					</div>
				);
			},
		},
		...(viewMode === 'default' ? [
			{
				title: 'Arrival',
				dataIndex: 'estimatedArrivalDate',
				key: 'estimatedArrivalDate',
				width: selectedTimeFormat === 'localTime' ? 315 : 225,
				render: (row: ItineraryPortCallDto) => {
					if (row == null) {
						return null;
					}

					if (row.type === PortCallTypes.COMMENCEMENT || row.prevSeaPassageId == null) {
						return (<div style={{ width: '100%', textAlign: 'center' }}>—</div>);
					}

					const utcOffset = getUtcOffset(row.port);

					const calculatedDate = row.arrivalDate ?? row.mastersEta ?? row.estimatedArrivalDate;
					const formattedDate = calculatedDate == null ? undefined : (selectedTimeFormat === 'localTime' ? calculatedDate : toMoment(calculatedDate).utc());

					return (
						<Flex gap={5} align="center">
							<DateBadge entry={row} type="arrival" />
							<DatePicker
								time
								showTimezone={selectedTimeFormat === 'localTime'}
								variant="borderless"
								className={styles.fullWidth}
								allowClear={row.arrivalDate != null}
								defaultValue={formattedDate}
								onChange={(d) => onUpdatePortCall('arrivalDate', d, row)}
								defaultUtcOffset={selectedTimeFormat === 'localTime' ? utcOffset : 0}
								forceUtcUpdate
								forceOnChange
								disabled={row.type === PortCallTypes.COMMENCEMENT}
							/>
						</Flex>
					);
				},
			},
			{
				title: 'Departure',
				dataIndex: 'estimatedDepartureDate',
				key: 'estimatedDepartureDate',
				width: selectedTimeFormat === 'localTime' ? 315 : 240,
				render: (row: ItineraryPortCallDto) => {
					if (row == null || row.port == null) {
						return null;
					}

					const calculatedDate = row.departureDate ?? row.estimatedDepartureDate;
					const formattedDate = calculatedDate == null ? undefined : (selectedTimeFormat === 'localTime' ? calculatedDate : toMoment(calculatedDate).utc());

					const utcOffset = getUtcOffset(row.port);

					return (
						<Flex gap={5} align="center">
							<DateBadge entry={row} type="departure" />
							<DatePicker
								time
								showTimezone={selectedTimeFormat === 'localTime'}
								variant="borderless"
								className={styles.fullWidth}
								allowClear={row.departureDate != null}
								defaultValue={formattedDate}
								onChange={(d) => onUpdatePortCall('departureDate', d, row)}
								defaultUtcOffset={selectedTimeFormat === 'localTime' ? utcOffset : 0}
								forceUtcUpdate
								forceOnChange
								disabled={row.type === PortCallTypes.COMMENCEMENT}
							/>
							{row.type === PortCallTypes.COMMENCEMENT && (
								<Button onClick={() => setShowCommencementLinking(true)} type="link" icon={(<FontAwesomeIcon size="lg" icon={faLink} />)} />
							)}
						</Flex>
					);
				},
			},
		] : []),
		...(viewMode === 'bunker' ?
			[
				...bunkerColumns,
			] : [
				{
					title: 'Actions',
					dataIndex: 'actions',
					key: 'actions',
					render: (row: ItineraryPortCallDto) => {
						if (row == null || row.actions == null) {
							return null;
						}

						const { actions } = row;

						return (
							<>
								{(actions.length === 0 && row.type !== PortCallTypes.COMMENCEMENT) && ' — '}
								{actions?.map((action) => PortActionTypeLabels[action.action]).join(', ')}
							</>
						);
					},
				},
				{
					title: 'Cargo',
					dataIndex: ['actions'],
					key: 'cargo',
					render: (row: ItineraryPortCallDto) => {
						if (row == null || row.actions == null) {
							return null;
						}

						const { actions } = row;

						return actions.map((action) => {
							if (row == null || row.actions == null) {
								return null;
							}

							const cargo = action?.CargoPort?.Cargo;

							if (cargo == null) {
								return undefined;
							}

							return (
								<Button
									padding={0}
									type="link"
									onClick={() => {
										setEditingCargoId(cargo.id);
										setEditingCargoPortId(action.cargoPortId ?? null);
									}}
								>
									<Space>
										<FontAwesomeIcon icon={faBoxes} />
										{cargo.type}
									</Space>
								</Button>
							);
						});
					},
				},
				{
					key: 'kebab',
					title: '',
					width: 25,
					render: (row: ItineraryPortCallDto) => (
						<Button type="link" danger icon={(<DeleteOutlined />)} onClick={() => deletePortCall(row)} />
					),
				},
			]),
	];
};

export default getItineraryColumns;
