import React from 'react';
import {
	Space,
	Typography,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons';
import { AccountingItemApprovalStates } from '@shared/utils/constants';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import Button from '@client/components/Button';
import styles from './styles/ExpenseActionButtons.module.css';

type Props = {
	expense: TransformedExpenses;
	onSubmitExpense: () => void;
	onUnsubmitExpense: () => void;
	onMarkAsUnpaid: () => void;
}

const ExpenseActionButtons = ({
	expense,
	onSubmitExpense,
	onUnsubmitExpense,
	onMarkAsUnpaid,
}: Props) => {
	let buttons = null;

	const readyForApproval = expense.amount != null && !expense.estimated;
	let tooltip = '';

	if (expense.amount == null) {
		tooltip = 'Expense amount needs to be specified';
	}

	if (expense.estimated) {
		tooltip = 'Estimated expenses cannot be submitted';
	}

	switch (expense.state) {
		case AccountingItemApprovalStates.PENDING:
			buttons = (
				<Button
					icon={(<CheckOutlined />)}
					type="link"
					disabled={!readyForApproval}
					disabledTooltip={tooltip}
					onClick={onSubmitExpense}
				>
					Submit for approval
				</Button>
			);
			break;

		case AccountingItemApprovalStates.SUBMITTED:
			buttons = (
				<Button
					onClick={onUnsubmitExpense}
					icon={(
						<FontAwesomeIcon
							icon={faUndo}
							className={styles.buttonIcon}
						/>
					)}
					type="link"
				>
					Undo Submit
				</Button>
			);
			break;

		case AccountingItemApprovalStates.APPROVED:
			buttons = (
				<Button
					onClick={onUnsubmitExpense}
					icon={(
						<FontAwesomeIcon
							icon={faUndo}
							className={styles.buttonIcon}
						/>
					)}
					type="link"
				>
					Undo Submit
				</Button>
			);
			break;

		case AccountingItemApprovalStates.PAID:
			buttons = (
				<Button
					onClick={onMarkAsUnpaid}
					type="link"
					icon={(
						<FontAwesomeIcon
							icon={faUndo}
							className={styles.buttonIcon}
						/>
					)}
				>
					Mark as Unpaid
				</Button>
			);
			break;

		default:
			return null;
	}

	return (
		<>
			<Typography.Title level={5}>Expense Actions</Typography.Title>
			<Space>
				{buttons}
			</Space>
		</>
	);
};

export default ExpenseActionButtons;
