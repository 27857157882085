import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Card } from 'antd';
import type { Port } from '@api/utils/ports';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import {
	getEstimatedDespatchAndDemurrage,
	getFixtureDetails,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import LaytimeCalculationDrawer from '../components/LaytimeCalculatorTab/LaytimeCalculationDrawer';
import LaytimeCalculatorTab from './LaytimeCalculatorTab';
import styles from './ClaimsTab.module.css';

const defaultPort = {
	'id': 4568,
	'isPort': true,
	'name': 'LONDON',
	'latitude': 51.5,
	'longitude': 0.08,
	'countryCode': 'GB',
	'unlocode': 'GBLON',
	'type': 'PRIMARY',
	isRange: undefined,
};

const ClaimsTab = ({
	fixtureId,
	voyageId,
	refreshDetails,
	cargos,
}: {
	fixtureId: number;
	voyageId: number;
	refreshDetails: () => void;
	cargos: GetCargosAndLaytimesResponse | undefined;
}) => {
	const [selectedCargoId, setSelectedCargoId] = useState<number | null>(null);
	const [showLaytimeDrawer, setShowLaytimeDrawer] = useState(false);
	const [currentCargoPortId, setCurrentCargoPortId] = useState<number | null>(null);
	const [currentCargoId, setCurrentCargoId] = useState<number | null>(null);

	const selectedCargo = useMemo(() => {
		if (selectedCargoId == null || cargos == null) {
			return null;
		}

		return cargos.find((c) => c.id === selectedCargoId);
	}, [cargos, selectedCargoId]);

	const [estimatedDemDes] = useFetchedState(
		async () => {
			const fixtureDetails = await getFixtureDetails(fixtureId);

			const { estimateId } = (fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>);

			if (estimateId != null) {
				const arr = await getEstimatedDespatchAndDemurrage(estimateId, voyageId);

				return arr.find((r) => r.cargoId === selectedCargo?.id);
			}

			return null;
		}, [selectedCargo],
	);

	const currentCargo = useMemo(
		() => cargos?.find(
			(c) => c.id === currentCargoId,
		) as GetCargosAndLaytimesResponse[number],
		[cargos, currentCargoId],
	);

	const currentCargoPort = useMemo(() => {
		return currentCargo?.CargoPorts.find((cp) => cp.id === currentCargoPortId);
	}, [currentCargo?.CargoPorts, currentCargoPortId]);

	const currentLaytimeItems = useMemo(() => {
		if (selectedCargo == null) {
			return [];
		}

		const items = [...selectedCargo.HIIDemurrages, ...selectedCargo.HIIDespatches];

		return items;
	}, [selectedCargo]);

	useEffect(() => {
		if (cargos != null && cargos.length > 0 && selectedCargoId == null) {
			setSelectedCargoId(cargos[0].id);
		}
	}, [cargos, selectedCargoId]);

	const onAddNewLaytimeCalc = useCallback((cargoId: number, id: number) => {
		setShowLaytimeDrawer(true);
		setCurrentCargoPortId(id);
		setCurrentCargoId(cargoId);
	}, []);

	const tabList = cargos?.sort((a, b) => a.id - b.id).map((c) => ({
		key: String(c.id),
		label: `${c.type} for ${c.FixtureCounterparty.name}`,
	}));

	return (
		<>
			<LaytimeCalculationDrawer
				fixtureId={fixtureId}
				cargoPort={currentCargoPort}
				laytimeCalculations={currentCargoPort?.LaytimeCalculations ?? []}
				onClose={() => setShowLaytimeDrawer(!showLaytimeDrawer)}
				visible={showLaytimeDrawer}
				port={currentCargoPort?.port ?? defaultPort as Port}
				refreshDetails={refreshDetails}
				askForCreditNote={currentLaytimeItems.length > 0}
			/>
			<Card
				onTabChange={(key: string) => setSelectedCargoId(Number(key))}
				tabList={tabList}
				className={styles.card}
			>
				{selectedCargo != null ? (
					<LaytimeCalculatorTab
						key={`selected-cargo-${selectedCargo.id}`}
						selectedCargo={selectedCargo}
						onAddNewLaytimeCalc={onAddNewLaytimeCalc}
						estimatedDemDes={estimatedDemDes}
						refreshDetails={refreshDetails}
					/>
				) : 'No cargo selected'}
			</Card>
		</>
	);
};

export default ClaimsTab;
