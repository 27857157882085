import { FreightRateType } from '@shared/utils/constants';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';

export type IntakeFDDCalculation = {
	freightQuantity: number;
	freightAmount: number;
	overageQuantity: number;
	overageAmount: number;
	deadfreightQuantity: number;
	deadfreightAmount: number;
};

const getIntakeFDDNumbers = (
	cargoDetails: GetCargoDetailsResponse | undefined,
	maximumIntake: number,
): IntakeFDDCalculation => {
	if (!cargoDetails) {
		return {
			freightQuantity: 0,
			freightAmount: 0,
			overageQuantity: 0,
			overageAmount: 0,
			deadfreightQuantity: 0,
			deadfreightAmount: 0,
		};
	}

	const {
		quantity = 0,
		quantityTolerance = 0,
		freightType,
		freightRate = 0,
		overageAmount: baseOverageAmount = 0,
		deadfreightAmount: baseDeadfreightAmount = 0,
	} = cargoDetails;

	const cpQuantity = quantity;
	const tolerance = (quantityTolerance ?? 0) / 100;
	const cpQuantityMin = cpQuantity * (1 - tolerance);
	const cpQuantityMax = cpQuantity * (1 + tolerance);

	let freightQuantity = 0;

	if (maximumIntake > cpQuantity) {
		freightQuantity = Math.min(maximumIntake, cpQuantityMax);
	} else {
		freightQuantity = Math.min(maximumIntake, cpQuantity);
	}

	const overageQuantity = maximumIntake > cpQuantityMax ? maximumIntake - cpQuantityMax : 0;
	const deadfreightQuantity = maximumIntake < cpQuantityMin ? cpQuantityMin - maximumIntake : 0;

	const freightAmount =
		freightType === FreightRateType.LUMPSUM ? freightRate : freightQuantity * freightRate;
	const overageAmount =
	freightType === FreightRateType.LUMPSUM ?
		baseOverageAmount :
		overageQuantity * (baseOverageAmount ?? 0);
	const deadfreightAmount =
		freightType === FreightRateType.LUMPSUM ?
			baseDeadfreightAmount :
			deadfreightQuantity * (baseDeadfreightAmount ?? 0);

	return {
		freightQuantity,
		freightAmount,
		overageQuantity,
		overageAmount: overageAmount ?? 0,
		deadfreightQuantity,
		deadfreightAmount: deadfreightAmount ?? 0,
	};
};

export default getIntakeFDDNumbers;
