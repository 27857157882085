// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nkk1q7nhu9PVIryDe_x6 {\n\tdisplay: flex;\n}\n\n.P1jDNw48KDmyhOxw0_sx {\n\tfont-size: 20px;\n}\n\n.M8fZ9W9HF3KLAP3MTI4b {\n\tmargin-right: 10px;\n}\n\n.U9m7F_ClPDalz39RsGVi {\n\tmargin-right: 20px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/ItineraryCargoDetailsModal/ItineraryCargoDetailsModal.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".flagAndNameRow {\n\tdisplay: flex;\n}\n\n.portName {\n\tfont-size: 20px;\n}\n\n.portFlag {\n\tmargin-right: 10px;\n}\n\n.column {\n\tmargin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flagAndNameRow": "Nkk1q7nhu9PVIryDe_x6",
	"portName": "P1jDNw48KDmyhOxw0_sx",
	"portFlag": "M8fZ9W9HF3KLAP3MTI4b",
	"column": "U9m7F_ClPDalz39RsGVi"
};
export default ___CSS_LOADER_EXPORT___;
