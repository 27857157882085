import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Card,
	Col,
	Descriptions,
	Row,
	Space,
	Typography,
} from 'antd';
import { Moment } from 'moment';
import { getUtcOffset } from '@shared/utils/date';
import {
	UtcOffsets,
	PortActionTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import normalize from '@shared/utils/normalizeNumber';
import { formatNumber } from '@shared/utils/formatNumber';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type { Port } from '@api/utils/ports';
import { updatePortCall } from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import LoadingIndicator from '@client/components/LoadingIndicator';
import EditableText from '@client/components/EditableText';
import EmptyText from '@client/components/EmptyText';
import PrettyDate from '@client/components/PrettyDate/PrettyDate';
import EditableField from '@client/components/EditableField';
import {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@client/screens/estimates/details/helpers/types';
import { useVoyage } from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageProvider/VoyageProvider';
import { useItinerary } from '../ItineraryProvider';
import styles from './styles/SummaryTab.module.css';

type OtherConsumptionData = {
	idKey: string;
	daysKey: string;
	idValue: number | null | undefined;
	daysValue: number | null | undefined;
};

const PortCallDetails = () => {
	const {
		refreshVoyageDetails,
		refreshItinerary,
		selectedTimeFormat,
		expandedEntry,
		itinerary,
	} = useItinerary();

	const {
		vessel,
	} = useVoyage();

	const refreshDetails = () => {
		refreshVoyageDetails();
		refreshItinerary();
	};

	const selectedEntry = expandedEntry as ItineraryPortCallDto;

	const {
		arrivalDate,
		departureDate,
		requiredArrivalDate,
		estimatedDepartureDate,
		estimatedArrivalDate,
		port,
		requiredSpeed,
		estimatedIdleDays,
		estimatedLoadingDays,
		estimatedDischargingDays,
		estimatedTurningDays,
		actions,
		mastersEta,
		otherConsumption1Id,
		otherConsumption1Days,
		otherConsumption2Id,
		otherConsumption2Days,
		otherConsumption3Id,
		otherConsumption3Days,
		otherConsumption4Id,
		otherConsumption4Days,
		otherConsumption5Id,
		otherConsumption5Days,
	} = selectedEntry;

	const generateOtherDaysMap = (
		otherConsumptionData: Record<string, any>,
	): Record<string, OtherConsumptionData> => {
		return Array.from({ length: 5 }, (_, i) => i + 1).reduce((acc, index) => {
			const idKey = `otherConsumption${index}Id`;
			const daysKey = `otherConsumption${index}Days`;

			acc[idKey] = {
				idKey,
				daysKey,
				idValue: otherConsumptionData[idKey],
				daysValue: otherConsumptionData[daysKey],
			};

			return acc;
		}, {} as Record<string, OtherConsumptionData>);
	};

	const otherConsumptionData = {
		otherConsumption1Id,
		otherConsumption1Days,
		otherConsumption2Id,
		otherConsumption2Days,
		otherConsumption3Id,
		otherConsumption3Days,
		otherConsumption4Id,
		otherConsumption4Days,
		otherConsumption5Id,
		otherConsumption5Days,
	};

	const otherDaysMap = generateOtherDaysMap(otherConsumptionData);

	const vesselOtherConsumptions = vessel?.otherPerformanceEntries;

	const [selectedPort, setSelectedPort] = useState(port);

	const latestActualDate = useMemo(() => {
		if (itinerary == null || itinerary.length === 0) {
			return null;
		}

		let candidate: Moment | null = null;
		itinerary.forEach((item) => {
			if (!isItemPortCall(item)) {
				return;
			}

			if (item.departureDate != null) {
				if (
					candidate == null ||
					item.departureDate.isAfter(candidate)
				) {
					candidate = item.departureDate;

					return;
				}
			}

			if (item.arrivalDate != null) {
				if (
					candidate == null ||
					item.arrivalDate.isAfter(candidate)
				) {
					candidate = item.arrivalDate;
				}
			}
		});

		return candidate;
	}, [itinerary]);

	const latestEstimatedDate = useMemo(() => {
		if (expandedEntry == null) {
			return null;
		}

		const prevSeaPassage = itinerary?.find((item): item is ItinerarySeaPassageDto => {
			return !isItemPortCall(item) && item.nextPortCallId === expandedEntry.id;
		});

		if (prevSeaPassage == null) {
			return null;
		}

		const prevPortCall = itinerary?.find(
			(pc): pc is ItineraryPortCallDto => pc.id === prevSeaPassage.prevPortCallId,
		);

		if (prevPortCall == null) {
			return null;
		}

		return prevPortCall.estimatedDepartureDate;
	}, [itinerary, expandedEntry]);

	const isPreviousActual = useMemo(() => {
		if (expandedEntry == null) {
			return false;
		}

		if (!isItemPortCall(expandedEntry)) {
			const prevPortCall = itinerary?.find((item): item is ItineraryPortCallDto => {
				return isItemPortCall(item) && item?.nextSeaPassageId === expandedEntry.id;
			});

			return prevPortCall != null && prevPortCall.arrivalDate != null;
		}

		const prevSeaPassage = itinerary?.find((item): item is ItinerarySeaPassageDto => {
			return !isItemPortCall(item) && item.nextPortCallId === expandedEntry.id;
		});

		if (prevSeaPassage == null) {
			return true;
		}

		const prevPortCall = itinerary?.find((item): item is ItineraryPortCallDto => {
			return isItemPortCall(item) && prevSeaPassage?.prevPortCallId === item.id;
		});

		return prevPortCall?.departureDate != null;
	}, [itinerary, expandedEntry]);

	const isCommencement = actions.some((a) => a.action === PortActionTypes.COMMENCEMENT);
	const hasDelivery = actions.some((a) => a.action === PortActionTypes.DELIVERING);
	const defaultPortTzOffset = useMemo(() => getUtcOffset(selectedPort), [selectedPort]);

	const arrivalOffsetLabel = UtcOffsets.find((o) => o.offset === arrivalDate?.utcOffset());
	const departureOffsetLabel = UtcOffsets.find((o) => o.offset === departureDate?.utcOffset());
	const [saving, setSaving] = useState(false);

	const updateEntry = async (
		attributes: {
			arrivalDate?: Moment;
			departureDate?: Moment;
			estimatedDepartureDate?: Moment;
			requiredArrivalDate?: Moment;
			port?: Port;
			estimatedIdleDays?: number;
			estimatedLoadingDays?: number;
			estimatedDischargingDays?: number;
			otherConsumption1Id?: number;
			otherConsumption2Id?: number;
			otherConsumption3Id?: number;
			otherConsumption4Id?: number;
			otherConsumption5Id?: number;
			otherConsumption1Days?: number;
			otherConsumption2Days?: number;
			otherConsumption3Days?: number;
			otherConsumption4Days?: number;
			otherConsumption5Days?: number;
		},
	) => {
		setSaving(true);

		try {
			await updatePortCall(selectedEntry.vesselId, selectedEntry.id, attributes, true);
			await refreshDetails();
		} catch (e) {
			showErrorNotification('Could not update port call', e as Error);
		} finally {
			setSaving(false);
		}
	};

	useEffect(() => {
		setSelectedPort(port);
	}, [port]);

	const requiredSpeedContent = useMemo(() => {
		if (requiredSpeed == null) {
			return (
				<Typography.Text>N/A</Typography.Text>
			);
		}

		if (Number.isNaN(requiredSpeed) || requiredSpeed < 0) {
			return (
				<Alert
					message="There is no possible speed to arrive at the required time"
					className={styles.requiredSpeedAlert}
				/>
			);
		}

		return (
			<Typography.Text>
				{requiredSpeed}
				{' '}
				kt.
			</Typography.Text>
		);
	}, [requiredSpeed]);

	const estimatedTimeInPortContent = useMemo(() => {
		const workingDays = normalize(estimatedLoadingDays) + normalize(estimatedDischargingDays);
		const idleDays = normalize(estimatedIdleDays);
		const turningDays = normalize(estimatedTurningDays);

		const inPort = round(workingDays + idleDays + turningDays, 2);

		return (
			<Typography.Text>
				{inPort}
				{' '}
				{inPort === 1 ? 'day' : 'days'}
			</Typography.Text>
		);
	}, [estimatedLoadingDays, estimatedDischargingDays, estimatedIdleDays, estimatedTurningDays]);

	const estimatedConsumptionsContent = useMemo(() => {
		if (selectedEntry.estimatedConsumptions == null) {
			return (
				<div>
					N/A
				</div>
			);
		}

		const cons = Object.entries(selectedEntry.estimatedConsumptions);

		return cons.map(([fuelType, { quantity }]) => {
			return (
				<div key={fuelType} className={styles.consumptionsRow}>
					<b style={{ whiteSpace: 'nowrap' }}>{`${fuelType}: `}</b>
					<span style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
						{`${formatNumber(quantity, { separateThousands: true }, 2)} MT`}
					</span>
				</div>
			);
		});
	}, [selectedEntry]);

	if (defaultPortTzOffset == null) {
		return (<LoadingIndicator />);
	}

	return (
		<>
			<Row gutter={[16, 16]}>
				{saving && (
					<div className={styles.loadingOverlay}>
						<LoadingIndicator />
					</div>
				)}
				{isCommencement ? (
					<Col span={12}>
						<Card className={styles.card}>
							<Typography.Text type="secondary">
								ETD
								{departureOffsetLabel != null && selectedTimeFormat === 'localTime' ? ` (${departureOffsetLabel.name})` : ' (UTC)'}
							</Typography.Text>
							<Typography.Title
								className={styles.date}
								level={3}
							>
								{latestEstimatedDate == null ? (
									<EditableText
										defaultValue={estimatedDepartureDate}
										placeholder={(<EmptyText />)}
										type="date"
										inputProps={{
											time: true,
											showTimezone: true,
											defaultUtcOffset: defaultPortTzOffset,
											forceUtcUpdate: true,
										}}
										onChange={(value: Moment) => updateEntry({ estimatedDepartureDate: value })}
										renderValue={
											(d: Moment) => (<PrettyDate date={d} format={selectedTimeFormat} />)
										}
										options={null}
									/>
								) : (<PrettyDate date={estimatedDepartureDate!} format={selectedTimeFormat} />)}
							</Typography.Title>
						</Card>
					</Col>
				) : (
					<>
						<Col span={12}>
							<Card className={styles.card}>
								<Typography.Text type="secondary">
									ETA
									{arrivalOffsetLabel != null && selectedTimeFormat === 'localTime' ? ` (${arrivalOffsetLabel.name})` : ' (UTC)'}
								</Typography.Text>
								<Typography.Title
									className={styles.date}
									level={3}
								>
									{estimatedArrivalDate != null ?
										(<PrettyDate date={estimatedArrivalDate} format={selectedTimeFormat} />) :
										'N/A'}
								</Typography.Title>
							</Card>
						</Col>
						<Col span={12}>
							<Card className={styles.card}>
								<Typography.Text type="secondary">
									ETD
									{departureOffsetLabel != null && selectedTimeFormat === 'localTime' ? ` (${departureOffsetLabel.name})` : ' (UTC)'}
								</Typography.Text>
								<Typography.Title
									className={styles.date}
									level={3}
								>
									{latestEstimatedDate == null ? (
										<EditableText
											defaultValue={estimatedDepartureDate}
											placeholder={(<EmptyText />)}
											type="date"
											inputProps={{
												time: true,
												showTimezone: true,
												defaultUtcOffset: defaultPortTzOffset,
												forceUtcUpdate: true,
											}}
											onChange={(value: Moment) => updateEntry({ estimatedDepartureDate: value })}
											renderValue={
												(d: Moment) => (<PrettyDate date={d} format={selectedTimeFormat} />)
											}
											options={null}
										/>
									) : (<PrettyDate date={estimatedDepartureDate!} format={selectedTimeFormat} />)}
								</Typography.Title>
							</Card>
						</Col>
					</>
				)}
				<Col span={24}>
					<Descriptions
						bordered
						size="small"
						column={1}
						className={styles.details}
					>
						{!isCommencement && (
							<>
								<Descriptions.Item
									key="arrivalDate"
									label="Actual Arrival Date"
								>
									<EditableField
										item={{
											editable: true,
											key: 'arrivalDate',
											label: 'Actual Arrival Date',
											type: 'date',
											value: arrivalDate,
											inputProps: {
												disabled: !isPreviousActual && !hasDelivery,
												allowClear: true,
												time: true,
												showTimezone: true,
												defaultUtcOffset: defaultPortTzOffset,
												forceUtcUpdate: true,
												staticTimezonePicker: true,
												className: styles.datePicker,
											},
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
								<Descriptions.Item
									key="mastersEta"
									label="Masters ETA"
								>
									<EditableField
										item={{
											editable: true,
											key: 'mastersEta',
											label: 'Masters ETA',
											type: 'date',
											value: mastersEta,
											inputProps: {
												allowClear: true,
												time: true,
												showTimezone: true,
												defaultUtcOffset: defaultPortTzOffset,
												forceUtcUpdate: true,
												staticTimezonePicker: true,
												className: styles.datePicker,
											},
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
								<Descriptions.Item
									key="departureDate"
									label="Actual Departure Date"
								>
									<EditableField
										item={{
											editable: true,
											key: 'departureDate',
											label: 'Actual Departure Date',
											type: 'date',
											inputProps: {
												disabled: arrivalDate == null && !isCommencement,
												forceUtcUpdate: true,
												minDate: arrivalDate ?? undefined,
												allowClear: true,
												time: true,
												showTimezone: true,
												defaultUtcOffset: defaultPortTzOffset,
												staticTimezonePicker: true,
												className: styles.datePicker,
												defaultValue: departureDate,
											},
											value: departureDate,
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
							</>
						)}
						{!isCommencement && (
							<>
								<Descriptions.Item
									key="requiredArrivalDate"
									label="Required Arrival Date"
								>
									<EditableField
										item={{
											editable: true,
											key: 'requiredArrivalDate',
											label: 'Required Arrival Date',
											type: 'date',
											value: requiredArrivalDate,
											inputProps: {
												minDate: isPreviousActual ?
													(latestActualDate ?? undefined) :
													(latestEstimatedDate ?? undefined),
												forceUtcUpdate: true,
												time: true,
												defaultUtcOffset: defaultPortTzOffset,
												showTimezone: true,
												staticTimezonePicker: true,
												className: styles.datePicker,
											},
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
								<Descriptions.Item
									key="requiredSpeed"
									label="Required Speed"
								>
									{requiredSpeedContent}
								</Descriptions.Item>

							</>
						)}
					</Descriptions>
				</Col>
				<Col span={24}>
					{!isCommencement && (
						<Descriptions
							bordered
							size="small"
							column={1}
							className={styles.estimatedDetails}
						>
							<Descriptions.Item
								key="estimatedIdleDays"
								label="Est. idle days"
							>
								<EditableField
									item={{
										editable: true,
										key: 'estimatedIdleDays',
										label: 'Est. idle days',
										type: 'number',
										style: { width: '100%' },
										value: estimatedIdleDays,
										inputProps: {
											addonAfter: 'days',
											className: styles.portDaysInput,
										},
									}}
									editChange={(key, newValue) => updateEntry({ [key]: newValue })}
									editing
								/>
							</Descriptions.Item>
							{vesselOtherConsumptions?.map((oc) => {
								const matchedEntry = Object.values(otherDaysMap)
									.find((odm) => odm.idValue === oc.id);

								// Set keys and values, using defaults when no match is found
								const idKey = matchedEntry?.idKey ?? `otherConsumption${oc.id}Id`;
								const daysKey = matchedEntry?.daysKey ?? `otherConsumption${oc.id}Days`;
								const daysValue = matchedEntry?.daysValue ?? 0;

								const handleEditChange = (key: string, newValue: number) => {
									updateEntry({
										[daysKey]: newValue,
										[idKey]: oc.id,
									});
								};

								return (
									<Descriptions.Item key={oc.id} label={`Est. ${oc.condition.toLowerCase()}`}>
										<EditableField
											item={{
												editable: true,
												key: daysKey,
												label: `Est. ${oc.condition.toLowerCase()}`,
												type: 'number',
												style: { width: '100%' },
												value: daysValue as number,
												inputProps: {
													addonAfter: 'days',
													className: styles.portDaysInput,
												},
											}}
											editChange={handleEditChange}
											editing
										/>
									</Descriptions.Item>
								);
							})}
							<Descriptions.Item
								key="estimatedDischargingDays"
								label="Est. discharging days"
							>
								<EditableField
									item={{
										editable: true,
										key: 'estimatedDischargingDays',
										label: 'Est. discharging days',
										type: 'number',
										value: estimatedDischargingDays,
										inputProps: { className: styles.portDaysInput },
									}}
									editChange={(key, newValue) => updateEntry({ [key]: newValue })}
									editing
								/>
							</Descriptions.Item>
							<Descriptions.Item
								key="estimatedTurningDays"
								label="Est. turn days"
							>
								<EditableField
									item={{
										editable: true,
										key: 'estimatedTurningDays',
										label: 'Est. turn days',
										type: 'number',
										value: estimatedTurningDays,
										inputProps: {
											addonAfter: 'days',
											className: styles.portDaysInput,
										},
									}}
									editChange={(key, newValue) => updateEntry({ [key]: newValue })}
									editing
								/>
							</Descriptions.Item>
							<Descriptions.Item
								key="estimatedTimeInPort"
								label="Est. time in port"
							>
								{estimatedTimeInPortContent}
							</Descriptions.Item>
							<Descriptions.Item
								key="estimatedConsumptions"
								label={(
									<Space
										direction="vertical"
										size={0}
									>
										<p className={styles.consumptionsLabel}>Est. consumption</p>
										<p className={styles.sulfurLabel}>
											Max sulfur:
											{selectedEntry.port.maxSulfur != null ?
												` ${selectedEntry.port.maxSulfur}% - ECA` :
												' N/A'}
										</p>
									</Space>
								)}
							>
								{estimatedConsumptionsContent}
							</Descriptions.Item>
						</Descriptions>
					)}
				</Col>
			</Row>
			<br />
		</>
	);
};

export default PortCallDetails;
