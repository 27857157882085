import React from 'react';
import {
	DeleteOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
	Space,
	Typography,
} from 'antd';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileInvoice,
	faUndo,
} from '@fortawesome/pro-light-svg-icons';
import {
	Icon,
	IconProp,
} from '@fortawesome/fontawesome-svg-core';
import {
	AccountingItemApprovalStates,
	AccountTypes,
	Currencies,
	currencySymbols,
	DATE_AND_TIME,
	FixtureTypes,
	FuelTypes,
	HireInvoiceItemStates,
} from '@shared/utils/constants';
import { getPayerString } from '@shared/utils/string';
import { formatCurrency } from '@shared/utils/currency';
import { formatDuration } from '@shared/utils/date';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { calculateTotal } from '@shared/utils/math';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { BunkerType } from '@api/models/bunker';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { FixtureCounterpartyProps } from '@api/models/fixture-counterparty';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import { formatDate } from '@client/utils/formatDate';
import { Links } from '@client/utils/links';
import {
	getFilterProps,
	renderDate,
} from '@client/utils/table';
import Button from '@client/components/Button';
import StateTag from '@client/components/StateTag';
import MultiCurrencyAmount, { MultiCurrencyValuesType } from '@client/components/MultiCurrencyAmount';
import EmptyText from '@client/components/EmptyText';
import styles from './styles/tableColumns.module.css';

type CurrenciesType = Values<typeof Currencies>

const getSharedBunkerColumns = (
	fixtureCurrency: CurrenciesType,
) => [
	{
		dataIndex: 'fuelGrade',
		title: 'Grade',
		editable: true,
		editingProps: {
			type: 'select',
			options: Object.values(FuelTypes).map((ft) => ({ label: ft, value: ft })),
		},
	},
	{
		dataIndex: 'quantity',
		title: 'Quantity',
		render: (text: string) => `${text} MT`,
		editable: true,
		editingProps: {
			type: 'number',
			placeholder: 'MT',
		},
	},
	{
		dataIndex: 'pricePerTon',
		title: 'Price',
		editable: true,
		editingProps: {
			inputProps: {
				baseCurrency: fixtureCurrency,
			},
			type: 'multiCurrency',
			placeholder: `${currencySymbols[fixtureCurrency] || fixtureCurrency} / MT`,
		},
		render: (c: MultiCurrencyValuesType) => (c != null ? (
			<MultiCurrencyAmount
				amount={c.value}
				inputCurrency={c.currency}
				fixtureCurrency={fixtureCurrency}
				exchangeRate={c.exchangeRate}
			/>
		) : (<EmptyText />)),
	},
	{
		dataIndex: 'total',
		title: 'Total',
		// Only show total when not editing a row and both quantity and price are set
		render: (
			_total: number,
			row: BunkerType & {pricePerTon: MultiCurrencyValuesType},
			_index: number,
			editingRow: number,
		) => {
			let price;

			if (
				row?.pricePerTon?.value != null &&
				row?.pricePerTon?.currency != null &&
				row?.pricePerTon?.exchangeRate != null
			) {
				const { value, currency, exchangeRate } = row.pricePerTon;
				price = (currency !== fixtureCurrency) ?
					value / exchangeRate :
					value;
			}

			return ((
				editingRow == null &&
				price != null
			) ?
				formatCurrency(row.quantity * price, fixtureCurrency) :
				''
			);
		},
	},
];

export const getOffHireBunkerColumns = (
	fixtureCurrency: CurrenciesType,
) => [
	...getSharedBunkerColumns(fixtureCurrency),
];

export const getInvoiceIdentifierColumn = (voyageDetails: {id: number}) => ({
	dataIndex: 'invoiceIdentifier',
	title: 'Invoice',
	render: (value: string, freightInvoice: {id: number}) => (
		<Link to={Links.VoyageInvoice.get(voyageDetails.id, freightInvoice.id)}>
			<Space>
				<FontAwesomeIcon icon={faFileInvoice as IconProp} />
				{value}
			</Space>
		</Link>
	),
	sorter: () => 1,
});

const getAmountInvoicedColumn = (
	voyageDetails: GetVoyageDetailsResponse,
) => ({
	title: 'Invoiced',
	align: 'right',
	dataIndex: 'amount',
	render: (amount: number) => (
		<div>
			{formatCurrency(amount, voyageDetails.bankAccount.currency, { forceDecimals: true })}
		</div>
	),
});

const getSharedInvoiceColumns = ({
	onUndo,
	onDelete,
	onSubmit,
	voyageInvoices,
	isTc,
}: {
	voyageInvoices: GetVoyageDetailsResponse['hireInvoices'];
	onDelete: (id: number) => void;
	onSubmit: (id: number) => void;
	onUndo: (id: number) => void;
	isTc: boolean;
}) => {
	const getPaidAmount = (item: GetVoyageDetailsResponse['hireInvoices'][number]) => calculateTotal(item.VoyagePaymentParts, (payment) => payment.amount);

	return [
		{
			dataIndex: '',
			title: 'Paid',
			align: 'right',
			render: (item: GetVoyageDetailsResponse['hireInvoices'][number]) => formatCurrency(getPaidAmount(item), item.currency),
		},
		{
			dataIndex: '',
			title: 'Outstanding',
			align: 'right',
			render: (item: GetVoyageDetailsResponse['hireInvoices'][number]) => {
				const paid = getPaidAmount(item);
				const outstanding = item.amount - paid;

				return formatCurrency(outstanding, item.currency);
			},
		},
		{
			dataIndex: 'state',
			title: 'State',
			render: (
				state: AccountingItemApprovalStates,
			) => (<StateTag state={state} />),
		},
		{
			dataIndex: '',
			title: '',
			align: 'center' as const,
			render: (_v: any, row: VoyageInvoice) => {
				const canBeSubmitted = row.state === AccountingItemApprovalStates.PENDING;
				const deleteDisabled = (
					isTc && voyageInvoices[0]?.id !== row.id
				) || row.accountingSystemId != null;
				const deleteMessage = row.accountingSystemId != null ? 'This invoice has been posted to accounts' : 'You can only delete the latest invoice';
				const { requiresApproval } = row;

				return (
					<div>
						{canBeSubmitted && (
							<Button
								onClick={() => onSubmit(row.id)}
								type="primary"
							>
								{`${requiresApproval ? 'Submit for approval' : 'Approve'}`}
							</Button>
						)}
						{(requiresApproval && !canBeSubmitted) && (
							<Button
								icon={(
									<FontAwesomeIcon
										icon={faUndo as Icon}
										className={styles.undoIcon}
									/>
								)}
								type="link"
								className={styles.undoButton}
								disabled={row.state === AccountingItemApprovalStates.REJECTED}
								disabledTooltip="You cannot unsubmit a rejected item. If you wish to make changes, delete the item and re-submit."
								onClick={async () => {
									await onUndo(row.id);
								}}
							>
								Undo submission
							</Button>
						)}
						{row.accountingSystemId == null ? (
							<Button
								disabled={deleteDisabled}
								confirmTitle={
									row.state !== AccountingItemApprovalStates.PENDING ? (
										row.posted ? 'This item has already been posted. Deleting this item will also delete/reverse the item in the accounting system' :
											'This invoice has been submitted for approvel or is approved, are you sure you want to delete it?'
									) : 'Are you sure you want to delete this invoice?'
								}
								className={styles.dismissButton}
								onClick={() => onDelete(row.id)}
								type="link"
								icon={(<DeleteOutlined />)}
								danger
								disabledTooltip={deleteMessage}
							/>
						) : (
							<Button
								disabled={voyageInvoices[0]?.id !== row.id}
								confirmTitle="
									This invoice has been posted to accounts. Would you like to reverse it? This will create a credit note in your accounting system, and allow you to create a new invoice in ClearVoyage."
								type="link"
								icon={(<ReloadOutlined />)}
								danger
								onClick={() => onDelete(row.id)}
							>
								Reverse
							</Button>
						)}

					</div>
				);
			},
		},
	];
};

export const getFreightInvoiceColumns = ({
	voyageDetails,
	charterers,
	onDelete,
	onSubmit,
	onUndo,
}: {
	charterers: FixtureCounterpartyProps[] | undefined;
	voyageDetails: GetVoyageDetailsResponse;
	voyageInvoices: VoyageInvoice[];
	onDelete: (id: number) => void;
	onSubmit: (id: number) => void;
	onUndo: (id: number) => void;
}) => [
	getInvoiceIdentifierColumn(voyageDetails),
	{
		title: 'Charterer',
		dataIndex: 'chartererId',
		render: (chartererId: number) => (charterers != null ? charterers.find((c) => c.id === chartererId)?.name : ''),
	},
	{
		title: 'Items',
		dataIndex: 'items',
		render: (items: Array<HireInvoiceItem>) => {
			const labels = items.map((i) => i.itemTypeLabel);

			return labels.join(', ');
		},
	},
	getAmountInvoicedColumn(voyageDetails),
	...getSharedInvoiceColumns({
		onUndo,
		onDelete,
		onSubmit,
		voyageInvoices: voyageDetails.hireInvoices ?? [],
		isTc: false,
	}),

];

const getPeriodColumnTitle = (fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>) => {
	const { useUTC } = fixtureDetails;
	const fixtureType = fixtureDetails.type;

	let string = 'Period';

	if (fixtureType !== FixtureTypes.BB_OUT) {
		string = `Hire ${string}`;
	}

	if (useUTC) {
		string += ' (UTC)';
	}

	return string;
};

export const getHireInvoiceColumns = ({
	voyageDetails,
	fixtureDetails,
	onDelete,
	onSubmit,
	onUndo,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	onDelete: (id: number) => void;
	onSubmit: (id: number) => void;
	onUndo: (id: number) => void;
}) => [
	getInvoiceIdentifierColumn(voyageDetails),
	{
		title: getPeriodColumnTitle(fixtureDetails),
		render: (_: any, row: GetVoyageDetailsResponse['hireInvoices'][number]) => {
			// If hire period was split, we could have multiple
			const hireInvoiceItems = row.HireInvoiceItems;
			let key: 'HIIHirePeriod' | 'HIIBbItem' = 'HIIHirePeriod';

			if (fixtureDetails.type === FixtureTypes.BB_OUT) {
				key = 'HIIBbItem';
			}

			if (hireInvoiceItems.length === 0) {
				return '—';
			}

			const firstItem = hireInvoiceItems[0];
			const nextItem = hireInvoiceItems?.[1];

			if (firstItem?.[key] == null) {
				return '—';
			}

			const firstPeriod = firstItem[key]!;
			const nextPeriod = nextItem?.[key];

			let toDate = firstPeriod?.to;

			if (nextPeriod != null) {
				toDate = nextPeriod.to;
			}

			if (toDate == null || firstPeriod?.from == null) {
				return '—';
			}

			return (
				<>
					{formatDate(firstPeriod.from, DATE_AND_TIME, false)}
					<b> - </b>
					{formatDate(toDate, DATE_AND_TIME, false)}
				</>
			);
		},
	},
	{
		title: 'Type',
		render: (row: {invoiceTitle: string}) => row?.invoiceTitle,
	},
	getAmountInvoicedColumn(voyageDetails),
	...getSharedInvoiceColumns({
		onUndo,
		onDelete,
		onSubmit,
		voyageInvoices: voyageDetails.hireInvoices ?? [],
		isTc: true,
	}),
];

export const getExpenseColumns = (
	fixtureCurrency: CurrenciesType,
	fixtureType: Values<typeof FixtureTypes>,
	hasDifferences: boolean,
) => [
	{
		dataIndex: 'createdAt',
		title: 'Creation date',
		render: renderDate(),
		sorter: standardSort('createdAt'),
	},
	{
		dataIndex: 'account',
		title: 'Account',
		render: (account: Values<typeof AccountTypes>) => getPayerString(account, fixtureType),
		...getFilterProps(Object.values(AccountTypes), 'select', 'account', 'asc', true),
		sorter: standardSort('account'),
	},
	{
		dataIndex: 'itemDescription',
		title: 'Item',
	},
	...(hasDifferences ?
		[{
			dataIndex: 'amount',
			title: 'Local Amount',
			align: 'right',
			render: (amount: number, row: GetVoyageDetailsResponse['voyageExpenseReceivables'][number]) => (
				formatCurrency(amount, row.currency)
			),
		},
		{
			dataIndex: 'amount',
			title: 'Contract Amount',
			align: 'right',
			render: (amount: number, row: GetVoyageDetailsResponse['voyageExpenseReceivables'][number]) => (
				formatCurrency(amount / row.exchangeRate, fixtureCurrency)
			),
		}] : [{
			dataIndex: 'amount',
			title: `Amount (${fixtureCurrency}) `,
			align: 'right',
			render: (amount: number, row: GetVoyageDetailsResponse['voyageExpenseReceivables'][number]) => (
				formatCurrency(amount / row.exchangeRate, fixtureCurrency, { hideSymbol: true })
			),
		}]
	),
	{
		dataIndex: 'state',
		title: 'State',
		render: (state: Values<typeof AccountingItemApprovalStates>) => (
			<StateTag state={state} />
		),
		width: 100,
		sorter: standardSort('state'),
		...getFilterProps([
			HireInvoiceItemStates.PENDING,
			HireInvoiceItemStates.INVOICED,
			HireInvoiceItemStates.UNRESOLVED,
			HireInvoiceItemStates.PAID,
			HireInvoiceItemStates.OVERPAID,
			HireInvoiceItemStates.PARTIAL,
		], 'select', 'state', undefined, true),
	},
];

export const getOffHireColumns = (
	useUTC: boolean,
	orgHasOffhireTypes: boolean,
	fixtureCurrency: CurrenciesType,
) => [
	{
		dataIndex: 'startTime',
		title: 'Start time',
		render: renderDate(DATE_AND_TIME, useUTC ? 'utc' : 'localTime'),
		sorter: standardSort('startTime'),
	},
	{
		dataIndex: 'endTime',
		title: 'End time',
		render: renderDate(DATE_AND_TIME, useUTC ? 'utc' : 'localTime'),
		sorter: standardSort('endTime'),
	},
	{
		dataIndex: 'duration',
		title: 'Duration',
		render: (
			_d: any,
			{ startTime, endTime }: {startTime: Moment; endTime: Moment},
			_index: number,
			editingRow: number,
		) => {
			if (editingRow != null || startTime == null || endTime == null) {
				return '';
			}

			return formatDuration(startTime, endTime);
		},
	},
	{
		dataIndex: 'note',
		title: 'Note',
		width: 200,
		render: (note: string) => (
			<Typography.Text
				ellipsis
				// eslint-disable-next-line react/forbid-component-props
				style={{ width: 300 }}
			>
				{note}
			</Typography.Text>
		),
	},
	(orgHasOffhireTypes ? {
		dataIndex: 'type',
		title: 'Type',
	} : []),
	{
		title: `Amount (${fixtureCurrency})`,
		dataIndex: 'amount',
		align: 'right',
		render: (amount: number) => formatCurrency(amount, fixtureCurrency, { hideSymbol: true }),
	},
	{
		dataIndex: 'state',
		title: 'State',
		render: (state: Values<typeof AccountingItemApprovalStates>) => (
			<StateTag state={state} />
		),
		sorter: standardSort('state'),
	},
];
