// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IiTokhPF_xsuHbYDJCg5 {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tmin-height: 50px;\n\talign-items: center;\n}\n\n.aKSnxIXh3KVDmcAom5NR {\n\twidth: 20px;\n\theight: 10px;\n\tbackground: red;\n\tborder-radius: 2px;\n\tmargin-right: 5px;\n\tmargin-left: 10px;\n}\n\n.oEKx_48o7UVQLW6M2vav {\n\tdisplay: flex;\n\talign-items: baseline;\n\tflex-shrink: 0;\n}\n\n.T5RdaYIcoRJuFZ_G1gJk {\n\tborder: 1px solid black;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/Legend.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,eAAe;CACf,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,eAAe;CACf,kBAAkB;CAClB,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,cAAc;AACf;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".chartLegend {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tmin-height: 50px;\n\talign-items: center;\n}\n\n.legendSquare {\n\twidth: 20px;\n\theight: 10px;\n\tbackground: red;\n\tborder-radius: 2px;\n\tmargin-right: 5px;\n\tmargin-left: 10px;\n}\n\n.legendItem {\n\tdisplay: flex;\n\talign-items: baseline;\n\tflex-shrink: 0;\n}\n\n.bordered {\n\tborder: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartLegend": "IiTokhPF_xsuHbYDJCg5",
	"legendSquare": "aKSnxIXh3KVDmcAom5NR",
	"legendItem": "oEKx_48o7UVQLW6M2vav",
	"bordered": "T5RdaYIcoRJuFZ_G1gJk"
};
export default ___CSS_LOADER_EXPORT___;
