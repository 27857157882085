import React from 'react';
import {
	Col,
	Row,
	Badge,
	Space,
	Flex,
} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import {
	FeatureFlags,
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import formatTcAndBbFixtureDuration from '@shared/utils/formatTcAndBbFixtureDuration';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import Details from '@client/components/Details';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import Button from '@client/components/Button';
import styles from './styles/SelectItineraryEntryAndDates.module.css';
import ItineraryTab from './ItineraryTab/ItineraryTab';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import ProjectView from './ProjectView/ProjectView';
import SelectItineraryEntryAndDates from './SelectItineraryEntryAndDates';

const TcSummaryTab = () => {
	const {
		voyageDetails,
		tcFixtureDetails: fixtureDetails,
		linkTheShip,
	} = useVoyage();

	const usesTheShip = useFeatureFlag(FeatureFlags.THE_SHIP);

	if (voyageDetails == null) {
		return null;
	}

	const getOtherItems = () => ([
		{
			type: 'text',
			key: '1',
			label: 'Duration',
			value: formatTcAndBbFixtureDuration(fixtureDetails as TcFixtureProps),
		},
		{
			key: '2',
			label: 'Charterer',
			value: (
				<>
					{FixtureTypeLabels[fixtureDetails.type]}
					{fixtureDetails.type === FixtureTypes.TC_IN ? ' from ' : ' to '}
					{fixtureDetails.counterpartyName}
				</>
			),
		},
		...(!usesTheShip ? [] : [{
			key: '3',
			label: 'TheShip Integration',
			value: voyageDetails.vesselExternalId ? (
				<Badge status="success" text="Connected" />
			) : (
				<Space>
					<Badge status="error" text="Not connected" />
					<Button
						onClick={linkTheShip}
						type="link"
						icon={(<LinkOutlined />)}
					>
						Connect
					</Button>
				</Space>
			),
		}]),
	]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={7}>
				<Flex gap={10} vertical>
					<SelectItineraryEntryAndDates
						labelWidth={180}
						columns={2}
					/>
					<Details
						labelWidth={180}
						className={styles.details}
						title={null}
						items={getOtherItems()}
					/>
				</Flex>
			</Col>
			{fixtureDetails.type === FixtureTypes.TC_IN && (
				<Col span={17}>
					<ProjectView />
				</Col>
			)}
			{fixtureDetails.type !== FixtureTypes.TC_IN && (
				<Col span={17}>
					<ItineraryTab />
				</Col>
			)}
		</Row>
	);
};

export default TcSummaryTab;
