import React, {
	useCallback,
	useEffect,
} from 'react';
import {
	Col,
	Divider,
	Flex,
	Grid,
	Row,
} from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { EstimateStatus } from '@shared/utils/constants';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import SavingIndicator from '@client/components/SavingIndicator';
import { useAuth } from '@client/lib/auth';
import { CentralStoreProvider } from '@client/screens/estimates/details/context/CentralStoreContext';
import TooltipIcon from '@client/components/TooltipIcon';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import { useEstimate } from '@client/screens/estimates/details/context/EstimatorContext';
import { useCargoProvider } from '@client/screens/estimates/details/context/CargoContext';
import IntakeCalculatorPortRotation from '../components/IntakeCalculatorPortRotation/IntakeCalculatorPortRotation';
import { getIntakeControlFields } from '../helpers/getIntakeControlFields';
import IntakeCalculatorKeyDetails from './IntakeCalculatorKeyDetails';
import IntakeCalculatorDetails from './IntakeCalculatorDetails';
import { getCargoDescription } from './CargoDetailsScreen';

type Props = {
	cargoId?: number;
	inEstimate?: boolean;
	blockClose?: boolean;
	setBlockClose?: React.Dispatch<React.SetStateAction<boolean>>;
	estimate: GetEstimateDetailsResponse | null;
}

const IntakeCalculatorScreen = ({
	cargoId,
	inEstimate,
	blockClose,
	setBlockClose,
	estimate,
}: Props) => {
	const { id } = useParams<{ id: string }>();
	const auth = useAuth();
	const baseCurrency = inEstimate && estimate != null ?
		estimate?.currency : auth.userInfo.baseCurrency;
	const history = useHistory();
	const screens = Grid.useBreakpoint();

	const {
		cargos,
		onUpdateCargo,
		onDeleteCargoMutator,
		pendingChanges,
		refreshEverything,
	} = useCargoProvider();

	const cargoDetails = cargos[0];

	useEffect(() => {
		setBlockClose?.(pendingChanges);
	}, [pendingChanges, blockClose, setBlockClose]);

	const {
		onUpdateEstimate,
		estimate: localEstimate,
		refreshVessel,
	} = useEstimate();

	const refreshAll = useCallback(() => {
		setTimeout(() => {
			refreshEverything();
			refreshVessel();
		}, 1200);
	}, [refreshEverything, refreshVessel]);

	const handleUpdate = useCallback(
		async (values: Record<string, any>) => {
			const [key, value] = Object.entries(values)[0];

			if (!estimate?.id) {
				return;
			}

			if ((estimate as any)[key] === value) {
				return;
			}

			await onUpdateEstimate({ ...values, estimateIdOverride: estimate.id }, true);
			refreshAll();
		},
		[estimate, onUpdateEstimate, refreshAll],
	);

	return (
		<CentralStoreProvider>
			<SimpleScreen
				title={getCargoDescription(cargoDetails)}
				breadcrumbs={
					[[
						'Cargos',
						Links.Cargos.get(),
					]]
				}
				headerActions={[
					(
						<Flex
							gap={10}
						>
							<SavingIndicator spinning={pendingChanges} />
							<Button
								danger
								confirmTitle="Are you sure you want to delete this cargo?"
								disabled={cargoDetails?.status === EstimateStatus.Fixed}
								disabledTooltip="You cannot remove cargos that are attached to a fixed estimate"
								onClick={() => {
									onDeleteCargoMutator.mutate(cargoId ?? Number(id), {
										onSuccess: () => {
											history.replace(Links.Cargos.get());
										},
									});
								}}
							>
								Delete Cargo
							</Button>
						</Flex>

					),
				]}
				canGoBack
				rootPageTitle="Cargos"
			>
				<Row gutter={[16, 16]}>
					<Col span={screens.lg ? 12 : 24}>
						<IntakeCalculatorKeyDetails
							handleChange={onUpdateCargo}
							cargoDetails={cargoDetails}
							handleUpdate={handleUpdate}
							refreshAll={refreshAll}
						/>
					</Col>
					<Col span={screens.lg ? 12 : 24}>
						<IntakeCalculatorDetails
							refreshCargoDetails={() => null}
							handleChange={onUpdateCargo}
							cargoDetails={cargoDetails}
							baseCurrency={baseCurrency}
						/>
						<Divider>
							Controls & Outputs
							<TooltipIcon>
								These fields control the freight calculation
							</TooltipIcon>
						</Divider>
						<EditableDetails
							title="Details"
							items={getIntakeControlFields({
								estimate: localEstimate,
							})}
							onEditValuesChange={handleUpdate}
							editing
							hideHeader
						/>
					</Col>
					<Col span={24}>
						<IntakeCalculatorPortRotation />
					</Col>
				</Row>
			</SimpleScreen>

		</CentralStoreProvider>
	);
};

export default IntakeCalculatorScreen;
