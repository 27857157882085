import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Col,
	Empty,
	Input,
	Row,
	Space,
	Tag,
} from 'antd';
import debounce from 'lodash.debounce';
import {
	CargoUnitLabels,
	CargoUnitTypes,
	currencySymbols,
	FreightRateType,
	VesselCostType,
	VesselOwnershipTypeLabels,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { PortRotationEntryGeneratorTypes } from '@shared/utils/port-rotation-entry-generator-types';
import type { UpdateEstimateAttributes } from '@api/features/estimates/updateEstimate';
import type { EditableCargo } from '@api/features/cargos/updateCargo';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import MatrixTable from '@client/components/Table/MatrixTable';
import Select from '@client/components/Select';
import getVesselOptions from '@client/utils/getVesselOptions';
import {
	updateCargo,
	updateEstimate,
} from '@client/lib/api';
import DatePicker, { DatePickerValue } from '@client/components/DatePicker';
import showErrorNotification from '@client/utils/showErrorNotification';
import ChartLegend from '@client/components/ChartLegend';
import NumericInput from '@client/components/NumericInput';
import { useCentralStore } from './context/CentralStoreContext';
import { useEstimate } from './context/EstimatorContext';
import { useCargoProvider } from './context/CargoContext';
import styles from './styles/GridView.module.css';

const GridView = () => {
	const {
		estimates,
		refreshEstimateGroup,
		setLoading,
	} = useCentralStore();

	const {
		allVessels,
	} = useEstimate();

	const {
		portOptions,
		charterers,
	} = useCargoProvider();

	const onUpdateEstimate = useCallback(async (
		estimateId: number,
		attributes: UpdateEstimateAttributes,
	) => {
		setLoading(true);

		try {
			await updateEstimate(estimateId, attributes, false, false);
			await refreshEstimateGroup();
		} catch (e) {
			showErrorNotification('Could not update estimate', e as Error);
		} finally {
			setLoading(false);
		}
	}, [refreshEstimateGroup, setLoading]);

	const onUpdateCargo = useCallback(async (
		cargoId: number,
		attributes: Partial<EditableCargo>,
	) => {
		setLoading(true);

		try {
			await updateCargo({ cargoId, attributes }, false);
			await refreshEstimateGroup();
		} catch (e) {
			showErrorNotification('Could not update cargo', e as Error);
		} finally {
			setLoading(false);
		}
	}, [refreshEstimateGroup, setLoading]);

	const onChangeCargo = useMemo(() => {
		return debounce((cargoId: number, attributes: Partial<EditableCargo>) => (
			onUpdateCargo(cargoId, attributes)
		), 1000);
	}, [onUpdateCargo]);

	const onChangeEstimate = useMemo(() => {
		return debounce((estimateId: number, attributes: UpdateEstimateAttributes) => (
			onUpdateEstimate(estimateId, attributes)
		), 1000);
	}, [onUpdateEstimate]);

	const updatePortRotationEntry = async (
		estimateId: number,
		attributes: Partial<{
			port: number | null;
			departureDate: DatePickerValue;
		}>,
	) => {
		const estimate = estimates?.find((e) => e.id === estimateId);

		if (estimate == null) {
			return;
		}

		const portRotation = estimate.EstimatePortRotationEntries;
		const ballastLeg = portRotation.find((pr) => (
			pr.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
		));

		if (ballastLeg == null) {
			return;
		}

		const portRotationCopy = [...portRotation ?? []];
		const entry = portRotationCopy.find((e) => e.id === ballastLeg.id);

		if (entry == null) {
			return;
		}

		Object.assign(entry, attributes);
		await onUpdateEstimate(estimateId, { portRotationEntries: portRotationCopy });
	};

	const options = [
		{
			label: 'No vessel',
			value: -1,
		},
		{
			label: 'My vessels',
			options: getVesselOptions(allVessels ?? [], 'myVessels'),
		},
		{
			label: 'Market vessels',
			options: getVesselOptions(allVessels ?? [], 'marketVessels'),
		},
	];

	const baseEntry = estimates?.[0];
	const baseBunkerExpenses = baseEntry?.bunkerExpenses;
	const missingCargo = estimates?.some((e) => e.cargo == null);

	if (missingCargo) {
		return (
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Card title="Unable to show grid comparison">
						<Empty
							image={manOnBoat}
							className={styles.emptyMessage}
							description={(
								<Row gutter={[16, 16]} className={styles.paddingBottom}>
									<Col span={24}>
										One or more of your estimates have no cargo
										<br />
										To show the grid comparison view,
										please make sure every estimate in the group has a cargo associated
									</Col>
								</Row>
							)}
						/>
					</Card>
				</Col>
			</Row>
		);
	}

	return (
		<Row gutter={[8, 8]}>
			<Col span={24}>
				<ChartLegend
					position="flex-start"
					items={[
						{
							key: 'calculated',
							label: 'Calculated Field',
							fill: 'hsla(56, 100%, 50%, 0.2)',
						},
						{
							key: 'difference',
							label: 'Has difference',
							fill: 'rgba(0, 123, 255, 0.08)',
						},
					]}
				/>
				<Card className={styles.fitContent} styles={{ body: { padding: 0 } }}>
					<div style={{
						width: 300 + ((estimates ?? []).length * 150),
						overflowX: 'scroll',
					}}
					>
						<MatrixTable
							compare
							data={(estimates ?? []).map((e) => ({ ...e, label: e.name }))}
							groups={[
								{
									title: 'P&L',
									children: [
										{ title: 'Freight', key: ['EstimateResult', 'freight'], type: 'currency', calculated: true },
										{ title: 'Port Costs', key: ['EstimateResult', 'portCharge'], type: 'currency', calculated: true },
										{ title: 'Bunker Exp.', key: ['EstimateResult', 'bunkerExpense'], type: 'currency', calculated: true },
										{ title: 'Other Costs', key: ['EstimateResult', 'otherVoyageExpenses'], type: 'currency', calculated: true },
										{ title: 'Net Profit', key: ['EstimateResult', 'voyageProfit'], type: 'currency', calculated: true },
										{ title: 'Total Days', key: ['EstimateResult', 'estimatedDuration'], type: 'number', inputProps: { addOnAfter: 'Days' }, calculated: true },
										{ title: 'TCE /day', key: ['EstimateResult', 'tce'], type: 'currency', calculated: true },
										{ title: 'Vessels Costs', key: ['EstimateResult', 'tcInExpense'], type: 'currency', calculated: true },
										{ title: 'Net Result /day', key: ['EstimateResult', 'netProfitPerDay'], type: 'currency', calculated: true },
									],
								},
								{
									title: 'Vessel',
									children: [
										{
											title: 'Name',
											render: (_, row) => (
												<Select
													placeholder="Select vessel"
													defaultValue={row?.vesselId}
													size="small"
													dropdownStyle={{ width: 350 }}
													optionRender={(option) => (
														<Space size="small">
															{option.data.ownershipType != null && (
																<Tag>
																	{VesselOwnershipTypeLabels[option.data.ownershipType as keyof typeof VesselOwnershipTypeLabels] ?? 'Unknown'}
																</Tag>
															)}
															{option.label}
														</Space>
													)}
													showSearch
													options={options}
													onChange={(vesselId) => onChangeEstimate(row.id, { vesselId })}
												/>
											),
										},
										{
											title: 'Vessel Cost',
											key: 'tcInRate',
											render: (vesselCosts, row) => (
												<NumericInput
													size="small"
													defaultValue={vesselCosts}
													placeholder="Rate"
													addonBefore={
														currencySymbols[row?.currency as keyof typeof currencySymbols] ??
														row?.currency
													}
													addonAfter={row?.vesselCostType === VesselCostType.PerDay ? '/d' : 'LS'}
													onChange={(e) => {
														onChangeEstimate(row.id, {
															tcInRate: Number(e),
														});
													}}
												/>
											),
										},
										{
											title: 'EUA Price',
											key: 'euaPriceOverride',
											render: (override, row) => (
												<NumericInput
													size="small"
													addonBefore={
														currencySymbols[row?.currency as keyof typeof currencySymbols] ??
														row?.currency
													}
													type="number"
													defaultValue={round((override ?? row?.remoteEuaPrice) ?? 0, 2)}
													placeholder="EUA Cost"
													onChange={(e) => {
														onChangeEstimate(row.id, {
															euaPriceOverride: Number(e),
														});
													}}
												/>
											),
										},
										...(baseBunkerExpenses != null ?
											Object.keys(baseBunkerExpenses ?? [{}]).map((fuelType) => ({
												title: fuelType,
												render: (_v: any, row: any) => {
													const consumption = row?.bunkerExpenses ?? {};
													const count = Object.keys(consumption).length;

													if (count === 0) {
														return ' - ';
													}

													return (
														<NumericInput
															size="small"
															defaultValue={consumption[
																fuelType as keyof typeof consumption
															]?.pricePerTon ?? 0}
															addonBefore={currencySymbols[
																row?.currency as keyof typeof currencySymbols
															] ?? row?.currency}
															placeholder={`${fuelType} price`}
															addonAfter="/MT"
															onChange={(e) => {
																onChangeEstimate(row.id, {
																	bunkerExpenses: {
																		...consumption,
																		[fuelType]: {
																			...consumption[fuelType as keyof typeof consumption],
																			pricePerTon: Number(e),
																		},
																	},
																});
															}}
														/>
													);
												},
											})) : []),
									],
								},
								{
									title: 'Cargo',
									children: [
										{
											title: 'Name',
											key: ['cargo', 'type'],
											render: (type, row) => (
												<Input
													size="small"
													defaultValue={type}
													placeholder="Cargo description"
													onChange={(e) => onChangeCargo(row?.cargo?.id, { type: e.target.value })}
												/>
											),
										},
										{
											title: 'Loadport(s)',
											key: ['cargo', 'loadingPorts'],
											render: (lps, row) => (
												<Select
													size="small"
													defaultValue={lps.map((lp: { id: number } | number) => (typeof lp === 'object' ? lp.id : lp))}
													// @ts-ignore This works because it's feature in rc-select
													showAction="focus"
													mode="multiple"
													placeholder="Loading Ports"
													options={portOptions}
													onChange={(e) => {
														onChangeCargo(row.cargo.id, {
															loadingPorts: e,
														});
													}}
												/>
											),
										},
										{
											title: 'Disport(s)',
											key: ['cargo', 'dischargePorts'],
											render: (dps, row) => (
												<Select
													size="small"
													defaultValue={dps.map((dp: { id: number } | number) => (typeof dp === 'object' ? dp.id : dp))}
													// @ts-ignore This works because it's feature in rc-select
													showAction="focus"
													mode="multiple"
													placeholder="Discharge Ports"
													options={portOptions}
													onChange={(e) => {
														onChangeCargo(row.cargo.id, {
															dischargePorts: e,
														});
													}}
												/>
											),
										},
										{
											title: 'Qty',
											type: 'number',
											key: ['cargo', 'quantity'],
											render: (qty, row) => (
												<NumericInput
													size="small"
													defaultValue={qty}
													placeholder="Quantity"
													addonAfter={CargoUnitLabels[row.cargo.unit]}
													onChange={(e) => onChangeCargo(row?.cargo?.id, { quantity: Number(e) })}
												/>
											),
										},
										{
											title: 'Tolerance',
											key: ['cargo', 'quantityTolerance'],
											render: (tolerance, row) => (
												<NumericInput
													size="small"
													defaultValue={tolerance}
													placeholder="Tolerance"
													addonAfter="%"
													onChange={(e) => onChangeCargo(row?.cargo?.id, {
														quantityTolerance: Number(e),
													})}
												/>
											),
										},
										{
											title: 'Units',
											key: ['cargo', 'unit'],
											render: (unit, row) => (
												<Select
													defaultValue={unit}
													size="small"
													// @ts-ignore This works because it's feature in rc-select
													showAction="focus"
													placeholder="Unit"
													options={Object.keys(CargoUnitTypes).map((u) => ({
														label: CargoUnitLabels[u as keyof typeof CargoUnitLabels],
														value: u,
													}))}
													onChange={(e) => {
														onChangeCargo(row.cargo.id, {
															unit: e,
														});
													}}
												/>
											),
										},
										{
											title: 'Rate',
											key: ['cargo', 'freightRate'],
											render: (rate, row) => (
												<NumericInput
													size="small"
													defaultValue={rate}
													placeholder="Freight Rate"
													addonBefore={currencySymbols[
														row?.currency as keyof typeof currencySymbols
													] ?? row?.currency}
													addonAfter={`/${CargoUnitLabels[row.cargo.unit]}`}
													onChange={(e) => onChangeCargo(
														row?.cargo?.id,
														{ freightRate: Number(e) },
													)}
												/>
											),
										},
										{
											title: 'Freight Type',
											key: ['cargo', 'freightType'],
											render: (freightType, row) => (
												<Select
													defaultValue={freightType}
													size="small"
													// @ts-ignore This works because it's feature in rc-select
													showAction="focus"
													placeholder="Unit"
													options={[
														{
															label: 'Lumpsum',
															value: FreightRateType.LUMPSUM,
														},
														{
															label: row.cargo.unit != null ? `Per ${CargoUnitLabels[row.cargo.unit]}` : 'Per unit',
															value: FreightRateType.PER_UNIT,
														},
													]}
													onChange={(e) => {
														onChangeCargo(row.id, {
															freightType: e,
														});
													}}
												/>
											),
										},
										{
											title: 'Add. Com',
											key: ['cargo', 'addressCommission'],
											render: (addCom, row) => (
												<NumericInput
													size="small"
													defaultValue={addCom}
													placeholder="Address Commission"
													addonAfter="%"
													onChange={(e) => onChangeCargo(row?.cargo?.id, {
														addressCommission: Number(e),
													})}
												/>
											),
										},
										{
											title: 'Br. Com',
											key: ['cargo', 'brokerCommission'],
											render: (brComm, row) => (
												<NumericInput
													size="small"
													defaultValue={brComm}
													placeholder="Broker Commission"
													addonAfter="%"
													onChange={(e) => onChangeCargo(row?.cargo?.id, {
														brokerCommission: Number(e),
													})}
												/>
											),
										},
										{
											title: 'Charterer',
											key: ['cargo', 'charterer'],
											render: (charterer, row) => (
												<Select
													defaultValue={charterer === 0 ? undefined : charterer}
													size="small"
													// @ts-ignore This works because it's feature in rc-select
													showAction="focus"
													placeholder="Select Charterer"
													options={(charterers ?? []).map((c) => ({
														label: c.name,
														value: c.id,
													}))}
													onChange={(c) => {
														onChangeCargo(row.id, {
															charterer: c,
														});
													}}
												/>
											),
										},
									],
								},
								{
									title: 'Port Rotation',
									children: [
										{
											title: 'Open Port',
											key: 'openPort',
											render: (_op, row) => {
												const portRotation = row.EstimatePortRotationEntries;
												const ballastLeg = portRotation.find((pr) => (
													pr.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
												));

												return (
													<Select
														size="small"
														defaultValue={ballastLeg?.port?.id}
														// @ts-ignore This works because it's feature in rc-select
														showAction="focus"
														showSearch
														placeholder="Open Port"
														options={portOptions}
														onChange={(portId) => updatePortRotationEntry(row.id, {
															port: portId,
														})}
													/>
												);
											},
										},
										{
											title: 'Open Date',
											key: 'openDate',
											render: (_od, row) => {
												const portRotation = row.EstimatePortRotationEntries;
												const ballastLeg = portRotation.find((pr) => (
													pr.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
												));

												return (
													<DatePicker
														time
														showTimezone={false}
														size="small"
														defaultValue={ballastLeg?.departureDate}
														onChange={(a) => updatePortRotationEntry(row.id, {
															departureDate: a,
														})}
													/>
												);
											},
										},
									],
								},
							]}
						/>
					</div>
				</Card>
			</Col>
		</Row>
	);
};

export default GridView;
