import React from 'react';
import { FreightEstimatingMethod } from '@shared/utils/constants';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import EmptyText from '@client/components/EmptyText';
import { EditableDetailsItem } from '@client/components/EditableField';

export const getIntakeControlFields = ({
	estimate,
}: {
	estimate: GetEstimateDetailsResponse | null;
}) => {
	const freightEstimatingMethodLabels: Record<FreightEstimatingMethod, string> = {
		[FreightEstimatingMethod.CP_QUANTITY]: 'CP Quantity',
		[FreightEstimatingMethod.MAX_INTAKE]: 'Max Intake',
	};

	return (
		[
			{
				key: 'vesselMaximumIntake',
				label: 'Maximum intake',
				type: 'number',
				value: estimate?.vesselMaximumIntake,
				editable: true,
				inputProps: {
					suffix: 'MT',
				},
			},
			{
				key: 'freightEstimatingMethod',
				label: 'For estimating freight',
				type: 'select',
				value: estimate?.freightEstimatingMethod,
				options: Object.values(FreightEstimatingMethod).map((c) => ({
					label: freightEstimatingMethodLabels[c],
					value: c,
				})),
				inputProps: {
					placeholder: 'Select Option',
				},
				render: () => (
					<>
						{estimate?.freightEstimatingMethod != null ?
							freightEstimatingMethodLabels[estimate.freightEstimatingMethod] : (<EmptyText />)}
					</>
				),
				editable: true,
			},
		] as EditableDetailsItem<any>[]
	);
};
