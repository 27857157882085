import React, { useCallback } from 'react';
import {
	Col,
	Row,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import {
	FuelTypes,
	VesselTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import Card from '@client/components/Card/Card';
import EditableCellTableRedux, { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import showErrorNotification from '@client/utils/showErrorNotification';
import Button from '@client/components/Button';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import AddButton from '@client/components/AddButton';
import { deleteVesselOtherPerformanceEntry } from '@client/lib/api';
import styles from './ConsumptionSets.module.css';
import {
	OtherSimplePerformance,
	SimplePerformance,
} from './ConsumptionSets';

type Props = {
	vesselId: number | undefined | null;
	vesselType: Values<typeof VesselTypes> | null;
	refreshVessel: () => void;
	inEstimate?: boolean;
	unsavedChanges?: boolean;
	onRefresh?: () => void;
	addEntry: () => void;
	updateEntries: (vals: Array<SimplePerformance | OtherSimplePerformance>, type: 'inPort' | 'atSea' | 'other', relation: 'main' | 'secondary') => Promise<void>;
	isLoading: boolean;
	localData: {
		atSea: {
			main: Array<SimplePerformance>;
			secondary: Array<SimplePerformance>;
		};
		inPort: {
			main: Array<SimplePerformance>;
			secondary: Array<SimplePerformance>;
		};
		other: {
			main: Array<OtherSimplePerformance>;
			secondary: Array<OtherSimplePerformance>;
		};
	};

}

const OtherConsumption = ({
	vesselId,
	refreshVessel,
	inEstimate = false,
	onRefresh,
	addEntry,
	updateEntries,
	isLoading,
	localData,
}: Props) => {
	const handleAdd = async () => {
		if (vesselId == null) {
			return;
		}

		addEntry();
	};

	const handleDeleteOtherConsumption = useCallback(async (id: number | string) => {
		if (vesselId == null) {
			return;
		}

		try {
			if (typeof id === 'string') {
				localData.other.main = localData.other.main.filter((oc) => oc.ballastId !== id as any);
			} else {
				await deleteVesselOtherPerformanceEntry(vesselId, id as number);
			}

			refreshVessel();
			showSuccessNotification('Other consumption deleted');
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not other consumption', e as Error);
		}
	}, [vesselId, refreshVessel, onRefresh, localData.other]);

	return (
		<Card
			className={classNames({
				[styles.card]: !inEstimate,
				[styles.cardWithMargin]: inEstimate,
			})}
			bordered={false}
		>
			<Row gutter={16}>
				<Col span={8} />
				<Col span={16}>
					<div className={styles.flexVertical}>
						<EditableCellTableRedux<OtherSimplePerformance>
							title={() => (<Title level={5} className={styles.title}>Other</Title>)}
							className={styles.consumptionTable}
							pagination={false}
							dataSource={localData.other.main}
							rowKey={(row) => `${row.ballastId}`}
							columns={[
								{
									key: 'condition',
									editable: true,
									title: 'Consumption type',
									width: 125,
									dataIndex: 'condition',
									type: 'text',
									inputProps: {
										asAsync: false,
										placeholder: 'Consumption type',
									},
								},
								{
									key: 'fuelType',
									editable: true,
									title: 'Fuel type',
									type: 'select',
									width: 125,
									inputProps: {
										options: Object.values(FuelTypes).map((f) => ({ label: f, value: f })),
									},
									dataIndex: 'fuelType',
								},
								{
									key: 'consumption',
									editable: true,
									title: 'MT per day',
									width: 125,
									dataIndex: 'consumption',
									type: 'number',
									inputProps: {
										addonAfter: 'MT',
										asAsync: false,
									},
								},
								{
									key: 'id',
									width: '20px',
									title: (
										<AddButton
											disabled={localData.other.main.length >= 5}
											onClick={handleAdd}
										/>),
									render: (row: OtherSimplePerformance) => (
										<Button
											onClick={async () => handleDeleteOtherConsumption(row.ballastId!)}
											type="text"
											confirmTitle={`Are you sure you want to delete ${row.condition || 'this row'}?`}
											danger
											icon={(<DeleteOutlined />)}
										/>
									),
								},
							] as EditableColumns<OtherSimplePerformance>}
							size="small"
							onChange={(values) => updateEntries(values, 'other', 'main')}
							loading={isLoading}
						/>
					</div>
				</Col>
			</Row>
		</Card>
	);
};

export default OtherConsumption;
