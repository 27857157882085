import React, {
	createContext,
	useContext,
} from 'react';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { CargoProps } from '@api/models/cargo';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type { CargoDetails } from '@api/features/cargos/getCargoDetails';
import type { CargoPortProps } from '@api/models/cargo-port';
import {
	OnCreateNewCargoMutator,
	OnDeleteCargoMutator,
	OnImportCargoMutator,
	UpdateCargoPort,
	useCargoHandlers,
} from './hooks/useCargoHandlers';
import { useMiscHandlers } from './hooks/useMiscHandlers';
import { useCentralStore } from './CentralStoreContext';

export type CargoContextType = {
	estimate: GetEstimateDetailsResponse | null;
	estimateLoading: boolean;
	cargos: Array<CargoDetails>;
	cargoPorts: Array<CargoPortProps>;
	allCargos: GetCargosResponse;
	onUpdateCargo: (id: number, attributes: Partial<CargoProps>) => Promise<void> | void;
	onUpdateCargoPort: (attributes: UpdateCargoPort) => Promise<void> | void;
	setSyncCargos: React.Dispatch<React.SetStateAction<boolean>>;
	setCargosChanged: React.Dispatch<React.SetStateAction<boolean>>;
	onCreateNewCargoMutator: OnCreateNewCargoMutator;
	onImportCargoMutator: OnImportCargoMutator;
	onDeleteCargoMutator: OnDeleteCargoMutator;
	chartererOptions: { chartererName: string | null }[];
	portOptions: {
		label: string;
		value: number;
	}[];
	refreshEverything: () => Promise<void>;
	pendingChanges: boolean;
}

export const CargoContext = createContext<CargoContextType>(undefined!);
export const useCargoProvider = () => useContext(CargoContext);

const CargoProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const {
		estimate,
		cargos: centralCargos,
		setCargosChanged,
		cargosChanged,
		syncCargos,
		setSyncCargos,
		refreshEverything,
		selectedEstimateId,
		setLoading,
	} = useCentralStore();

	const {
		cargos,
		cargoPorts,
		allCargos,
		onCreateNewCargoMutator,
		onImportCargoMutator,
		onDeleteCargoMutator,
		onUpdateCargo,
		onUpdateCargoPort,
		pendingChanges,
	} = useCargoHandlers({
		cargosChanged,
		setCargosChanged,
		syncCargos,
		setSyncCargos,
		estimate,
		centralCargos,
		refreshEverything,
		selectedEstimateId,
		setLoading,
	});

	const {
		portOptions,
		chartererOptions,
	} = useMiscHandlers();

	return (
		<CargoContext.Provider
			value={{
				estimate,
				estimateLoading: false,
				cargos,
				cargoPorts: cargoPorts ?? [],
				allCargos,
				onUpdateCargo,
				onUpdateCargoPort,
				setSyncCargos,
				setCargosChanged,
				onCreateNewCargoMutator,
				onImportCargoMutator,
				onDeleteCargoMutator,
				chartererOptions,
				portOptions,
				refreshEverything,
				pendingChanges,
			}}
		>
			{children}
		</CargoContext.Provider>
	);
};

export default CargoProvider;
