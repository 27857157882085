import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	useHistory,
	useLocation,
} from 'react-router';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { CargoDetails } from '@api/features/cargos/getCargoDetails';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { EstimateProps } from '@api/models/estimate';
import {
	createEstimate,
	deleteEstimate,
	getEstimateDetails,
	getEstimateGroup,
	getVesselDetails,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import { PartialPortRotationEntry } from './hooks/usePortRotationHandlers';

type CentralStoreContextType = {
	estimate: GetEstimateDetailsResponse | null;
	estimates: EstimateProps[];
	setEstimate: React.Dispatch<React.SetStateAction<GetEstimateDetailsResponse | null>>;
	syncEstimate: boolean;
	setSyncEstimate: React.Dispatch<React.SetStateAction<boolean>>;
	selectedEstimateId: number | null;
	setSelectedEstimateId: React.Dispatch<React.SetStateAction<number | null>>;
	setEstimateGroupId: React.Dispatch<React.SetStateAction<number | null>>;
	result: GetEstimateDetailsResponse | null;
	setResultChanged: React.Dispatch<React.SetStateAction<boolean>>;
	estimateChanged: boolean;
	setEstimateChanged: React.Dispatch<React.SetStateAction<boolean>>;
	cargos: CargoDetails[];
	setCargos: React.Dispatch<React.SetStateAction<CargoDetails[]>>;
	cargosChanged: boolean;
	setCargosChanged: React.Dispatch<React.SetStateAction<boolean>>;
	syncCargos: boolean;
	setSyncCargos: React.Dispatch<React.SetStateAction<boolean>>;
	portRotation: PartialPortRotationEntry[];
	setPortRotation: React.Dispatch<React.SetStateAction<PartialPortRotationEntry[]>>;
	portRotationChanged: boolean;
	setPortRotationChanged: React.Dispatch<React.SetStateAction<boolean>>;
	syncPortRotation: 'partial' | 'full' | undefined;
	setSyncPortRotation: React.Dispatch<React.SetStateAction<'partial' | 'full' | undefined>>;
	vessel: GetVesselDetailsResponse | null | undefined;
	refreshVessel: () => void;
	refreshEverything: () => Promise<void>;
	handleCreateSubEstimate: () => Promise<void>;
	onDeleteEstimate: () => Promise<void>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CentralStoreContext = createContext<CentralStoreContextType>(undefined!);
export const useCentralStore = () => useContext(CentralStoreContext);

export const CentralStoreProvider = ({ children }: {children: React.ReactNode}) => {
	const history = useHistory();
	const location = useLocation();
	const [selectedEstimateId, setSelectedEstimateId] = useState<number | null>(null);
	const [estimateGroupId, setEstimateGroupId] = useState<number | null>(null);
	const [estimate, setEstimate] = useState<GetEstimateDetailsResponse | null>(null);
	const [estimateChanged, setEstimateChanged] = useState(false);
	const [syncEstimate, setSyncEstimate] = useState(false);
	const [loading, setLoading] = useState(false);

	const [result, setResult] = useState<GetEstimateDetailsResponse | null>(null);
	const [resultChanged, setResultChanged] = useState(false);

	const [cargos, setCargos] = useState<CargoDetails[]>([]);
	const [cargosChanged, setCargosChanged] = useState(false);
	const [syncCargos, setSyncCargos] = useState(false);

	const [portRotation, setPortRotation] = useState<PartialPortRotationEntry[]>([]);
	const [portRotationChanged, setPortRotationChanged] = useState(false);
	const [syncPortRotation, setSyncPortRotation] = useState<'partial' | 'full'>();

	const [
		vessel,
		refreshVessel,
	] = useFetchedState(
		async () => {
			if (estimate?.vesselId == null || estimate?.vesselId === -1) {
				return null;
			}

			return getVesselDetails(estimate.vesselId);
		},
		[estimate?.vesselId],
	);

	const [
		estimateGroup,
		refreshEstimateGroup,
	] = useFetchedState(
		async () => {
			if (estimateGroupId == null) {
				return null;
			}

			return await getEstimateGroup(estimateGroupId);
		},
		[estimateGroupId],
	);

	const handleCreateSubEstimate = async () => {
		if (estimateGroupId == null || estimateGroup == null || vessel == null) {
			return;
		}

		const createdEstimate = await createEstimate(
			{
				currency: estimateGroup.estimates[0].currency,
				name: '',
				vesselId: vessel.id,
			},
			estimateGroupId,
		);

		await refreshEstimateGroup();
		setSelectedEstimateId(createdEstimate.id);
	};

	const onDeleteEstimate = async () => {
		if (selectedEstimateId == null) {
			return;
		}

		await deleteEstimate(selectedEstimateId);
		showSuccessNotification('Estimate deleted');
		await refreshEstimateGroup();
		setSelectedEstimateId(null);
	};

	const refreshCargoData = async (incomingEstimate: GetEstimateDetailsResponse) => {
		setCargos(incomingEstimate.cargos);
		setSyncCargos(true);
	};

	const refreshEstimateData = async (incomingEstimate: GetEstimateDetailsResponse) => {
		setEstimate(incomingEstimate);
		setSyncEstimate(true);
	};

	const refreshPortRotationData = async (
		incomingEstimate: GetEstimateDetailsResponse,
		sync: 'partial' | 'full',
	) => {
		setPortRotation(incomingEstimate.portRotationEntries);
		setSyncPortRotation(sync);
	};

	const refreshResult = useCallback(async (incomingEstimate: GetEstimateDetailsResponse) => {
		setResult(incomingEstimate);
	}, []);

	const fetchEstimate = useCallback(async () => {
		if (selectedEstimateId == null) {
			return null;
		}

		const estimateDetails = await getEstimateDetails(selectedEstimateId);

		return estimateDetails;
	}, [selectedEstimateId]);

	const refreshEverything = useCallback(async () => {
		const estimateDetails = await fetchEstimate();

		if (estimateDetails != null) {
			refreshCargoData(estimateDetails);
			refreshEstimateData(estimateDetails);
			refreshPortRotationData(estimateDetails, 'full');
			refreshResult(estimateDetails);
		}
	}, [fetchEstimate, refreshResult]);

	useEffect(() => {
		refreshEverything();
	}, [refreshEverything, selectedEstimateId]);

	useEffect(() => {
		if (
			selectedEstimateId == null &&
			estimateGroup != null
		) {
			const estimateIds = estimateGroup?.estimates?.map((e) => e.id);

			if (estimateIds == null || estimateIds.length === 0) {
				history.push('/estimates/spot');

				return;
			}

			setSelectedEstimateId(estimateIds[0]);
		}
	}, [selectedEstimateId, estimateGroup, history]);

	useEffect(() => {
		if (estimateChanged) {
			setEstimateChanged(false);
			fetchEstimate().then((estimateDetails) => {
				if (estimateDetails != null) {
					refreshCargoData(estimateDetails);
					refreshPortRotationData(estimateDetails, 'partial');
					refreshResult(estimateDetails);
				}
			});
		}
	}, [estimateChanged, fetchEstimate, refreshResult]);

	useEffect(() => {
		if (cargosChanged) {
			setCargosChanged(false);
			fetchEstimate().then((estimateDetails) => {
				if (estimateDetails != null) {
					refreshEstimateData(estimateDetails);
					refreshPortRotationData(estimateDetails, 'full');
					refreshResult(estimateDetails);
				}
			});
		}
	}, [cargosChanged, fetchEstimate, refreshResult]);

	useEffect(() => {
		if (resultChanged) {
			setResultChanged(false);
			fetchEstimate().then((estimateDetails) => {
				if (estimateDetails != null) {
					refreshCargoData(estimateDetails);
					refreshEstimateData(estimateDetails);
					refreshPortRotationData(estimateDetails, 'partial');
					refreshResult(estimateDetails);
				}
			});
		}
	}, [fetchEstimate, refreshResult, resultChanged]);

	useEffect(() => {
		if (portRotationChanged) {
			fetchEstimate().then((estimateDetails) => {
				if (estimateDetails != null) {
					refreshCargoData(estimateDetails);
					refreshEstimateData(estimateDetails);
					refreshPortRotationData(estimateDetails, 'partial');
					refreshResult(estimateDetails);
					setPortRotationChanged(false);
				}
			});
		}
	}, [cargosChanged, fetchEstimate, portRotationChanged, refreshResult, resultChanged]);

	useEffect(() => {
		if (selectedEstimateId != null) {
			fetchEstimate().then((estimateDetails) => {
				if (estimateDetails != null) {
					setEstimate(estimateDetails);
					refreshCargoData(estimateDetails);
					refreshPortRotationData(estimateDetails, 'full');
					refreshResult(estimateDetails);
				}
			});

			const pathSegments = location.pathname.split('/');
			pathSegments[pathSegments.length - 1] = selectedEstimateId.toString();

			const newPath = pathSegments.join('/');
			history.replace(newPath);
		}
	}, [
		selectedEstimateId,
		fetchEstimate,
		refreshResult,
		history,
		estimateGroupId,
		location.pathname,
	]);

	useEffect(() => {
		if (!portRotationChanged && !cargosChanged && !estimateChanged && !resultChanged) {
			setLoading(false);
		}
	}, [cargosChanged, estimateChanged, portRotationChanged, resultChanged]);

	return (
		<CentralStoreContext.Provider
			value={{
				estimate,
				selectedEstimateId,
				estimates: estimateGroup?.estimates ?? [],
				setEstimate,
				syncEstimate,
				setSyncEstimate,
				result,
				setResultChanged,
				estimateChanged,
				setEstimateChanged,
				setSelectedEstimateId,
				setEstimateGroupId,
				cargos,
				setCargos,
				cargosChanged,
				syncCargos,
				setCargosChanged,
				setSyncCargos,
				portRotation,
				setPortRotation,
				portRotationChanged,
				setPortRotationChanged,
				syncPortRotation,
				setSyncPortRotation,
				vessel,
				refreshVessel,
				refreshEverything,
				handleCreateSubEstimate,
				onDeleteEstimate,
				loading,
				setLoading,
			}}
		>
			{children}
		</CentralStoreContext.Provider>
	);
};
