import React from 'react';
import { Moment } from 'moment';
import {
	useMutation,
	useQuery,
	useQueryClient,
} from 'react-query';
import {
	Currencies,
	DATE,
} from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import type VoyageExpensePayment from '@api/models/voyage-expense-payment';
import { formatDate } from '@client/utils/formatDate';
import {
	createVoyageExpensePayment,
	deleteVoyageExpensePayment,
	getVoyageExpensePayments,
	updateVoyageExpensePayment,
} from '@client/lib/api';
import styles from '@client/screens/fleet/VoyageDetailsScreen/components/styles/PaymentsTable.module.css';
import EditableTable from '@client/components/EditableTable';

type ExpensePaymentsTableType = {
	expenseId: number;
	currency?: Values<typeof Currencies>;
	refreshExpenses: () => void;
};

const ExpensePaymentsTable = ({
	expenseId,
	currency,
	refreshExpenses,
}: ExpensePaymentsTableType) => {
	const queryClient = useQueryClient();

	const invalidate = () => queryClient.invalidateQueries('payments');

	const createVoyageExpensePaymentMutation = useMutation(
		createVoyageExpensePayment,
		{
			onSuccess: () => {
				invalidate();
				refreshExpenses();
			},
		},
	);
	const createPayment = (payment: VoyageExpensePayment) => {
		createVoyageExpensePaymentMutation.mutate({
			expenseId,
			paymentDate: payment.paymentDate,
			amount: payment.amount,
		});
	};

	const updateVoyageExpensePaymentMutation = useMutation(
		updateVoyageExpensePayment,
		{
			onSuccess: () => {
				invalidate();
				refreshExpenses();
			},
		},
	);
	const updatePayment = (id: number, payment: VoyageExpensePayment) => {
		updateVoyageExpensePaymentMutation.mutate({
			attributes: { ...payment },
			id,
		});
	};

	const deleteVoyageExpensePaymentMutation = useMutation(
		deleteVoyageExpensePayment,
		{
			onSuccess: () => {
				invalidate();
				refreshExpenses();
			},
		},
	);
	const deletePayment = (id: number) => {
		deleteVoyageExpensePaymentMutation.mutate({
			id,
		});
	};

	const {
		data: payments,
	} = useQuery(
		['payments', expenseId],
		() => getVoyageExpensePayments(expenseId),
		{
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'paymentDate',
			editable: true,
			sorter: standardSort('paymentDate'),
			defaultSortOrder: 'desc',
			editingProps: {
				type: 'date',
			},
			render: (date: Moment) => formatDate(date, DATE),
		},
		{
			align: 'right' as const,
			title: 'Amount',
			dataIndex: 'amount',
			editable: true,
			editingProps: {
				type: 'currency',
			},
			render: (v: string) => formatCurrency(v ?? 0, currency ?? Currencies.USD),
		},
	];

	return (
		<EditableTable
			className={styles.paymentTable}
			iconButtons
			addNewText="Payment(s)"
			emptyText="No payments"
			allowAddNew
			enableDelete
			onAddNew={createPayment}
			onSave={updatePayment}
			onDelete={deletePayment}
			keyDataIndex="id"
			// @ts-ignore
			columns={columns}
			pagination={false}
			dataSource={payments ?? []}
			rowKey="id"
			size="small"
		/>
	);
};

export default ExpensePaymentsTable;
