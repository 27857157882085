import React from 'react';
import { VesselCapacityType } from '@shared/utils/constants';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import { DetailsItem } from '@client/components/Details';
import EmptyText from '@client/components/EmptyText';

const getIntakeVesselFields = ({
	estimate,

}: {
	estimate: GetEstimateDetailsResponse | null;
}) => {
	return [
		{
			key: 'dwtOverride',
			label: 'Summer DWT',
			type: 'number',
			value: estimate?.dwtOverride ?? estimate?.vessel?.summerDWT,
			editable: true,
			inputProps: {
				suffix: 'MT',
			},
		},
		{
			key: 'summerDraftOverride',
			label: 'Vessel Summer Draft',
			type: 'number',
			value: estimate?.summerDraftOverride ?? estimate?.vessel?.summerDraft,
			editable: true,
			inputProps: {
				suffix: 'm',
			},
			render: (item: DetailsItem) => (item.value != null ? `${item.value} m` : '—'),
		},
		{
			key: 'tpcOverride',
			label: 'Vessel TPC',
			type: 'number',
			value: estimate?.tpcOverride ?? estimate?.vessel?.TPC,
			editable: true,
			inputProps: {
				suffix: 'MT',
			},
			render: (item: DetailsItem) => (item.value != null ? `${item.value} MT` : '—'),
		},
		{
			key: 'constantsOverride',
			label: 'Vessel constants',
			type: 'number',
			value: estimate?.constantsOverride ?? estimate?.vessel?.constants,
			editable: true,
			inputProps: { suffix: 'MT' },
		},
		{
			key: 'vesselCapacityType',
			label: 'Grain or bale',
			type: 'select',
			value: estimate?.vesselCapacityType,
			options: Object.values(VesselCapacityType).map((c) => ({
				label: `${VesselCapacityType[c]} CBM`,
				value: c,
			})),
			inputProps: {
				placeholder: 'Select Option',
			},
			render: () => (
				<>
					{estimate?.vesselCapacityType != null ?
						VesselCapacityType[estimate?.vesselCapacityType] : (<EmptyText />)}
				</>
			),
			editable: true,
		},
		{
			key: 'grainCapacity',
			label: 'Grain capacity',
			value: estimate?.vessel?.grainCapacity ?? 0,
			editable: true,
			type: 'number',
			inputProps: { suffix: 'CBM' },
		},
		{
			key: 'baleCapacity',
			label: 'Bale capacity',
			value: estimate?.vessel?.baleCapacity ?? 0,
			editable: true,
			type: 'number',
			inputProps: { suffix: 'CBM' },
		},
	];
};

export default getIntakeVesselFields;
