import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
	Alert,
	Flex,
	Popover,
	TooltipProps,
} from 'antd';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { faWrench } from '@fortawesome/pro-solid-svg-icons';
import {
	PortActionTypes,
	PortActionTypeLabels,
	Currencies,
	ShortPortActionTypes,
	LaytimeIntervals,
} from '@shared/utils/constants';
import { PortRotationEntryGeneratorTypes } from '@shared/utils/port-rotation-entry-generator-types';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import { round } from '@shared/utils/math';
import type { Port } from '@api/utils/ports';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import type { SeaPassageSettings } from '@api/utils/sequelize/calculateVoyageItineraryDetails/helpers/itineraryConstants';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import Button from '@client/components/Button';
import { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import Select from '@client/components/Select';
import NumericInput from '@client/components/NumericInput';
import { OtherConsumptionMappings } from '@client/screens/fleet/VesselDetailsScreen/components/ConsumptionSets/helpers';
import ConfigContent from '../ConfigContent';
import styles from '../styles/shared.module.css';
import { TimeFormat } from '../PortRotationCard';
import {
	LocalPortRotation,
	LocalPortRotationWithKey,
	PartialPortRotationEntry,
} from '../context/hooks/usePortRotationHandlers';

type Props = {
	onDeletePortRotationEntry: (entry: PartialPortRotationEntry) => void;
	onPortRotationRowChange: (
		id: number,
		attributes: any
	) => void;
	onConfigUpdate: (id: number, routeSettings: SeaPassageSettings) => void;
	renderWrappingTime: (value: Moment, format: TimeFormat) => JSX.Element | null;
	speedPopoverOpen: number | null;
	setSpeedPopoverOpen: React.Dispatch<React.SetStateAction<number | null>>;
	selectedTimeFormat: TimeFormat;
	currency: Values<typeof Currencies>;
	ports: Port[];
	consumptionSets: Array<ConsumptionSetProps>;
	screens: Partial<Record<string, boolean>>;
	vessel: GetVesselDetailsResponse | null | undefined;
}

export const getPortRotationOtherConsumptionColumns = ({
	onDeletePortRotationEntry,
	onConfigUpdate,
	onPortRotationRowChange,
	speedPopoverOpen,
	setSpeedPopoverOpen,
	ports,
	consumptionSets,
	vessel,
}: Props): EditableColumns<LocalPortRotation> => {
	const otherConsumptionRows = vessel?.otherPerformanceEntries
		.sort((a, b) => Number(a.id) - Number(b.id))
		.map((pe, i) => {
			return {
				title: pe.condition,
				editable: false,
				className: styles.cellNoPadding,
				type: 'number',
				render: (row: LocalPortRotationWithKey) => {
					return (
						<NumericInput
							size="small"
							placeholder={pe.condition}
							className={styles.slimInput}
							addonAfter="days"
							asAsync
							value={row[OtherConsumptionMappings[i].daysKey] as unknown as number}
							variant="borderless"
							onChange={(v) => {
								if (row.id) {
									onPortRotationRowChange(row.id,
										{
											[OtherConsumptionMappings[i].daysKey]: Number(v),
											[OtherConsumptionMappings[i].idKey]: pe.id,
										});
								}
							}}
						/>
					);
				},
			};
		});

	return [
		{
			title: '',
			editable: false,
			render: (portRotationEntry: PartialPortRotationEntry) => (
				<Button
					type="link"
					danger
					size="small"
					confirmTitle="Are you sure you want to delete this entry?"
					onClick={() => onDeletePortRotationEntry(portRotationEntry)}
					disabledTooltip="Auto-generated entries cannot be deleted directly"
					disabled={portRotationEntry.generatedBy != null}
					tooltipProps={{
						placement: 'topRight',
					} as TooltipProps}
					icon={(
						<DeleteOutlined
							// eslint-disable-next-line react/forbid-component-props
							style={{
								fontSize: '16px',
								color: portRotationEntry.generatedBy != null ? 'hsl(0, 100%, 90%)' : 'red',
							}}
						/>
					)}
				/>
			),
			width: '30px',
		},
		{
			title: '',
			editable: false,
			className: styles.cellNoPadding,
			render: (portRotationEntry: PartialPortRotationEntry) => (
				<ConfigContent
					portRotationId={portRotationEntry.id}
					handleConfigUpdate={(id, settings) => id != null && onConfigUpdate(id, settings)}
					routeOptions={portRotationEntry.routeOptions}
					disabled={
						portRotationEntry.type === PortActionTypes.CANAL_TRANSIT ||
						portRotationEntry.type === PortActionTypes.BALLAST_LEG
					}
				/>
			),
			width: '40px',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			type: 'select',
			renderValue: (p) => ShortPortActionTypes[p.value as keyof typeof ShortPortActionTypes] ??
				p.value,
			editable: (r: PartialPortRotationEntry) => r.generatedBy == null,
			className: (r: PartialPortRotationEntry) => (
				r.generatedBy != null ?
					styles.calculated :
					undefined
			),
			inputProps: {
				showSearch: true,
				className: styles.typeSelector,
				options: Object.values(PortActionTypes)
					.map((type) => ({
						label: PortActionTypeLabels[type],
						value: type,
					}))
					.sort(standardSort('label')),
				popupMatchSelectWidth: false,
				popupClassName: styles.typeSelector,
				labelRender: (p) => ShortPortActionTypes[p.value as keyof typeof ShortPortActionTypes],
			},
			width: 30,
		},
		{
			title: 'Port',
			dataIndex: 'port',
			// @ts-ignore when showAction is set, this field fails. Makes no sense
			type: 'select',
			editable: (r: PartialPortRotationEntry) => (
				r.generatedBy == null ||
				r.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
			),
			className: (r: PartialPortRotationEntry) => {
				if (
					r.generatedBy != null &&
					r.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG
				) {
					return styles.calculated;
				}

				return undefined;
			},
			inputProps: {
				// @ts-ignore when showAction is set, this field fails. Makes no sense
				placeholder: 'Select port',
				className: styles.slimInput,
				showAction: 'focus',
				options: ports.map((port) => ({
					label: port.name?.toUpperCase(),
					value: port.id,
				})).sort(standardSort('label')),
				showSearch: true,
				dropdownStyle: {
					minWidth: '250px',
				},
			},
			transformData: {
				in: (port: Port | null) => port?.id ?? null,
				out: (portId: number | null) => (
					ports.find((p) => p.id === portId) ?? null
				),
			},
		},
		...(otherConsumptionRows ?? []) as any,
		{
			title: 'Distance',
			children: [
				{
					title: 'Normal',
					dataIndex: 'normalDistance',
					type: 'number',
					editable: false,
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled :
							styles.calculatedNumber
					),
					renderValue: (p) => (p.value != null ? `${p.value} ` : null),
					width: 40,
				},
				{
					title: 'ECA',
					dataIndex: 'ecaDistance',
					type: 'number',
					editable: false,
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled :
							styles.calculatedNumber
					),
					renderValue: (p) => (p.value != null ? `${p.value} ` : null),
					width: 40,
				},
			],
		},
		{
			title: 'Marg.',
			dataIndex: 'seaMargin',
			inputProps: {
				placeholder: 'Margin',
				className: styles.slimInput,
				addonAfter: '%',
				size: 'small',
			},
			type: 'number',
			editable: (row) => (row.seaDays != null && row.seaDays !== 0),
			className: (row) => (
				row.seaDays != null && row.seaDays !== 0 ?
					undefined :
					styles.disabled
			),
			width: 50,
		},
		{
			title: 'Speed',
			// @ts-ignore
			type: 'number',
			inputProps: {
				// @ts-ignore
				placeholder: 'Speed',
			},
			editable: false,
			width: 75,
			className: (row) => (row.nextEntryId == null ? styles.disabled : styles.calculated),
			render: (row) => row != null && (
				(
					<div
						className={styles.speedProfileContainer}
					>
						<span style={{ whiteSpace: 'nowrap' }}>{`${row.speed} kts`}</span>
						<Popover
							title="Choose consumption set"
							trigger="click"
							open={speedPopoverOpen === row?.id}
							onOpenChange={(open) => (
								open ?
									setSpeedPopoverOpen(row.id) :
									setSpeedPopoverOpen(null)
							)}
							content={(
								<Flex
									gap={10}
									vertical
								>
									<Alert
										// eslint-disable-next-line react/forbid-component-props
										style={{ width: 300 }}
										message="The chosen consumption profile will be used to calculate the speed for the sea passage between this entry and the next."
										type="info"
										showIcon
									/>
									<Select
										fullWidth
										value={row.consumptionSetId}
										options={consumptionSets.map((c) => ({
											value: c.id,
											label: c.name,
										}))}
										onSelect={(v) => {
											onPortRotationRowChange(row.id, { consumptionSetId: Number(v) });
											setSpeedPopoverOpen(null);
										}}
									/>
								</Flex>
							)}
						>
							<Button
								type="link"
								className={styles.speedProfileBtn}
								icon={(<FontAwesomeIcon size="sm" icon={faWrench as Icon} />)}
							/>
						</Popover>
					</div>
				)),
		},
		{
			title: 'Days',
			children: [
				{
					title: 'Sea',
					dataIndex: 'seaDays',
					type: 'number',
					width: 50,
					align: 'right',
					editable: false,
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled :
							styles.calculatedNumber
					),
					renderValue: (p) => (p.value != null ? round(p.value) : null),
				},
				{
					title: 'Working',
					dataIndex: 'workingDays',
					type: 'number',
					inputProps: {
						className: styles.slimInput,
					},
					width: 45,
					align: 'right',
					editable: (row) => (
						row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG &&
						row.generatedBy !== PortRotationEntryGeneratorTypes.CARGO
					),
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ? styles.disabled : (
							row.generatedBy === PortRotationEntryGeneratorTypes.CARGO
						) ? styles.calculatedNumber :
							undefined
					),
					renderValue: (p) => (p.value != null ? round(p.value) : '0'),
				},
				{
					title: 'Idle',
					dataIndex: 'idleDays',
					type: 'number',
					width: 45,
					inputProps: {
						className: styles.slimInput,
					},
					align: 'right',
					editable: (row) => (
						row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG
					),
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled : undefined
					),
					renderValue: (p) => (p.value != null ? round(p.value) : 'N/A'),
				},
				{
					title: 'Turn',
					dataIndex: 'turnDays',
					type: 'number',
					inputProps: {
						className: styles.slimInput,
					},
					width: 45,
					align: 'right',
					editable: (row) => (
						row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG
					),
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled : undefined
					),
					renderValue: (p) => (p.value != null ? round(p.value) : '0'),
				},
			],
		},

		{
			title: 'L/D Rate',
			dataIndex: 'loadDischargeRate',
			type: 'number',
			editable: (row) => (row.generatedBy === PortRotationEntryGeneratorTypes.CARGO),
			className: (row) => (
				row.generatedBy !== PortRotationEntryGeneratorTypes.CARGO ?
					styles.disabled :
					undefined
			),
			width: 60,
			inputProps: {
				allowNegative: false,
				className: styles.slimInput,
			},
		},
		{
			title: 'Unit',
			dataIndex: 'laytimeInterval',
			type: 'select',
			editable: true,
			width: 50,
			inputProps: {
				placeholder: 'Unit',
				className: styles.slimInput,
				popupMatchSelectWidth: false,
				options: [{
					label: '/ day',
					value: LaytimeIntervals.DAY,
				},
				{
					label: '/ hour',
					value: LaytimeIntervals.HOUR,
				}],
			},
			renderValue: (v) => `/ ${v.value} `,
		},
	];
};
