import {
	ChemicalSizes,
	ContainerSizes,
	CrudeProductSizes,
	DryBulkSizes,
	FerrySizes,
	LNGSizes,
	LPGSizes,
	VesselTypes,
	WINDSizes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

const sizeMappings = {
	[VesselTypes.DRY_BULK]: DryBulkSizes,
	[VesselTypes.CHEMICAL]: ChemicalSizes,
	[VesselTypes.CRUDE_PRODUCT]: CrudeProductSizes,
	[VesselTypes.LPG]: LPGSizes,
	[VesselTypes.LNG]: LNGSizes,
	[VesselTypes.CONTAINER]: ContainerSizes,
	[VesselTypes.WIND]: WINDSizes,
	[VesselTypes.FERRY]: FerrySizes,
};

const getSizeOptions = (type: Values<typeof VesselTypes> | null | undefined) => {
	if (type == null) {
		return [];
	}

	const sizes = sizeMappings[type] || {};

	return Object.keys(sizes).map((size) => ({
		label: size,
		value: size,
	}));
};

export default getSizeOptions;
