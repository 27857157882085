import
React from 'react';
import { Space } from 'antd/lib';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import {
	AccountingItemApprovalStates,
	HireInvoiceItemStates,
} from '@shared/utils/constants';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import ExpenseDetailsCard from '@client/components/ExpenseDetailsCard/ExpenseDetailsCard';
import {
	createVoyageExpenseReceivable,
	deleteVoyageExpense,
	deleteVoyageExpenseReceivable,
} from '@client/lib/api';
import asyncWrapper from '@client/utils/asyncWrapper';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import history from '@client/utils/history';
import { Links } from '@client/utils/links';
import Button from '@client/components/Button';
import Card from '@client/components/Card/Card';
import showErrorNotification from '@client/utils/showErrorNotification';
import styles from './components/styles/ExpenseScreen.module.css';

type Props = {
	item: TransformedExpenses;
	refreshExpenses: () => void | Promise<void>;
	onCancel: () => void;
	open: boolean;
};

const getDisabledTooltip = (item: TransformedExpenses) => {
	let str = '';

	if (item.state === AccountingItemApprovalStates.PAID) {
		str = 'Expense is already paid and cannot be deleted';
	} else if (item.state === AccountingItemApprovalStates.PENDING) {
		str = 'Expense receivable has already been invoiced';
	}

	if (item.isBunkerStem) {
		str = 'To delete this expense, please delete the entire bunkering event from the itinerary on the contract';
	}

	return str;
};

const ExpenseDetailModal = ({ item, refreshExpenses, onCancel, open }: Props) => {
	const onUndoReceivable = asyncWrapper(async (expense) => {
		try {
			await deleteVoyageExpenseReceivable(expense.voyageId, expense.receivable.id);
			showSuccessNotification('Receivable deleted');
		} catch (e) {
			showErrorNotification('Could not undo receivable', e as Error);
		}

		refreshExpenses();
	}, 'Could not undo receivable');

	const onReceivableExpense = asyncWrapper(async (expense) => {
		try {
			// @ts-ignore not typed yet
			await createVoyageExpenseReceivable({
				voyageId: expense.voyageId,
				voyageExpenseId: expense.id,
			});
			showSuccessNotification('Receivable created', (
				<Button
					type="link"
					onClick={() => history.push(`${Links.Voyage.get(expense.voyageId)}/#/expenses`)}
				>
					{`Go to expenses on ${expense.voyageIdentifier}`}
				</Button>
			));
		} catch (e) {
			showErrorNotification('Could not create receivable', e as Error);
		}

		refreshExpenses();
	}, 'Could not create receivable');

	const onDeleteExpense = asyncWrapper(async (e) => {
		await deleteVoyageExpense(e.id);
		showSuccessNotification('Expense was deleted', 'Expense was successfully deleted.');
		refreshExpenses();
	}, 'Could not delete expense');
	const disabled = item == null ?
		true : ((
			item.state === AccountingItemApprovalStates.PAID ||
			(item.receivable != null && item.receivable?.state !== HireInvoiceItemStates.PENDING)) ||
			(item.isBunkerStem));

	return (
		<Modal
			centered
			footer={null}
			width={800}
			onCancel={onCancel}
			open={open}
		>
			<Card
				title={item.itemDescription}
				className={styles.settingsModal}
				extra={(
					<Space
						className={styles.titleButtons}
					>
						{item.receivable != null ? (
							<Button
								type="default"
								onClick={() => onUndoReceivable(item)}
								disabled={item.receivable.state !== HireInvoiceItemStates.PENDING}
							>
								Undo receivable
							</Button>
						) : (
							<Button
								type="primary"
								onClick={() => onReceivableExpense(item)}
								disabled={item.amount == null || item.amount < 0}
							>
								Create receivable
							</Button>
						)}
						<Button
							confirmTitle={
								item.posted ? 'The item has already been posted. Deleting this item will also delete/reverse the item in the accounting system' :
									(item.receivable != null ?
										'This expense has a pending receivable. Do you wish to delete it and its corresponding receivable?' :
										'Do you wish to delete this expense?'
									)
							}
							key="delete"
							danger
							icon={(<DeleteOutlined />)}
							onClick={() => onDeleteExpense(item)}
							disabled={disabled}
							disabledTooltip={getDisabledTooltip(item)}
						>
							Delete
						</Button>
					</Space>
				)}
			>
				<ExpenseDetailsCard expense={item} refreshExpenses={refreshExpenses} />
			</Card>
		</Modal>
	);
};

export default ExpenseDetailModal;
