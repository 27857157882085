import React from 'react';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import Details from '@client/components/Details';
import { getDateDetailsItems } from './helpers';

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	updateVoyageField: (field: string, value: any, contractId?: number | null) => void;
	portOptions: Array<{
		label: string;
		value: number;
	}>;
}

const EstimatedDates = ({
	voyageDetails,
	updateVoyageField,
	portOptions,
}: Props) => {
	if (voyageDetails == null) {
		return null;
	}

	return (
		<Details
			title="Commencement / Completion"
			items={getDateDetailsItems(voyageDetails, updateVoyageField, portOptions)}
			hideHeader
		/>
	);
};

export default EstimatedDates;
