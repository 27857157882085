import React, {
	SetStateAction,
	useState,
} from 'react';
import {
	Alert,
	Checkbox,
	Flex,
	Form,
	Input,
	Skeleton,
	Space,
	Tag,
	Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
	CargoUnitTypes,
	Currencies,
	ExpenseTypes,
	FixtureCounterpartyTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatQuantity } from '@shared/utils/string';
import normalize from '@shared/utils/normalizeNumber';
import { formatCurrency } from '@shared/utils/currency';
import type { SupplierProps } from '@api/models/supplier';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import { getCounterparties } from '@client/lib/api';
import { rules } from '@client/utils/form';
import { useAuth } from '@client/lib/auth';
import { Links } from '@client/utils/links';
import TooltipIcon from '@client/components/TooltipIcon';
import { VendorExpenseSubTypes } from '@client/screens/fleet/VoyageDetailsScreen/components/VcExpensesTab';
import Select from '../Select';
import MultiCurrencyInput from '../MultiCurrencyInput';
import Button from '../Button';
import CountryFlag from '../CountryFlag';
import DatePicker from '../DatePicker';
import styles from './CreateExpenseForm.module.css';

const VcFormItems = ({
	expenseType,
	expenseSubType,
	suppliers,
	voyages,
	voyageId,
	portCalls,
	setSelectedPortCall,
	linerCostValues,
	isBunkerStem,
	onCloseForm,
	isEditing,
	extra,
	creatingNewSupplier,
	setCreatingNewSupplier,
	linerCostUnitPriceValues,
}: {
	voyages: GetVoyagesResponse | undefined;
	isBunkerStem: boolean;
	expenseType: Values<typeof ExpenseTypes>;
	expenseSubType: VendorExpenseSubTypes;
	suppliers: SupplierProps[] | undefined;
	voyageId: number;
	portCalls: ItineraryPortCallDto[];
	setSelectedPortCall: React.Dispatch<SetStateAction<ItineraryPortCallDto | undefined>>;
	isEditing: boolean;
	extra?: React.ReactElement;
	onCloseForm: () => void;
	creatingNewSupplier: boolean;
	setCreatingNewSupplier: (state: boolean) => void;
	linerCostValues?: {
		type: 'BL Quantity' | 'CP Quantity';
		quantity: number;
		action: string;
		unit: Values<typeof CargoUnitTypes>;
	} | null;
	linerCostUnitPriceValues?: {
		value: number | null | undefined;
		currency: Values<typeof Currencies>;
		exchangeRate: number;
	};
}) => {
	const { userInfo } = useAuth();
	const history = useHistory();

	const [selectedType, setSelectedType] = useState<Values<typeof ExpenseTypes> | null>(null);
	const [isAmountNegative, setIsAmountNegative] = useState(false);

	const selectedVoyage = (voyages ?? []).find((v) => v.id === voyageId);

	const isPortDA = expenseSubType === 'portDA';
	const isLinerCost = expenseSubType === 'linerCost';

	const {
		data: charterers,
	} = useQuery(
		'charterers',
		() => getCounterparties(FixtureCounterpartyTypes.CHARTEREROWNER),
	);

	const goToItin = () => {
		onCloseForm();
		history.push(`${Links.Voyage.get(voyageId)}/#/itinerary`);
	};

	return (
		<>
			<Form.Item
				label="Type"
				name="type"
			>
				<Select<Values<typeof ExpenseTypes>>
					placeholder="Select type"
					disabled={isEditing}
					showSearch
					allowClear
					onChange={(v) => setSelectedType(v)}
					options={[
						{ label: 'Payable', value: ExpenseTypes.PAYABLE },
						{ label: 'Payable & Receivable', value: ExpenseTypes.BOTH },
					]}
				/>
			</Form.Item>
			{expenseType == null ? (
				<>
					<p>Please select a type</p>
					<Skeleton />
				</>
			) : (
				<>
					{expenseType === ExpenseTypes.BOTH && (
						<Form.Item
							name="charterer"
							label="Charterer"
							rules={[rules.required]}
						>
							<Select
								options={charterers?.map((c) => ({
									label: c.name, value: c.id,
								}))}
								disabled={isEditing && expenseType === ExpenseTypes.BOTH}
							/>
						</Form.Item>
					)}
					{(isPortDA || isLinerCost) && (
						<Form.Item
							name="portCallId"
							rules={[rules.required]}
							label="Select port call"
							extra={(
								<>
									{'Don\'t see the port call you\'re looking for? Check the '}
									<Button className={styles.noPaddingButton} onClick={goToItin} type="link">
										Itinerary
									</Button>
								</>
							)}
						>
							<Select
								showSearch
								disabled={isBunkerStem}
								options={portCalls.map((pc) => ({
									value: pc.id,
									label: (
										<Space>
											<Tag className={styles.tag} color={pc.estimated ? 'cyan' : 'geekblue'}>
												{pc.estimated ? 'Estimated' : 'Actual'}
											</Tag>
											<CountryFlag countryCode={pc.port.countryCode} />
											{pc.port.name}
										</Space>),
								}))}
								placeholder="Select Port"
								onSelect={(id) => {
									const pc = portCalls.find((p) => p.id === id);

									if (pc != null) {
										setSelectedPortCall(pc);
									}
								}}
							/>
						</Form.Item>
					)}
					{isLinerCost && (
						<Flex gap={10} vertical>
							{linerCostValues != null && (
								<Typography.Text className={styles.grayLabel}>
									{linerCostValues?.type}
									{': '}
									{formatQuantity(linerCostValues?.quantity, linerCostValues?.unit) }
								</Typography.Text>
							)}
							<Form.Item
								name="linerCost"
								rules={[rules.required]}
								label="Liner Cost / Unit"
							>
								<MultiCurrencyInput
									baseCurrency={selectedVoyage?.currency || userInfo.baseCurrency}
									placeholder="Price per unit"
								/>
							</Form.Item>
						</Flex>
					)}
					<Form.Item
						name="estimated"
						valuePropName="checked"
						initialValue={false}
						label={(
							<Space>
								Estimated
								<TooltipIcon>
									Estimated items are used for estimating P&L items,
									and cannot be invoiced or put through
									an approvals process.
									If you wish to invoice the item,
									convert the item to an actual by
									deselecting the &quot;estimated&quot; checkbox
								</TooltipIcon>
							</Space>
						)}
					>
						<Checkbox />
					</Form.Item>
					<Form.Item
						name="supplierId"
						label="Supplier"
					>
						<Select
							placeholder="Select Supplier"
							showSearch
							allowClear
							options={[
								{
									label: (<i>Create new...</i>),
									value: 'new',
								},
								...(suppliers ?? []).map((s) => ({
									label: s.name,
									value: s.id,
								}))]}
							onChange={(counterpartyId) => {
								setCreatingNewSupplier(counterpartyId === 'new');
							}}
						/>
					</Form.Item>
					<Form.Item
						name="supplierName"
						label="Supplier Name"
						hidden={!creatingNewSupplier}
						rules={[{ required: creatingNewSupplier, message: 'Field is missing' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="customInvoiceId"
						label="Invoice ID"
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Invoice Date"
					>
						<DatePicker
							className={styles.datepicker}
							range={false}
						/>
					</Form.Item>
					<Form.Item
						name="dueDate"
						label="Due Date"
					>
						<DatePicker
							className={styles.datepicker}
							range={false}
						/>
					</Form.Item>
					<Form.Item
						name="itemDescription"
						label="Item description"
						rules={[rules.required]}
					>
						<Input />
					</Form.Item>
					<Form.Item>
						<Flex vertical gap={0}>
							<Form.Item
								name="amount"
								label={(() => {
									if (
										isLinerCost &&
										linerCostValues != null &&
										linerCostUnitPriceValues?.value != null &&
										linerCostUnitPriceValues?.currency != null
									) {
										return (
											<Flex vertical gap={0}>
												<p>Invoice Amount</p>
												<Typography.Text className={styles.grayLabel} italic>
													{formatQuantity(linerCostValues.quantity, linerCostValues.unit)}
													{' '}
													*
													{' '}
													{formatCurrency(
														linerCostUnitPriceValues.value,
														linerCostUnitPriceValues.currency,
													)}
													{' '}
													/
													{' '}
													{linerCostValues.unit}
												</Typography.Text>
											</Flex>
										);
									}

									return 'Invoice Amount';
								})()}
								rules={isLinerCost ? [] : [rules.required]}
							>
								<MultiCurrencyInput
									inputValues={(isLinerCost && linerCostUnitPriceValues != null) ? {
										value: normalize(linerCostUnitPriceValues.value) *
										normalize(linerCostValues?.quantity),
										currency: linerCostUnitPriceValues.currency,
										exchangeRate: linerCostUnitPriceValues.exchangeRate,
									} : undefined}
									disabled={isLinerCost}
									allowNegative={selectedType === ExpenseTypes.PAYABLE}
									baseCurrency={selectedVoyage?.currency || userInfo.baseCurrency}
									placeholder="Amount"
									onChange={(v) => setIsAmountNegative(v.value != null ? v.value < 0 : false)}
								/>
							</Form.Item>
						</Flex>
						{isAmountNegative && (
							<Alert
								message={(
									<>
										<b>NOTE:</b>
										{' '}
										The amount being entered should only be negative for e.g. rebates.
									</>
								)}
							/>
						)}
					</Form.Item>
					<Form.Item
						name="note"
						label="Internal note"
					>
						<Input.TextArea />
					</Form.Item>
					{extra}
					<Button
						htmlType="submit"
						type="primary"
					>
						Save Expense
					</Button>
				</>
			)}
		</>
	);
};

export default VcFormItems;
