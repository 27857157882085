import React, {
	useMemo,
	useState,
} from 'react';
import Title from 'antd/lib/typography/Title';
import { PlusOutlined } from '@ant-design/icons';
import { sortByDates } from '@shared/utils/sortByDates';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import CreateExpenseForm from '@client/components/CreateExpenseForm/CreateExpenseForm';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVoyageExpenses } from '@client/lib/api';
import Table from '@client/components/Table/Table';
import Button from '@client/components/Button';
import styles from '../ExpensesScreen/components/styles/ExpenseScreen.module.css';
import useGetVendorExpensesTableColumns from './getVendorExpensesTableColumn';

type Props = {
	expenses: TransformedExpenses[];
	item: TransformedExpenses | undefined;
	handleClick: (id: number) => void;
	refreshExpenses: () => void | Promise<void>;
};

const VendorExpensesTable = ({ expenses, item, handleClick, refreshExpenses }: Props) => {
	const [allExpenses, _refresh, _error, loading] = useFetchedState(getVoyageExpenses);

	const [drawerVisible, setDrawerVisible] = useState(false);

	const sortedExpenses = useMemo(() => {
		if (expenses === undefined) {
			return [];
		}

		return sortByDates(expenses, 'createdAt', { newestFirst: true });
	},
	[expenses]);

	return (
		<>
			<div className={styles.tableHeaderSection}>
				<Title level={3}>
					Vendor Expenses
				</Title>
			</div>
			<Table
				loading={loading}
				scroll={{ x: 800, y: 500 }}
				columns={useGetVendorExpensesTableColumns(allExpenses)}
				dataSource={sortedExpenses}
				size="small"
				onRowClick={(rowItem) => handleClick(rowItem.id)}
				showSearch
				showExportToCsv
				rowClassName={(record) => (record === item ? styles.selectedRow : '')}
				searchAttributes={[
					'amount',
					'customInvoiceId',
					'itemDescription',
					'supplierName',
					'voyageIdentifier',
					'invoiceDate',
					'state',
				]}
				extraArea={(
					<Button
						// eslint-disable-next-line react/forbid-component-props
						style={{ marginLeft: 10 }}
						key="createExpense"
						onClick={() => setDrawerVisible(true)}
						type="primary"
						icon={(
							<PlusOutlined />
						)}
					>
						Create Expense
					</Button>
				)}
			/>
			<CreateExpenseForm
				open={drawerVisible}
				onClose={() => {
					setDrawerVisible(false);
					refreshExpenses();
				}}
			/>
		</>
	);
};

export default VendorExpensesTable;
