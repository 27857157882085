import { VesselCostType } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetVcPnlResponse } from '@api/features/voyages/getVcPnl';

const getVcPnlItems = (pnl: GetVcPnlResponse, vesselCostType: Values<typeof VesselCostType>) => {
	if (vesselCostType === VesselCostType.Lumpsum) {
		pnl.tcInCosts.actual = 0;
		pnl.netResult.actual = 0;
		pnl.netResultPerDay.actual = 0;
	}

	const revenueItems = [
		{
			item: 'Freight',
			...pnl.freight,
		},
		{
			item: 'Other Revenue',
			...pnl.otherRevenue,
		},
		{
			item: 'Demurrage',
			...pnl.demurrage,
		},
		{
			item: 'Despatch',
			...pnl.despatch,
		},
		{
			item: 'Address Commission',
			...pnl.addressCommission,
		},
		{
			item: 'Broker Commission',
			...pnl.brokerCommission,
		},
		{
			item: 'Total Revenue',
			bold: true,
			...pnl.totalRevenue,
		},
	];

	const expenseItems = [
		{
			item: 'Port Costs',
			...pnl.expensesInPort,
		},
		{
			item: 'Liner Costs',
			...pnl.linerCostExpenses,
		},
		{
			item: 'Other Expenses',
			...pnl.otherExpenses,
		},
		{
			item: 'Bunker Expenditure',
			...pnl.bunkerExpenditure,
		},
		{
			item: 'Total Expenses',
			bold: true,
			...pnl.totalExpenses,
		},
	];

	const resultsItems = [
		{
			item: 'TCE Earnings',
			bold: true,
			...pnl.voyageProfit,
		},
		{
			item: 'Voyage Days',
			number: true,
			bold: true,
			...pnl.voyageDays,
		},
		{
			item: 'TCE / Day',
			bold: true,
			...pnl.tce,
		},
		{
			item: 'CAPEX',
			...pnl.capex,
		},
		{
			item: 'OPEX',
			...pnl.opex,
		},
		{
			item: 'Net Hire Cost',
			...pnl.tcInCosts,
		},
		{
			item: 'Net Result',
			...pnl.netResult,
		},
		{
			item: 'Net Result / Day',
			...pnl.netResultPerDay,
		},
	];

	return {
		revenueItems,
		expenseItems,
		resultsItems,
	};
};

export default getVcPnlItems;
