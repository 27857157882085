import React, {
	useEffect,
	useState,
} from 'react';
import {
	Divider,
	Empty,
} from 'antd';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type
{ GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import type {
	CargoProps,
	EstimatedExpenseItem,
	EstimatedRevenueItem,
} from '@api/models/cargo';
import Card from '@client/components/Card/Card';
import { useExchangeRates } from '@client/utils/hooks/useExchangeRates';
import IntakeCalculatorFreightDetails from '../components/IntakeCalculatorFreightCard/IntakeCalculatorFreightDetails';

const IntakeCalculatorDetails = ({
	cargoDetails,
	refreshCargoDetails,
	handleChange,
	baseCurrency,
}: {
	cargoDetails?: GetCargoDetailsResponse | null;
	refreshCargoDetails: () => void;
	handleChange: (id: number, attributes: Partial<CargoProps>) => Promise<void> | void;
	baseCurrency: Values<typeof Currencies>;
}) => {
	const [selectedCurrency, setSelectedCurrency] = useState<
		Values<typeof Currencies> | null | undefined
	>(cargoDetails?.currency);
	const exchangeRates = useExchangeRates(baseCurrency);

	useEffect(() => {
		setSelectedCurrency(cargoDetails?.currency);

		if (cargoDetails != null && selectedCurrency != null &&
			selectedCurrency !== cargoDetails.currency) {
			const items = cargoDetails?.estimatedItems ?? [];

			cargoDetails.estimatedItems = items
				.map((item) => (
					{
						...item,
						currency: cargoDetails.currency,
						exchangeRate: exchangeRates[cargoDetails.currency],
					} as EstimatedRevenueItem | EstimatedExpenseItem
				));

			handleChange(cargoDetails.id,
				{ currency: cargoDetails.currency,
					exchangeRate: exchangeRates[cargoDetails.currency] });
			setSelectedCurrency(cargoDetails.currency);
		}
	}, [cargoDetails, cargoDetails?.currency, exchangeRates, handleChange, selectedCurrency]);

	useEffect(() => {
		if (cargoDetails != null && selectedCurrency != null &&
			selectedCurrency !== cargoDetails.currency) {
			const items = cargoDetails?.estimatedItems ?? [];

			cargoDetails.estimatedItems = items
				.map((item) => (
					{
						...item,
						currency: cargoDetails.currency,
						exchangeRate: exchangeRates[cargoDetails.currency],
					} as EstimatedRevenueItem | EstimatedExpenseItem
				));

			handleChange(cargoDetails.id, cargoDetails);
		}
	}, [cargoDetails, cargoDetails?.exchangeRate, exchangeRates, handleChange, selectedCurrency]);

	if (cargoDetails == null) {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description="No data"
			/>
		);
	}

	return (
		<>
			<Card>
				<Divider>
					Freight, overage & deadfreight
				</Divider>
				<IntakeCalculatorFreightDetails
					refreshCargoDetails={refreshCargoDetails}
					cargoDetails={cargoDetails}
				/>
			</Card>
		</>
	);
};

export default IntakeCalculatorDetails;
