// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u52BdSgwL3NoFYMTWgpQ {\n\twidth: 100%;\n}\n\n.cGMUizXS3CambYWE75PN {\n\tmargin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/VcSummaryTab.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n\n.marginTop {\n\tmargin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "u52BdSgwL3NoFYMTWgpQ",
	"marginTop": "cGMUizXS3CambYWE75PN"
};
export default ___CSS_LOADER_EXPORT___;
