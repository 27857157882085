import React from 'react';
import { useQuery } from 'react-query';
import { VesselCostType } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { getVcPnl } from '@client/lib/api';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import VcPnl from '../VcPnl/VcPnl';

export type TablePnlEntry = {
	item: string;
	actual: number;
	estimated: number;
	invoiced: number;
	bold?: boolean;
	number?: boolean;
	vesselCostType?: Values<typeof VesselCostType>;
}

const VcPnlTab = () => {
	const {
		voyageId,
		fixtureCurrency,
		voyageDetails,
	} = useVoyage();

	const {
		data: pnl,
	} = useQuery(
		'vcPnl',
		() => getVcPnl(voyageId),
		{ enabled: voyageId != null },
	);

	return (
		<VcPnl
			pnl={pnl}
			voyageDetails={voyageDetails}
			fixtureCurrency={fixtureCurrency}
		/>
	);
};

export default VcPnlTab;
