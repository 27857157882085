import { FixtureTypes } from '@shared/utils/constants';
import getAvailableFreightInvoiceItems from '@shared/utils/getAvailableFreightInvoiceItems';
import { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import { SpotFixtureProps } from '@api/models/spot-fixture';
import { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import { BankAccountProps } from '@api/models/bank-account';

const getAvailableHIIsFromSpotDetails = (
	voyageDetails: GetVoyageDetailsResponse,
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>,
	invoices: VoyageInvoice[],
	chartererId: number,
	selectedBankAccount: BankAccountProps,
) => {
	const {
		voyageExpenseReceivables,
		revenueItems,
	} = voyageDetails;

	const {
		addressCommission,
		brokers,
		cargos,
		type,
	} = fixtureDetails;

	if (selectedBankAccount == null) {
		return [];
	}

	if (type !== FixtureTypes.SPOT) {
		return [];
	}

	return getAvailableFreightInvoiceItems({
		type,
		brokers,
		cargos,
		chartererId,
		hireInvoices: invoices ?? [],
		addressCommission,
		voyageExpenseReceivables,
		revenueItems,
		selectedBankAccount,
	});
};

export default getAvailableHIIsFromSpotDetails;
