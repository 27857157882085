import React from 'react';
import {
	Col,
	Flex,
	Row,
} from 'antd';
import { Link } from 'react-router-dom';
import {
	FixtureTypes,
	VcContractCompletionTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { VoyageResponse } from '@api/features/voyages/getVoyages';
import { Links } from '@client/utils/links';
import TooltipIcon from '@client/components/TooltipIcon';
import Select from '@client/components/Select';
import VoyageSelector from './VoyageSelector/VoyageSelector';
import styles from './styles/VcSummaryTab.module.css';

const VoyageCompletionDetails = ({
	voyageDetails,
	updateVoyageField,
	voyages,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	updateVoyageField: (field: string, value: any) => Promise<void>;
	voyages: VoyageResponse[] | undefined;
}) => {
	return (
		<Row gutter={[16, 16]} className={styles.marginTop}>
			<Col xl={10} sm={24}>
				<b>
					Contract completion
				</b>
				<Select
					className={styles.fullWidth}
					value={voyageDetails.completionType}
					defaultValue={voyageDetails.completionType}
					onSelect={async (value: VcContractCompletionTypes | null) => {
						await updateVoyageField('completionType', value);
					}}
					options={[
						{
							value: VcContractCompletionTypes.NEXT_CONTRACT,
							label: (
								<>
									Next contract
									<TooltipIcon>
										This requires you set manually set a completion date.
										Bunkers on completion will be carried over to the next contract.
									</TooltipIcon>
								</>
							),
						},
						{
							value: VcContractCompletionTypes.TC_IN_DELIVERY,
							label: (
								<>
									TC In redelivery
									<TooltipIcon>
										This will automatically set the completion date
										to be equal the delivery date of the TC in contract.
										All bunkers will be sold on redelivery.
									</TooltipIcon>
								</>
							),
						},
					]}
				/>

			</Col>
			<Col xl={14} sm={24}>
				<Flex vertical>
					<b>
						Linked TC-in voyage
					</b>
					<VoyageSelector
						type="linkedTcIn"
						defaultValue={voyageDetails?.linkedTcInVoyageId}
						voyages={(voyages ?? []).filter((v) => v.fixtureType === FixtureTypes.TC_IN)}
						onSelect={async (c) => {
							await updateVoyageField('linkedTcInVoyageId', c);
						}}
					/>
					{voyageDetails.linkedTcInVoyageId != null && (
						<Link target="_blank" to={`${Links.Voyage.get(voyageDetails.linkedTcInVoyageId)}#/recap`}>
							View TC-In recap
						</Link>
					)}
				</Flex>
			</Col>
		</Row>
	);
};

export default VoyageCompletionDetails;
