import React from 'react';
import { Link } from 'react-router-dom';
import {
	DATE_AND_TIME,
	DATE,
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import {
	capitalize,
	toTitleCase,
} from '@shared/utils/string';
import formatTcAndBbFixtureDuration from '@shared/utils/formatTcAndBbFixtureDuration';
import { formatPercentage } from '@shared/utils/formatPercentage';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import EmptyText from '@client/components/EmptyText';

const getFixtureItems = ({
	voyageDetails: {
		identifier,
		vesselId,
		vesselName,
		vesselFlag,
		counterpartyName,
		counterpartyId,
		createdAt,
		bankAccount,
	},
	fixtureDetails,
}) => {
	const {
		id: fixtureId,
		identifier: fixtureIdentifier,
		laycanFrom,
		laycanTo,
		cpDate,
		hireType,
		hireRate,
		hireDescription,
		grossBallastBonus,
		daysPerHirePeriod,
		deliveryNoticesApproximate,
		deliveryNoticesDefinite,
		calculationMethodForExpensesSubjectToHireDays,
		expensesSubjectToHireDays,
		brokers,
		addressCommission,
		type,
		ownerExpenses,
		chartererExpenses,
		invoicePerCalenderMonth,
	} = fixtureDetails;

	if (fixtureDetails.type === FixtureTypes.SPOT) {
		return [];
	}

	return [
		{
			key: 'fixtureId',
			label: 'Estimate',
			value: (
				<LinkButton
					url={Links.Fixtures[type].Details.get(fixtureId)}
				>
					{fixtureIdentifier}
				</LinkButton>
			),
		},
		{
			key: 'fixtureType',
			label: 'Estimate Type',
			value: FixtureTypeLabels[type],
		},
		...(fixtureDetails.autofilledFrom != null ? [{
			key: 'autofilledFrom',
			label: 'As Per',
			value: (
				<>
					{`${fixtureDetails.autofilledFrom.vesselName}, ${fixtureDetails.autofilledFrom.identifier}`}
					<br />
					{FixtureTypeLabels[fixtureDetails.autofilledFrom.fixtureType]}
					{' '}
					with
					{' '}
					{fixtureDetails.autofilledFrom.counterpartyName}
					<br />
					CP
					{' '}
					{fixtureDetails.autofilledFrom.cpDate}
				</>
			),
		}] : []),
		{
			key: 'vesselId',
			label: 'Vessel',
			value: (
				<LinkButton
					url={Links.Vessel.get(vesselId)}
				>
					<VesselDescription
						name={vesselName}
						flag={vesselFlag}
					/>
				</LinkButton>
			),
		},
		{
			key: 'charterer',
			label: 'Charterer',
			value: (
				<LinkButton
					url={Links.Counterparty.get(counterpartyId)}
				>
					{counterpartyName}
				</LinkButton>
			),
		},
		{
			key: 'identifier',
			label: 'Identifier',
			value: identifier,
		},
		{
			key: 'laycan',
			label: 'Laycan',
			value: (
				<>
					{formatDate(laycanFrom, DATE_AND_TIME, true)}
					<br />
					{formatDate(laycanTo, DATE_AND_TIME, true)}
				</>
			),
		},
		{
			key: 'duration',
			label: 'Duration',
			value: formatTcAndBbFixtureDuration(fixtureDetails),
		},
		{
			key: 'commencementPort',
			label: 'Contractual Delivery Port(s) / Range(s)',
			value: fixtureDetails.deliveryPortsOrRangesLabel &&
                toTitleCase(fixtureDetails.deliveryPortsOrRangesLabel),
		},
		{
			key: 'completionPort',
			label: 'Contractual Redelivery Port(s) / Range(s)',
			value: fixtureDetails.redeliveryPortsOrRangesLabel &&
                toTitleCase(fixtureDetails.redeliveryPortsOrRangesLabel),
		},
		{
			key: 'cpDate',
			label: 'CP Date',
			value: formatDate(cpDate, DATE),
		},
		...(hireType ? [{
			key: 'hireType',
			label: 'Hire Type',
			value: capitalize(hireType),
		}] : []),
		...(hireRate ? [{
			key: 'hireRate',
			label: 'Hire Amount',
			value: hireRate != null && formatCurrency(hireRate, bankAccount.currency),
		}] : []),
		...(hireDescription ? [{
			key: 'hireDescription',
			label: 'Hire Description',
			value: hireDescription,
		}] : []),
		...(grossBallastBonus != null ? [{
			key: 'grossBallastBonus',
			label: 'Gross Ballast Bonus',
			value: grossBallastBonus != null && formatCurrency(grossBallastBonus, bankAccount.currency),
		}] : []),
		{
			key: 'daysPerHirePeriod',
			label: 'Days per Hire Period',
			value: invoicePerCalenderMonth ? 'PCM' : daysPerHirePeriod,
		},
		{
			key: 'deliveryNoticesApprox',
			label: 'Delivery Notices (approx.)',
			value: deliveryNoticesApproximate && deliveryNoticesApproximate.join(', '),
		},
		{
			key: 'deliveryNoticesDefinite',
			label: 'Delivery Notices (definite)',
			value: deliveryNoticesDefinite && deliveryNoticesDefinite.join(', '),
		},
		{
			key: 'redeliveryNoticesApprox',
			label: 'Redelivery Notices (approx.)',
			value: deliveryNoticesApproximate && deliveryNoticesApproximate.join(', '),
		},
		{
			key: 'redeliveryNoticesDefinite',
			label: 'Redelivery Notices (definite)',
			value: deliveryNoticesDefinite && deliveryNoticesDefinite.join(', '),
		},
		...(calculationMethodForExpensesSubjectToHireDays ? [{
			key: 'calculationMethodForExpensesSubjectToHireDays',
			label: 'Expense Formula',
			value: capitalize(calculationMethodForExpensesSubjectToHireDays),
		}] : []),
		...(expensesSubjectToHireDays ? [{
			key: 'cve',
			label: 'Expenses Subject To Hire Days',
			value: expensesSubjectToHireDays.length > 0 ? expensesSubjectToHireDays.map((e) => (
				<span>
					{`${e.name}: ${formatCurrency(e.amount, bankAccount.currency)}`}
					<br />
				</span>
			)) : (<EmptyText text="None" />),
		}] : []),
		{
			key: 'ownerExpenses',
			label: 'Owner\'s expenses',
			value: ownerExpenses.length > 0 ? ownerExpenses.map((e) => (
				<span>
					{`${e.name}: ${formatCurrency(e.amount, bankAccount.currency)}`}
					<br />
				</span>
			)) : (<EmptyText text="None" />),
		},
		{
			key: 'chartererExpenses',
			label: 'Charterer\'s expenses',
			value: chartererExpenses.length > 0 ? chartererExpenses.map((e) => (
				<span>
					{`${e.name}: ${formatCurrency(e.amount, bankAccount.currency)}`}
					<br />
				</span>
			)) : (<EmptyText text="None" />),
		},
		{
			key: 'brokers',
			label: 'Broker commission(s)',
			value: brokers.length > 0 ? brokers.map((b) => (
				<span>
					<Link to={Links.Broker.get(b.id)}>{b.name}</Link>
					{`: ${formatPercentage(b.commission)}, paid by ${b.paidBy}`}
					<br />
				</span>

			)) : (<EmptyText text="None" />),
		},
		{
			key: 'addressCommission',
			label: 'Address Commission',
			value: formatPercentage(addressCommission),
		},
		{
			key: 'createdAt',
			label: 'Creation Date',
			value: formatDate(createdAt, DATE_AND_TIME),
			editable: false,
		},
	];
};

export default getFixtureItems;
