export const round = (x: number, decimalPlaces = 2): number => {
	const factorOfTen = 10 ** decimalPlaces;
	const rounded: number = Math.round((x + Number.EPSILON) * factorOfTen) / factorOfTen;

	return rounded;
};

export const roundFixedDecimals = (x: number, decimalPlaces = 2): string | number => {
	return round(x, decimalPlaces).toFixed(decimalPlaces);
};

export const calculateTotal = <Item, Extra>(
	array: Item[],
	extractor?: (item: Item, extra?: Extra) => number,
	extra?: Extra,
) => {
	const getValue = extractor || ((i) => i as unknown as number);

	return round(array.reduce(
		(total, item) => total + getValue(item, extra),
		0,
	), 3);
};

export const average = (...nums: number[]): number => nums.reduce((acc, n) => acc + n) / nums.length;

export const ensureNegative = (num: number) => (num > 0 ? num * -1 : num);

export const RADIAN = Math.PI / 180;
