import React from 'react';
import {
	Row,
	Col,
} from 'antd';
import { useQuery } from 'react-query';
import { getEuas } from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import PaymentsTable from '../PaymentsTable';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import EuaRequirementTable from './EuaRequirementTable';
import EuaInvoiceTable from './EuaInvoiceTable';

const EuEtsTab = () => {
	const {
		voyageDetails,
		refreshVoyageDetails,
		tcFixtureDetails: fixtureDetails,
	} = useVoyage();

	const { data, isLoading, refetch: refreshRequirements } = useQuery(
		'euas',
		() => getEuas(voyageDetails.id),
		{
			enabled: voyageDetails != null,
			refetchOnWindowFocus: false,
		},
	);

	if (voyageDetails == null) {
		return (<LoadingIndicator />);
	}

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<EuaRequirementTable
					isLoading={isLoading}
					voyageId={voyageDetails.id}
					data={data ?? []}
				/>
			</Col>
			<Col span={24} xxl={8}>
				<PaymentsTable
					isEua
					refresh={refreshRequirements}
				/>
			</Col>
			<Col span={24} xxl={16}>
				<EuaInvoiceTable
					euas={data}
					voyageDetails={voyageDetails}
					fixtureDetails={fixtureDetails}
					refresh={refreshVoyageDetails}
				/>
			</Col>
		</Row>
	);
};

export default EuEtsTab;
