import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Col,
	Row,
} from 'antd';
import { Link } from 'react-router-dom';
import {
	FreightRateType,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { Port } from '@api/utils/ports';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import {
	getEstimateDetails,
	getPorts,
	getVesselDetails,
	getVoyages,
	updateVoyage,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';
import LinkedVoyages from '@client/screens/fleet/VoyageDetailsScreen/components/LinkedVoyages/LinkedVoyages';
import { Links } from '@client/utils/links';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import EstimatedDates from '../EstimatedDates/EstimatedDates';
import VoyageCompletionDetails from '../VoyageCompletionDetails';
import ItineraryTab from '../ItineraryTab/ItineraryTab';

const VcSummaryTab = ({
	voyageDetails,
	fixtureDetails,
	refreshDetails,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps | SpotFixtureProps | BbFixtureProps
	> & { estimateId: number };
	refreshDetails: () => Promise<void> | void;
}) => {
	const [ports] = useFetchedState(getPorts);
	const portOptions = getPortOptions(ports ?? []);
	const [voyages] = useFetchedState(getVoyages);

	const filteredVoyages = useMemo(() => {
		if (voyages == null) {
			return [];
		}

		return voyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, voyages]);

	const [vessel, _refresh, error, loadingVesselDetails] = useFetchedState(
		() => getVesselDetails(voyageDetails.vesselId),
		[voyageDetails.vesselId],
	);

	const [
		estimateDetails,
		_refreshEstimate,
		estimateError,
		loadingEstimate,
	] = useFetchedState(
		async () => {
			const { estimateId } = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

			if (estimateId != null) {
				return await getEstimateDetails(estimateId);
			}

			return null;
		},
		[fixtureDetails],
	);

	const cargos = estimateDetails?.cargos;

	const updateVoyageField = useCallback(async (
		field: string,
		value: any,
		contractId?: number | null,
	) => {
		try {
			await updateVoyage(contractId ?? voyageDetails.id, { [field]: value });
			await refreshDetails();
			showSuccessNotification('Contract updated');
		} catch (e) {
			if (value !== undefined) {
				showErrorNotification('Could not update contract', e as Error);
			}
		}
	}, [refreshDetails, voyageDetails.id]);

	if (
		error ||
		estimateError ||
		loadingEstimate ||
		loadingVesselDetails ||
		vessel == null ||
		estimateDetails == null
	) {
		return null;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xxl={7} sm={24}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card size="small">
							<LinkedVoyages
								voyageDetails={voyageDetails}
								voyages={filteredVoyages}
								ownershipPeriods={vessel.ownershipPeriods}
								refreshVoyageDetails={refreshDetails}
							/>
							{voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN && (
								<VoyageCompletionDetails
									voyageDetails={voyageDetails}
									voyages={filteredVoyages}
									updateVoyageField={updateVoyageField}
								/>
							)}
						</Card>
					</Col>
					<Col span={24}>
						<Card slim size="small">
							<EstimatedDates
								voyageDetails={voyageDetails}
								updateVoyageField={updateVoyageField}
								portOptions={portOptions}
							/>
						</Card>
					</Col>
				</Row>
			</Col>
			<Col xxl={17} sm={24}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card slim title="Cargo" size="small">
							<Table
								size="small"
								pagination={false}
								columns={[
									{
										title: 'Type',
										dataIndex: 'type',
										key: 'cargo',
										render: (type, row) => (<Link to={`${Links.Cargo.get(row.id)}`}>{type}</Link>),
										width: 200,
									},
									{
										title: 'Freight',
										key: 'freight',
										render: (row: GetCargosResponse[number]) => {
											const total = row.freightType === FreightRateType.LUMPSUM ?
												row.freightRate :
												row.quantity * row.freightRate;

											return formatCurrency(total / row.exchangeRate, estimateDetails.currency);
										},
										width: 200,

									},
									{
										title: 'Loading',
										dataIndex: 'loadingPorts',
										key: 'loadingPorts',
										render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
									},
									{
										title: 'Discharge',
										dataIndex: 'dischargePorts',
										key: 'dischargePorts',
										render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
									},
									{
										title: 'Charterer',
										dataIndex: 'chartererName',
										key: 'chartererName',
										render: (charterer) => charterer,
									},
								]}
								dataSource={cargos ?? []}
							/>
						</Card>
					</Col>
					{cargos?.some((c) => c.currency !== estimateDetails?.currency) && (
						<Col span={24}>
							<Card slim title="FX rates" size="small">
								<Table
									size="small"
									pagination={false}
									columns={[
										{
											title: 'Type',
											dataIndex: 'type',
											key: 'cargo',
											render: (type, row) => (<Link to={`${Links.Cargo.get(row.id)}`}>{type}</Link>),
											width: 200,
										},
										{
											title: 'Freight',
											key: 'freight',
											render: (row: GetCargosResponse[number]) => {
												const total = row.freightType === FreightRateType.LUMPSUM ?
													row.freightRate :
													row.quantity * row.freightRate;

												return formatCurrency(total, row.currency);
											},
											width: 200,
										},
										{
											title: 'Exchange Rate',
											key: 'exchangeRate',
											render: (row: GetCargosResponse[number]) => {
												return `${row.exchangeRate} ${row.currency}`;
											},
										},
									]}
									dataSource={cargos?.filter((c) => c.currency !== estimateDetails.currency) ?? []}
								/>
							</Card>
						</Col>
					)}
					<Col span={24} flex="auto">
						<ItineraryTab />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default VcSummaryTab;

