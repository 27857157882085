import React from 'react';
import {
	DATE_AND_TIME,
	FixtureTypes,
	MillisecondsPer,
} from '@shared/utils/constants';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';
import HIIOffHirePeriod from '@shared/hireInvoice/HIIOffHirePeriod';
import HIIBallastBonus from '@shared/hireInvoice/HIIBallastBonus';
import { toMoment } from '@shared/utils/date';
import {
	calculateTotal,
	round,
} from '@shared/utils/math';
import HIIFreight from '@shared/hireInvoice/HIIFreight';
import HIIOverage from '@shared/hireInvoice/HIIOverage';
import HIIDeadfreight from '@shared/hireInvoice/HIIDeadfreight';
import HIIRevenueItem from '@shared/hireInvoice/HIIRevenueItem';
import HIIDemurrage from '@shared/hireInvoice/HIIDemurrage';
import HIIDespatch from '@shared/hireInvoice/HIIDespatch';
import HIIBbItem from '@shared/hireInvoice/HIIBbItem';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import { formatDate } from '@client/utils/formatDate';

export const getBrokerInvoiceMap = ({
	fixtureDetails,
	voyageDetails,
	hireInvoices,
}: {
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps | TcFixtureProps>;
	voyageDetails: GetVoyageDetailsResponse;
	hireInvoices: VoyageInvoice[] | undefined;
}) => {
	const hireInvoicesByItemId =
	(hireInvoices ?? []).reduce((obj, hireInvoice) => ({
		...obj,
		...hireInvoice.items
			.filter((i) => !i.isPrevious)
			.reduce((itemObj, i) => ({
				...itemObj,
				[i.id]: hireInvoice,
			}), {}),
	}), {});

	const getInvoiceIdentifier = (item: HireInvoiceItem) => (
		hireInvoicesByItemId[item.id]?.invoiceIdentifier ||
	'Cumulative'
	);

	const allItems = [
		...(hireInvoices ?? []).reduce<Array<HireInvoiceItem>>((arr, hireInvoice) => [
			...arr,
			...hireInvoice.items,
		], []).filter((i) => !i.isPrevious),
		...voyageDetails.cumulativeItems.map((i) => HireInvoiceItem.fromJSON(i)),
	];

	const bbItemBrokerCommissions =
		allItems
			.filter((i) => i instanceof HIIBbItem)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

	const getSpotItems = (cargoId: number) => {
		const freightItems =
		allItems
			.filter((i) => i instanceof HIIFreight && i.cargoId === cargoId)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

		const overageItems =
		allItems
			.filter((i) => i instanceof HIIOverage && i.cargoId === cargoId)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

		const deadfreightItems =
		allItems
			.filter((i) => i instanceof HIIDeadfreight && i.cargoId === cargoId)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

		const demurrageItems =
		allItems
			.filter((i) => i instanceof HIIDemurrage && i.cargoId === cargoId)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

		const despatchItems =
		allItems
			.filter((i) => i instanceof HIIDespatch && i.cargoId === cargoId)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

		const revenueItems =
		allItems
			.filter((i) => (
				i instanceof HIIRevenueItem &&
				i.cargoId === cargoId &&
				i.subjectToCommissions
			))
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

		return {
			freightItems,
			overageItems,
			deadfreightItems,
			demurrageItems,
			despatchItems,
			revenueItems,
		};
	};

	if (fixtureDetails.type === FixtureTypes.SPOT) {
		const test = fixtureDetails.cargos.reduce((acc, cargo) => {
			const result = cargo.Brokers.reduce((accc, cb) => {
				const notesByHireInvoiceItemId = voyageDetails.brokerPayments
					.filter((b) => b.brokerId === cb.id)
					.reduce((obj, brokerPayment) => ({
						...obj,
						[brokerPayment.hireInvoiceItemId]: brokerPayment.note,
					}), {});

				const getItemRows = (
					item: HireInvoiceItem,
					total: number,
					invoiceIdentifier: string,
					type: string,
				) => ({
					itemId: item.id,
					brokerId: cb.id,
					brokerName: cb.name,
					invoiceId: item.hireInvoiceId,
					commissionPercent: cb.BrokerInCargo.commission,
					commissionAmount: (total / 100) * (cb.BrokerInCargo.commission ?? 0),
					invoiceAmount: total,
					invoiceIdentifier,
					type: cargo.type,
					freightType: type,
					note: notesByHireInvoiceItemId[item.id],
				});

				const {
					freightItems,
					overageItems,
					deadfreightItems,
					despatchItems,
					demurrageItems,
					revenueItems,
				} = getSpotItems(cargo.id);

				const rows = [
					...freightItems.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Freight')),
					...overageItems.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Overage')),
					...deadfreightItems.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Deadfreight')),
					...despatchItems.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Despatch')),
					...demurrageItems.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Demurrage')),
					...revenueItems.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Revenue')),
					...bbItemBrokerCommissions.map(({ item, total, invoiceIdentifier }) => getItemRows(item, total, invoiceIdentifier, 'Commission')),

				];

				return {
					...accc,
					[cb.name]: rows,
				};
			}, {});

			return {
				...acc,
				...result,
			};
		}, {});

		return test;
	}

	const hirePeriodEntries =
		allItems
			.filter((i) => i instanceof HIIHirePeriod)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

	const offHireEntries =
		allItems
			.filter((i) => i instanceof HIIOffHirePeriod)
			.map((i) => ({
				item: i,
				total: i.getTotal(allItems, false),
				invoiceIdentifier: getInvoiceIdentifier(i),
			}));

	const getBallastBonusEntry = () => {
		const ballastBonus = allItems.find((i) => i instanceof HIIBallastBonus);

		if (ballastBonus == null) {
			return undefined;
		}

		return {
			id: ballastBonus.id,
			item: ballastBonus,
			total: ballastBonus.amount,
			invoiceIdentifier: getInvoiceIdentifier(ballastBonus),
		};
	};

	const ballastBonusEntry = getBallastBonusEntry();

	const getRows = (broker: GetFixtureDetailsResponse<TcFixtureProps>['brokers'][number]) => {
		const notesByHireInvoiceItemId = voyageDetails.brokerPayments
			.filter((b) => b.brokerId === broker.id)
			.reduce((obj, brokerPayment) => ({
				...obj,
				[brokerPayment.hireInvoiceItemId]: brokerPayment.note,
			}), {});

		const rows = [
			...bbItemBrokerCommissions.map(({ item, total, invoiceIdentifier }) => ({
				brokerId: broker.id,
				itemId: item.id,
				type: 'bareboatItem',
				invoiceIdentifier,
				period: `${formatDate(item.from, DATE_AND_TIME)} - ${formatDate(item.to, DATE_AND_TIME)}`,
				days: round(toMoment(item.to).diff(item.from) / MillisecondsPer.DAY, 4),
				commission: broker.commission,
				amount: total * (broker.commission / 100),
			})),
			...hirePeriodEntries.map(({ item, total, invoiceIdentifier }) => ({
				brokerId: broker.id,
				itemId: item.id,
				type: 'Hire Period',
				invoiceIdentifier,
				hireRate: item.hireRate,
				period: `${formatDate(item.from, DATE_AND_TIME)} - ${formatDate(item.to, DATE_AND_TIME)}`,
				days: round(toMoment(item.to).diff(item.from) / MillisecondsPer.DAY, 4),
				commission: broker.commission,
				amount: total * (broker.commission / 100),
				note: notesByHireInvoiceItemId[item.id],
			})),
			...offHireEntries.map(({ item, total, invoiceIdentifier }) => ({
				brokerId: broker.id,
				itemId: item.id,
				type: 'Off-hire',
				invoiceIdentifier,
				hireRate: item.hirePerDay,
				period: `${formatDate(item.startTime, DATE_AND_TIME)} - ${formatDate(item.endTime, DATE_AND_TIME)}`,
				days: round(toMoment(item.endTime).diff(item.startTime) / MillisecondsPer.DAY, 4),
				commission: broker.commission,
				amount: total * (broker.commission / 100),
				note: notesByHireInvoiceItemId[item.id],
			})),
			...(ballastBonusEntry != null ? [{
				brokerId: broker.id,
				itemId: ballastBonusEntry?.item?.id ?? -1,
				type: 'Ballast Bonus',
				invoiceIdentifier: ballastBonusEntry?.invoiceIdentifier,
				hireRate: ballastBonusEntry?.total,
				period: '-',
				days: '-',
				commission: broker.commission,
				amount: ballastBonusEntry?.total * (broker.commission / 100),
				note: notesByHireInvoiceItemId[ballastBonusEntry?.id],
			}] : []),
		];

		return [
			...rows,
			{
				isTotal: true,
				type: (<strong>Total</strong>),
				amount: calculateTotal(rows, (i) => i.amount),
			},
		];
	};

	return fixtureDetails.brokers.reduce((acc, broker) => {
		return {
			...acc,
			[broker.name]: getRows(broker),
		};
	}, {});
};
