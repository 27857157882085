import React, {
	SetStateAction,
	useMemo,
} from 'react';
import {
	Grid,
	Modal,
} from 'antd';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import { useCargoHandlers } from '@client/screens/estimates/details/context/hooks/useCargoHandlers';
import { getLoadedAndDischarged } from '@client/screens/cargos/details/CargoDetailsScreen';
import { ItineraryCargoPortsDetails } from './ItineraryCargoPortsDetails';

type Props = {
	cargoId: number | null;
	visible: boolean;
	refresh?: () => Promise<void>;
	setEditingCargoId: React.Dispatch<SetStateAction<number | null>>;
	updateInProgress?: boolean;
	editingCargoPortId: number | null;
}

const ItineraryCargoDetailsModal = ({
	cargoId,
	visible,
	refresh,
	setEditingCargoId,
	editingCargoPortId,
}: Props) => {
	const screens = Grid.useBreakpoint();

	const onClose = async () => {
		await refresh?.();

		setEditingCargoId(null);
	};

	const {
		makeBlockable,
	} = useNavigationBlock();

	const onCloseModal = makeBlockable(onClose);

	const {
		cargo: cargoDetails,
		cargoPorts,
		onUpdateCargoPort,
		pendingChanges,
	} = useCargoHandlers({
		cargoId,
		selectedEstimateId: null,
	});

	const { loaded, discharged } = useMemo(() => {
		if (cargoDetails != null && cargoDetails.cargoPorts != null) {
			return getLoadedAndDischarged(cargoDetails.cargoPorts);
		}

		return {
			loaded: 0,
			discharged: 0,
		};
	}, [cargoDetails]);

	const quantitiesRemaining = useMemo(() => {
		if (cargoDetails == null) {
			return null;
		}

		const loadedRemaining = cargoDetails.quantity - loaded;
		const dischargeRemaining = loaded - discharged;

		return {
			loadedRemaining,
			dischargeRemaining,
		};
	}, [cargoDetails, discharged, loaded]);

	const loadingPorts = useMemo(() => {
		if (cargoPorts == null) {
			return [];
		}

		return cargoPorts
			.filter((cp) => {
				const { action } = splitActionKey(cp.portAndActionKey);

				return action === 'loading';
			}).map((cp) => ({
				...cp,
				key: cp.id,
			}));
	}, [cargoPorts]);

	const dischargePorts = useMemo(() => {
		if (cargoPorts == null) {
			return [];
		}

		return cargoPorts
			.filter((cp) => {
				const { action } = splitActionKey(cp.portAndActionKey);

				return action === 'discharging';
			})
			.map((cp) => ({
				...cp,
				key: cp.id,
			}));
	}, [cargoPorts]);

	const sortedCargoPorts = [
		...loadingPorts.sort((a, b) => a.id - b.id),
		...dischargePorts.sort((a, b) => a.id - b.id),
	];
	const cargoPort = sortedCargoPorts.filter((port) => port.id === editingCargoPortId);

	return (
		<Modal
			closable={false}
			open={visible}
			onCancel={onCloseModal}
			destroyOnClose
			width="calc(80vw - 6%)"
			okText="Close"
			okButtonProps={{
				onClick: () => onCloseModal(),
			}}
			styles={{ body: { padding: screens.lg ? 5 : 0 } }}
			cancelButtonProps={{
				style: {
					visibility: 'hidden',
				},
			}}
		>
			{cargoId != null && (
				<ItineraryCargoPortsDetails
					handleChange={onUpdateCargoPort}
					cargoPorts={cargoPorts ?? []}
					sortedCargoPorts={cargoPort}
					cargoDetails={cargoDetails}
					quantitiesRemaining={quantitiesRemaining}
					pendingChanges={pendingChanges}
				/>
			)}
		</Modal>
	);
};

export default ItineraryCargoDetailsModal;
