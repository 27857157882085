import React, {
	useEffect,
	useState,
} from 'react';
import {
	Alert,
	Card,
} from 'antd';
import {
	BankAccountTypes,
	CustomFieldParentTypes,
} from '@shared/utils/constants';
import type { BankAccountProps } from '@api/models/bank-account';
import type { UpdateBankAccountRequest } from '@api/features/bank-accounts/updateBankAccount';
import { BankAccountTabs } from '@client/screens/fleet/VesselDetailsScreen/components/BankAccountTabs';
import {
	createBankAccount,
	deleteBankAccount,
	getOrgBankAccounts,
	updateBankAccount,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import CustomFields from './CustomFields';

const OrgBankAccounts = () => {
	const [bankAccounts, refreshAccount] = useFetchedState(() => getOrgBankAccounts(true));
	const [activeBankAccountTab, setActiveBankAccountTab] = useState<number | undefined>();

	useEffect(() => {
		if (bankAccounts && bankAccounts.length > 0) {
			setActiveBankAccountTab(bankAccounts[0].id);
		}
	}, [bankAccounts]);

	const handleSaveChanges = async (account: BankAccountProps, values: UpdateBankAccountRequest['attributes']) => {
		await updateBankAccount(values, account.id);
		await refreshAccount();
	};

	const handleAddAccount = async () => {
		const newAccount = await createBankAccount(BankAccountTypes.OURS);
		await refreshAccount();

		return newAccount;
	};

	const handleDeleteAccount = async (id: number) => {
		await deleteBankAccount(id);
		await refreshAccount();
	};

	return (
		<>
			<Alert
				message="Bank accounts created here will appear as options for all vessels"
				// eslint-disable-next-line react/forbid-component-props
				style={{ marginBottom: 10 }}
			/>
			<Card
				bordered
				title="Your Banking Details"
			>
				<BankAccountTabs
					bankAccounts={bankAccounts ?? []}
					deleteBankAccount={handleDeleteAccount}
					addBankAccount={handleAddAccount}
					saveBankAccount={handleSaveChanges}
					activeBankAccountTab={activeBankAccountTab}
					setActiveBankAccountTab={setActiveBankAccountTab}
					allowCreate
				/>
				{activeBankAccountTab && (
					<CustomFields
						parentId={activeBankAccountTab}
						parentType={CustomFieldParentTypes.BANK}
					/>
				)}

			</Card>
		</>
	);
};

export default OrgBankAccounts;
