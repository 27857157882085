import React from 'react';
import {
	Empty,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	FixtureTypes,
	VoyageBunkerTypeLabels,
	VoyageBunkerTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { formatNumber } from '@shared/utils/formatNumber';
import type { VoyageProps } from '@api/models/voyage';
import type { FixtureProps } from '@api/models/fixture';
import type { FixtureCounterpartyProps } from '@api/models/fixture-counterparty';
import type { BunkersWithUndefined } from '@api/features/voyages/utils/getPreviousBunkers';
import { formatDate } from '@client/utils/formatDate';
import Table from '@client/components/Table/Table';
import Details from '@client/components/Details';
import Card from '../Card/Card';
import CountryFlag from '../CountryFlag';
import styles from './LinkedContractDetails.module.css';

const getBunkerTable = (
	bunkers: Array<BunkersWithUndefined>,
	bunkersType: Values<typeof VoyageBunkerTypes> | 'on completion' | undefined,
) => {
	let title = 'Bunkers';

	if (bunkersType != null) {
		title = `Bunkers ${bunkersType !== 'on completion' ? `on ${VoyageBunkerTypeLabels[bunkersType]}` : 'on completion'}`;
	}

	if (bunkers.length === 0) {
		return null;
	}

	return (
		<Table
			pagination={false}
			dataSource={bunkers}
			title={() => (
				<Space>
					<FontAwesomeIcon
						color="red"
						icon={faGasPump as IconProp}
					/>
					{title}
				</Space>
			)}
			columns={[
				{
					title: 'Fuel grade',
					dataIndex: 'fuelGrade',
				},
				{
					dataIndex: 'quantity',
					title: 'Quantity',
					render: (c: number) => `${formatNumber(c, { separateThousands: true }, 3)} MT`,
				},
				{
					dataIndex: 'pricePerTon',
					title: 'Price / MT',
					render: (c, record) => {
						const price = c;

						if (Number.isNaN(c)) {
							return 0;
						}

						return `${formatCurrency(price, record.currency ?? 'USD')}`;
					},
				},
			]}
		/>
	);
};

type Props = {
	linkedContract: VoyageProps & {
		fixture: FixtureProps & {
			fixtureCounterparty: FixtureCounterpartyProps;
		};
	} | null | undefined;
	bunkers: Array<BunkersWithUndefined>;
	bunkersType?: Values<typeof VoyageBunkerTypes> | 'on completion';
	extraBunkers?: Array<BunkersWithUndefined>;
	extraBunkersType?: Values<typeof VoyageBunkerTypes>;
	title?: string | React.ReactElement;
}

const LinkedContractDetails = ({
	title,
	linkedContract,
	bunkers,
	bunkersType,
	extraBunkers,
	extraBunkersType,
}: Props) => {
	if (linkedContract == null) {
		return (
			<Card>
				<Empty description="No linked voyage" />
			</Card>
		);
	}

	const isTc = linkedContract.fixture.type !== FixtureTypes.SPOT;
	const startDate = isTc ? linkedContract.deliveryDate : linkedContract.commencementDate;
	const endDate = linkedContract.completionDate;

	const startPort = isTc ? linkedContract.deliveryPort : linkedContract.commencementPort;
	const endPort = linkedContract.completionPort;

	return (
		<Card
			title={title}
			className={styles.card}
			slim
		>
			<Details
				hideHeader
				items={[
					{
						key: 'charterer',
						label: 'Charterer',
						value: linkedContract.fixture?.fixtureCounterparty?.name,
					},
					{
						key: 'deliveryDate',
						label: `${isTc ? 'Delivery' : 'Commencement'} date`,
						value: startDate != null ? (
							formatDate(startDate)
						) : 'Not set',
					},
					{
						key: 'deliveryPort',
						label: `${isTc ? 'Delivery' : 'Commencement'} port`,
						value: (
							<div className={styles.container}>
								<CountryFlag countryCode={startPort?.countryCode} />
								{startPort?.name ?? 'Not set'}
							</div>
						),
					},
					{
						key: 'redeliveryDate',
						label: `${isTc ? 'Redelivery' : 'Completion'} date`,
						value: endDate != null ? (
							formatDate(endDate)
						) : 'Not set',
					},
					{
						key: 'redeliveryPort',
						label: `${isTc ? 'Redelivery' : 'Completion'} port`,
						value: (
							<div className={styles.container}>
								<CountryFlag countryCode={endPort?.countryCode} />
								{endPort?.name ?? 'Not set'}
							</div>
						),
					},
				]}
			/>
			{getBunkerTable(bunkers, bunkersType)}
			{extraBunkers != null && getBunkerTable(extraBunkers, extraBunkersType)}
		</Card>
	);
};

export default LinkedContractDetails;
