import React from 'react';
import { Moment } from 'moment';
import {
	FreightEstimatingMethod,
	PortActionTypes,
	ShortPortActionTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatNumber } from '@shared/utils/formatNumber';
import type { Port } from '@api/utils/ports';
import type Vessel from '@api/models/vessel';
import { usePortRotationProvider } from '@client/screens/estimates/details/context/PortRotationContext';
import { renderWrappingTime } from '@client/screens/estimates/details/PortRotationCard';
import calculateMaxCargoForPort from '@client/screens/cargos/helpers/calculateMaxCargoForPort';
import {
	LocalPortRotationWithKey,
	PartialPortRotationEntry,
} from '@client/screens/estimates/details/context/hooks/usePortRotationHandlers';
import NumericInput from '@client/components/NumericInput';
import Table from '@client/components/Table/Table';
import { useEstimate } from '@client/screens/estimates/details/context/EstimatorContext';
import styles from '../CommissionsCard/BrokerCommissionDetails.module.css';

const IntakeCalculatorPortRotation = () => {
	const { portRotation, onPortRotationRowChange } = usePortRotationProvider();
	const {
		estimate: estimateResult,
	} = useEstimate();

	const cargoDetails = estimateResult?.cargos[0];

	const vessel = estimateResult?.vessel as unknown as Vessel;

	return (
		<div className={styles.container}>
			<Table
				pagination={false}
				showSorterTooltip
				dataSource={portRotation}
				columns={[
					{
						title: 'Type',
						dataIndex: 'type',
						width: 100,
						render: (p: string) => ShortPortActionTypes[p as Values<typeof PortActionTypes>] ??
							p,
					},
					{
						title: 'Port',
						dataIndex: 'port',
						render: (port: Port) => port?.name,
					},
					{
						title: 'Arrival',
						dataIndex: 'arrivalDate',
						render: (arrivalDate: Moment) => renderWrappingTime(arrivalDate, 'utc') ?? '-',
					},
					{
						title: 'Departure',
						dataIndex: 'departureDate',
						render: (departureDate: Moment) => renderWrappingTime(departureDate, 'utc') ?? '-',
					},
					{
						title: 'Draft limit',
						dataIndex: 'Draft',
						width: 120,
						render: (_, record: LocalPortRotationWithKey) => {
							return (
								<NumericInput
									value={record.draft}
									placeholder="Draft (m)"
									addonAfter="m"
									onChange={(newValue: number | null) => (record?.id != null ?
										onPortRotationRowChange(record.id, {
											draft: newValue == null ? null : newValue,
										} as PartialPortRotationEntry) : undefined)}
								/>
							);
						},
					},
					{
						title: 'Max restriction point',
						dataIndex: 'portMax',
						align: 'right',
						render: (_, record: LocalPortRotationWithKey, index: number) => {
							if (!vessel) {
								return record.portMax;
							}

							const { portMax } = calculateMaxCargoForPort(
								record,
								index,
								vessel,
								estimateResult,
							);

							return typeof portMax === 'number' ? `${formatNumber(portMax, { separateThousands: true }, 0)} MT` : `${portMax}`;
						},
					},
					{
						title: 'Port limiting factor',
						dataIndex: 'limitingFactor',
						render: (_, record: LocalPortRotationWithKey, index: number) => {
							if (!vessel) {
								return record.limitingFactor;
							}

							const { limitingFactor } = calculateMaxCargoForPort(
								record,
								index,
								vessel,
								estimateResult,
							);

							return limitingFactor;
						},
					},
					{
						title: 'CP max',
						dataIndex: '',
						align: 'right',
						render: () => {
							if (estimateResult?.freightEstimatingMethod === FreightEstimatingMethod.MAX_INTAKE) {
								return estimateResult.vesselMaximumIntake ?
									`${formatNumber(estimateResult.vesselMaximumIntake, { separateThousands: true }, 0)} MT` :
									'-';
							}

							const quantityTolerance = cargoDetails?.quantityTolerance ?? 0;

							const quantity = cargoDetails?.quantity ?? 0;

							const toleranceAmount = (quantity / 100) *
							quantityTolerance;

							const total = quantity + toleranceAmount;

							return total ? `${formatNumber(total, { separateThousands: true }, 0)} MT` : '-';
						},
					},
				]}
			/>
		</div>
	);
};

export default IntakeCalculatorPortRotation;
