import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Empty,
	Row,
	Space,
	Typography,
} from 'antd';
import classNames from 'classnames';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { capitalize } from '@shared/utils/string';
import type { CargoPortWithEstimatedValues } from '@api/features/cargos/transformCargos';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import Card from '@client/components/Card/Card';
import CountryFlag from '@client/components/CountryFlag';
import CargoPortForm from '@client/screens/cargos/list/CargoPortForm';
import EstimatedCargoPortForm from '@client/screens/cargos/list/EstimatedCargoPortForm';
import SavingIndicator from '@client/components/SavingIndicator';
import styles from './ItineraryCargoDetailsModal.module.css';

type ItineraryCargoPortsDetailsProps = {
	cargoPorts: CargoPortWithEstimatedValues[];
	sortedCargoPorts: CargoPortWithEstimatedValues[];
	cargoDetails: GetCargoDetailsResponse | null | undefined;
	handleChange: (values: { cargoPortId: number; cargoId: number; attributes: any }) => void;
	quantitiesRemaining: {
		loadedRemaining: number;
		dischargeRemaining: number;
	} | null;
	pendingChanges: boolean;
}

export const ItineraryCargoPortsDetails = ({
	cargoPorts,
	sortedCargoPorts,
	handleChange,
	cargoDetails,
	quantitiesRemaining,
	pendingChanges,
}: ItineraryCargoPortsDetailsProps) => {
	const [selectedCargoPort, setSelectedCargoPort] = useState<
		GetCargoDetailsResponse['cargoPorts'][number] | null
	>(null);

	useEffect(() => {
		if (cargoPorts != null && cargoPorts.length > 0 && selectedCargoPort == null) {
			setSelectedCargoPort(cargoPorts[0]);
		}
	}, [cargoPorts, selectedCargoPort]);

	const tabs = useMemo(() => sortedCargoPorts.map((cp) => ({
		key: cp.id,
		tabKey: `${cp.id}`,
		details: cp,
	})), [sortedCargoPorts]);

	return (
		<Card>
			{(tabs == null || tabs.length === 0) && (
				<Empty description="No cargo ports. Please add loading or discharge ports above." />
			)}
			{tabs.map((cargoPort) => {
				const { action } = splitActionKey(cargoPort.details.portAndActionKey);

				return (
					<Row gutter={[16, 16]} key={cargoPort.key}>
						<Col span={24}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography.Title level={4}>
									<Space direction="horizontal">
										{`${capitalize(action)} in `}
										<div className={classNames(styles.flagAndNameRow)}>
											<CountryFlag
												countryCode={cargoPort.details.port.countryCode}
												className={styles.portFlag}
												size={25}
											/>
											<Typography.Text className={styles.portName}>
												{cargoPort.details.port.name}
											</Typography.Text>
										</div>
									</Space>
								</Typography.Title>
								<SavingIndicator spinning={pendingChanges} />
							</div>
						</Col>
						<Col flex="1" className={styles.column}>
							<div>
								<Typography.Title level={5}>Charter party</Typography.Title>
								{cargoDetails != null && (
									<CargoPortForm
										key={cargoPort.details.id}
										handleCargoPortChange={handleChange}
										refreshCargoDetails={() => undefined}
										cargoPort={cargoPort.details}
										cargo={cargoDetails}
										enableDemDes
										type={
											splitActionKey(cargoPort.details.portAndActionKey).action ===
												'loading' ?
												'loadingPort' :
												'dischargePort'
										}
										quantitiesRemaining={quantitiesRemaining}
									/>
								)}
							</div>
						</Col>
						<Col flex="1">
							<div>
								<Typography.Title level={5}>Estimated calculation</Typography.Title>
								{cargoDetails != null && cargoPort.details != null && (
									<EstimatedCargoPortForm
										key={cargoPort.details.id}
										handleCargoPortChange={handleChange}
										cargoPort={cargoPort.details}
										cargo={cargoDetails}
										refreshCargoDetails={() => undefined}
									/>
								)}
							</div>
						</Col>
					</Row>
				);
			})}
		</Card>

	);
};
