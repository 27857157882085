import React, {
	useMemo,
	useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faFlagCheckered } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { CompassOutlined } from '@ant-design/icons';
import { nowMoment } from '@shared/utils/date';
import { FixtureTypes } from '@shared/utils/constants';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	markFixtureAsCompleted,
	resumeFixture,
} from '@client/lib/api';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import styles from './styles/HeaderAction.module.css';
import { useVoyage } from './VoyageProvider/VoyageProvider';

const HeaderAction = () => {
	const {
		voyageDetails,
		refreshDetails,
		fixtureDetails,
	} = useVoyage();
	const [actionLoading, setActionLoading] = useState<boolean>(false);

	const estimateId = useMemo(() => {
		if (voyageDetails != null) {
			if (voyageDetails?.fixture.type === FixtureTypes.SPOT && fixtureDetails != null) {
				const spotFixture = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

				return {
					estimateId: spotFixture.estimateId,
					estimateGroupId: spotFixture.estimateGroupId,
				};
			}

			return {
				estimateId: voyageDetails.fixture.id,
			};
		}

		return null;
	}, [voyageDetails, fixtureDetails]);

	const fixtureType = fixtureDetails?.type ?? voyageDetails.fixture.type;
	const isSpot = fixtureType === FixtureTypes.SPOT;

	let link = null;

	if (estimateId?.estimateId) {
		if (isSpot && estimateId.estimateGroupId != null) {
			link = Links.Fixtures[FixtureTypes.SPOT]?.Details.get(
				estimateId.estimateGroupId,
				estimateId.estimateId,
			);
		} else if (!isSpot) {
			link = Links.Fixtures[fixtureType]?.Details.get(estimateId.estimateId);
		}
	}

	const estimateLink = link != null ? (
		<Link key="voyage-link" to={link}>
			<Button
				className={styles.goToEstimate}
				type="link"
				icon={(<CompassOutlined />)}
			>
				Go to estimate
			</Button>
		</Link>
	) : null;

	const markComplete = async () => {
		setActionLoading(true);

		try {
			await markFixtureAsCompleted(voyageDetails?.id);

			await refreshDetails();
			showSuccessNotification('Contract completed', 'The contract has successfully been completed');
		} catch (e) {
			showErrorNotification('Could not complete contract', e as Error);
		}

		setActionLoading(false);
	};

	const resumeVoyage = async () => {
		setActionLoading(true);

		try {
			await resumeFixture(voyageDetails?.id);

			await refreshDetails();
			showSuccessNotification('Contract resumed', 'The contract has been resumed');
		} catch (e) {
			showErrorNotification('Could not resume contract', e as Error);
		}

		setActionLoading(false);
	};

	let action;

	if (voyageDetails?.id == null) {
		action = null;
	} else if (voyageDetails.fixture.completed) {
		action = (
			<Space>
				{estimateLink}
				<Button
					onClick={resumeVoyage}
					className={styles.approveButton}
					type="primary"
					loading={actionLoading}
					icon={(<FontAwesomeIcon className={styles.icon} icon={faPlay as IconProp} />)}
				>
					Resume contract
				</Button>
			</Space>
		);
	} else {
		let confirmTitle = (<>Mark contract as complete? This can be undone at any point</>);

		if (voyageDetails.stats.outstanding > 0) {
			confirmTitle = (
				<>
					There is still an outstanding amount.
					<br />
					Really mark contract as complete?
					<br />
					This can be undone at any point.
				</>
			);
		} else if (!nowMoment().isAfter(voyageDetails.completionDate)) {
			confirmTitle = (
				<>
					Contract completion date is in the future.
					<br />
					Really mark contract as complete?
					<br />
					This can be undone at any point.
				</>
			);
		}

		action = (
			<Space>
				{estimateLink}
				<Button
					loading={actionLoading}
					onClick={markComplete}
					confirmTitle={confirmTitle}
					icon={(<FontAwesomeIcon icon={faFlagCheckered as IconProp} className={styles.icon} />)}
				>
					<b>Complete Contract</b>
				</Button>
			</Space>
		);
	}

	return action;
};

export default HeaderAction;
