import React, { useState } from 'react';
import {
	Card,
	Empty,
	Grid,
	Modal,
	Space,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-solid-svg-icons';
import { EstimateStatus } from '@shared/utils/constants';
import Button from '@client/components/Button';
import Table from '@client/components/Table/Table';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import CargoDetailsModal from '@client/screens/cargos/components/CargoDetailsModal/CargoDetailsModal';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import IntakeCalculatorModal from '@client/screens/cargos/components/IntakeCalculatorModal/IntakeCalculatorModal';
import getImportCargoColumns from '../helpers/importCargoColumns';
import { getCargoCardColumns } from '../helpers/cargoCardColumns';
import { useCargoProvider } from '../context/CargoContext';
import styles from './styles/CargosCard.module.css';

const CargosCard = () => {
	const {
		estimate,
		estimateLoading,
		cargos,
		allCargos,
		onUpdateCargo,
		onCreateNewCargoMutator,
		onImportCargoMutator,
		onDeleteCargoMutator,
		charterers,
		chartererOptions,
		portOptions,
		refreshEverything,
	} = useCargoProvider();

	const [showModal, setShowModal] = useState(false);
	const [editingCargoId, setEditingCargoId] = useState<number | null>(null);
	const [editingCargoIntakeId, setEditingCargoIntakeId] = useState<number | null>(null);

	const screens = Grid.useBreakpoint();

	const getModalWidth = () => {
		if (screens.xxl) {
			return '60%';
		}

		if (screens.xs) {
			return '100%';
		}

		return '75%';
	};

	const handleImportCargo = async (cargoId: number) => {
		onImportCargoMutator.mutate(cargoId, {
			onSuccess: () => {
				showSuccessNotification('Cargo added to estimate');
				setShowModal(false);
			},
			onError: (e: Error) => {
				showErrorNotification('Failed to add cargo to estimate', e as Error);
			},
		});
	};

	const columns = getImportCargoColumns({
		onImportCargo: handleImportCargo,
		cargosOnEstimate: estimate?.cargos ?? [],
		chartererOptions,
	});

	const cargoCardColumns = getCargoCardColumns({
		onDeleteCargoMutator,
		onUpdateCargo,
		setEditingCargoId,
		ports: portOptions,
		isTceLocked: estimate?.isTceLocked ?? false,
		xxl: screens.xxl,
		charterers,
	});

	return (
		<>
			<Card
				styles={{ body: { padding: 0 } }}
				size="small"
				extra={(
					<Space size="small">
						<Button
							disabled={(estimate && estimate?.cargos.length !== 1) ?? true}
							size="small"
							icon={(
								<FontAwesomeIcon
									className={styles.editIcon}
									icon={faCalculator}
								/>
							)}
							onClick={() => setEditingCargoIntakeId(estimate?.cargos[0].id!)}
						>
							Intake Calculator
						</Button>
						<Button onClick={() => setShowModal(true)} size="small">Import from cargo-book</Button>
						<Button
							onClick={() => onCreateNewCargoMutator.mutate()}
							disabled={estimate?.status === EstimateStatus.Fixed}
							disabledTooltip="Cannot add cargo to a fixed estimate"
							size="small"
							icon={(<PlusOutlined />)}
							type="primary"
						>
							New Cargo
						</Button>
					</Space>
				)}
			>
				{cargos.length === 0 ? (
					<div style={{ padding: 16 }}>
						<Empty
							description="Create or import a cargo to estimate the voyage"
							image={manOnBoat}
						/>
					</div>
				) : (
					<Table
						size="small"
						className={styles.table}
						pagination={false}
						columns={cargoCardColumns}
						dataSource={cargos}
						emptyText="No cargos"
						scroll={{ x: 'max-content' }}
					/>
				)}
			</Card>
			<Modal
				title="Add existing cargo"
				open={showModal}
				width={getModalWidth()}
				onCancel={() => setShowModal(false)}
				footer={false}
			>
				<Table
					size="small"
					loading={estimateLoading}
					pagination={false}
					columns={columns}
					dataSource={allCargos.filter((c) => !c.ghost)}
				/>
			</Modal>
			<CargoDetailsModal
				cargoId={editingCargoId}
				setEditingCargoId={setEditingCargoId}
				visible={editingCargoId != null}
				isTceLocked={false}
				refresh={refreshEverything}
			/>
			<IntakeCalculatorModal
				cargoId={editingCargoIntakeId}
				setEditingCargoIntakeId={setEditingCargoIntakeId}
				visible={editingCargoIntakeId != null}
				refresh={refreshEverything}
			/>
		</>
	);
};

export default CargosCard;
