import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
	ReactNode,
} from 'react';
import {
	notification,
	Form,
	Typography,
	Space,
} from 'antd';
import {
	EditOutlined,
	DeleteOutlined,
	CheckOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import {
	ColumnGroupType,
	ColumnType,
} from 'antd/lib/table/interface';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import type { RenderedCell } from 'rc-table/lib/interface';
import { NULL_STRING } from '@shared/utils/constants';
import { stringToValidNumericString } from '@shared/utils/string';
import Table, { TableProps } from '@client/components/Table/Table';
import showErrorNotification from '@client/utils/showErrorNotification';
import Button from '@client/components/Button';
import {
	EditableCell,
	EditableExtraProps,
} from '@client/components/EditableTable/EditableCell';
import styles from '../styles/EditableTable.module.css';
import AddButton from '../AddButton';

export type EditableColumn<Row extends object, KeyDataIndex extends keyof Row> = {
	editable?: boolean | ((row?: Row) => boolean);
	editingProps?: {
		defaultValue?: any;
	} & EditableExtraProps<Row>;
	render?: (
		value: any,
		record: Row,
		index: number,
		editingRow: Row[KeyDataIndex] | null | typeof NULL_STRING
	) => React.ReactNode | RenderedCell<Row>;
} & Omit<ColumnType<Row>, 'render'>;

export type EditableTableProps<Row extends object, KeyDataIndex extends keyof Row> = {
	keyDataIndex: KeyDataIndex;
	columns: Array<(
		| ColumnGroupType<Row>
		| EditableColumn<Row, KeyDataIndex>
	)>;
	dataSource: Array<Row>;
	onSave: (id: Row[KeyDataIndex], newRow: Row) => void;
	// Optionals
	onDelete?: (id: Row[KeyDataIndex], row: Row) => void;
	editingRow?: Row[KeyDataIndex] | null | typeof NULL_STRING;
	onEditingRowChange?: (id: Row[KeyDataIndex] | null, row?: Row) => void;
	editLabel?: string;
	rowClassName?: string;
	showActions?: boolean;
	enableDelete?: ((row: Row) => boolean) | boolean;
	enableEdit?: ((row: Row) => boolean) | boolean;
	disableEdit?: ((row: Row) => boolean);
	allowAddNew?: boolean;
	addNewText?: string | ReactNode;
	getCustomActions?: (row: Row) => ReactNode;
	iconButtons?: boolean;
	actionsTitle?: string;
	onAddNew?: (newRow: Row) => void;
	onRowClick?: (row: Row) => void;
	editingRowClassName?: string;
	form?: FormInstance<Row>;
	addButtonInHeader?: boolean;
	extraOnCancelEdit?: () => void;
	deleteMessage?: string | undefined;
	confirmTitle?: string | ReactNode;
	saveOnEnter?: boolean;
	allowEditRow?: (record: Row) => boolean;
} & Omit<TableProps<Row>, 'columns'>;

const EditableTable = <Row extends object, KeyDataIndex extends keyof Row>({
	keyDataIndex,
	columns,
	dataSource,
	editLabel = 'Edit',
	rowClassName = undefined,
	showActions = true,
	enableDelete = () => false,
	deleteMessage = undefined,
	enableEdit = () => true,
	disableEdit = () => false,
	allowAddNew = false,
	addNewText = '',
	getCustomActions = undefined,
	iconButtons = false,
	actionsTitle = 'Actions',
	saveOnEnter = true,
	confirmTitle = undefined,
	onSave,
	onAddNew = undefined,
	onDelete,
	onRowClick = undefined,
	editingRow: editingRowProp,
	onEditingRowChange,
	editingRowClassName = undefined,
	form: formProp = undefined,
	addButtonInHeader = true,
	extraOnCancelEdit,
	allowEditRow,
	...props
}: EditableTableProps<Row, KeyDataIndex>) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm(formProp);
	const [data, setData] = useState(dataSource);
	const [editingRowState, setEditingRow] = useState(null);
	const controlled = editingRowProp !== undefined;

	const editingRow = controlled ? editingRowProp : editingRowState;
	const updateEditingRow = controlled ? onEditingRowChange : setEditingRow;

	useEffect(() => {
		let newData = [...dataSource];

		// If we're currently creating a new item, keep the row!
		if (editingRow === NULL_STRING) {
			const newRow = data.find((row) => {
				const key = row[keyDataIndex] as Row[KeyDataIndex] | typeof NULL_STRING;

				return key === NULL_STRING;
			});

			if (newRow != null) {
				newData = [
					...dataSource,
					newRow,
				];
			}
		}

		setData(newData);

		// If we're editing a row that no longer exists, reset edit state
		if (
			editingRow != null &&
			updateEditingRow != null &&
			editingRow !== NULL_STRING &&
			!dataSource.map((r) => r[keyDataIndex]).includes(editingRow) &&
			updateEditingRow != null
		) {
			updateEditingRow(null);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSource]);

	const startEdit = useCallback(
		(record: Row, e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
			if (updateEditingRow == null) {
				return;
			}

			form.setFieldsValue({ ...record });

			const fn = updateEditingRow as typeof onEditingRowChange;

			if (fn != null) {
				fn(record[keyDataIndex], record);
			}

			e?.stopPropagation();
		}, [form, keyDataIndex, updateEditingRow],
	);

	const checkIsEditable = useCallback((
		column: EditableTableProps<Row, KeyDataIndex>['columns'][number],
		row?: Row,
	): column is EditableColumn<Row, KeyDataIndex> => {
		const columnAsEditable = column as EditableColumn<Row, KeyDataIndex>;

		if (typeof columnAsEditable.editable === 'function') {
			return columnAsEditable.editable(row);
		}

		return columnAsEditable.editable ?? false;
	}, []);

	const addNew = () => {
		// Add a new row with a null id and `null` in all the editable columns
		const fields = columns
			.filter((c) => checkIsEditable(c))
			.reduce<Row>((obj, column) => {
				const editableColumn = column as EditableColumn<Row, KeyDataIndex>;

				return ({
					...obj,
					[editableColumn.dataIndex as string]: editableColumn.editingProps?.defaultValue || null,
				});
			}, {} as Row);

		const newRow: Row = {
			[keyDataIndex]: NULL_STRING,
			...fields,
		};

		// @ts-ignore
		form.setFieldsValue(fields);

		setData([
			...data,
			newRow,
		]);

		// @ts-ignore
		updateEditingRow(newRow[keyDataIndex], newRow);
	};

	useEffect(() => {
		// If a new row has been added (not yet saved)
		// and the user cancels the editing operation,
		// the newly added row should be removed
		const rowWithNullStringKey = data.find((row) => {
			const key = row[keyDataIndex] as Row[KeyDataIndex] | typeof NULL_STRING;

			return key === NULL_STRING;
		});

		if (editingRow == null && rowWithNullStringKey != null) {
			const dataWithoutNullStringKey = data.filter((row) => {
				const key = row[keyDataIndex] as Row[KeyDataIndex] | typeof NULL_STRING;

				return key !== NULL_STRING;
			});
			setData(dataWithoutNullStringKey);
		}
	}, [keyDataIndex, data, editingRow]);

	const deleteRow = useCallback(async (record: Row) => {
		try {
			if (onDelete != null) {
				await onDelete(record[keyDataIndex], record);
				notification.success({
					message: 'Successfully deleted',
				});
			}
		} catch (error) {
			console.error('Error deleting table row.', error);
			showErrorNotification('Could not delete item', error as Error);
		}
	}, [keyDataIndex, onDelete]);

	const cancelEditing = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (typeof extraOnCancelEdit === 'function') {
			extraOnCancelEdit();
		}

		if (updateEditingRow != null) {
			updateEditingRow(null);
		}

		if (e && typeof e.stopPropagation === 'function') {
			e.stopPropagation();
		}
	}, [extraOnCancelEdit, updateEditingRow]);

	const saveEditing = useCallback(async (record: Row, e?: any) => {
		setLoading(true);

		e?.stopPropagation();

		try {
			const row = await form.validateFields();

			if (record[keyDataIndex] === NULL_STRING) {
				await onAddNew?.(row);
			} else {
				await onSave(record[keyDataIndex], row);
			}

			if (updateEditingRow != null) {
				updateEditingRow(null);
			}
		} catch (error) {
			console.error('Error saving table item.', error);
			showErrorNotification('Could not save item', error as Error);
		} finally {
			setLoading(false);
		}
	}, [form, keyDataIndex, onSave, onAddNew, updateEditingRow]);

	const mergedColumns = useMemo(() => {
		const merged: any = columns.map((col) => ({
			...col,
			// Add a 4th parameter, the currently edited row id, to column render
			render: col.render != null ?
				(text: any, record: Row, index: number): ((
					| React.ReactNode
					| RenderedCell<Row>
				)) => (
					col?.render?.(text, record, index, editingRow)
				) :
				col.render,
			onCell: (record: Row) => ({
				save: () => saveEditing(record),
				col,
				editing: (
					record[keyDataIndex] === editingRow &&
					checkIsEditable(col, record)
				),
				extra: {
					...((col as EditableColumn<Row, KeyDataIndex>).editingProps ?? {}),
					form,
					saveOnEnter,
					inputProps: {
						...((col as EditableColumn<Row, KeyDataIndex>).editingProps?.inputProps ?? {}),
						onKeyDown: (e: React.KeyboardEvent<HTMLElement> & {
							target: HTMLInputElement;
						}) => {
							if (e.key === 'Enter') {
								e.preventDefault();

								let value;

								switch ((col as EditableColumn<Row, KeyDataIndex>).editingProps?.type) {
									case 'currency':
									case 'number':
										value = Number(stringToValidNumericString(e.target.value));

										break;
									default:
										value = e.target.value;

										break;
								}

								form.setFieldValue(e.target.id, value);
							}
						},
					},
				},
			}),
		}));

		if (!showActions) {
			return merged;
		}

		merged.push({
			title: addButtonInHeader ? actionsTitle : (
				<AddButton
					onClick={addNew}
					disabled={!allowAddNew || editingRow != null}
					size="small"
				/>
			),
			dataIndex: keyDataIndex as string,
			key: keyDataIndex as string,
			align: 'center',
			// If icon buttons are used, always make room for the two of them next to eachother
			width: iconButtons ? 100 : undefined,
			render: (text: any, record: Row) => {
				const isEditable = typeof enableEdit === 'function' ? enableEdit(record) : enableEdit;
				const isDeletable = typeof enableDelete === 'function' ? enableDelete(record) : enableDelete;

				const isActionAllowed = typeof allowEditRow === 'function' ? allowEditRow(record) : isEditable;

				const isDisabled = disableEdit(record) || editingRow != null;

				const editTooltip = isDisabled ? 'Item cannot be edited' : undefined;
				const deleteTooltip = isDisabled ? 'Item cannot be deleted' : undefined;

				const actions = editingRow === record[keyDataIndex] ? (
					<>
						<Button
							type="link"
							onClick={(e) => saveEditing(record, e)}
							confirmTitle={confirmTitle}
							icon={iconButtons ? (
								<CheckOutlined
									className={styles.buttonIcon}
								/>
							) : null}
						>
							{!iconButtons && 'Save'}
						</Button>
						<Button
							type="link"
							disabled={loading}
							onClick={cancelEditing}
							icon={iconButtons ? (
								<CloseOutlined
									className={styles.buttonIcon}
								/>
							) : null}
							danger={iconButtons}
						>
							{!iconButtons && 'Cancel'}
						</Button>
					</>
				) : (
					<>
						{isEditable && isActionAllowed && (
							<Button
								type="link"
								disabled={isDisabled}
								onClick={(e) => startEdit(record, e)}
								disabledTooltip={editTooltip}
								icon={
									iconButtons ? (
										<EditOutlined className={styles.buttonIcon} />
									) : null
								}
							>
								{!iconButtons && editLabel}
							</Button>
						)}
						{isDeletable && isActionAllowed && (
							<Button
								type="link"
								danger={iconButtons}
								disabled={isDisabled}
								onClick={() => deleteRow(record)}
								confirmTitle={deleteMessage ?? 'Are you sure you want to delete this item?'}
								disabledTooltip={deleteTooltip}
								icon={
									iconButtons ? (
										<DeleteOutlined className={styles.buttonIcon} />
									) : null
								}
							>
								{!iconButtons && 'Delete'}
							</Button>
						)}
						{getCustomActions && getCustomActions(record)}
					</>
				);

				return (
					<div className={styles.actionsWrapper}>
						{actions}
					</div>
				);
			},
		});

		return merged;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		columns,
		showActions,
		actionsTitle,
		keyDataIndex,
		iconButtons,
		editingRow,
		checkIsEditable,
		form,
		saveEditing,
		enableEdit,
		enableDelete,
		loading,
		cancelEditing,
		editLabel,
		getCustomActions,
		startEdit,
		deleteRow,
		addButtonInHeader,
	]);

	// This messes with the "No data" cell
	// So only use it if we have at least 1 row shown
	const components = (data != null && data.length > 0) ? {
		body: { cell: EditableCell },
	} : undefined;

	return (
		<Form form={form} component={false}>
			{(allowAddNew && addButtonInHeader) && (
				<Typography.Title level={4}>
					<Space>
						{addNewText}
						<AddButton
							onClick={addNew}
							disabled={editingRow != null}
							size="small"
						/>
					</Space>

				</Typography.Title>
			)}
			<Table
				rowKey={keyDataIndex as string}
				columns={mergedColumns}
				dataSource={data}
				components={{
					...components,
					...props.components,
				}}
				pagination={{
					onChange: () => cancelEditing(),
				}}
				onRowClick={((editingRow == null && onRowClick) ?
					onRowClick :
					undefined
				)}
				rowClassName={(r) => classNames(
					rowClassName,
					{
						[styles.rowClickable]: (
							typeof onRowClick === 'function' &&
							editingRow == null
						),
						...(editingRowClassName != null ? {
							[editingRowClassName]: r[keyDataIndex] === editingRow,
						} : {}),
					},
				)}
				{...props}
			/>
		</Form>
	);
};

export default EditableTable;

