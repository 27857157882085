import React from 'react';
import {
	Col,
	Empty,
	Modal,
	Row,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import LoadingIndicator from '@client/components/LoadingIndicator';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import Table from '@client/components/Table/Table';
import FormPopover from '@client/components/FormPopover';
import CommencementLinking from '../CommencementLinking';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import PortCallModal from '../PortCallModal';
import Itinerary from './Itinerary';
import styles from './ItineraryTab.module.css';
import { useItinerary } from './ItineraryProvider';

const ItineraryTab = () => {
	const {
		expandedEntry,
		onSelectEntry,
		itineraryLoading,
		itinerary,
		showCommencementLinking,
		setShowCommencementLinking,
		addItineraryEntry,
		setItineraryShouldUpdate,
	} = useItinerary();

	const {
		portOptions,
	} = useVoyage();

	if (itinerary?.length === 0) {
		return (
			<>
				<Table
					className={styles.fakeTable}
					size="small"
					locale={{
						emptyText: (
							<Empty
								image={manOnBoat}
								className={styles.emptyMessage}
								description={(
									<Row gutter={[16, 16]} className={styles.paddingBottom}>
										<Col span={24}>
											The itinerary is currently empty.
											<br />
											To get started, add a the first port call.
										</Col>
										<Col span={24}>
											<FormPopover
												title="Add planned port call"
												popoverWidth={400}
												fields={[
													{
														label: 'Port',
														required: true,
														name: 'portId',
														type: 'select',
														options: portOptions,
														inputProps: {
															showSearch: true,
														},
													},
													{
														label: 'Est. Departure Date',
														required: true,
														name: 'estimatedDepartureDate',
														type: 'date',
														inputProps: {
															time: true,
														},
													},
												]}
												buttonText={(
													<Space>
														<FontAwesomeIcon icon={faPlus} />
														Add Planned Port Call
													</Space>
												)}
												disabled={false}
												onSubmit={addItineraryEntry}
											/>
										</Col>
									</Row>
								)}
							/>
						),

					}}
					columns={[
						{
							title: '',
						},
						{
							title: 'Port',
						},
						{
							title: 'Arrival date',
						},
						{
							title: 'Departure date',
						},
						{
							title: 'Cargo',
						},
						{
							title: 'Activities',
						},
						{
							title: '',
						},
					]}
				/>
				<Modal
					open={showCommencementLinking}
					footer={null}
					width={1200}
					onCancel={() => setShowCommencementLinking(false)}
				>
					<CommencementLinking />
				</Modal>
			</>
		);
	}

	return (
		<>
			<Row gutter={[4, 4]}>
				<Col span={24}>
					{itineraryLoading && (
						<div className={styles.itinLoadingOverlay}>
							<LoadingIndicator />
						</div>
					)}
					<Itinerary />
				</Col>
			</Row>
			<Modal
				className={styles.modal}
				width="80%"
				footer={null}
				open={expandedEntry != null}
				onCancel={() => onSelectEntry(undefined)}
				styles={{ body: { padding: 0 } }}
			>
				<PortCallModal />
			</Modal>
			<Modal
				open={showCommencementLinking}
				footer={null}
				width={1200}
				onCancel={() => {
					setItineraryShouldUpdate(true);
					setShowCommencementLinking(false);
				}}
			>
				<CommencementLinking />
			</Modal>
		</>
	);
};

export default ItineraryTab;
