import {
	CargoUnitSingularLabels,
	CargoUnitTypes,
	Currencies,
	FreightRateType,
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import TemplateItem from '@shared/TemplateItem';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import { formatQuantity } from '@shared/utils/string';
import calculateTotalFreight from '@shared/utils/calculateTotalFreight';
import type { HIIFreightType } from '@api/models/hii-freight';
import type HireInvoiceItemModel from '@api/models/hire-invoice-item';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

class HIIFreight extends HireInvoiceItem {
	static get itemType() {
		return 'freight';
	}

	cargoId: number;
	quantity: number;
	cargoType: string;
	freightRate: number;
	freightType: Values<typeof FreightRateType>;
	charterer: number | null;
	note: string;
	unit: Values<typeof CargoUnitTypes>;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	percentage: number;
	maxPercentage: number;
	addressCommission: number;
	brokerCommission: number;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			cargoId: number;
			quantity: number;
			cargoType: string;
			freightRate: number;
			freightType: Values<typeof FreightRateType>;
			charterer: number | null;
			note: string;
			unit: Values<typeof CargoUnitTypes>;
			currency: Values<typeof Currencies>;
			exchangeRate: number;
			percentage: number;
			maxPercentage: number;
			addressCommission: number;
			brokerCommission: number;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });
		this.cargoId = params.cargoId;
		this.quantity = params.quantity;
		this.cargoType = params.cargoType;
		this.freightRate = params.freightRate;
		this.freightType = params.freightType;
		this.charterer = params.charterer;
		this.note = params.note;
		this.unit = params.unit;
		this.currency = params.currency;
		this.exchangeRate = params.exchangeRate;
		this.addressCommission = params.addressCommission;
		this.brokerCommission = params.brokerCommission;

		this.percentage = params.percentage;
		this.maxPercentage = params.maxPercentage ?? params.percentage;

		this._showTotal = true;
		this.addManually = true;

		this.itemTypeLabel = 'Freight';
		this.pnlGroup = PnlGroups.FREIGHT;
		this.templateSection = TemplateItem.Sections.FREIGHT;

		this._fields = {
			quantity: {
				type: HIIFieldTypes.NUMBER,
				label: 'Quantity',
			},
			freightRate: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Freight Rate',
			},
		};
	}

	_calculateTotal(_invoiceItems?: HireInvoiceItem[], convertCurrency?: boolean) {
		const total = calculateTotalFreight(this, this.fixtureCurrency, convertCurrency);

		return total * (this.percentage / 100);
	}

	getDescription() {
		return `${formatQuantity((this.quantity), this.unit)} ${this.cargoType} at 
		${formatCurrency(this.freightRate ?? 0, this.currency)}/${this.unit}`;
	}

	static async _getClassParams(model: HIIFreightType, _parentModel: typeof HireInvoiceItemModel) {
		const cargo = await model.getCargo();

		return {
			cargoId: cargo.id,
			cargoType: cargo.type,
			freightRate: model.freightRate,
			freightType: cargo.freightType,
			charterer: cargo.charterer,
			unit: cargo.unit,
			currency: cargo.currency,
			exchangeRate: cargo.exchangeRate,
			quantity: model.quantity,
			percentage: model.percentage,
		};
	}

	async saveModel(model: HIIFreightType) {
		await super.saveModel(model);

		model.cargoId = this.cargoId;
		model.percentage = this.percentage;
		model.quantity = this.quantity;
		model.freightRate = this.freightRate;

		await model.save();
	}

	_getTemplateItemParams() {
		const freightRateWithUnit = (
			this.freightType === FreightRateType.PER_UNIT ?
				`${formatCurrency(this.freightRate, this.currency)} / ${CargoUnitSingularLabels[this.unit]}` :
				`${formatCurrency(this.freightRate, this.currency)}`
		);

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					`${formatQuantity(
						this.quantity, this.unit,
					)} ${this.cargoType} @ ${freightRateWithUnit} @ ${this.percentage}%`,
				],
				children: [
					[this.note],
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					`${this.cargoType} (${this.percentage}%)`,
					`${this.quantity}`,
					this.unit,
					`${this.freightRate}`,
				],
				children: this.note != null ? [
					[{
						content: this.note,
						small: true,
						italic: true,
					}],
				] : undefined,
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIFreight);

export default HIIFreight;
