import React, { SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Badge,
	Empty,
	Tabs,
} from 'antd';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { UnnamedBankAccountName } from '@shared/utils/constants';
import type { BankAccountProps } from '@api/models/bank-account';
import type { UpdateBankAccountRequest } from '@api/features/bank-accounts/updateBankAccount';
import type { BankAccountWithCurrencyFlag } from '@api/features/vessels/getVesselDetails';
import { getBankingItems } from '@client/screens/fleet/VesselDetailsScreen/helpers/descriptionItemsHelper';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import Button from '@client/components/Button';
import styles from './styles/BankAccountTabs.module.css';

type Props = {
	bankAccounts: BankAccountWithCurrencyFlag[];
	addBankAccount: () => Promise<BankAccountProps>;
	saveBankAccount: (account: BankAccountProps, changes: UpdateBankAccountRequest['attributes']) => Promise<void>;
	deleteBankAccount: (id: number) => void;
	allowCreate: boolean;
	setActiveBankAccountTab: React.Dispatch<SetStateAction<number | undefined>>;
	activeBankAccountTab: number | undefined;
	vesselOwner?: boolean;
}

export const BankAccountTabs = ({
	bankAccounts,
	addBankAccount,
	saveBankAccount,
	deleteBankAccount,
	allowCreate,
	vesselOwner = false,
	setActiveBankAccountTab,
	activeBankAccountTab,
}: Props) => {
	const onDeleteBankAccount = async (bankAccountId: number) => {
		await deleteBankAccount(bankAccountId);

		const newActiveId = bankAccounts.find((a) => a.id !== bankAccountId)?.id;
		setActiveBankAccountTab(newActiveId);
	};

	const onAddBankAccount = async () => {
		const newAccount = await addBankAccount();
		setActiveBankAccountTab(newAccount.id);
	};

	const addBankAccountButton = (
		<Button
			type="primary"
			onClick={async () => onAddBankAccount()}
		>
			Create Bank Account
		</Button>
	);

	return (
		<Tabs
			tabBarExtraContent={allowCreate ? addBankAccountButton : null}
			onChange={(activeKey) => setActiveBankAccountTab(Number(activeKey))}
			activeKey={activeBankAccountTab?.toString()}
			items={
				bankAccounts.length > 0 ?
					bankAccounts.map((account) => ({
						key: account.id.toString(),
						closable: false,
						label: (
							<>
								<span>{account.name || UnnamedBankAccountName}</span>
								{bankAccounts.length > 1 && (
									<Button
										type="link"
										className={styles.tabDeleteButton}
										danger
										onClick={() => onDeleteBankAccount(account.id)}
										confirmTitle="Are you sure you want to delete this bank account?"
										icon={(<FontAwesomeIcon icon={faTimes} />)}
									/>
								)}
							</>
						),
						children: (
							<EditableDetails
								key={account.id.toString()}
								className={styles.bankAccount}
								// @ts-ignore
								items={getBankingItems(account, account.allowCurrencyChange, vesselOwner)}
								onSave={(changes) => saveBankAccount(account, changes)}
								title={(
									<div className={styles.titleContainer}>
										Details
										<div className={styles.badgeContainer}>
											<Badge
												dot
												color="red"
												className={styles.bankAccountBadge}
											/>
											<p>Does not appear on invoices</p>
										</div>
									</div>
								)}
							/>
						),
					})) :
					[
						{
							key: 'empty',
							label: null,
							children: (
								<div className={styles.emptyContainer}>
									<Empty description="No bank accounts found" />
								</div>
							),
						},
					]
			}
		/>
	);
};
