import { QueryClient } from 'react-query';
import {
	DashboardPeriods,
	DashboardTypes,
	DepartmentKey,
	Departments,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { deleteCacheEntry } from '@client/lib/api';
import TcOutDashboard from '../dashboards/TcOutDashboard';
import ReceivablesAgingDashboard from '../dashboards/ReceivablesAgingDashboard';
import OffHireDashboard from '../dashboards/OffHireDashboard';
import HomeDashboard from '../dashboards/HomeDashboard';
import OutstandingsDashboard from '../dashboards/OutstandingsDashboard';
import FleetTimelineDashboard from '../dashboards/FleetTimelineDashboard';
import OpenPositionsDashboard from '../dashboards/OperationsDashboard';
import { Dashboard } from './dashboardInfo';

const yearlyQuarterly = [DashboardPeriods.YEAR, DashboardPeriods.QUARTER];

const getDashboards = (queryClient: QueryClient) => {
	const refresh = async (
		dashboard: Values<typeof DashboardTypes>,
		period: Values<typeof DashboardPeriods>,
	) => {
		let url = '';

		switch (dashboard) {
			case DashboardTypes.HOME:
				url = '/api/vessels/activity';
				break;

			case DashboardTypes.TC_OUT:
				url = `/api/statistics/analytics?period=${period}`;
				break;

			case DashboardTypes.OUTSTANDINGS:
				url = '/api/statistics/outstandings';
				break;

			case DashboardTypes.RECEIVABLES:
				url = `/api/statistics/receivables-aging?period=${period}`;
				break;

			case DashboardTypes.PAYABLES:
				url = '/api/statistics/payables';
				break;

			case DashboardTypes.OFF_HIRE:
				url = `/api/statistics/off-hire?period=${period}`;
				break;

			case DashboardTypes.TC_IN:
				url = '/api/statistics/full-fleet-timeline-data';
				break;

			case DashboardTypes.OPEN_POSITIONS:
				url = '/api/statistics/open-positions';
				break;

			default:
				url = '';
		}

		if (url === '') {
			return;
		}

		await deleteCacheEntry(url);
		await queryClient.invalidateQueries(dashboard);
	};

	return [
		{
			key: DashboardTypes.HOME,
			label: 'Home',
			periodOptions: null,
			component: HomeDashboard,
			refresh,
		},
		{
			key: DashboardTypes.OPEN_POSITIONS,
			label: 'Open Positions',
			periodOptions: null,
			component: OpenPositionsDashboard,
			refresh,
		},
		{
			key: DashboardTypes.OFF_HIRE,
			label: 'Off-hire',
			periodOptions: yearlyQuarterly,
			component: OffHireDashboard,
			refresh,
		},
		{
			key: DashboardTypes.RECEIVABLES,
			label: 'Receivables',
			periodOptions: [DashboardPeriods.SIXTY_DAYS, DashboardPeriods.HUNDRED_TWENTY_DAYS],
			component: ReceivablesAgingDashboard,
			refresh,
		},
		{
			key: DashboardTypes.OUTSTANDINGS,
			label: 'Outstandings',
			periodOptions: null,
			component: OutstandingsDashboard,
			refresh,
		},
		/*
		DISABLED
		{
			key: DashboardTypes.PAYABLES,
			label: 'Payables Status',
			periodOptions: null,
			component: PayablesDashboard,
			refresh,
		},
		*/
		{
			key: DashboardTypes.TC_OUT,
			label: 'TC Out',
			periodOptions: yearlyQuarterly,
			component: TcOutDashboard,
			refresh,
		},
		{
			key: DashboardTypes.TC_IN,
			label: 'Timeline',
			periodOptions: null,
			component: FleetTimelineDashboard,
			refresh,
		},
	] as const;
};

const departmentToDashboardTypesMap = {
	[Departments.CHARTERING.key]: null,
	[Departments.OPERATIONS.key]: null,
	[Departments.FINANCE.key]: null,
	[Departments.MANAGEMENT.key]: null,
	[Departments.ADMIN.key]: null,
};

const defaultDashboardsTypes = [
	DashboardTypes.HOME,
	DashboardTypes.OPEN_POSITIONS,
	DashboardTypes.TC_OUT,
	DashboardTypes.OUTSTANDINGS,
	DashboardTypes.PAYABLES,
	DashboardTypes.RECEIVABLES,
	DashboardTypes.FLEET_PNL,
	DashboardTypes.OFF_HIRE,
	DashboardTypes.TC_IN,
];

const getDepartmentDashboards = (
	activeDepartment: DepartmentKey,
	queryClient: QueryClient,
): Dashboard<Values<typeof DashboardPeriods>>[] => {
	const dashboardTypes = (
		departmentToDashboardTypesMap[activeDepartment] ||
		defaultDashboardsTypes
	);

	const dashboards = getDashboards(queryClient);

	const departmentDashboards = (dashboardTypes
		.map((type) => dashboards.find((d) => d.key === type))
		.filter((d) => d != null)
	) as unknown as Dashboard<Values<typeof DashboardPeriods>>[];

	return departmentDashboards;
};

export default getDepartmentDashboards;
