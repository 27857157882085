import React, {
	Key,
	useMemo,
} from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
	CheckOutlined,
	CloseOutlined,
	PaperClipOutlined,
} from '@ant-design/icons';
import { Grid } from 'antd';
import { toMoment } from '@shared/utils/date';
import { standardSort } from '@shared/utils/standardSort';
import { formatCurrency } from '@shared/utils/currency';
import {
	ExpenseStatus,
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import type VoyageExpensePayment from '@api/models/voyage-expense-payment';
import type { GetVoyageExpensesResponse } from '@api/features/invoices/getVoyageExpenses';
import StateTag from '@client/components/StateTag';
import { stringSetToSortedList } from '@client/utils/stringSetToSortedList';
import VendorExpensePaymentStateTag, { getExpenseStatus } from '@client/components/VendorExpensePaymentStateTag';
import styles from '../ExpensesScreen/components/styles/ExpenseScreen.module.css';

const useGetVendorExpensesTableColumns = (allExpenses: GetVoyageExpensesResponse | undefined) => {
	const screens = Grid.useBreakpoint();

	const calculatePaidAmount = (
		voyageExpensePayments: VoyageExpensePayment[],
		record: TransformedExpenses,
	) => {
		if (voyageExpensePayments.length > 0 && Array.isArray(voyageExpensePayments)) {
			const totalAmount = voyageExpensePayments.reduce((sum, payment) => {
				const amount = Number(payment.amount);

				return sum + amount;
			}, 0);

			const formattedTotal = formatCurrency(
				totalAmount,
				record.currency,
				{ forceDecimals: true },
			);

			return formattedTotal;
		}

		return '-';
	};

	const calculateOutstandingAmount = (
		voyageExpensePayments: VoyageExpensePayment[],
		record: TransformedExpenses,
	) => {
		if (voyageExpensePayments.length > 0 && Array.isArray(voyageExpensePayments)) {
			const totalAmount = voyageExpensePayments.reduce((sum, payment) => {
				const amount = Number(payment.amount);

				return sum + amount;
			}, 0);

			const formattedTotal = formatCurrency(
				record.amount - totalAmount,
				record.currency,
				{ forceDecimals: true },
			);

			return formattedTotal;
		}

		const total = formatCurrency(
			record.amount,
			record.currency,
			{ forceDecimals: true },
		);

		return record.amount !== 0 ? total : '-';
	};

	const calculateTotalPayment = (voyageExpensePayments: VoyageExpensePayment[]) => {
		if (!Array.isArray(voyageExpensePayments)) {
			return 0;
		}

		return voyageExpensePayments.reduce((sum, payment) => {
			const amount = Number(payment.amount);

			return sum + (Number.isNaN(amount) ? 0 : amount);
		}, 0);
	};

	const filterMaps = useMemo(() => {
		const contracts = new Set<string>();
		const suppliers = new Set<string>();
		const rebillableStatus = new Set<string>();

		allExpenses?.forEach((expense) => {
			if (expense.voyageIdentifier) {
				contracts.add(expense.voyageIdentifier);
			}

			if (expense.supplierName) {
				suppliers.add(expense.supplierName);
			}

			if (expense.receivable?.state) {
				rebillableStatus.add(expense.receivable.state);
			}
		});

		return {
			contracts: stringSetToSortedList<string>(contracts),
			suppliers: stringSetToSortedList<string>(suppliers),
			rebillableStatus: stringSetToSortedList<string>(rebillableStatus),
		};
	}, [allExpenses]);

	const vesselColumns: ColumnsType<TransformedExpenses> = [
		{
			title: 'Description',
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			defaultSortOrder: 'ascend',
			render: (v) => {
				if (!v) {
					return '-';
				}

				return	(
					// eslint-disable-next-line react/forbid-component-props
					<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
						{v}
					</Paragraph>
				);
			},
		},
		{
			title: 'Supplier',
			dataIndex: 'supplierName',
			key: 'supplierName',
			render: (v) => {
				if (!v) {
					return '-';
				}

				return (
					// eslint-disable-next-line react/forbid-component-props
					<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
						{v}
					</Paragraph>
				);
			},
			filters: filterMaps.suppliers.map((supplier) => ({
				text: supplier,
				value: supplier,
			})),
			filterMultiple: true,
			onFilter: (value, record) => record.supplierName === value,
		},
		{
			title: 'Vessel',
			dataIndex: 'vesselName',
			key: 'vessel',
			render: (v) => (
				// eslint-disable-next-line react/forbid-component-props
				<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
					{v}
				</Paragraph>
			),
		},
		{
			width: 95,
			title: 'Contract',
			dataIndex: 'voyageIdentifier',
			key: 'voyageIdentifier',
			filters: filterMaps.contracts.map((contract) => ({
				text: contract,
				value: contract,
			})),
			filterMultiple: true,
			onFilter: (value, record) => record.voyageIdentifier === value,
		},
		...(!screens.xxl ? [] : [
			{
				title: 'Contract type',
				dataIndex: 'fixtureType',
				key: 'fixtureType',
				render: (v: Values<typeof FixtureTypes>) => (
					// eslint-disable-next-line react/forbid-component-props
					<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
						{FixtureTypeLabels[v]}
					</Paragraph>
				),
			},
		]),
		{
			width: 80,
			title: 'Id',
			dataIndex: 'customInvoiceId',
			key: 'customInvoiceId',
			render: (v) => (v ? (
				// eslint-disable-next-line react/forbid-component-props
				<Paragraph style={{ margin: 0, padding: 0 }} ellipsis={{ tooltip: v }}>
					<b>
						{v}
					</b>
				</Paragraph>
			) :
				(<b>-</b>)),
		},
		...(!screens.xxl ? [] : [
			{
				width: 125,
				title: 'Invoice Date',
				dataIndex: 'invoiceDate',
				key: 'invoiceDate',
				render: (v: string) => (
					v ?? '-'
				),
				sorter: (a: TransformedExpenses, b: TransformedExpenses) => {
					if (a.invoiceDate == null) {
						return 1;
					}

					if (b.invoiceDate == null) {
						return -1;
					}

					return toMoment(a.invoiceDate).unix() - toMoment(b.invoiceDate).unix();
				},
			},
		]),
		{
			title: 'Due date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (v) => {
				if (!v) {
					return '-';
				}

				return (
					// eslint-disable-next-line react/forbid-component-props
					<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
						{v}
					</Paragraph>
				);
			},
		},
		{
			align: 'right',
			title: 'Total',
			dataIndex: 'amount',
			key: 'amount',
			render: (v, record) => (v ? (
				formatCurrency(v, record.currency, { forceDecimals: true })
			) : '-'),
			sorter: standardSort('amount'),
		},
		{
			align: 'right',
			title: 'Paid',
			dataIndex: 'VoyageExpensePayments',
			key: 'VoyageExpensePayments',
			render: (voyageExpensePayments, record) => {
				const paidAmount = calculatePaidAmount(voyageExpensePayments, record);

				return paidAmount;
			},
			sorter: (a, b) => {
				const totalA = a.VoyageExpensePayments?.reduce(
					(sum, payment) => sum + (Number(payment.amount) || 0), 0,
				) || 0;
				const totalB = b.VoyageExpensePayments?.reduce(
					(sum, payment) => sum + (Number(payment.amount) || 0), 0,
				) || 0;

				return totalA - totalB;
			},
		},
		{
			align: 'right',
			title: 'Outstanding',
			dataIndex: 'VoyageExpensePayments',
			key: 'outstanding',
			render: (voyageExpensePayments, record) => {
				const totalOutstanding = calculateOutstandingAmount(voyageExpensePayments, record);

				return totalOutstanding;
			},
			sorter: (a, b) => {
				const totalA = Array.isArray(a.VoyageExpensePayments) ?
					a.VoyageExpensePayments.reduce((sum, payment) => sum + (Number(payment.amount) || 0), 0) :
					0;
				const totalB = Array.isArray(b.VoyageExpensePayments) ?
					b.VoyageExpensePayments.reduce((sum, payment) => sum + (Number(payment.amount) || 0), 0) :
					0;

				const outstandingA = a.amount - totalA;
				const outstandingB = b.amount - totalB;

				return outstandingA - outstandingB;
			},
		},
		...(!screens.xxl ? [] : [
			{
				title: 'Rebillable status',
				dataIndex: ['receivable', 'state'],
				key: 'state',
				render: (state: string) => (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<StateTag
							className={styles.statusTag}
							state={state ?? 'Not created'}
						/>
					</div>
				),
				filters: filterMaps.rebillableStatus.map((rebillableStatus) => ({
					text: <StateTag
						className={styles.filterStatusTag}
						state={rebillableStatus}
					/>,
					value: rebillableStatus,
				})),
				filterMultiple: true,
				onFilter: (
					value: boolean | Key,
					record: TransformedExpenses,
				) => record.receivable?.state === value,
			},
		]),
		...(!screens.xxl ? [] : [
			{
				width: 95,
				title: 'Status',
				dataIndex: 'VoyageExpensePayments',
				key: 'status',
				render: (VoyageExpensePayments: Array<{ amount: number }>, record: TransformedExpenses) => (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<VendorExpensePaymentStateTag
							amount={record.amount}
							dueDate={record.dueDate}
							voyageExpensePayments={VoyageExpensePayments}
							className={styles.statusTag}
						/>
					</div>
				),
				filters: Object.values(ExpenseStatus).map((status: ExpenseStatus) => ({
					text: (
						<VendorExpensePaymentStateTag
							state={status}
							className={styles.filterStatusTag}
						/>
					),
					value: status,
				})),
				filterMultiple: true,
				onFilter: (value: boolean | Key, record: TransformedExpenses) => {
					const totalPayment = calculateTotalPayment(record.VoyageExpensePayments);
					const status = getExpenseStatus(totalPayment, record.amount, record.dueDate);

					return status === value;
				},
			},
		]),

		{
			width: 35,
			align: 'right',
			title: '',
			dataIndex: 'attachments',
			key: 'attachments',
			render: (attachment) => {
				return (attachment.length > 0 ? (<PaperClipOutlined />) : '-');
			},
			filters: [
				{
					text: (
						<span>
							{/* eslint-disable-next-line react/forbid-component-props */}
							<CheckOutlined style={{ color: 'green', marginRight: 4 }} />
							Has Attachments
						</span>
					),
					value: true,
				},
				{
					text: (
						<span>
							{/* eslint-disable-next-line react/forbid-component-props */}
							<CloseOutlined style={{ color: 'red', marginRight: 4 }} />
							No Attachments
						</span>
					),
					value: false,
				},
			],
			filterMultiple: true,
			onFilter: (value, record): boolean => {
				const boolValue = value as boolean;
				const hasAttachments = Array.isArray(record.attachments) && record.attachments.length > 0;

				return boolValue ? hasAttachments : !hasAttachments;
			},

		},
	];

	return vesselColumns;
};

export default useGetVendorExpensesTableColumns;
