import React from 'react';
import classNames from 'classnames';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import styles from './styles/DateBadge.module.css';

const DateBadge = ({ entry, type }: { entry: ItineraryPortCallDto; type: string }) => {
	let letter = 'Est';
	let classToUse = styles.estimated;

	let dateType = 'estimated';

	if (type === 'arrival' && entry.arrivalDate != null) {
		dateType = 'actual';
	}

	if (type === 'departure' && entry.departureDate != null) {
		dateType = 'actual';
	}

	if (type === 'arrival' && dateType === 'estimated' && entry.mastersEta != null) {
		dateType = 'estimatedMastersEta';
	}

	switch (dateType) {
		case 'actual':
			letter = 'Act';
			classToUse = styles.actual;
			break;
		case 'estimatedMastersEta':
			letter = 'M. ETA';
			classToUse = styles.master;
			break;
		default:
			letter = 'Est';
			classToUse = styles.estimated;
			break;
	}

	return (
		<div className={classNames(styles.badge, classToUse)}>
			{letter}
		</div>
	);
};

export default DateBadge;
