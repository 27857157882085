import React, {
	useState,
	useMemo,
} from 'react';
import { Typography } from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table/InternalTable';
import { SortOrder } from 'antd/es/table/interface';
import { Moment } from 'moment';
import {
	Currencies,
	DATE,
	VoyagePaymentTypes,
} from '@shared/utils/constants';
import { calculateTotal } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { UpdateVoyagePaymentRequest } from '@api/features/voyages/updateVoyagePayment';
import type { VoyagePaymentProps } from '@api/models/voyage-payment';
import type { VoyagePaymentPartProps } from '@api/models/voyage-payment-part';
import type Cargo from '@api/models/cargo';
import { formatDate } from '@client/utils/formatDate';
import AddButton from '@client/components/AddButton';
import Table from '@client/components/Table/Table';
import showErrorNotification from '@client/utils/showErrorNotification';
import FormPopover from '@client/components/FormPopover';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	deleteVoyagePayment,
	createVoyagePayment,
	updateVoyagePayment,
} from '@client/lib/api';
import Button from '@client/components/Button';
import { useAuth } from '@client/lib/auth';
import TooltipIcon from '@client/components/TooltipIcon';
import Card from '@client/components/Card/Card';
import { Field } from '@client/components/SimpleForm';
import Select from '@client/components/Select';
import validatePaymentInvoiceInput from '../helpers/validatePaymentInvoiceInput';
import styles from './styles/PaymentsTable.module.css';
import PaymentInvoiceInput from './PaymentInvoiceInput';
import PaymentPartRow from './PaymentPartRow';
import { useVoyage } from './VoyageProvider/VoyageProvider';

type Payments = GetVoyageDetailsResponse['hireInvoicePayments'][number] | GetVoyageDetailsResponse['euaInvoicePayments'][number];

type Props = {
	isEua?: boolean;
	refresh?: () => void;
}

const PaymentsTable = ({
	isEua,
	refresh,
}: Props) => {
	const {
		voyageDetails,
		voyageId,
		refreshDetails,
	} = useVoyage();

	const invoices = isEua ? voyageDetails.euaInvoices : voyageDetails.hireInvoices;

	const [formAmount, setFormAmount] = useState<number | null>(null);
	const { userInfo } = useAuth();

	const payments = useMemo(() => {
		if (isEua) {
			return voyageDetails.euaInvoicePayments ?? [];
		}

		return voyageDetails.hireInvoicePayments ?? [];
	}, [voyageDetails, isEua]);

	const currencySource = useMemo(() => (voyageDetails as any)?.estimate ?? voyageDetails?.fixture,
		[voyageDetails]);

	const uniqueCurrencies = useMemo(() => {
		if (!currencySource) {
			return [];
		}

		return Array.from(
			new Set([
				currencySource.currency,
				...(currencySource.cargos?.map((cargo: Cargo) => cargo.currency) ?? []),
			]),
		).filter(Boolean);
	}, [currencySource]);

	const deletePayment = async (id: number) => {
		try {
			await deleteVoyagePayment(voyageId, id);
			refreshDetails();
			refresh?.();

			showSuccessNotification(
				'Payment deleted',
			);
		} catch (e) {
			showErrorNotification('Could not delete payment', e as Error);
		}
	};

	const editPayment = async (id: number, attributes: UpdateVoyagePaymentRequest['attributes']) => {
		try {
			await updateVoyagePayment(id, voyageId, attributes);
			refreshDetails();
			refresh?.();

			showSuccessNotification(
				'Payment saved',
			);
		} catch (e) {
			showErrorNotification('Could not edit payment', e as Error);
		}
	};

	const createPayment = async (values: {
		paymentDate: Moment;
		amount: number;
		parts: VoyagePaymentPartProps[];
		bankCharge?: number;
	}) => {
		const {
			paymentDate,
			amount: totalAmount,
			parts,
			bankCharge,
		} = values;

		await createVoyagePayment(
			voyageId,
			paymentDate,
			totalAmount,
			parts,
			bankCharge ?? 0,
			isEua ? VoyagePaymentTypes.EUA : VoyagePaymentTypes.INVOICE,
			currency,
		);
		refreshDetails();
		refresh?.();
	};

	const fixtureCurrency = useMemo(() => {
		if (isEua) {
			return Currencies.EUA;
		}

		return voyageDetails.bankAccount == null ?
			userInfo.baseCurrency :
			voyageDetails.bankAccount.currency;
	}, [isEua, userInfo.baseCurrency, voyageDetails.bankAccount]);

	const [currency, setCurrency] = useState <Values<typeof Currencies>>(fixtureCurrency);

	const columns: ColumnsType<Payments> = [
		{
			title: 'Date',
			dataIndex: 'paymentDate',
			sorter: standardSort('paymentDate'),
			defaultSortOrder: 'desc' as SortOrder,
			render: (date) => formatDate(date, DATE),
		},
		{
			align: 'right',
			title: 'Amount',
			dataIndex: 'amount',
			render: (v) => formatCurrency(v, currency),
		},
		{
			title: '',
			align: 'right',
			dataIndex: '',
			className: styles.editButton,
			render: (values, payment) => (
				<FormPopover<VoyagePaymentProps & { parts: VoyagePaymentPartProps[] }>
					title="Edit payment"
					onSubmit={(v) => editPayment(
						payment.id,
						v,
					)}
					fields={newPaymentFields}
					initialValues={values}
					placement="leftTop"
					onOpenChange={(visible) => setFormAmount(visible ? values.amount : null)}
					popoverWidth={500}
					destroyTooltipOnHide
				>
					<Button
						className={styles.editButton}
						icon={(<EditOutlined />)}
						type="link"
					/>
				</FormPopover>
			),

		},
		{
			title: '',
			align: 'left',
			dataIndex: '',
			render: (_, payment) => (
				<Button
					confirmTitle="Are you sure you want to delete this payment?"
					onClick={() => deletePayment(payment.id)}
					className={styles.deleteButton}
					icon={(<DeleteOutlined />)}
					danger
					type="link"
				/>
			),
		},
	];

	const newPaymentFields = useMemo(() => (
		[
			{
				name: 'paymentDate' as keyof VoyagePaymentProps,
				label: 'Payment date',
				type: 'date',
				required: true,
			},
			{
				name: 'amount' as keyof VoyagePaymentProps,
				label: 'Amount',
				type: 'currency',
				required: true,
				inputProps: {
					allowNegative: true,
					onChange: (v: number) => setFormAmount(v),
					currency,
				},
			},
			{
				name: 'currency' as keyof VoyagePaymentProps,
				label: 'Currency',
				renderInput: () => (
					<Select
						value={currency}
						defaultValue={currency}
						options={uniqueCurrencies.map((c) => ({ label: c, value: c }))}
						onChange={(newValue) => setCurrency(newValue as Values<typeof Currencies>)}
					/>
				),
			},
			{
				name: 'parts' as keyof VoyagePaymentProps,
				label: 'Invoice(s)',
				type: 'custom',
				required: false,
				renderInput: () => (
					<PaymentInvoiceInput
						hireInvoices={invoices ?? []}
						totalAmount={formAmount}
						currency={currency}
					/>
				),
				rules: [{
					validator: async (_rule, value) => validatePaymentInvoiceInput(
						formAmount,
						value,
						currency,
					),
				}],
			},
			...(isEua ? [] : [
				{
					name: 'bankCharge' as keyof VoyagePaymentProps,
					label: (
						<>
							Bank Charge
							<TooltipIcon>
								If a bank charge was subtracted from the payment, add it here.
								<br />
								Bank charges will be shown on the cumulative hire statement.
							</TooltipIcon>
						</>
					),
					required: false,
					type: 'currency',
					inputProps: {
						currency,
					},
				},
			]),
		] as Field<VoyagePaymentProps>[]
	), [currency, formAmount, invoices, isEua, uniqueCurrencies]);

	return (
		<Card
			slim
			className={styles.paginationPadding}
			extra={(
				<FormPopover<VoyagePaymentProps & { parts: VoyagePaymentPartProps[] }>
					title="Add payment"
					onSubmit={createPayment}
					fields={newPaymentFields}
					placement="leftTop"
					onOpenChange={() => setFormAmount(null)}
					data-tour="createPaymentForm"
					popoverWidth={500}
					destroyTooltipOnHide
				>
					<AddButton
						data-tour="createPaymentButton"
					>
						New Payment
					</AddButton>
				</FormPopover>
			)}
			title="Payments"
		>
			<Table
				columns={columns}
				pagination={false}
				dataSource={payments}
				expandable={{
					expandedRowRender: (row) => {
						const partsTotal = calculateTotal(row.parts, (part) => part.amount);

						return (
							<div className={styles.expandedRow}>
								{row.bankCharge != null && row.bankCharge > 0 && (
									<>
										<strong>Bank charge:</strong>
										{' '}
										{formatCurrency(row.bankCharge, currency)}
										<hr />
									</>
								)}
								<Typography.Title level={4}>Payment was attributed to</Typography.Title>
								<div className={styles.partsWrapper}>
									{row.parts.map((part) => (
										<PaymentPartRow
											key={part.hireInvoiceIdentifier}
											identifier={part.hireInvoiceIdentifier}
											amount={part.amount}
											currency={currency}
										/>
									))}
									{row.amount > partsTotal && (
										<PaymentPartRow
											identifier="No invoice"
											amount={row.amount - partsTotal}
											currency={currency}
										/>
									)}
								</div>
							</div>
						);
					},
				}}
				rowKey="id"
				size="small"
			/>
		</Card>
	);
};

export default PaymentsTable;
