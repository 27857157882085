import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/pro-light-svg-icons';
import {
	Space,
	Tag,
} from 'antd';
import {
	currencySymbols,
	DATE,
	VesselOwnershipTypeLabels,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { GetVesselsResponse } from '@api/features/vessels/getVessels';
import Select from '@client/components/Select';
import getVesselOptions from '@client/utils/getVesselOptions';
import styles from '../components/styles/EstimateConfigurationCard.module.css';
import TemporaryNumberInput from '../components/TemporaryNumberInput';
import {
	OnRefreshEuaPriceMutator,
	OnUpdateEstimate,
} from '../context/hooks/useEstimateHandlers';

const estimateConfigurationItems = ({
	estimate,
	vessel,
	onUpdateEstimate,
	onRefreshEuaPriceMutator,
	allVessels,
	refreshEverything,
}: {
	estimate: GetEstimateDetailsResponse | null | undefined;
	vessel: GetVesselDetailsResponse | null | undefined;
	onUpdateEstimate: OnUpdateEstimate;
	onRefreshEuaPriceMutator: OnRefreshEuaPriceMutator;
	allVessels: GetVesselsResponse | undefined;
	refreshEverything: () => Promise<void>;
}) => {
	const vesselFinanceEntries = vessel?.vesselFinanceEntries || [];
	const thisYear = new Date().getFullYear();

	const vesselFinanceEntry = vesselFinanceEntries.find((entry) => entry.year === thisYear);
	const financeCost = (vesselFinanceEntry?.capex ?? 0) + (vesselFinanceEntry?.opex ?? 0);
	const hireCost = estimate?.tcInRate ?? financeCost;
	const hireAddComm = estimate?.hireAddComm ?? 0;

	const euaPrice = (estimate?.euaPriceOverride ?? estimate?.remoteEuaPrice) ?? 0;
	const dwt = (estimate?.dwtOverride ?? vessel?.summerDWT) || undefined;
	const tpc = (estimate?.tpcOverride ?? vessel?.TPC) || undefined;
	const summerDraft = (estimate?.summerDraftOverride ?? vessel?.summerDraft) || undefined;
	const constants = (estimate?.constantsOverride ?? vessel?.constants) || undefined;

	const consumption = estimate?.bunkerExpenses ?? {};
	const vessels = allVessels ?? [];

	const exchangeRatesAndCurrencies = Array.from(
		new Map(
			estimate?.cargos
				?.filter((cargo) => cargo.currency !== estimate.currency)
				?.map((cargo) => [cargo.currency,
					{ exchangeRate: cargo.exchangeRate, currency: cargo.currency }]),
		).values(),
	);

	const options = [
		{
			label: 'No vessel',
			value: -1,
		},
		{
			label: 'My vessels',
			options: getVesselOptions(vessels, 'myVessels'),
		},
		{
			label: 'Market vessels',
			options: getVesselOptions(vessels, 'marketVessels'),
		},
	];

	return [
		{
			label: 'Vessel',
			span: 1,
			children: (
				<Select
					placeholder="Select vessel"
					value={estimate?.vesselId}
					variant="borderless"
					size="small"
					dropdownStyle={{ width: 350 }}
					optionRender={(option) => (
						<Space size="small">
							{option.data.ownershipType != null && (
								<Tag className={styles.tag}>
									{VesselOwnershipTypeLabels[option.data.ownershipType as keyof typeof VesselOwnershipTypeLabels] ?? 'Unknown'}
								</Tag>
							)}
							{option.label}
						</Space>
					)}
					className={styles.select}
					showSearch
					options={options}
					onChange={async (e) => {
						await onUpdateEstimate({
							vesselId: Number(e),
						}, true);

						await refreshEverything();
					}}
				/>
			),
		},
		{
			label: 'Hire cost',
			span: 1,
			children: (
				<TemporaryNumberInput
					value={hireCost}
					placeholder="Rate"
					addonBefore={currencySymbols[estimate?.currency as keyof typeof currencySymbols] ??
						estimate?.currency}
					addonAfter="/d"
					controls={false}
					onChange={(e) => {
						onUpdateEstimate({
							tcInRate: Number(e),
						});
					}}
				/>
			),
		},
		{
			label: 'Add. comm.',
			span: 1,
			children: (
				<TemporaryNumberInput
					value={hireAddComm}
					placeholder=""
					addonAfter="%"
					controls={false}
					onChange={(e) => {
						onUpdateEstimate({
							hireAddComm: Number(e),
						});
					}}
				/>
			),
		},
		{
			label: 'EUA price',
			span: 1,
			children: (
				<TemporaryNumberInput
					addonBefore={currencySymbols[estimate?.currency as keyof typeof currencySymbols] ??
						estimate?.currency}
					type="number"
					controls={false}
					value={round(euaPrice, 2)}
					addonAfter={(
						<FontAwesomeIcon
							color="#1677ff"
							className={styles.refreshIcon}
							spin={onRefreshEuaPriceMutator.isLoading}
							icon={faRefresh}
							onClick={() => {
								onRefreshEuaPriceMutator.mutate();
							}}
						/>
					)}
					placeholder="EUA Cost"
					onChange={(e) => {
						onUpdateEstimate({
							euaPriceOverride: Number(e),
						});
					}}
				/>
			),
		},
		{
			label: 'Open port',
			span: 1,
			children: estimate?.openPort?.name ?? 'N/A',
			className: styles.calc,
		},
		{
			label: 'Open date',
			span: 1,
			children: estimate?.openDate?.format(DATE) ?? 'N/A',
			className: styles.calc,
		},
		{
			label: 'DWT',
			span: 1,
			children: (
				<TemporaryNumberInput
					addonAfter="MT"
					value={dwt}
					decimalSeparator=","
					controls={false}
					placeholder="DWT"
					onChange={(e) => {
						onUpdateEstimate({
							dwtOverride: Number(e),
						});
					}}
				/>
			),
		},
		{
			label: 'TPC',
			span: 1,
			children: (
				<TemporaryNumberInput
					value={tpc}
					addonAfter="MT"
					decimalSeparator=","
					controls={false}
					placeholder="TPC"
					onChange={(e) => {
						onUpdateEstimate({
							tpcOverride: Number(e),
						});
					}}
				/>
			),
		},
		{
			label: 'Summer draft',
			span: 1,
			children: (
				<TemporaryNumberInput
					addonAfter="m"
					decimalSeparator=","
					controls={false}
					placeholder="Summer draft"
					value={summerDraft}
					onChange={(e) => {
						onUpdateEstimate({
							summerDraftOverride: Number(e),
						});
					}}
				/>
			),
		},
		{
			label: 'Constants',
			span: 1,
			children: (
				<TemporaryNumberInput
					addonAfter="MT"
					value={constants}
					controls={false}
					decimalSeparator=","
					placeholder="Constants"
					onChange={(e) => {
						onUpdateEstimate({
							constantsOverride: Number(e),
						});
					}}
				/>
			),
		},
		...Object.keys(consumption ?? [{}]).map((fuelType) => {
			return {
				label: `${fuelType} price`,
				span: 1,
				children: (
					<TemporaryNumberInput
						value={consumption[fuelType as keyof typeof consumption]?.pricePerTon ?? 0}
						addonBefore={currencySymbols[estimate?.currency as keyof typeof currencySymbols] ??
							estimate?.currency}
						controls={false}
						placeholder={`${fuelType} price`}
						addonAfter="/MT"
						onChange={(e) => {
							onUpdateEstimate({
								bunkerExpenses: {
									...consumption,
									[fuelType]: {
										...consumption[fuelType as keyof typeof consumption],
										pricePerTon: Number(e),
									},
								},
							});
						}}
					/>
				),
			};
		}),
		...Array.from(exchangeRatesAndCurrencies ?? []).map((item) => {
			return {
				label: `${item.currency} > ${estimate?.currency} exchange rate`,
				span: 1,
				children: item.exchangeRate,
				className: styles.calc,
			};
		}),
	];
};

export default estimateConfigurationItems;
