import React, {
	useMemo,
	useState,
} from 'react';
import { Card } from 'antd';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { FeatureFlags } from '@shared/utils/constants';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import { useItinerary } from './ItineraryTab/ItineraryProvider';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import { getTabs } from './ItineraryTab/tabs/getTabs';
import styles from './ItineraryTab/ItineraryTab.module.css';
import PortCallSelector from './PortCallPortSelector';

const PortCallModal = () => {
	const [activeTab, setActiveTab] = useState('summary');
	const usesTheShip = useFeatureFlag(FeatureFlags.THE_SHIP);
	const {
		expandedEntry,
		itinerary,
		refreshItinerary,
	} = useItinerary();

	const {
		vessel,
		voyageDetails,
		refreshVoyageDetails,
		refreshVessel,
	} = useVoyage();

	const isConsMissingForSpeed = useMemo(() => {
		if (expandedEntry == null || isItemPortCall(expandedEntry)) {
			return false;
		}

		const selectedSpeed = expandedEntry.routeOptions.speed;
		const performanceEntries = vessel?.performanceEntries;

		if (performanceEntries == null) {
			return false;
		}

		return performanceEntries.find((s) => s.speed === selectedSpeed) == null;
	}, [expandedEntry, vessel?.performanceEntries]);

	const { tabContent, tabList } = getTabs({
		itinerary,
		voyageDetails,
		selectedEntry: expandedEntry,
		refreshDetails: () => {
			refreshVoyageDetails();
			refreshItinerary();
		},
		setActiveTab,
		vessel,
		refreshVessel: async () => {
			refreshVessel();
			refreshVoyageDetails();
			refreshItinerary();
		},
		isConsMissingForSpeed,
		usesTheShip,
	});

	return (
		<Card
			title={expandedEntry != null &&
				isItemPortCall(expandedEntry) && (
				<PortCallSelector />
			)}
			onTabChange={setActiveTab}
			activeTabKey={activeTab}
			tabList={tabList}
			className={styles.tabsCard}
		>
			{tabContent[activeTab]}
		</Card>
	);
};

export default PortCallModal;
