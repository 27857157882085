import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { message } from 'antd';
import moment from 'moment';
import {
	IMPERSONATION_HEADER,
	SELECTED_ORGANIZATION_HEADER,
	SELECTED_ORGANIZATION_ID_LOCAL_STORAGE_KEY,
} from '@shared/utils/constants';
import APIError from '@client/utils/APIError';
import { API_URL } from '@client/utils/constants';
import { handleDates } from './handleDates';

type HttpMethod =
  | 'get'
  | 'post'
  | 'delete'
  | 'put'
  | 'patch';

type ExtraOptions = {
	savingIndicator?: boolean;
};

const instance = axios.create({
	baseURL: API_URL,
	headers: {
		[SELECTED_ORGANIZATION_HEADER]: localStorage.getItem(
			SELECTED_ORGANIZATION_ID_LOCAL_STORAGE_KEY,
		) || undefined,
	},
});

instance.interceptors.response.use((originalResponse) => {
	originalResponse.data = handleDates(originalResponse.data);

	return originalResponse;
});

export const sendRequest = async <Request = undefined, Response = undefined>(
	url: string,
	method: HttpMethod = 'get',
	// If a request type is not specified, don't require request parameters
	...[params, options, extraOptions]: (
		undefined extends Request ?
			[undefined?, AxiosRequestConfig?, ExtraOptions?] :
			[Request, AxiosRequestConfig?, ExtraOptions?]
	)
): Promise<Response> => {
	const savingIndicatorId = Math.random();

	try {
		if (extraOptions?.savingIndicator === true) {
			message.loading({
				content: 'Saving...',
				key: savingIndicatorId,
				duration: 0,
				style: {
					marginLeft: '85vw',
				},
			});
		}

		let finalUrl = url;
		let body;

		if (method === 'get') {
			finalUrl = `${url}`;
			if (params != null && Object.keys(params).length > 0) {
				finalUrl += `?${qs.stringify(params)}`;
			}
		} else {
			body = params;
		}

		const result = await instance.request({
			...options,
			url: finalUrl,
			method,
			data: body,
		});

		if (extraOptions?.savingIndicator === true) {
			message.success({
				content: 'Saved',
				key: savingIndicatorId,
				duration: 1,
				style: {
					marginLeft: '85vw',
				},
			});
		}

		return result.data;
	} catch (error: any) {
		console.error('Request error', url, error.response || error);
		if (extraOptions?.savingIndicator === true) {
			message.destroy(savingIndicatorId);
		}

		if (error.response != null && error.response.status > 500) {
			throw new APIError(
				'An error has occurred. Please try again later.',
				true,
				error.response.status,
			);
		}

		if (error.response != null && error.response.status === 413) {
			throw new APIError(
				'File is too large (max 10 MB)',
				true,
				413,
			);
		}

		if (error.response != null && error.response.data != null) {
			throw new APIError(error.response.data, true, error.response.status);
		}

		throw error;
	}
};

/*
* For sending files along with regular data. Remember that FormData stringifies everything so
* types will need to be cast on the server when receiving. As an example, the number 123 will become string "123".
*/
export const sendRequestWithFiles = async <Request, Response = undefined>(
	url: string,
	method: HttpMethod,
	...[params, attachments, options, extraOptions]: [Request, File[], AxiosRequestConfig?, ExtraOptions?]
): Promise<Response> => {
	const formData = new FormData();

	Object.entries(params || {}).forEach(([key, value]) => {
		if (value == null) {
			return;
		}

		// If it's a Moment instance, convert to a normal ISO string
		if (moment.isMoment(value)) {
			formData.append(key, value.toISOString());

			return;
		}

		// If it's a plain object or array, JSON-encode it once
		if (typeof value === 'object') {
			formData.append(key, JSON.stringify(value));

			return;
		}

		// Otherwise (string, number, boolean), append as-is
		formData.append(key, String(value));
	});

	attachments.forEach((file) => {
		formData.append('attachments', file);
	});

	return sendRequest(url, method, formData, options, extraOptions);
};

export const setJWT = (token: string) => {
	instance.defaults.headers.authorization = `Bearer ${token}`;
};

export const setImpersonationId = (orgId: number) => {
	instance.defaults.headers[IMPERSONATION_HEADER] = orgId;
};

export const setSelectedOrganizationId = (orgId: number) => {
	localStorage.setItem(SELECTED_ORGANIZATION_ID_LOCAL_STORAGE_KEY, String(orgId));
	instance.defaults.headers[SELECTED_ORGANIZATION_HEADER] = orgId;
};

