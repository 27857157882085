import React from 'react';
import { Descriptions } from 'antd';
import { TsReportTypes } from '@shared/utils/constants';
import type { TheShipReport } from '@api/lib/the-ship/theShip';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import styles from './styles/TsReport.module.css';

const labelMap: Record<string, string> = {
	isLeg: 'Is Leg',
	type: 'Event Type',
	nextPortCall: 'Next Port Call',
	etaNextPortCall: 'ETA Next Port Call',
	legFrom: 'Leg From',
	legTo: 'Leg To',
	dateAndTime: 'Date & Time',
	distance: 'Distance',
	noxDistance: 'NOx Distance',
	speedSog: 'Speed SOG',
	speedStw: 'Speed STW',
	course: 'Course',
	comments: 'Comments',
	lat: 'Latitude',
	lng: 'Longitude',
	draftAft: 'Draft Aft',
	draftForward: 'Draft Forward',
	displacement: 'Displacement',
	windDirectionTrue: 'Wind Direction (True)',
	windDirectionRelative: 'Wind Direction (Relative)',
	windForce: 'Wind Force',
	cargoOnboard: 'Cargo Onboard',
	deadweightCarried: 'Deadweight Carried',
	created: 'Created Date',
	modified: 'Modified Date',
};

const TsReport = ({ report }: { report: TheShipReport }) => {
	const consumptionColumns = [
		{
			title: 'Engine',
			dataIndex: ['engine', 'name'],
			key: 'engine',
		},
		{
			title: 'Fuel',
			dataIndex: ['fuel', 'name'],
			key: 'fuel',
		},
		{
			title: 'Consumed (tons)',
			dataIndex: 'consumed',
			key: 'consumed',
		},
		{
			title: 'State',
			dataIndex: 'state',
			key: 'state',
		},
		{
			title: 'RPM',
			dataIndex: 'rpm',
			key: 'rpm',
			render: (value: number | null) => (value !== null ? value : 'N/A'),
		},
	];

	const robColumns = [
		{
			title: 'Tank',
			dataIndex: ['tank', 'name'],
			key: 'tank',
		},
		{
			title: 'Fuel',
			dataIndex: ['fuel', 'name'],
			key: 'fuel',
		},
		{
			title: 'Quantity',
			dataIndex: 'qty',
			key: 'qty',
		},
	];

	const renderValue = (key: string, value: any) => {
		if (value == null) {
			return 'N/A';
		}

		switch (key) {
			case 'nextPortCall':
			case 'legFrom':
			case 'legTo':
				return value?.port?.name ?? 'N/A';
			case 'type':
				return TsReportTypes[value] ?? 'N/A';
			default:
				return JSON.stringify(value);
		}
	};

	return (
		<Card slim title="Event Details">
			<Descriptions column={1} bordered size="small">
				{Object.entries(report).map(([key, value]) => {
					if (!labelMap[key] || key === 'consumptions') {
						return null;
					}

					return (
						<Descriptions.Item key={key} label={labelMap[key]}>
							{renderValue(key, value)}
						</Descriptions.Item>
					);
				})}
			</Descriptions>
			<Card slim className={styles.marginTop} title="Fuel Consumptions">
				<Table
					dataSource={report.consumptions}
					columns={consumptionColumns}
					rowKey="id"
					size="small"
					pagination={false}
					locale={{ emptyText: 'No consumption data in report' }}
				/>
			</Card>
			<Card slim title="ROB" className={styles.marginTop}>
				<Table
					dataSource={report.robs}
					columns={robColumns}
					rowKey="id"
					size="small"
					pagination={false}
					locale={{ emptyText: 'No ROB\'s in report' }}
				/>
			</Card>
		</Card>
	);
};

export default TsReport;
