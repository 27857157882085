import React, { useCallback } from 'react';
import {
	Col,
	Flex,
	Grid,
	Row,
} from 'antd';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
	DATE,
	FreightRateType,
	PortActionTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { formatQuantity } from '@shared/utils/string';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { formatDate } from '@shared/utils/date';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type { Port } from '@api/utils/ports';
import {
	getEstimateDetails,
	getVcPnl,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import { Links } from '@client/utils/links';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import VcPnl from '../VcPnl/VcPnl';
import ItineraryTab from '../ItineraryTab/ItineraryTab';
import SelectItineraryEntryAndDates from '../SelectItineraryEntryAndDates';

const VcSummaryTab = () => {
	const {
		voyageDetails,
		fixtureCurrency,
		vcFixtureDetails: fixtureDetails,
		vessel,
		loadingVesselDetails,
	} = useVoyage();

	const { xxl } = Grid.useBreakpoint();

	const {
		data: pnl,
	} = useQuery(
		'vcPnl',
		() => getVcPnl(voyageDetails.id),
		{
			enabled: voyageDetails?.id != null,
		},
	);

	const [
		estimateDetails,
		_refreshEstimate,
		estimateError,
		loadingEstimate,
	] = useFetchedState(
		async () => {
			const { estimateId } = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

			if (estimateId != null) {
				return await getEstimateDetails(estimateId);
			}

			return null;
		},
		[fixtureDetails],
	);

	const getCargoQuantity = useCallback((cargo: GetCargosResponse[number]) => {
		const val = Object.entries(cargo.cargoPorts);
		const quantity = val.reduce((acc, [key, value]) => {
			const { action } = splitActionKey(key);

			if (action === PortActionTypes.DISCHARGING) {
				return acc;
			}

			const inputQuantity = value.blQuantity ?? value.cpQuantity ?? 0;

			return acc + inputQuantity;
		}, 0);

		return formatQuantity(quantity, cargo.unit);
	}, []);

	const cargos = estimateDetails?.cargos;

	if (
		estimateError ||
		loadingEstimate ||
		loadingVesselDetails ||
		vessel == null ||
		estimateDetails == null
	) {
		return null;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col span={18}>
				<Flex vertical gap={10}>
					<SelectItineraryEntryAndDates
						labelWidth={xxl ? 225 : 180}
						columns={4}
					/>
					<Card slim title="Cargo" size="small">
						<Table
							size="small"
							pagination={false}
							columns={[
								{
									title: 'Charterer',
									dataIndex: 'chartererName',
									key: 'chartererName',
									render: (charterer) => charterer,
								},
								{
									title: 'Type',
									dataIndex: 'type',
									key: 'cargo',
									render: (type, row) => (<Link to={`${Links.Cargo.get(row.id)}`}>{type}</Link>),
									width: 200,
								},
								{
									title: 'Quantity',
									dataIndex: 'quantity',
									key: 'quantity',
									render: (_value, row) => (
										<Flex>
											{getCargoQuantity(row)}
										</Flex>
									),
								},
								{
									title: 'Load Port(s)',
									dataIndex: 'loadingPorts',
									key: 'loadingPorts',
									render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
								},
								{
									title: 'Discharge Port(s)',
									dataIndex: 'dischargePorts',
									key: 'dischargePorts',
									render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
								},
								{
									title: 'Freight',
									key: 'freight',
									render: (row: GetCargosResponse[number]) => {
										const total = row.freightType === FreightRateType.LUMPSUM ?
											row.freightRate :
											row.quantity * row.freightRate;

										return formatCurrency(total / row.exchangeRate, estimateDetails.currency);
									},
									width: 200,

								},
								{
									title: 'CP Date',
									dataIndex: 'cpDate',
									key: 'cpDate',
									render: (cpDate) => formatDate(cpDate, DATE),
								},
							]}
							dataSource={cargos ?? []}
						/>
					</Card>
					<ItineraryTab />
				</Flex>
			</Col>
			<Col span={6}>
				<VcPnl
					pnl={pnl}
					voyageDetails={voyageDetails}
					fixtureCurrency={fixtureCurrency}
					columnsToHide={['invoiced', 'estimated', 'variance']}
					hideHeaders
					showTcInAllocation={false}
					spanOverride={{
						xs: 24,
						sm: 24,
						xl: 24,
						xxl: 24,
					}}
					slim
				/>
			</Col>
		</Row>
	);
};

export default VcSummaryTab;

