import React, { useMemo } from 'react';
import {
	Col,
	Row,
} from 'antd';
import sortBy from 'lodash.sortby';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import EditableTable from '@client/components/EditableTable';
import { useAuth } from '@client/lib/auth';
import { updatePortActivityTypes } from '@client/lib/api';

const PortActionTypes = () => {
	const { userInfo, refreshAuthInfo } = useAuth();

	const portActivityTypes = useMemo(() => (userInfo as UserData)?.portActivityTypes, [userInfo]);

	const formattedPortActivityTypes = useMemo(() => {
		return portActivityTypes?.map((pat) => {
			return { portActivityType: pat };
		});
	}, [portActivityTypes]);

	const onSave = async (editingEntry: string | null, values: { portActivityType: string }) => {
		const newPortActionTypesArr: string[] = portActivityTypes ?? [];

		if (editingEntry != null) {
			const indexOfEditingEntry = newPortActionTypesArr?.findIndex((t) => t === editingEntry);
			newPortActionTypesArr?.splice(indexOfEditingEntry, 1);
		}

		if (newPortActionTypesArr?.find((t) => t === values.portActivityType) != null) {
			showErrorNotification('Port activity type with that name already exists', undefined, { duration: 5 });

			return;
		}

		if (values.portActivityType === '' || values.portActivityType == null) {
			showErrorNotification('Port activity type cannot be empty', undefined, { duration: 5 });

			return;
		}

		newPortActionTypesArr.push(values.portActivityType);

		try {
			await updatePortActivityTypes({
				portActivityTypes: newPortActionTypesArr || portActivityTypes,
			});
			showSuccessNotification('Port activity types updated');
			refreshAuthInfo();
		} catch (e) {
			showErrorNotification('Cannot update port activity types');
		}
	};

	const onDelete = async (values: string) => {
		const newPortActionTypesArr = portActivityTypes;

		const indexOfEntryToDelete = portActivityTypes?.findIndex((ot) => ot === values);

		if (indexOfEntryToDelete != null) {
			newPortActionTypesArr?.splice(indexOfEntryToDelete, 1);

			try {
				await updatePortActivityTypes({
					portActivityTypes: newPortActionTypesArr || portActivityTypes,
				});
			} catch (e) {
				showErrorNotification('Cannot update off-hire types');
			}

			refreshAuthInfo();
		}
	};

	return (
		<Row>
			<Col xxl={8} xl={10} lg={16} sm={18}>
				<EditableTable
					size="small"
					// @ts-ignore
					columns={[{
						dataIndex: 'portActivityType',
						title: 'Type',
						editable: true,
					}]}
					dataSource={sortBy(formattedPortActivityTypes) ?? []}
					pagination={false}
					allowAddNew
					enableDelete
					enableEdit={() => true}
					onAddNew={(newEntry) => onSave(null, newEntry)}
					onDelete={(item) => onDelete(item)}
					onSave={(editingEntry, newEntry) => onSave(editingEntry, newEntry)}
					iconButtons
					keyDataIndex="portActivityType"
					addNewText="Add new port activity type"
					extraOnCancelEdit={refreshAuthInfo}
				/>
			</Col>
		</Row>
	);
};

export default PortActionTypes;
