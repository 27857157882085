import React from 'react';
import {
	Input,
	Space,
} from 'antd';
// the import does actually work, not sure why its unresolved
// eslint-disable-next-line import/no-unresolved
import { faLightBoxPen } from '@awesome.me/kit-a3c0f04348/icons/kit/custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined } from '@ant-design/icons';
import {
	CargoUnitLabels,
	CargoUnitTypes,
	currencySymbols,
	FreightRateType,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type { CargoProps } from '@api/models/cargo';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import type { CargoDetails } from '@api/features/cargos/getCargoDetails';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import NumericInput from '@client/components/NumericInput';
import styles from '../components/styles/misc.module.css';
import { OnDeleteCargoMutator } from '../context/hooks/useCargoHandlers';

export const getCargoCardColumns = ({
	onDeleteCargoMutator,
	onUpdateCargo,
	setEditingCargoId,
	ports,
	isTceLocked,
	charterers,
}: {
	onDeleteCargoMutator: OnDeleteCargoMutator;
	onUpdateCargo: (id: number, attributes: Partial<CargoProps>) => void;
	setEditingCargoId: (id: number) => void;
	ports: {
		label: string;
		value: number;
	}[];
	isTceLocked: boolean;
	xxl?: boolean;
	charterers: GetCounterpartiesResponse | undefined;
}): ColumnsType<GetCargosResponse[number]> => [
	{
		title: 'Cargo',
		dataIndex: 'type',
		key: 'cargo',
		width: 120,
		render: (value, row) => (
			<Input
				value={value}
				autoFocus
				variant="borderless"
				placeholder="Description"
				size="small"
				onChange={(e) => {
					onUpdateCargo(row.id, {
						type: e.target.value,
					});
				}}
			/>
		),
	},
	{
		title: 'Stw fac',
		dataIndex: 'stowageFactor',
		key: 'stowage',
		width: 105,
		render: (value, row) => (
			<NumericInput
				variant="borderless"
				value={value}
				addonAfter="m³"
				placeholder="Stowage Factor"
				onChange={(e) => {
					onUpdateCargo(row.id, {
						stowageFactor: Number(e),
					});
				}}
			/>
		),
	},
	{
		title: 'Loading',
		dataIndex: 'loadingPorts',
		key: 'loadingPorts',
		width: 200,
		render: (values, row) => (
			<Select
				value={values.map((lp: { id: number } | number) => (typeof lp === 'object' ? lp.id : lp))}
				variant="borderless"
				// @ts-ignore This works because it's feature in rc-select
				showAction="focus"
				mode="multiple"
				placeholder="Loading Ports"
				className={styles.select}
				options={ports}
				onChange={(e) => {
					onUpdateCargo(row.id, {
						loadingPorts: e,
					});
				}}
			/>
		),
	},
	{
		title: 'Discharging',
		dataIndex: 'dischargePorts',
		key: 'dischargePorts',
		width: 200,
		render: (values, row) => (
			<Select
				value={values.map((dp: { id: number } | number) => (typeof dp === 'object' ? dp.id : dp))}
				variant="borderless"
				// @ts-ignore This works because it's feature in rc-select
				showAction="focus"
				mode="multiple"
				placeholder="Discharge Ports"
				className={styles.select}
				options={ports}
				onChange={(e) => {
					onUpdateCargo(row.id, {
						dischargePorts: e,
					});
				}}
			/>
		),
	},
	{
		title: 'Qty',
		dataIndex: 'quantity',
		key: 'quantity',
		width: 80,
		render: (value, row) => (
			<NumericInput
				variant="borderless"
				value={value}
				placeholder="Quantity"
				onChange={(e) => {
					onUpdateCargo(row.id, {
						quantity: Number(e),
					});
				}}
			/>
		),
	},
	{
		title: 'Unit',
		dataIndex: 'unit',
		key: 'unit',
		width: 100,
		render: (value, row) => (
			<Select
				variant="borderless"
				value={value}
				size="small"
				// @ts-ignore This works because it's feature in rc-select
				showAction="focus"
				placeholder="Unit"
				className={styles.select}
				options={Object.keys(CargoUnitTypes).map((unit) => ({
					label: CargoUnitLabels[unit as keyof typeof CargoUnitLabels],
					value: unit,
				}))}
				onChange={(e) => {
					onUpdateCargo(row.id, {
						unit: e,
					});
				}}
			/>
		),
	},
	{
		title: 'Rate',
		dataIndex: 'freightRate',
		key: 'freightRate',
		width: 110,
		render: (value, row) => (
			<NumericInput
				variant="borderless"
				value={round(value, 2)}
				addonBefore={currencySymbols[row.currency as keyof typeof currencySymbols] ??
						row.currency}
				disabled={isTceLocked}
				placeholder="Rate"
				onChange={(e) => {
					onUpdateCargo(row.id, {
						freightRate: Number(e),
					});
				}}
			/>
		),
	},
	{
		title: 'Type',
		dataIndex: 'freightType',
		key: 'freightType',
		width: 100,
		render: (value, row) => (
			<Select
				variant="borderless"
				value={value}
				size="small"
				// @ts-ignore This works because it's feature in rc-select
				showAction="focus"
				placeholder="Unit"
				className={styles.select}
				options={[
					{
						label: 'Lumpsum',
						value: FreightRateType.LUMPSUM,
					},
					{
						label: row.unit != null ? `Per ${CargoUnitLabels[row.unit]}` : 'Per unit',
						value: FreightRateType.PER_UNIT,
					},
				]}
				onChange={(e) => {
					onUpdateCargo(row.id, {
						freightType: e,
					});
				}}
			/>
		),
	},
	{
		title: 'Ad. Com',
		dataIndex: 'addressCommission',
		key: 'addressCommission',
		width: 70,
		render: (value, row) => (
			<NumericInput
				variant="borderless"
				value={value}
				addonAfter="%"
				placeholder="Ad. Com."
				onChange={(e) => {
					onUpdateCargo(row.id, {
						addressCommission: Number(e),
					});
				}}
			/>
		),
	},
	{
		title: 'Br. Com',
		dataIndex: 'brokerCommission',
		key: 'brokerCommission',
		width: 70,
		render: (value, row) => (
			<NumericInput
				variant="borderless"
				value={value}
				addonAfter="%"
				placeholder="Br. Com."
				onChange={(e) => {
					onUpdateCargo(row.id, {
						brokerCommission: Number(e),
					});
				}}
			/>
		),
	},
	{
		title: 'Charterer',
		dataIndex: 'charterer',
		width: 130,
		render: (value: number, row: CargoDetails) => (
			<Select
				variant="borderless"
				value={value}
				size="small"
				// @ts-ignore This works because it's feature in rc-select
				showAction="focus"
				placeholder="Charterer"
				className={styles.select}
				options={(charterers ?? []).map((c) => ({
					label: c.name,
					value: c.id,
				}))}
				onChange={(c) => {
					onUpdateCargo(row.id, {
						charterer: c,
					});
				}}
			/>
		),
	},
	{
		title: '',
		dataIndex: '',
		width: 65,
		align: 'right',
		render: (_a, row) => (
			<Space size={0}>
				<Button
					type="link"
					icon={(
						<FontAwesomeIcon
							className={styles.editIcon}
							onClick={() => setEditingCargoId(row.id)}
							icon={faLightBoxPen}
						/>
					)}
				/>
				<Button
					onClick={() => onDeleteCargoMutator.mutate(row.id)}
					confirmTitle="Are you sure you want to remove this cargo from the estimate?"
					type="link"
					danger
					icon={(<DeleteOutlined />)}
				/>
			</Space>

		),
	},
];
