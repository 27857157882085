// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J8exSxBqNsOpApQyaFD7 {\n\twidth: 100%;\n}\n\n.OnKWY6tOBbtpAR4PlMbw .ant-descriptions-item-label {\n\twidth: 49.95%;\n}\n\n.OnKWY6tOBbtpAR4PlMbw .ant-descriptions-item-content {\n\tpadding: 4px !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/SelectItineraryEntryAndDates.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n\n.details :global(.ant-descriptions-item-label) {\n\twidth: 49.95%;\n}\n\n.details :global(.ant-descriptions-item-content) {\n\tpadding: 4px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "J8exSxBqNsOpApQyaFD7",
	"details": "OnKWY6tOBbtpAR4PlMbw"
};
export default ___CSS_LOADER_EXPORT___;
