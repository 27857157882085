import { extendMoment } from 'moment-range';
import moment from 'moment';
import {
	Currencies,
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import overrideMomentToJson from '@shared/utils/overrideMomentToJson';
// eslint-disable-next-line max-len
import getPnlAmountAveragedOverFullPeriodCalculator from '@shared/utils/pnlAmountCalculators/getPnlAmountAveragedOverFullPeriodCalculator';
import { Values } from '@shared/utils/objectEnums';
import { PnlAmountCalculatorParams } from '@shared/utils/pnlAmountCalculators';
import type HireInvoiceItemModel from '@api/models/hire-invoice-item';
import TemplateItem from '../TemplateItem';
import {
	asyncForEach,
	asyncMap,
} from '../utils/array';
import { round } from '../utils/math';
import type HireInvoiceDifference from './HireInvoiceDifference';

const _hireInvoiceItemClasses = {};

export type HIIConstructorParams = {
	internalNote?: string;
	fixtureCurrency: Values<typeof Currencies>;
};

type TemplateColumn = string | {
	content: TemplateColumn | (TemplateColumn[][]);
	small?: boolean;
	italic?: boolean;
	span?: number;
};

export type TemplateItemChildren = Array<{
	total?: number;
	section?: string;
	columns: Array<TemplateColumn | TemplateColumn[][]>;
} | string[]>;

type TypedField<T extends Values<typeof HIIFieldTypes>> = {
	label: string;
	type: T;
	options?: Array<{ label: string; value: string }>;
	inputProps?: {};
};

// @ts-ignore
overrideMomentToJson(extendMoment(moment));

export type HireInvoiceItemType = HireInvoiceItem;

abstract class HireInvoiceItem {
	static get _hireInvoiceItemClasses() {
		return _hireInvoiceItemClasses;
	}

	static addItemClass(Class: typeof HireInvoiceItem) {
		_hireInvoiceItemClasses[Class.itemType] = Class;
	}

	declare static itemType: string;

	id: number;
	isOriginal: boolean;
	isDisabled: boolean = false;
	children: HireInvoiceItem[];
	fixtureCurrency: Values<typeof Currencies>;
	totalOverride: number | null = null;
	parentId: number | null = null;
	internalNote: string | null = null;
	isGrouped: boolean | null = null;
	groupedItems: null | HireInvoiceItem[] = null;
	isPrevious: boolean | null = null;
	accepted: boolean | null = null;
	hireInvoiceId: number | null = null;
	cargoId?: number | null = null;
	currency: Values<typeof Currencies> = Currencies.USD;

	declare pnlGroup: Values<typeof PnlGroups>;
	declare itemTypeLabel: string;
	declare addManually: boolean;
	declare templateSection: string;
	declare _canBeGroupedWith: undefined | ((otherItem: HireInvoiceItem) => boolean);
	declare _fields: Record<string, TypedField<Values<typeof HIIFieldTypes>>>;
	declare pnlAmountCalculator: (
		allItems: HireInvoiceItem[],
		parameters: PnlAmountCalculatorParams,
	) => number;

	abstract _calculateTotal(items: HireInvoiceItem[], convertCurrency: boolean): number;
	abstract _getTemplateItemParams(items: HireInvoiceItem[]): {
		[templateType in InvoiceTemplates]: {
			columns: Array<TemplateColumn | TemplateColumn[][]>;
			hideTotal?: boolean;
			children?: TemplateColumn[][] | TemplateItemChildren;
		};
	};
	abstract getDescription(items?: HireInvoiceItem[]): string;
	_showTotal: boolean;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams,
	) {
		// Default to not showing total
		this._showTotal = false;

		this.id = id;

		this._setParentProperties({
			fixtureCurrency: params?.fixtureCurrency,
			internalNote: params?.internalNote,
			accepted: false,
		});

		// This is the default P&L value calculator
		this.pnlAmountCalculator = getPnlAmountAveragedOverFullPeriodCalculator(this);

		this.isOriginal = isOriginal;
		this.children = [];
		this.fixtureCurrency = params?.fixtureCurrency;
	}

	static _findType(name: string) {
		if (name == null) {
			throw new Error(`Name of type to find was ${name}. It must be a string.`);
		}

		const Type = HireInvoiceItem._hireInvoiceItemClasses[name];

		if (Type == null) {
			throw new Error(
				`Hire invoice item of type "${name}" is not defined.\n` +
				'Did you remember to:\n' +
				'- Give it a static itemType\n' +
				`- Add it with HireInvoiceItem.addItemClass in ${name}.js\n` +
				'- Import it in shared/src/hireInvoice/index.js?\n' +
				`Defined types: ${Object.keys(_hireInvoiceItemClasses).join(', ') || '(none)'}`,
			);
		}

		return Type;
	}

	static async fromModel(
		parentModel: HireInvoiceItemModel & {
			children?: HireInvoiceItemModel[];
		},
		fixtureCurrency: Values<typeof Currencies> | null,
	) {
		// If it's already loaded, use that
		// Otherwise, fetch it
		const childModel = parentModel.childModel ||
			parentModel[parentModel.itemType] ||
			await parentModel.getChildModel();

		let children: HireInvoiceItemModel[] = [];

		if (parentModel.parentHireInvoiceItemId == null) {
			children = parentModel.children || await parentModel.getChildren({
				where: {
					isOriginal: parentModel.isOriginal,
				},
				order: [['id', 'asc']],
			});
		}

		const Type = HireInvoiceItem._findType(childModel.constructor.itemType);
		const params = await Type._getClassParams(childModel, parentModel);
		const instance = new Type(parentModel.id, parentModel.isOriginal, params);

		instance._setParentProperties({
			totalOverride: parentModel.totalOverride,
			parentId: parentModel.parentHireInvoiceItemId,
			internalNote: parentModel.internalNote,
			isPrevious: false,
			accepted: parentModel.accepted,
			hireInvoiceId: parentModel.hireInvoiceId,
			fixtureCurrency,
			cargoId: parentModel?.cargoId,
		});

		instance.children = await asyncMap(
			children,
			(i) => HireInvoiceItem.fromModel(i, fixtureCurrency),
		);

		return instance;
	}

	getItemType(): string {
		// @ts-ignore
		return this.constructor.itemType;
	}

	async createModel(
		{
			HIIModel,
			voyageId,
			hireInvoiceId,
			parentId = null,
			createChildren = true,
			cargoId = null,
		} : {
			HIIModel: typeof HireInvoiceItemModel;
			voyageId: number;
			hireInvoiceId: number | null;
			parentId?: number | null;
			createChildren?: boolean;
			cargoId?: number | null;
		},
	) {
		// Create the HireInvoiceItem model (parent)
		const parentModel = await HIIModel.create({
			voyageId,
			hireInvoiceId,
			cargoId,
			itemType: this.getItemType(),
			isOriginal: this.isOriginal,
			parentHireInvoiceItemId: parentId,
			totalOverride: this.totalOverride,
			internalNote: this.internalNote,
			accepted: this.accepted ?? undefined,
		});

		// Create the actual HII-subclass model - it will be populated with data in saveModel
		const childModel = await parentModel.createChildModel({
			hireInvoiceItemId: parentModel.id,
		});
		await this.saveModel(childModel);

		// Make sure to update itemId so it reflects the actual item id
		parentModel.itemId = childModel.id;
		await parentModel.save();

		if (createChildren) {
			// Also save the children
			childModel.children = await asyncMap(
				this.children,
				(child) => child.createModel(
					{
						HIIModel,
						voyageId,
						hireInvoiceId,
						parentId: parentModel.id,
						cargoId,
					},
				),
			);
		}

		return childModel;
	}

	async saveModel(childModel: any) {
		const model = await childModel.getHireInvoiceItem();

		if (this._showTotal) {
			model.totalOverride = this.totalOverride;
		}

		model.accepted = this.accepted;
		model.internalNote = this.internalNote;
		await model.save();
	}

	async deleteModel(childModel: any) {
		const model = await childModel.getHireInvoiceItem();
		const difference = await model.getHireInvoiceItemDifference();

		const childModels = await model.getChildren();

		await asyncForEach(this.children, async (child) => {
			const matchingModel = childModels.find((c: any) => c.id === child.id);

			await child.deleteModel(await matchingModel.getChildModel());
		});

		if (difference != null && difference.deletedAt == null) {
			const theirsModel = await difference.getChartererItem();
			const theirsChildModel = await theirsModel.getChildModel();

			await theirsModel.destroy();
			await theirsChildModel.destroy();
			await difference.destroy();
		}

		await childModel.destroy();
		await model.destroy();
	}

	getFields() {
		if (this._showTotal) {
			return {
				...this._fields,
				totalOverride: {
					label: 'Total',
					type: HIIFieldTypes.CURRENCY,
					allowNegative: true,
					returnNullOnEmpty: false,
				},
			};
		}

		return { ...this._fields };
	}

	getParent(invoiceItems: HireInvoiceItem[]) {
		if (invoiceItems == null) {
			return null;
		}

		return invoiceItems.find((i) => i.id === this.parentId);
	}

	// This function checks if them item itself OR its parent is accepted
	isAccepted(invoiceItems: HireInvoiceItem[]) {
		const parent = this.getParent(invoiceItems);

		if (parent == null) {
			return this.accepted;
		}

		return this.accepted || parent.accepted;
	}

	isChild() {
		return this.parentId != null;
	}

	getTotal(invoiceItems: HireInvoiceItem[], includeChildren = false, convertCurrency = true) {
		let total;

		if (this.totalOverride != null) {
			total = this.totalOverride;
		} else {
			total = this._calculateTotal(invoiceItems, convertCurrency);
		}

		if (includeChildren) {
			const childrenTotal = this.children.reduce(
				(sum, child) => sum + child.getTotal(invoiceItems, true),
				0,
			);

			total += childrenTotal;
		}

		return round(total, 3);
	}

	static findItem(items: HireInvoiceItem[], Type: typeof HireInvoiceItem) {
		// Try to find the item in the list of root items
		const item = items.find((i) => i instanceof Type);

		if (item != null) {
			return item;
		}

		// Try to find the item in one of the root items' children array
		const children = items.reduce<HireInvoiceItem[]>((arr, i) => [
			...arr,
			...i.children,
		], []);

		return children.find((i) => i instanceof Type);
	}

	_setParentProperties(props: {
		totalOverride?: number;
		parentId?: number;
		internalNote?: string;
		isGrouped?: boolean;
		groupedItems?: HireInvoiceItem[];
		isPrevious?: boolean;
		accepted?: boolean;
		hireInvoiceId?: number;
		fixtureCurrency?: Values<typeof Currencies>;
	}) {
		// Make sure the value is AT LEAST null (meaning never undefined)
		const valueOrNull = <T>(value: T | null) => (value != null ? value : null);

		this.totalOverride = valueOrNull(props.totalOverride!);
		this.parentId = valueOrNull(props.parentId!);
		this.internalNote = valueOrNull(props.internalNote!);
		this.isGrouped = valueOrNull(props.isGrouped!);
		this.groupedItems = valueOrNull(props.groupedItems!);
		this.isPrevious = valueOrNull(props.isPrevious!);
		this.accepted = valueOrNull(props.accepted!);
		this.fixtureCurrency = props.fixtureCurrency!;
		this.hireInvoiceId = valueOrNull(props.hireInvoiceId!);
	}

	// Supports both JSON string and an object
	static fromJSON(json: string | object) {
		const obj = typeof json === 'string' ? JSON.parse(json) : json;

		const {
			__itemType,
			id,
			children,
			isOriginal,
			...properties
		} = obj;

		const Type = HireInvoiceItem._findType(__itemType);
		const instance = new Type(id, isOriginal, properties);

		instance.children = children.map((c: string) => HireInvoiceItem.fromJSON(c));

		const newParentProps = {
			totalOverride: obj.totalOverride,
			parentId: obj.parentId,
			internalNote: obj.internalNote,
			isGrouped: obj.isGrouped,
			isPrevious: obj.isPrevious,
			accepted: obj.accepted,
			fixtureCurrency: obj.fixtureCurrency,
			groupedItems: obj.groupedItems?.map((i: string) => HireInvoiceItem.fromJSON(i)) ?? undefined,
			hireInvoiceId: obj.hireInvoiceId,
		};

		instance._setParentProperties(newParentProps);

		return instance;
	}

	toJSON(): object {
		const props: any = Object.getOwnPropertyNames(this)
			.reduce((o, prop) => ({
				...o,
				[prop]: this[prop],
			}), {});

		delete props.pnlAmountCalculator;

		const result = {
			...props,
			__itemType: this.getItemType(),
			children: (this.children || []).map((c) => c.toJSON()),
		};

		if (this.groupedItems != null) {
			result.groupedItems = this.groupedItems.map((i) => i.toJSON());
		}

		return result;
	}

	copy() {
		return HireInvoiceItem.fromJSON(this.toJSON());
	}

	toTemplateItem(hireInvoiceItems: HireInvoiceItem[], templateType: InvoiceTemplates): TemplateItem {
		// Retrieve the specific template configuration based on the provided templateType
		const templateConfig = this._getTemplateItemParams(hireInvoiceItems)[templateType];

		if (!templateConfig) {
			throw new Error(`Template type ${templateType} is not supported.`);
		}

		const {
			hideTotal,
			columns: columnsContent,
			children: rawChildren,
		} = templateConfig;

		const getColumnObject = (
			column: TemplateColumn | string | Array<Array<TemplateColumn | string>>,
		): Exclude<TemplateColumn, string> => {
			// If it is an array, it is an array of columns, which itself is an array
			// Each item in the inner array must be turned into a proper column object
			if (Array.isArray(column)) {
				return {
					content: column.map((col) => col.map((cell) => getColumnObject(cell))),
				};
			}

			// If it's an object and not an array, it is the full column object
			if (typeof column === 'object') {
				return column;
			}

			// If it's neither an array nor an object, it must just be the content string
			return { content: column };
		};

		const columns = columnsContent.map(getColumnObject);

		const manualChildren = Array.isArray(rawChildren) ? rawChildren.map((child) => {
			let params;
			let section;

			if (Array.isArray(child)) {
				params = {
					columns: child.map(getColumnObject),
				};
			} else if (typeof child === 'object') {
				// If child is an object, assume it contains all the right parameters
				params = {
					...child,
					columns: child.columns.map(getColumnObject),
				};

				section = child.section;
			}

			if (params == null) {
				return null;
			}

			return new TemplateItem(section, params);
		}) : [];

		const itemChildren = this.children.map((i) => i.toTemplateItem(hireInvoiceItems, templateType));

		const children = [
			...manualChildren,
			...itemChildren,
		];

		// Helper function to filter out children with a total of 0
		const filterChildren = (child: any) => {
			return child
				.filter((grandchild: { total: number }) => grandchild.total !== 0)
				.map((grandchild: { children: string | any[] }) => {
					if (grandchild.children && grandchild.children.length > 0) {
						grandchild.children = filterChildren(grandchild.children);
					}

					return grandchild;
				});
		};

		// Apply filtering to children
		const filteredChildren = filterChildren(children);

		// Make the last column span the rest of the columns (at most 3)
		columns[columns.length - 1].span = 4 - columns.length;

		const template = new TemplateItem(this.templateSection, {
			total: hideTotal ? '' : this.getTotal(hireInvoiceItems),
			columns,
			children: filteredChildren,
		});

		return template;
	}

	canBeGroupedWith(other: HireInvoiceItem, differences: HireInvoiceDifference[]) {
		if (this._canBeGroupedWith == null) {
			throw new Error(`${this.constructor.name} does not implement _canBeGroupedWith.`);
		}

		const getChildrenMatch = () => {
			if (this.children.length !== other.children.length) {
				return false;
			}

			let remainingOtherChildren = [...other.children];
			this.children.forEach((c1) => {
				const match = remainingOtherChildren.find((c2) => (
					c1.canBeGroupedWith != null &&
					c1.canBeGroupedWith(c2, differences)
				));

				if (match == null) {
					return;
				}

				remainingOtherChildren = remainingOtherChildren.filter((c) => c !== match);
			});

			return remainingOtherChildren.length === 0;
		};

		return (
			(this.constructor.name === other.constructor.name) &&
			(this._canBeGroupedWith(other)) &&
			getChildrenMatch()
		);
	}
}

export default HireInvoiceItem;
