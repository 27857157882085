import React, { ReactNode } from 'react';
import { Descriptions } from 'antd';
import classNames from 'classnames';
import { DescriptionsItemType } from 'antd/es/descriptions';
import EmptyText from '@client/components/EmptyText';
import styles from './styles/Details.module.css';

type AlignLabel = 'top' | 'baseline' | 'middle';

export type DetailsItem = {
	key: string;
	label: string | React.ReactElement;
	value: string | ReactNode;
	alignLabel?: AlignLabel;
	style?: React.CSSProperties;
	maxHeight?: number;
}

type Props = {
	items: DetailsItem[];
	hideHeader?: boolean;
	title?: string | null | React.ReactNode;
	boldValue?: boolean;
	extra?: ReactNode[];
	className?: string;
	clean?: false;
	alignLabel?: AlignLabel;
	labelWidth?: number | string;
	column?: number;
	colSpan?: number;
} & Omit<DescriptionsItemType, 'children'>

const Details = ({
	items,
	hideHeader = false,
	title = 'Details',
	boldValue = false,
	extra = [],
	className,
	clean = false,
	alignLabel = 'baseline',
	labelWidth,
	colSpan = 3,
	column,
	...props
}: Props) => {
	let header = null;

	if (!hideHeader) {
		header = (
			<span className={styles.detailsTitle}>
				{title}
				<span className={styles.extra}>
					{extra}
				</span>
			</span>
		);
	}

	return (
		<Descriptions
			className={
				classNames(
					styles.details,
					className, {
						[styles.cleanDetails]: clean,
						[styles.noHeader]: title == null || title === '',
					},
				)
			}
			bordered
			title={header}
			size="small"
			column={column ?? 3}
			{...props}
		>
			{items.map(({ label, value, key, ...options }) => (
				<Descriptions.Item
					key={key}
					span={colSpan}
					label={label}
					labelStyle={{
						verticalAlign: options.alignLabel || alignLabel,
						width: labelWidth,
					}}
				>
					{value != null && value !== '' ? (
						<div
							className={classNames({ [styles.boldValue]: boldValue })}
							style={{
								...(options.maxHeight != null ?
									{
										maxHeight: options.maxHeight,
										overflowY: 'auto',
									} :
									{}),
								...options.style,
							}}
						>
							{value}
						</div>
					) : (
						<EmptyText />
					)}
				</Descriptions.Item>
			))}
		</Descriptions>
	);
};

export default Details;
