import React, { useState } from 'react';
import { Drawer } from 'antd';
import { VesselConditionTypes } from '@shared/utils/constants';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import ConsumptionSets from '@client/screens/fleet/VesselDetailsScreen/components/ConsumptionSets/ConsumptionSets';
import styles
	from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/styles/ConditionTab.module.css';
import { useNavigationBlock } from '@client/lib/navigationBlock';

type Props = {
	vessel: GetVesselDetailsResponse | undefined;
	refreshVessel: () => Promise<void> | void;
	activeConsumptionSetId?: number;
}

const ConsumptionContainer = ({
	vessel,
	refreshVessel,
	activeConsumptionSetId,
}: Props) => {
	const [editing, setEditing] = useState(false);
	const [blockClose, setBlockClose] = useState(false);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	useBlocker(blockClose);

	const onCloseModal = makeBlockable(() => setEditing(false));

	if (vessel == null) {
		return null;
	}

	return (
		<>
			<Drawer
				open={editing}
				onClose={onCloseModal}
				width={1100}
			>
				{('consumptionSets' in vessel) && (
					<ConsumptionSets
						consumptionSets={vessel.consumptionSets}
						inPortEntries={vessel?.performanceEntries
							.filter(
								(entry) => entry.condition === VesselConditionTypes.LOADING ||
									entry.condition === VesselConditionTypes.DISCHARGING ||
									entry.condition === VesselConditionTypes.IDLE,
							)}
						vesselId={vessel.id}
						refreshVessel={refreshVessel}
						secondaryInPortEnabled={vessel?.enableSecondaryInPortConsumption ?? false}
						setEditing={setEditing}
						activeConsumptionSetId={activeConsumptionSetId}
						setBlockClose={setBlockClose}
					/>
				)}
			</Drawer>
			<div className={styles.consumptionContainer}>
				<ConsumptionSets
					consumptionSets={vessel.consumptionSets}
					inPortEntries={vessel.inPortEntries}
					vesselId={vessel.id}
					refreshVessel={refreshVessel}
					secondaryInPortEnabled={vessel.enableSecondaryInPortConsumption}
					inEstimate
					setEditing={setEditing}
					activeConsumptionSetId={activeConsumptionSetId}
				/>
			</div>
		</>
	);
};

export default ConsumptionContainer;
