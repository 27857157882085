import React, {
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Drawer,
	Flex,
	Form,
	Input,
	Space,
	Tag,
} from 'antd';
import isEqual from 'lodash.isequal';
import { useHistory } from 'react-router';
import { DeleteOutlined } from '@ant-design/icons';
import uuid from 'short-uuid';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
	ParcelAccountTypes,
} from '@shared/utils/constants';
import { calculateLiftedBunkerPrice } from '@shared/utils/calculateLiftedBunkerPrice';
import type { VoyageWithFixtureAndCounterparty } from '@api/features/voyages/getVoyageDetails';
import type {
	AdditionalCost,
	Parcel,
	StemRob,
} from '@api/features/voyages/bunker-stems/createBunkerStem';
import type { GetBunkerStemsResponse } from '@api/features/voyages/bunker-stems/getBunkerStems';
import type PerformanceEntry from '@api/models/performance-entry';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import {
	createBunkerStem,
	createSupplier,
	getSuppliers,
	getVoyageDetails,
	getVoyageItinerary,
	updateBunkerStem,
} from '@client/lib/api';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { useAuth } from '@client/lib/auth';
import { Links } from '@client/utils/links';
import useAttachments from '@client/utils/hooks/useAttachments';
import Select from '../Select';
import Button from '../Button';
import CountryFlag from '../CountryFlag';
import DatePicker from '../DatePicker';
import CurrencySelect from '../CurrencySelect/CurrencySelect';
import EditableCellTableRedux from '../EditableTableRedux/EditableCellTableRedux';
import AddButton from '../AddButton';
import CreditNoteCheckbox from '../CreditNoteCheckbox';
import styles from './CreateBunkerStemForm.module.css';
import {
	getAdditionalCostTableColumns,
	getParcelTableColumns,
} from './getTableColumns';

const getStemDateRange = (portCall: ItineraryPortCallDto | undefined) => {
	if (portCall == null) {
		return [];
	}

	const start = portCall.arrivalDate;
	const end = portCall.departureDate;

	return [start, end];
};

const required = [{ required: true, message: 'Field is required' }];

type Props = {
	open: boolean;
	onClose: () => void;
	refreshDetails: () => void;
	editing?: GetBunkerStemsResponse[number] | undefined;
	documentId?: number;
	voyageId?: number;
	selectedPortCall?: ItineraryPortCallDto;
	tcInContract?: VoyageWithFixtureAndCounterparty | null;
	editingStem: GetBunkerStemsResponse[number] | undefined | null;
	setEditingStem: React.Dispatch<SetStateAction<GetBunkerStemsResponse[number] | undefined | null>>;
	fixtureCurrency: Values<typeof Currencies>;
}

const CreateBunkerStemForm = ({
	open,
	onClose,
	voyageId,
	selectedPortCall,
	tcInContract,
	refreshDetails,
	editingStem,
	setEditingStem,
	fixtureCurrency,
}: Props) => {
	const auth = useAuth();
	const history = useHistory();
	const [startEdit, setStartEdit] = useState(false);
	const [suppliers, refreshSuppliers] = useFetchedState(getSuppliers);
	const [creatingNewSupplier, setCreatingNewSupplier] = useState(false);
	const [createCreditNote, setCreateCreditNote] = useState(false);
	const [selectedCurrencyAndRate, setSelectedCurrencyAndRate] = useState({
		currency: fixtureCurrency,
		exchangeRate: 1,
	});
	const [additionalCosts, setAdditionalCosts] = useState<AdditionalCost[]>([]);
	const [parcels, setParcels] = useState<Parcel[]>([]);
	const [robs, setRobs] = useState<StemRob[]>([]);
	const [itineraryEntry, setItineraryEntry] = useState<
		ItineraryPortCallDto | undefined
	>(selectedPortCall);
	const [dirty, setDirty] = useState(false);
	const [form] = Form.useForm();

	const [stemDateMin, stemDateMax] = getStemDateRange(itineraryEntry);

	const [voyage] = useFetchedState(() => getVoyageDetails(
		Number(voyageId),
	));

	const [didSetDefaults, setDidSetDefaults] = useState(false);

	useEffect(() => {
		if (
			!editingStem &&
			voyage?.vessel.consumptionSets?.[0]?.performanceEntries &&
			!didSetDefaults
		) {
			const entries = voyage?.vessel.consumptionSets[0].performanceEntries as PerformanceEntry[];

			const usedFuelTypes = new Set();
			const newParcels: Parcel[] = [];

			entries.forEach((entry) => {
				if (!usedFuelTypes.has(entry.fuelType)) {
					usedFuelTypes.add(entry.fuelType);

					newParcels.push({
						id: uuid.generate(),
						account: 'owner',
						fuelGrade: entry.fuelType,
						quantity: 0,
						basisPPT: 0,
						liftedPPT: 0,
						subjectToCosts: false,
						currency: selectedCurrencyAndRate.currency,
					});
				}
			});

			if (parcels.length === 0) {
				setParcels(newParcels);
			}

			setDidSetDefaults(true);
		}
	}, [
		editingStem,
		voyage,
		didSetDefaults,
		setParcels,
		parcels.length,
		selectedCurrencyAndRate.currency,
	]);

	const goToItin = () => {
		onCloseForm();
		if (voyageId == null) {
			return;
		}

		history.push(`${Links.Voyage.get(voyageId)}/#/itinerary`);
	};

	const formSupplierId = Form.useWatch('supplierId', form);

	const alert = useMemo(() => {
		if (
			parcels.length > 0 &&
			robs.length > 0 &&
			formSupplierId != null
		) {
			return (
				false
			);
		}

		return true;
	}, [parcels.length, robs.length, formSupplierId]);

	const disabledReason = useMemo(() => {
		if (!formSupplierId) {
			return 'Please select a supplier.';
		}

		if (parcels.length === 0) {
			return 'Please add at least one parcel.';
		}

		if (robs.length === 0) {
			return 'Please enter the full ROB (including new bunkers).';
		}

		return '';
	}, [formSupplierId, parcels, robs]);

	const parcelsWithLiftedPPT = useMemo(() => parcels.map((p) => ({
		...p,
		liftedPPT: calculateLiftedBunkerPrice({ parcel: p, parcels, additionalCosts }),
	})), [parcels, additionalCosts]);

	// Check if we need to ask user for credit note generation
	const dirtyParcels = useMemo(() => {
		if (!editingStem) {
			return false;
		}

		const orignalParcels = editingStem.parcels;

		const changedIds: number[] = [];
		orignalParcels.forEach((parcel) => {
			const localParcel = parcels.find((p) => p.voyageBunkerId === parcel.voyageBunkerId);

			if (parcel.voyageBunkerId != null && parcel.isInvoiced && !isEqual(parcel, localParcel)) {
				changedIds.push(parcel.voyageBunkerId);
			}
		});

		return changedIds.length > 0;
	}, [parcels, editingStem]);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	useBlocker(dirty);

	const close = () => {
		onClose();
		onClose();
		setDirty(false);
		setParcels([]);
		setAdditionalCosts([]);
		setRobs([]);
		setEditingStem(undefined);
		setStartEdit(false);
		form.resetFields();
	};

	const onCloseForm = makeBlockable(close);

	const {
		uploaded,
		modal,
	} = useAttachments({
		attachments: editingStem?.attachments ?? [],
		open,
		setOpen: onCloseForm,
		spacerWidth: 800,
		attachmentTypeLabel: 'EUA',
	});

	useEffect(() => {
		if (editingStem != null) {
			form.setFieldsValue(editingStem);
			setSelectedCurrencyAndRate({
				currency: editingStem.currency,
				exchangeRate: editingStem.exchangeRate,
			});
		}
	}, [form, editingStem]);

	const [itinerary] = useFetchedState(() => getVoyageItinerary(voyageId!), [voyageId]);
	const portCalls = useMemo(
		() => itinerary?.filter((i): i is ItineraryPortCallDto => isItemPortCall(i)) ?? [],
		[itinerary],
	);

	useEffect(() => {
		if (itineraryEntry == null && selectedPortCall == null && portCalls != null) {
			const relevantPortCall = portCalls.find((pc) => pc.id === editingStem?.portCallId);

			if (relevantPortCall == null) {
				return;
			}

			setItineraryEntry(relevantPortCall);
		}
	}, [editingStem, itineraryEntry, portCalls, selectedPortCall]);

	useEffect(() => {
		if (!startEdit && editingStem != null) {
			setParcels(editingStem.parcels.map((p) => ({
				...p,
				account: p.accountType === ParcelAccountTypes.OWNER ?
					ParcelAccountTypes.OWNER :
					p.accountId!,
			})));
			setRobs(editingStem.robs);
			setAdditionalCosts(editingStem.additionalCosts);
			setStartEdit(true);
			setDirty(false);
		}
	}, [startEdit, editingStem]);

	const saveBunkerStem = async () => {
		const {
			supplierName,
			invoiceDate,
			stemDate,
			portCallId,
		} = form.getFieldsValue(true);

		if (voyageId == null) {
			return;
		}

		let { supplierId } = form.getFieldsValue(true);

		try {
			if (creatingNewSupplier && supplierName != null) {
				const supplier = await createSupplier(supplierName);
				supplierId = supplier.id;
			}

			const commonData = {
				voyageId,
				supplierId,
				invoiceDate,
				stemDate,
				robs,
				parcels: parcelsWithLiftedPPT,
				attachments: uploaded,
				currency: selectedCurrencyAndRate.currency,
				exchangeRate: selectedCurrencyAndRate.exchangeRate,
				additionalCosts,
			};

			if (editingStem) {
				await updateBunkerStem({
					id: editingStem.id,
					...commonData,
					generateCreditNote: createCreditNote,
				});
			} else {
				await createBunkerStem({
					...commonData,
					portCallId,
				});
			}

			refreshDetails();
			refreshSuppliers();
			close();
		} catch (e) {
			console.error(e);
			showErrorNotification('Could not save bunker stem', e as Error);
		}
	};

	return (
		<>
			{modal}
			<Drawer
				open={open}
				placement="left"
				width="49%"
				title={editingStem != null ? 'Update bunker stem' : 'Create new bunker stem'}
				onClose={onCloseForm}
				mask={!open}
			>
				<Form
					layout="vertical"
					form={form}
					preserve
				>
					<Form.Item
						name="supplierId"
						label="Supplier"
						rules={required}
						initialValue={editingStem?.supplierId}
					>
						<Select
							placeholder="Select Supplier"
							showSearch
							allowClear
							options={[
								{
									label: (<i>Create new...</i>),
									value: 'new',
								},
								...(suppliers ?? []).map((s) => ({
									label: s.name,
									value: s.id,
								}))]}
							onChange={(counterpartyId) => {
								setCreatingNewSupplier(counterpartyId === 'new');
							}}
						/>
					</Form.Item>
					<Form.Item
						fieldId="supplierName"
						name="supplierName"
						label="Supplier Name"
						hidden={!creatingNewSupplier}
						rules={[{ required: creatingNewSupplier, message: 'Field is missing' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="portCallId"
						rules={required}
						label="Select port call"
						initialValue={itineraryEntry?.id}
						extra={(
							<>
								{'Don\'t see the port call you\'re looking for? Check the '}
								<Button className={styles.noPaddingButton} onClick={goToItin} type="link">
									Itinerary
								</Button>
							</>
						)}
					>
						<Select
							showSearch
							disabled={selectedPortCall == null || portCalls.length === 0}
							options={portCalls.map((pc) => ({
								value: pc.id,
								label: (
									<Space>
										<Tag className={styles.tag} color={pc.estimated ? 'cyan' : 'geekblue'}>
											{pc.estimated ? 'Estimated' : 'Actual'}
										</Tag>
										<CountryFlag countryCode={pc.port.countryCode} />
										{pc.port.name}
									</Space>),
							}))}
							placeholder="Select Port"
							onSelect={(pcId) => {
								const portCall = portCalls.find((p) => p.id === pcId);
								setItineraryEntry(portCall);
							}}
						/>
					</Form.Item>
					<Flex
						gap={20}
						align="center"
					>
						<Form.Item
							name="stemDate"
							label="Stem Date"
							initialValue={itineraryEntry?.arrivalDate ?? itineraryEntry?.estimatedArrivalDate}
							rules={required}
							tooltip="Bunker stems are required to happen between the arrival and departure date of a given port call"
						>
							<DatePicker
								className={styles.datepicker}
								range={false}
								minDate={stemDateMin ?? undefined}
								maxDate={stemDateMax ?? undefined}
								time
							/>
						</Form.Item>
						<Form.Item
							name="invoiceDate"
							label="Invoice Date"
							initialValue={editingStem?.invoiceDate}
							className={styles.invoiceDate}
						>
							<DatePicker
								className={styles.datepicker}
								range={false}
							/>
						</Form.Item>
					</Flex>
					<Form.Item
						label="Parcel currency"
						name="currency"
						valuePropName="v"
					>
						<CurrencySelect
							baseCurrency={fixtureCurrency}
							value={{
								currency: selectedCurrencyAndRate.currency,
								exchangeRate: selectedCurrencyAndRate.exchangeRate,
							}}
							onChange={(c, e) => {
								setSelectedCurrencyAndRate({
									currency: c,
									exchangeRate: e,
								});
							}}
						/>
					</Form.Item>
					{tcInContract != null && (
						<Alert
							showIcon
							message={
								`Any parcels created to account ${tcInContract?.fixture.fixtureCounterparty.name} \
								will result in a bunker expense also being created on contract ${tcInContract?.identifier} (the associated TC-in contract)`
							}
							className={styles.alert}
						/>
					)}
					<Form.Item label="Additional costs">
						<EditableCellTableRedux
							dataSource={additionalCosts}
							size="small"
							pagination={false}
							onChange={setAdditionalCosts}
							emptyText="Empty"
							rowKey="id"
							columns={getAdditionalCostTableColumns({
								additionalCosts,
								currency: selectedCurrencyAndRate.currency,
								setAdditionalCosts,
							})}
						/>
					</Form.Item>
					<Form.Item label="Parcel(s)">
						<Flex vertical gap={10}>
							{alert}
							<EditableCellTableRedux
								dataSource={parcelsWithLiftedPPT}
								size="small"
								pagination={false}
								onChange={(updatedParcels) => setParcels(updatedParcels)}
								emptyText="Empty"
								rowKey="id"
								columns={getParcelTableColumns({
									currency: selectedCurrencyAndRate.currency,
									organizationName: auth?.userInfo?.organizationName ?? '',
									setParcels,
									tcInContract,
								})}
							/>
						</Flex>
					</Form.Item>
					<Alert
						message="To finalize your bunker stem, please enter the full ROB's including the new bunkers"
						type="info"
						showIcon
					/>
					<Form.Item label="ROB (including parcels)" required>
						<EditableCellTableRedux
							dataSource={robs}
							size="small"
							pagination={false}
							onChange={(v) => setRobs(v)}
							emptyText="Empty"
							columns={[
								{
									title: 'Fuel grade',
									dataIndex: 'fuelGrade',
									editable: true,
									width: 100,
									type: 'select',
									inputProps: {
										options: Object.keys(FuelTypes).map((key) => ({
											label: FuelTypes[key],
											value: key,
										})),
									},
								},
								{
									title: 'Quantity',
									dataIndex: 'quantity',
									editable: true,
									type: 'number',
									inputProps: {
										addonAfter: 'MT',
									},
									transformData: {
										in: (v) => Math.abs(v).toFixed(3),
										out: Math.abs,
									},
								},
								{
									title: (
										<AddButton
											onClick={() => {
												setRobs((prev) => [
													...prev,
													{
														id: uuid.generate(),
														fuelGrade: FuelTypes.VLSFO,
														quantity: 0,
													},
												]);
											}}

										/>),
									// @ts-ignore
									dataIndex: 'addEntry',
									render: (row: StemRob) => (
										<Button
											onClick={() => setRobs((prev) => prev.filter((p) => p.id !== row.id))}
											type="text"
											confirmTitle="Are you sure you want to delete this row?"
											danger
											icon={(<DeleteOutlined />)}
										/>
									),
								},
							]}
						/>
					</Form.Item>
					<Button
						disabled={alert}
						disabledTooltip={disabledReason}
						onClick={saveBunkerStem}
						type="primary"
						confirmTitle={dirtyParcels ? (
							<CreditNoteCheckbox
								description={`One or more parcels have already been invoiced on ${tcInContract?.identifier} -
								do you want to generate a credit note?`}
								createCreditNote={createCreditNote}
								setCreateCreditNote={setCreateCreditNote}
								showCumulativeAlert={false}
							/>
						) : undefined}
					>
						Save Bunker Stem
					</Button>
				</Form>
			</Drawer>
		</>
	);
};

export default CreateBunkerStemForm;
