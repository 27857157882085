import React, {
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Drawer,
	Grid,
	Empty,
} from 'antd';
import classNames from 'classnames';
import { NULL_STRING } from '@shared/utils/constants';
import useArrayState from '@client/utils/hooks/useArrayState';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import Button from '../Button';
import CustomDragLayer from './private/CustomDragLayer';
import FormSection from './private/FormSection';
import DraggableCard from './private/DraggableCard';
import Preview from './private/Preview';
import styles from './PdfGenerator.module.css';
import Settings, { SettingsProps } from './private/Settings';

type PdfGeneratorProps<Item> = {
	title: string | ReactNode;
	open: boolean | object;
	onClose: () => void;
	sections: Record<string, Item[]>;
	defaultOpenSections?: any;
	renderItem: (item: Item) => ReactNode;
	getPreview: (itemIds: (number | string)[]) => Promise<string>;
	onSave: (itemIds: (number | string)[]) => Promise<void>;
	readOnly?: boolean;
	width?: number;
	settings?: SettingsProps['settings'];
	settingsDefaultOpen?: boolean;
	initialAddedItems?: string[];
	getConfirmStatus?: (items: (number | string)[]) => null | [null | 'error' | 'warning', string];
	onUpdateInvoice?: (items: (number | string)[]) => void;
}

const PdfGenerator = <Item extends { id: number | string; isDisabled?: boolean }>({
	title,
	open,
	onClose: rawOnClose,
	// Items that shouldn't be draggable, should be added to a section with key "_"
	sections,
	defaultOpenSections,
	renderItem,
	onSave,
	readOnly = false,
	// Preview will be reloaded every time this function changes, please memoize it
	getPreview,
	width,
	settings,
	settingsDefaultOpen = false,
	// Added items are reset every time this is changed, please memoize it
	initialAddedItems,
	getConfirmStatus = () => null,
	onUpdateInvoice,
}: PdfGeneratorProps<Item>) => {
	const screens = Grid.useBreakpoint();

	const [loading, setLoading] = useState(false);
	const [addedItemIds, addItem, removeItem, setItems] = useArrayState<string | number>([]);
	const [dirty, setDirty] = useState(false);

	useEffect(() => {
		setItems(initialAddedItems || []);
		setDirty(false);
	}, [initialAddedItems, open, setItems]);

	const {
		makeBlockable,
		useBlocker,
	} = useNavigationBlock();

	const onClose = makeBlockable(rawOnClose);

	useBlocker(dirty);

	const onAddItem = (itemId: number | string) => {
		setDirty(true);
		addItem(itemId);
	};

	const items = useMemo(() => Object.values(sections).reduce((arr, sectionItems) => [
		...arr,
		...sectionItems,
	], []), [sections]);

	const addedItems = useMemo(() => (
		items.filter((i) => addedItemIds.includes(i.id))
	), [items, addedItemIds]);

	const save = async () => {
		setLoading(true);

		try {
			await onSave(addedItemIds);
			setLoading(false);
		} catch (e) {
			setLoading(false);

			throw e;
		}
	};

	useEffect(() => {
		if (!onUpdateInvoice) {
			return;
		}

		onUpdateInvoice(addedItemIds);
	}, [addedItemIds, onUpdateInvoice]);

	const [confirmStatus, confirmMessage] = (getConfirmStatus(addedItems.map((item) => item.id)) ||
	[]);
	const defaultWidth = screens.xl ? '80%' : '100%';

	const hasItems = Object.entries(sections).filter(([sectionName, sectionItems]) => (
		sectionName !== NULL_STRING &&
		sectionItems.length > 0
	)).length > 0;

	return (
		(
			<Drawer
				title={title}
				placement="left"
				width={width || defaultWidth}
				onClose={onClose}
				open={!!open}
				className={styles.drawer}
			>
				<CustomDragLayer />
				<div className={styles.container}>
					<div className={classNames(styles.left, { [styles.readOnly]: readOnly })}>
						<div data-tour="pdfGeneratorSettings">
							<Settings
								defaultOpen={settingsDefaultOpen}
								settings={settings ?? []}
							/>
						</div>
						<div data-tour="pdfGeneratorSections">
							{(hasItems ?
								Object.entries(sections).map(([sectionName, sectionItems]) => (
									sectionName !== NULL_STRING ? (
										<FormSection
											key={sectionName}
											title={sectionName}
											count={sectionItems.length}
											defaultOpen={open ? (
												defaultOpenSections == null ||
											defaultOpenSections.includes(sectionName)
											) : undefined}
										>
											{[...sectionItems].sort().map((i) => (
												<DraggableCard
													key={i.id}
													id={i.id}
													added={addedItemIds.includes(i.id)}
													onRemoveClick={() => removeItem(i.id)}
													onAddItem={() => onAddItem(i.id)}
													enabled={!readOnly}
													isDisabled={i.isDisabled}
												>
													{renderItem(i)}
												</DraggableCard>
											))}
										</FormSection>
									) : null
								)) : (
									<div className={styles.emptyWrapper}>
										<Empty description="No Items" />
									</div>
								)
							)}
						</div>
					</div>
					<div className={styles.right}>
						<Preview
							getPreview={getPreview}
							onAddItem={onAddItem}
							addedItemIds={addedItemIds}
						/>
						<div className={styles.bottom}>
							{readOnly && (
								<p className={styles.readOnlyTip}>
									Cannot save while in read-only mode.
								</p>
							)}
							<div className={styles.buttons}>
								<Button
									onClick={onClose}
									className={styles.cancelButton}
								>
									Cancel
								</Button>
								<Button
									popconfirmProps={{
										okButtonProps: {
											disabled: confirmStatus === 'error' || readOnly,
										},
										placement: 'topRight',
										arrow: { pointAtCenter: false },
									}}
									confirmTitle={confirmMessage}
									type="primary"
									loading={loading}
									disabled={readOnly}
									onClick={save}
									data-tour="pdfGeneratorSubmit"
								>
									Save and generate
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Drawer>
		)
	);
};

export default PdfGenerator;
