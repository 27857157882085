import React from 'react';
import { Typography } from 'antd';
import {
	Currencies,
	HireUnit,
	PnlItemTypes,
	PnlRenderTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import type { GetTcPnlResponse } from '@api/features/voyages/getTcPnl';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import styles from '@client/screens/fixtures/shared/styles/SummaryCard.module.css';
import Table from '@client/components/Table/Table';

type SectionData = {
	description: string;
	amount: number;
	bold: boolean;
	className: string;
	renderType: Values<typeof PnlRenderTypes> | null;
	render: Function | null;
	type: PnlItemTypes;
};

const formatItemsToSectionData = (
	items: Array<{ item: string; pnl: number; bold?: boolean; number?: boolean; type: PnlItemTypes }>,
	renderType: Values<typeof PnlRenderTypes> | null,
): Array<SectionData> => {
	return items.map(({ item, pnl, bold = false, type }) => ({
		description: item,
		amount: pnl,
		bold,
		className: bold ? styles.boldRow : '',
		renderType,
		render: null,
		type,
	}));
};

const getTcPnlEstimateItems = (pnl: GetTcPnlResponse) => {
	const revenueItems = [
		{ item: 'Gross Hire', pnl: pnl.grossHire.estimated, type: PnlItemTypes.GROSS_HIRE },
		{ item: 'Gross Ballast Bonus', pnl: pnl.grossBallastBonus.estimated, type: PnlItemTypes.GROSS_BALLAST_BONUS },
		{ item: 'Address Commissions', pnl: pnl.addressCommission.estimated, type: PnlItemTypes.ADDRESS_COMMISSIONS },
		{ item: 'Broker Commissions', pnl: pnl.brokerCommission.estimated, type: PnlItemTypes.BROKER_COMMISSIONS },
		{ item: 'Expenses Subject To Hire Days', pnl: pnl.expensesSubjectToHireDays.estimated, type: PnlItemTypes.EXPENSES_SUBJECT_TO_HIRE_DAYS },
		{ item: 'Revenue Subject To Days', pnl: pnl.revenueSubjectToDays.estimated, type: PnlItemTypes.REVENUE_SUBJECT_TO_DAYS },
		{ item: 'Charterer\'s Expenses', pnl: pnl.chartererExpenses.estimated, type: PnlItemTypes.REIMBURSABLE_EXPENSES },
		{ item: 'Bunker Gain/(Loss)', pnl: pnl.bunkerGainAndLoss.estimated, type: PnlItemTypes.BUNKER_LOSS_GAIN },
		{ item: 'Total Revenue', pnl: pnl.totalRevenue.estimated, bold: true, type: PnlItemTypes.TOTAL_REVENUE },
	];

	const expenseItems = [
		{ item: 'Owner\'s Expenses', pnl: pnl.ownersExpenses.estimated, type: PnlItemTypes.PAYABLE_EXPENSES },
		{ item: 'Repositioning Bunkers', pnl: pnl.repositioningBunkers.estimated, type: PnlItemTypes.REPOSITIONING_BUNKERS },
		{ item: 'Total Expenses', pnl: pnl.totalExpenses.estimated, bold: true, type: PnlItemTypes.TOTAL_EXPENSES },
	];

	const resultsItems = [
		{ item: 'Total Days', pnl: pnl.voyageDays.estimated, number: true, bold: true, type: PnlItemTypes.DAYS },
		{ item: 'TC Earnings', pnl: pnl.voyageProfit.estimated, bold: true, type: PnlItemTypes.FINAL_VOYAGE_PROFIT },
		{ item: 'TC Earnings per day', pnl: pnl.tce.estimated, bold: true, type: PnlItemTypes.FINAL_VOYAGE_PROFIT_PER_DAY },
		{ item: 'CAPEX', pnl: pnl.capex.estimated, type: PnlItemTypes.CAPEX },
		{ item: 'OPEX', pnl: pnl.opex.estimated, type: PnlItemTypes.OPEX },
		{ item: 'Net hire cost', pnl: pnl.tcInCosts.estimated, type: PnlItemTypes.TC_IN_COSTS },
		{ item: 'Net Result', pnl: pnl.netResult.estimated, bold: true, type: PnlItemTypes.NET_RESULT },
		{ item: 'Net Result per day', pnl: pnl.netResultPerDay.estimated, bold: true, type: PnlItemTypes.NET_RESULT_PER_DAY },
	];

	const revenueSection = formatItemsToSectionData(revenueItems, PnlRenderTypes.CURRENCY);
	const expenseSection = formatItemsToSectionData(expenseItems, PnlRenderTypes.CURRENCY);
	const resultsSection = formatItemsToSectionData(resultsItems, PnlRenderTypes.CURRENCY);

	return {
		revenueSection,
		expenseSection,
		resultsSection,
	};
};

export const getTcFixtureEstimateColumns = (
	sectionData: Array<SectionData>,
	currency: Values<typeof Currencies>,
	fixture: GetFixtureDetailsResponse<TcFixtureProps>,
) => {
	const currencyField = (v: number) => (v == null ? ' ' : formatCurrency(v, currency, { forceDecimals: true, maximumFractionDigits: 2 }));

	const numberField = (v: number) => (v == null ? ' ' : round(v, 2));

	const {
		hireRate,
		addressCommission,
		brokers,
		hireUnit,
	} = fixture;

	const brokerCommission = brokers.reduce((total: number, broker) => {
		return total + broker.commission;
	}, 0);

	return (
		<Table
			className={styles.table}
			pagination={false}
			size="small"
			columns={[
				{
					key: 'description',
					title: 'Description',
					dataIndex: 'description',
					width: '70%',
					render: (val: any, entry) => {
						if (entry.amount != null && entry.type === PnlItemTypes.GROSS_HIRE) {
							return (
								<>
									{entry.description}
									<Typography.Text className={styles.smallerText} type="secondary">
										{` (${formatCurrency(hireUnit === HireUnit.MONTHS ? ((hireRate ?? 0) * 12) / 365 : hireRate ?? 0, currency)}/day)`}
									</Typography.Text>
								</>
							);
						}

						if (entry.amount != null && entry.type === PnlItemTypes.ADDRESS_COMMISSIONS) {
							return (
								<>
									{entry.description}
									<Typography.Text className={styles.smallerText} type="secondary">
										{` (${addressCommission ?? 0}%)`}
									</Typography.Text>
								</>
							);
						}

						if (entry.amount != null && entry.type === PnlItemTypes.BROKER_COMMISSIONS) {
							return (
								<>
									{entry.description}
									<Typography.Text className={styles.smallerText} type="secondary">
										{` (${brokerCommission}%)`}
									</Typography.Text>
								</>
							);
						}

						return (
							<Typography.Text strong={entry.bold}>
								{entry.description}
							</Typography.Text>
						);
					},
				},
				{
					key: 'amount',
					dataIndex: 'amount',
					title: 'Amount',
					width: '30%',
					render: (v: number, entry: SectionData) => {
						if ([
							PnlItemTypes.ADDRESS_COMMISSIONS,
							PnlItemTypes.BROKER_COMMISSIONS,
							PnlItemTypes.CAPEX,
							PnlItemTypes.OPEX,
							PnlItemTypes.TC_IN_COSTS,
						].includes(entry.type)) {
							return currencyField(v * -1);
						}

						if (entry.type === PnlItemTypes.DAYS) {
							return (
								<Typography.Text strong={entry.bold}>
									{numberField(v)}
								</Typography.Text>
							);
						}

						return (
							<Typography.Text strong={entry.bold}>
								{currencyField(v)}
							</Typography.Text>
						);
					},
					align: 'right',
				},
			]}
			dataSource={sectionData.map((item, index) => ({ ...item, key: index }))}
		/>
	);
};

export default getTcPnlEstimateItems;
