import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
	Flex,
	Space,
	Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table/InternalTable';
import { Link } from 'react-router-dom';
import {
	DashboardTypes,
	FixtureCounterpartyTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { fixtureTypeToPascalCase } from '@shared/utils/string';
import { formatNumber } from '@shared/utils/formatNumber';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import type { GetOpenPositionsDataResponse } from '@api/features/statistics/getOperationsData';
import { formatDate } from '@client/utils/formatDate';
import { getOperationsData } from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import Table from '@client/components/Table/Table';
import VesselDescription from '@client/components/VesselDescription';
import { Links } from '@client/utils/links';
import { getFilterProps } from '@client/utils/table';
import { DashboardComponent } from '../helpers/dashboardInfo';
import styles from './OperationsDashboard.module.css';

const OpenPositionsDashboard: DashboardComponent = ({
	setLastUpdated,
}) => {
	const {
		data,
		isLoading,
		isFetching,
	} = useQuery(
		[DashboardTypes.OPEN_POSITIONS],
		getOperationsData,
		{
			onSuccess: (d) => setLastUpdated(d.lastUpdated),
			refetchOnWindowFocus: false,
		},
	);

	const columns = useMemo(() => {
		if (data == null) {
			return [];
		}

		return [
			{
				title: 'Name',
				dataIndex: ['vessel'],
				render: (vessel) => (
					<VesselDescription vesselId={vessel.id} name={vessel.name} />
				),
				sorter: standardSort(['vessel', 'name']),
				...getFilterProps((data.data.map((d) => d.vessel) ?? []), 'select', 'name', 'desc'),
				onFilter: (value, record) => record.vessel?.name === value,
			},
			{
				title: 'DWT',
				dataIndex: ['vessel', 'dwt'],
				render: (dwt: number) => (dwt != null ?
					formatNumber(dwt, { separateThousands: true }) :
					'-'),
				align: 'right',
			},
			{
				title: 'LOA',
				dataIndex: ['vessel', 'loa'],
				render: (loa: number) => (loa != null ?
					formatNumber(loa, { separateThousands: true }) :
					'-'),
				align: 'right',
			},
			{
				title: 'Open Port',
				dataIndex: ['openPort'],
				render: (openPort) => openPort?.name ?? '-',
			},
			{
				title: 'Open Date',
				className: styles.header,
				dataIndex: ['openDate'],
				render: (openDate) => (openDate != null ? formatDate(openDate) : '-'),
				sorter: standardSort('openDate'),
			},
			{
				title: 'TC In',
				className: styles.header,
				children: [
					{
						title: 'Identifier',
						dataIndex: ['tcIn'],
						render: (tcIn) => (
							tcIn == null ? 'No contract' : (
								<Space size="small">
									<Link to={Links.Voyage.get(tcIn.id)}>{tcIn?.identifier ?? 'Unnamed'}</Link>
								</Space>
							)
						),
					},
					{
						title: 'Hire Rate',
						dataIndex: ['tcIn', 'hireRate'],
						render: (hireRate) => (hireRate.rate != null ? formatCurrency(hireRate.rate, hireRate.currency) : '-'),
						align: 'right',
					},
					{
						title: 'Owner',
						className: styles.header,
						dataIndex: ['tcIn', 'owner'],
						render: (owner) => owner ?? '-',
					},
				],
			},
			{
				title: 'Current Contract',
				className: styles.header,
				children: [
					{
						title: 'Type',
						dataIndex: ['currentContract', 'type'],
						render: (type) => (type != null ? fixtureTypeToPascalCase(type) : '-'),
					},
					{
						title: 'Identifier',
						dataIndex: ['currentContract'],
						render: (contract) => (
							(contract == null || contract.identifier == null) ? 'No contract' : (
								<Space size="small">
									<Link to={Links.Voyage.get(contract.id)}>{contract.identifier}</Link>
								</Space>
							)
						),
					},
					{
						title: 'Charterer(s)',
						dataIndex: ['currentContract', 'charterers'],
						render: (charterers) => (charterers != null ? (
							<Flex vertical gap={5}>
								{charterers.map((
									c: {
										name: string;
										id: number;
										type: Values<typeof FixtureCounterpartyTypes>;
									},
								) => (
									<Link to={Links.Counterparty.get(c.id, c.type)}>{c.name}</Link>
								))}
							</Flex>
						) : '-'),
					},
					{
						title: 'Cargo(s)',
						dataIndex: ['currentContract', 'cargos'],
						render: (cargos) => (cargos != null ? (
							<Flex vertical gap={5}>
								{cargos.map((c: {type: string; id: number}) => (
									<Link to={Links.Cargo.get(c.id)}>{c.type}</Link>
								))}
							</Flex>
						) : '-'),
					},
					{
						className: styles.header,
						title: 'Redelivering',
						dataIndex: ['currentContract', 'redelivering'],
						render: (redelivering) => (redelivering ? 'Yes' : 'No'),
					},
				],
			},
			{
				title: 'Earliest Redelivery',
				dataIndex: 'earliestRedelivery',
				render: (date) => (date != null ? formatDate(date) : '-'),
				sorter: standardSort('earliestRedelivery'),
			},
			{
				title: 'Latest Redelivery',
				dataIndex: 'latestRedelivery',
				render: (date) => (date != null ? formatDate(date) : '-'),
				sorter: standardSort('latestRedelivery'),
			},
			{
				title: 'Redelivery Port',
				dataIndex: 'redeliveryPort',
				render: (port) => port?.name ?? '-',
			},
		] as ColumnsType<GetOpenPositionsDataResponse['data'][number]>;
	}, [data]);

	if (isLoading || isFetching || data == null) {
		return (
			<Flex vertical justify="center" align="center" gap={5}>
				<LoadingIndicator fullHeight size="large" />
				<Typography.Title level={3}>Loading data..</Typography.Title>
			</Flex>
		);
	}

	return (
		<Table
			size="small"
			dataSource={data.data}
			className={styles.table}
			columns={columns}
			pagination={false}
			bordered
			scroll={{ x: true }}
		/>
	);
};

export default OpenPositionsDashboard;
