import React from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import {
	DndContext,
	DragEndEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import Table from '@client/components/Table/Table';
import styles from './ItineraryTab.module.css';
import { useItinerary } from './ItineraryProvider';
import ItineraryControls from './components/ItineraryControls';

const extractRowAttributes = (v: string) => {
	const [id, _updatedAt] = v.split(',');

	return {
		id: Number(id),
	};
};

const Itinerary = () => {
	const {
		movePortCall,
		portCalls,
		columns,
	} = useItinerary();

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1,
			},
		}),
	);

	// eslint-disable-next-line max-len
	const TableRow = (tableRowProps: any) => {
		// eslint-disable-next-line react/destructuring-assignment
		const key = tableRowProps['data-row-key'];
		const portCall = portCalls.find((pc) => pc.id === Number(key));

		const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
			id: key,
			disabled: portCall?.isCanalTransit,
		});

		const style: React.CSSProperties = {
			transform: CSS.Translate.toString(transform),
			transition,
			cursor: portCall?.isCanalTransit ? undefined : 'move',
			...(isDragging ? { position: 'relative', zIndex: 9999, backgroundColor: 'white' } : {}),
		};

		return (
			<tr
				key={key}
				{...tableRowProps}
				ref={setNodeRef}
				style={style}
				{...attributes}
				{...listeners}
			/>
		);
	};

	const onDragEnd = async ({ active, over }: DragEndEvent) => {
		if (over == null) {
			return;
		}

		const { id: overId } = extractRowAttributes(over.id.toString());
		const { id: activeId } = extractRowAttributes(active.id.toString());

		movePortCall(activeId, overId);
	};

	return (
		<Row>
			<Col span={24}>
				<Row align="middle">
					<Col span={24}>
						<Card size="small" className={styles.itineraryControls}>
							<ItineraryControls />
						</Card>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<DndContext
					sensors={sensors}
					modifiers={[restrictToVerticalAxis]}
					onDragEnd={onDragEnd}
				>
					<SortableContext
						items={portCalls.map((i) => i.id)}
						strategy={verticalListSortingStrategy}
					>
						<Table
							components={{
								body: {
									row: TableRow,
								},
							}}
							dataSource={portCalls}
							rowKey="id"
							size="small"
							pagination={false}
							columns={columns}
						/>
					</SortableContext>
				</DndContext>
			</Col>
		</Row>
	);
};

export default Itinerary;
