import React, {
	useMemo,
	useState,
} from 'react';
import {
	Badge,
	Flex,
	Space,
} from 'antd';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-thin-svg-icons';
import {
	FeatureFlags,
	FixtureTypes,
	PortActionTypes,
	VcContractCompletionTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import isItemPortCall from '@shared/utils/isItemPortCall';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import { capitalize } from '@shared/utils/string';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import Select from '@client/components/Select';
import CountryFlag from '@client/components/CountryFlag';
import DatePicker from '@client/components/DatePicker';
import {
	selectPortCallAction,
	updateVoyage,
} from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import Details from '@client/components/Details';
import { Links } from '@client/utils/links';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import Button from '@client/components/Button';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import { useItinerary } from './ItineraryTab/ItineraryProvider';
import styles from './styles/SelectItineraryEntryAndDates.module.css';
import VoyageSelector from './VoyageSelector/VoyageSelector';

type Props = {
	labelWidth?: number | string;
	columns?: number;
}

const SelectItineraryEntryAndDates = ({
	labelWidth = 300,
	columns,
}: Props) => {
	const [updateLoading, setUpdateLoading] = useState(false);
	const usesTheShip = useFeatureFlag(FeatureFlags.THE_SHIP);
	const {
		itinerary,
		itineraryLoading: loading,
		setItineraryShouldUpdate,
		refreshItinerary,
	} = useItinerary();

	const {
		voyageDetails,
		refreshVoyageDetails,
		fixtureDetails,
		updateVoyageField,
		portOptions,
		allVoyages,
		linkTheShip,
	} = useVoyage();

	const { previousVoyage } = voyageDetails;
	const { nextVoyage } = voyageDetails;

	const {
		setShowCommencementLinking,
	} = useItinerary();

	const filteredVoyages = useMemo(() => {
		if (allVoyages == null) {
			return [];
		}

		return allVoyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, allVoyages]);

	const [completionType, setCompletionType] = useState<
		VcContractCompletionTypes | null
	>(voyageDetails.completionType);

	const portCalls = useMemo(() => {
		return itinerary.filter((entry) => (
			isItemPortCall(entry) && !entry.isCanalTransit
		)) as ItineraryPortCallDto[];
	}, [itinerary]);

	const onSelectEntry = async (
		type: Values<typeof PortActionTypes>,
		portCallId: number | null,
	) => {
		setUpdateLoading(true);

		try {
			await selectPortCallAction(type, voyageDetails.id, portCallId);
			showSuccessNotification(`${capitalize(type)} selected`);
			await refreshVoyageDetails();
			await refreshItinerary();
			setItineraryShouldUpdate(true);
		} catch (e) {
			showErrorNotification('Could not update delivery/redelivery', e as Error);
		} finally {
			setUpdateLoading(false);
		}
	};

	const matchActionWithEntry = useMemo(() => {
		const delivery = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.DELIVERING)
		));

		const redelivery = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.REDELIVERING)
		));

		const commencement = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.COMMENCEMENT)
		));

		const completion = portCalls.find((entry) => (
			entry.actions.some((action) => action.action === PortActionTypes.COMPLETION)
		));

		return {
			commencement,
			delivery,
			redelivery,
			completion,
		};
	}, [portCalls]);

	const completionEntry = useMemo(() => {
		if (fixtureDetails.type === FixtureTypes.SPOT) {
			return matchActionWithEntry.completion;
		}

		return matchActionWithEntry.redelivery;
	}, [matchActionWithEntry, fixtureDetails]);

	const isSpot = fixtureDetails.type === FixtureTypes.SPOT;
	const isTcInVoyage = fixtureDetails.type === FixtureTypes.TC_IN;
	const isTcInVessel = voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN;
	const tcInRedelivery = voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY;

	const onChangeCompletionType = async (type: VcContractCompletionTypes | null) => {
		const currentType = completionType;
		setCompletionType(type);

		try {
			await updateVoyage(voyageDetails.id, { completionType: type });
			showSuccessNotification('Completion type updated');
			await refreshVoyageDetails();
		} catch (e) {
			setCompletionType(currentType);
			showErrorNotification('Could not update completion type', e as Error);
		}
	};

	const allItems = [
		{
			visible: true,
			key: 'previousVoyage',
			label: (
				<Flex gap={5} align="center">
					<FontAwesomeIcon icon={faArrowLeft} />
					Prev. Contract
					<Button
						type="link"
						icon={(<FontAwesomeIcon size="lg" icon={faLink} />)}
						onClick={() => setShowCommencementLinking(true)}
					/>
				</Flex>
			),
			value: previousVoyage == null ? ' - ' : (
				// eslint-disable-next-line react/forbid-component-props
				<Space style={{ padding: 4 }}>
					<Link to={Links.Voyage.get(previousVoyage.id)}>
						{previousVoyage?.identifier ?? ' - '}
					</Link>
				</Space>
			),
		},
		{
			visible: true,
			key: 'nextVoyage',
			label: (
				<Flex gap={5} align="center">
					Next Contract
					<FontAwesomeIcon icon={faArrowRight} />
				</Flex>
			),
			value: nextVoyage == null ? ' - ' : (
				// eslint-disable-next-line react/forbid-component-props
				<Space style={{ padding: 4 }}>
					<Link to={Links.Voyage.get(nextVoyage.id)}>
						{nextVoyage?.identifier ?? ' - '}
					</Link>
				</Space>
			),
		},
		{
			visible: isTcInVessel && !isTcInVoyage,
			key: 'linkedTcIn',
			label: (
				<Flex vertical>
					Linked TC-in voyage
					{voyageDetails.linkedTcInVoyageId != null && (
						<Link target="_blank" to={`${Links.Voyage.get(voyageDetails.linkedTcInVoyageId)}#/recap`}>
							View TC-In recap
						</Link>
					)}
				</Flex>

			),
			value: (
				<VoyageSelector
					type="linkedTcIn"
					defaultValue={voyageDetails?.linkedTcInVoyageId}
					voyages={(filteredVoyages ?? []).filter((v) => v.fixtureType === FixtureTypes.TC_IN)}
					allowClear
					onSelect={async (c) => {
						await updateVoyageField('linkedTcInVoyageId', c);
						await refreshItinerary();
						setItineraryShouldUpdate(true);
					}}
				/>
			),
		},
		{
			visible: isTcInVessel && !isTcInVoyage,
			key: 'completionType',
			label: 'Redeliver the vessel on TC IN to the owner',
			value: (
				<Select
					value={completionType}
					key={completionType}
					onChange={onChangeCompletionType}
					options={[
						{
							label: 'Redeliver the vessel',
							value: VcContractCompletionTypes.TC_IN_DELIVERY,
							disabled: voyageDetails.linkedTcInVoyageId == null,
						},
						{ label: 'No', value: VcContractCompletionTypes.NEXT_CONTRACT },
					]}
				/>
			),
		},
		{
			visible: !isTcInVoyage,
			key: '10',
			label: 'Commencement',
			value: (
				<Select
					placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
					showSearch
					loading={loading || updateLoading}
					key={matchActionWithEntry.commencement?.id}
					defaultValue={matchActionWithEntry.commencement?.id}
					allowClear
					disabled
					className={styles.fullWidth}
					onChange={(value) => onSelectEntry(PortActionTypes.COMMENCEMENT, value)}
					optionRender={(option) => (
						<Space direction="vertical" size={0}>
							<Space>
								<CountryFlag countryCode={option.data.country} />
								{option.label}
							</Space>
							{option.data.departureDate && (
								<div style={{ fontSize: 11 }}>
									Departure:
									{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
								</div>
							)}
						</Space>
					)}
					options={portCalls.map((pc) => ({
						value: pc.id,
						label: pc.port.name,
						departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
						country: pc.port.countryCode,
						disabled: matchActionWithEntry.commencement?.id === pc.id,
					}))}
				/>
			),
		},
		{
			visible: isSpot,
			key: '11',
			label: 'Completion',
			value: (
				<Select
					placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
					defaultValue={completionEntry?.id}
					onChange={(value) => onSelectEntry(PortActionTypes.COMPLETION, value)}
					key={completionEntry?.id}
					className={styles.fullWidth}
					disabled={
						portCalls.length === 0 ||
							fixtureDetails.type !== FixtureTypes.SPOT ||
							voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY
					}
					loading={loading || updateLoading}
					showSearch
					allowClear
					optionRender={(option) => (
						<Space direction="vertical" size={0}>
							<Space>
								<CountryFlag countryCode={option.data.country} />
								{option.label}
							</Space>
							{option.data.departureDate && (
								<div style={{ fontSize: 11 }}>
									Departure:
									{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
								</div>
							)}
						</Space>
					)}
					options={portCalls.map((pc) => ({
						value: pc.id,
						label: pc.port.name,
						departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
						country: pc.port.countryCode,
						disabled: matchActionWithEntry.completion?.id === pc.id,
					}))}
				/>
			),
		},
		{
			visible: !isTcInVoyage,
			key: '1',
			label: 'Commencement date',
			value: (
				<DatePicker
					placeholder="Commencement date"
					className={styles.fullWidth}
					value={voyageDetails?.commencementDate}
					onChange={(date) => updateVoyageField('commencementDate', date)}
					time
					disabled={!isTcInVoyage}
				/>
			),
		},
		{
			visible: isTcInVoyage,
			key: 'deliveryPort',
			label: 'Delivery Port',
			value: (
				<Select
					showSearch
					placeholder="Select Delivery Port"
					options={portOptions}
					onChange={(port) => updateVoyageField('deliveryPort', port)}
					defaultValue={voyageDetails.deliveryPort?.id}
				/>
			),
		},
		{
			visible: !isTcInVoyage && !isSpot,
			key: '0',
			label: 'Delivery',
			value: (
				<Select
					placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
					showSearch
					loading={loading || updateLoading}
					key={matchActionWithEntry.delivery?.id}
					defaultValue={matchActionWithEntry.delivery?.id}
					allowClear
					disabled={portCalls.length === 0}
					className={styles.fullWidth}
					onChange={(value) => onSelectEntry(PortActionTypes.DELIVERING, value)}
					optionRender={(option) => (
						<Space direction="vertical" size={0}>
							<Space>
								<CountryFlag countryCode={option.data.country} />
								{option.label}
							</Space>
							{option.data.departureDate && (
								<div style={{ fontSize: 11 }}>
									Departure:
									{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
								</div>
							)}
						</Space>
					)}
					options={portCalls.map((pc) => ({
						value: pc.id,
						label: pc.port.name,
						departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
						country: pc.port.countryCode,
						disabled: matchActionWithEntry.delivery?.id === pc.id,
					}))}
				/>
			),
		},
		{
			visible: !isSpot,
			key: '1',
			label: 'Delivery date',
			value: (
				<DatePicker
					placeholder="Delivery date"
					allowClear
					className={styles.fullWidth}
					defaultValue={voyageDetails?.deliveryDate}
					onChange={(date) => updateVoyageField('deliveryDate', date)}
					time
					forceOnChange
				/>
			),
		},
		{
			visible: isTcInVoyage,
			key: 'redeliveryPort',
			label: 'Redelivery Port',
			value: (
				<Select
					showSearch
					placeholder="Select Redelivery Port"
					options={portOptions}
					onChange={(port) => updateVoyageField('redeliveryPort', port)}
					defaultValue={voyageDetails.redeliveryPort?.id}
				/>
			),
		},
		{
			visible: !isTcInVoyage && !isSpot,
			key: '-1',
			label: 'Redelivery',
			value: (
				<Select
					placeholder={portCalls.length === 0 ? 'Create a port call first' : 'Select port call'}
					defaultValue={matchActionWithEntry.redelivery?.id}
					onChange={(value) => onSelectEntry(PortActionTypes.REDELIVERING, value)}
					key={matchActionWithEntry.redelivery?.id}
					className={styles.fullWidth}
					disabled={
						portCalls.length === 0
					}
					loading={loading || updateLoading}
					allowClear
					showSearch
					optionRender={(option) => (
						<Space direction="vertical" size={0}>
							<Space>
								<CountryFlag countryCode={option.data.country} />
								{option.label}
							</Space>
							{option.data.departureDate && (
								<div style={{ fontSize: 11 }}>
									Departure:
									{toMoment(option.data.departureDate).format('DD/MM/YYYY')}
								</div>
							)}
						</Space>
					)}
					options={portCalls.map((pc) => ({
						value: pc.id,
						label: pc.port.name,
						departureDate: pc.departureDate ?? pc.estimatedDepartureDate,
						country: pc.port.countryCode,
						disabled: matchActionWithEntry.redelivery?.id === pc.id,
					}))}
				/>
			),
		},
		{
			visible: !isSpot,
			key: '2',
			label: `Redelivery date ${tcInRedelivery ? '(TC-Out)' : ''}`,
			value: (
				<DatePicker
					placeholder="Redelivery date"
					className={styles.fullWidth}
					allowClear
					defaultValue={voyageDetails?.redeliveryDate}
					onChange={(date) => updateVoyageField('redeliveryDate', date)}
					time
					forceOnChange
				/>
			),
		},

		{
			visible: isSpot,
			key: voyageDetails.completionDate?.toISOString() ?? 'completionDate',
			label: 'Completion date',
			value: (
				<DatePicker
					key={`CD-${voyageDetails?.completionDate?.toLocaleString() ?? nowMoment().toLocaleString()}`}
					placeholder="Completion date"
					className={styles.fullWidth}
					disabled
					value={voyageDetails?.completionDate}
					onChange={(date) => updateVoyageField('completionDate', date)}
					time
				/>
			),
		},
		{
			visible: usesTheShip,
			key: '3',
			label: 'TheShip Integration',
			value: voyageDetails.vesselExternalId ? (
				<Badge status="success" text="Connected" />
			) : (
				<Space>
					<Badge status="error" text="Not connected" />
					<Button
						onClick={linkTheShip}
						type="link"
						icon={(<LinkOutlined />)}
					>
						Connect
					</Button>
				</Space>
			),
		},
	];

	/* const items = [
		...commencementItems,
		...(isSpot ? [] : [
			// only show delivery and redelivery items when not VCO
			...deliveryItems,
			...redeliveryItems,
		]),
		...completionItems,
	];
 */

	const filtered = allItems.filter((a) => a.visible);

	return (
		<Details
			column={columns ?? 4}
			title={null}
			items={filtered}
			className={styles.details}
			labelWidth={labelWidth}
		/>
	);
};

export default SelectItineraryEntryAndDates;
