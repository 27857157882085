import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Form,
	FormInstance,
} from 'antd';
import { FuelTypes } from '@shared/utils/constants';
import Button from '@client/components/Button';
import Select from '@client/components/Select';
import {
	ItineraryPortCallDto,
	NullableBunkerRecord,
} from '@client/screens/estimates/details/helpers/types';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	createPortCallAction,
	createRobs,
	updatePortCallAction,
	updateRob,
} from '@client/lib/api';
import DatePicker from '@client/components/DatePicker';
import { useVoyage } from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageProvider/VoyageProvider';
import RobTable from '@client/screens/fleet/VoyageDetailsScreen/tabs/components/RobTable';
import { BunkerRecord } from '@client/screens/fleet/VoyageDetailsScreen/tabs/components/RobEntryForm';
import { useItinerary } from '../ItineraryProvider';

const ActivityWithRobsForm = ({
	form,
	editingAction,
	afterOnSave,
}: {
	form: FormInstance;
	editingAction: ItineraryPortCallDto['actions'][number] | null;
	afterOnSave: () => void;
}) => {
	const editingRob = editingAction?.Robs[0];

	const formattedRobs = useMemo(() => {
		if (editingRob == null) {
			return [];
		}

		return editingRob.RobBunkers.map((rb) => ({
			id: rb.id,
			fuelGrade: rb.fuelGrade,
			quantity: rb.totalQuantity,
		}));
	}, [editingRob]);

	const [robs, setRobs] = useState<NullableBunkerRecord[]>(formattedRobs);
	const {
		expandedEntry,
		refreshItinerary,
	} = useItinerary();

	useEffect(() => {
		if (editingAction) {
			form.setFieldsValue(editingAction);
		} else {
			form.resetFields();
			setRobs([]);
		}
	}, [form, editingAction]);

	const {
		cargos,
		voyageDetails,
		fixtureCurrency,
		vessel,
	} = useVoyage();

	const selectedPortCall = expandedEntry as ItineraryPortCallDto;
	const isEdit = !!editingAction;

	const onSave = async () => {
		const {
			action,
			actionDate,
			cargoId,
		} = form.getFieldsValue(true);

		if (expandedEntry == null || vessel == null) {
			return;
		}

		try {
			const filteredEntries = robs.filter(
				(entry): entry is BunkerRecord => entry.quantity !== null,
			);

			if (isEdit) {
				await updatePortCallAction(
					expandedEntry.vesselId,
					expandedEntry.id,
					editingAction.id,
					action,
					actionDate,
					cargoId,
				);

				if (editingRob != null) {
					await updateRob({
						vesselId: vessel.id,
						voyageId: voyageDetails.id,
						robId: editingRob.id,
						currency: fixtureCurrency,
						attributes: {
							remainingOnBoard: filteredEntries,
						},
						deleteMissingEntries: false,
					});
				} else {
					await createRobs({
						vesselId: vessel.id,
						voyageId: voyageDetails.id,
						event: action,
						port: selectedPortCall.port,
						date: actionDate,
						currency: fixtureCurrency,
						robs: filteredEntries,
						bunkersReceived: [],
						portCallActionId: editingAction.id,
						portCallId: selectedPortCall.id,
					});
				}
			} else {
				const actionId = await createPortCallAction(
					expandedEntry?.vesselId,
					expandedEntry?.id,
					action,
					actionDate,
					cargoId,
				);

				await createRobs({
					vesselId: vessel.id,
					voyageId: voyageDetails.id,
					event: action,
					port: selectedPortCall.port,
					date: actionDate,
					currency: fixtureCurrency,
					robs: filteredEntries,
					bunkersReceived: [],
					portCallActionId: actionId,
					portCallId: selectedPortCall.id,
				});
			}

			await refreshItinerary();
			afterOnSave();
		} catch (e) {
			showErrorNotification('Could not create action', e as Error);
		} finally {
			form.resetFields();
		}
	};

	const deleteEntry = (record: NullableBunkerRecord): void => {
		if (record == null) {
			return;
		}

		const index = robs.findIndex(
			(entry) => entry.fuelGrade === record.fuelGrade &&
                    entry.quantity === record.quantity &&
                    entry.pricePerTon === record.pricePerTon,
		);

		if (index !== -1) {
			const newArr = [...robs];
			newArr.splice(index, 1);
			setRobs(newArr);
		}
	};

	const fuelOptions = Object.keys(FuelTypes).map((key) => ({
		label: FuelTypes[key],
		value: key,
	}));

	return (
		<>
			<Form.Item label="Date" name="actionDate">
				<DatePicker time />
			</Form.Item>
			<Form.Item label="Cargo (optional)" name="cargoId">
				<Select
					showSearch
					placeholder="Select cargo"
					options={(cargos ?? []).map((c) => ({
						label: c.type,
						value: c.id,
					}))}
				/>
			</Form.Item>
			<RobTable
				label="ROB"
				dataSource={robs}
				onChange={setRobs}
				onDelete={deleteEntry}
				fixtureCurrency={fixtureCurrency}
				fuelOptions={fuelOptions}
				showPricePerTon={false}
			/>
			<Button onClick={onSave} type="primary">
				{isEdit ? 'Update activity' : 'Create Activity'}
			</Button>
		</>
	);
};

export default ActivityWithRobsForm;
