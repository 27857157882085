import type HIIFreight from '@shared/hireInvoice/HIIFreight';
import {
	Currencies,
	FreightRateType,
} from './constants';
import { Values } from './objectEnums';

const calculateTotalFreight = (
	freight: HIIFreight,
	fixtureCurrency: Values<typeof Currencies>,
	convertCurrency?: boolean,
) => {
	let total = 0;

	if (freight.freightType === FreightRateType.PER_UNIT) {
		total = freight.quantity * freight.freightRate;
	} else {
		total = freight.freightRate;
	}

	if (convertCurrency && fixtureCurrency !== freight.currency) {
		total /= freight.exchangeRate;
	}

	return total;
};

export default calculateTotalFreight;
