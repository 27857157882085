import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import isEqual from 'lodash.isequal';
import { formatCurrency } from '@shared/utils/currency';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { trimString } from '@shared/utils/string';
import type {
	TransformedEUAInvoices,
	TransformedHireInvoices,
} from '@api/features/voyages/getVoyageDetails';
import Select from '@client/components/Select';
import CurrencyInput from '@client/components/CurrencyInput';
import Button from '@client/components/Button';
import styles from './styles/PaymentInvoiceInput.module.css';

const getNewRow = () => ({
	key: Math.random(),
	dirty: false,
	hireInvoiceId: -1,
});

type Row = {
	key: string | number;
	dirty: boolean;
	hireInvoiceId: number | null;
	amount?: number | null;
	originalAmount?: number | null;
}

const getInitialState = (value: Value[] | undefined) => {
	if (value != null && value.length > 0) {
		return value.map((val) => ({
			key: Math.random(),
			dirty: true,
			hireInvoiceId: val.hireInvoiceId,
			amount: val.amount,
			originalAmount: val.amount,
		}));
	}

	return [getNewRow()];
};

const statesAreEqual = (value: Value[] | undefined, internalRows: Row[] | undefined) => {
	if (value && value.length > 0) {
		const initialState = value.map((val) => ({
			hireInvoiceId: val.hireInvoiceId,
			amount: val.amount,
			originalAmount: val.amount,
		}));

		const transformedRows = internalRows?.map(({ hireInvoiceId, amount, originalAmount }) => ({
			hireInvoiceId,
			amount,
			originalAmount,
		}));

		return isEqual(initialState, transformedRows);
	}

	return true;
};

type Value = { hireInvoiceId: number | null; amount: number | null }

type Props = {
	hireInvoices: TransformedHireInvoices | TransformedEUAInvoices;
	totalAmount: number | null;
	currency: Values<typeof Currencies>;
	value?: Value[];
	onChange?: (values: Value[] | undefined) => void;
}

const PaymentInvoiceInput = ({
	hireInvoices,
	totalAmount,
	onChange,
	currency,
	value,
}: Props) => {
	const [initialTotalAmount, setInitialTotalAmount] = useState(totalAmount);
	const [internalRows, setInternalRows] = useState<Row[] | undefined>(getInitialState(value));

	const updateAmount = (rowKey: string | number) => (newAmount: number | null) => {
		setInternalRows((oldRows) => oldRows?.map((row) => {
			if (row.key !== rowKey) {
				return row;
			}

			return {
				...row,
				amount: newAmount,
				dirty: true,
			};
		}));
	};

	const updateInvoiceId = (rowKey: string | number) => (newInvoiceId: number | null) => {
		setInternalRows((oldRows) => oldRows?.map((row) => (row.key === rowKey ? ({
			...row,
			hireInvoiceId: newInvoiceId,
		}) : row)));
	};

	useEffect(() => {
		if (
			statesAreEqual(value, internalRows) &&
			totalAmount === initialTotalAmount
		) {
			return;
		}

		if (internalRows?.every((row) => row.hireInvoiceId === -1)) {
			onChange?.([{
				hireInvoiceId: -1,
				amount: totalAmount,
			}]);

			return;
		}

		if (
			internalRows?.length === 1 &&
			internalRows[0].hireInvoiceId != null &&
			internalRows[0]?.hireInvoiceId > 0
		) {
			const [row] = internalRows;

			onChange?.([{
				hireInvoiceId: row.hireInvoiceId,
				amount: totalAmount,
			}]);

			return;
		}

		onChange?.(internalRows?.map((row) => ({
			hireInvoiceId: row.hireInvoiceId,
			amount: row.amount ?? null,
		})));
		// Don't update every time onChange is updated
		// We don't control that prop, since we're using Ant Forms
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalRows, totalAmount]);

	const selectedInvoices = useMemo(() => (
		internalRows?.map((row) => row.hireInvoiceId)
	), [internalRows]);

	const addRow = () => setInternalRows((oldRows) => [
		...(oldRows ?? []),
		getNewRow(),
	]);

	const isLarge = internalRows != null &&
		(internalRows?.length > 1 || internalRows?.[0].hireInvoiceId === null);

	return (
		<div>
			<div className={styles.inputs}>
				<div className={styles.invoices}>
					{internalRows?.map(({ key, hireInvoiceId }) => {
						return (
							<Select
								key={key}
								className={styles.invoiceInput}
								value={hireInvoiceId}
								onChange={updateInvoiceId(key)}
								placeholder="Invoice"
								// @ts-ignore
								defaultValue="No invoice"
								allowClear={internalRows.length > 1}
								onClear={() => {
									setInternalRows(internalRows.filter((row) => row.key !== key));
									setInitialTotalAmount(0);
								}}
								popupMatchSelectWidth={false}
								optionLabelProp="selectedLabel"
								size={isLarge ? 'large' : 'middle'}
								options={[
									{
										label: 'No invoice',
										// @ts-ignore
										selectedLabel: 'No invoice',
										value: -1,
									},
									...hireInvoices.map((hireInvoice) => {
										if (hireInvoice.currency === currency) {
											return {
												label: (
													<div className={styles.invoiceWrapper}>
														{hireInvoice.invoiceIdentifier}
														<span className={styles.outstanding}>
															{`${formatCurrency(
																hireInvoice.amount,
																currency,
															)} invoiced`}
														</span>
													</div>
												),
												value: hireInvoice.id,
												disabled: selectedInvoices?.includes(hireInvoice.id),
												selectedLabel: (
													<span className={styles.invoiceOptionLabel}>
														<span title={hireInvoice.invoiceIdentifier}>
															{trimString(hireInvoice.invoiceIdentifier, 40, true)}
														</span>
														{' '}
														<span className={styles.outstanding}>
															{formatCurrency(hireInvoice.amount, currency)}
														</span>
													</span>
												),
											};
										}

										return {};
									}),
								]}
							/>
						);
					})}
				</div>
				{(internalRows != null && internalRows.length > 1) && (
					<div className={styles.amounts}>
						{internalRows.map(({ key, amount }) => (
							<CurrencyInput
								currency={currency}
								key={key}
								className={styles.amountInput}
								size="large"
								value={amount}
								onChange={updateAmount(key)}
								allowNegative
							/>
						))}
					</div>
				)}
			</div>
			{(internalRows != null && internalRows.length !== hireInvoices.length + 1) && (
				<Button
					onClick={addRow}
					type="link"
					className={styles.addButton}
					icon={(<PlusOutlined />)}
				>
					Split payment
				</Button>
			)}
		</div>
	);
};

export default PaymentInvoiceInput;
