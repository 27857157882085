import React from 'react';
import {
	Col,
	Form,
	Row,
	Skeleton,
} from 'antd';
import { Moment } from 'moment';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
	SofActions,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import DatePicker from '@client/components/DatePicker';
import Select from '@client/components/Select';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import Button from '@client/components/Button';
import { rules } from '@client/utils/form';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getPorts } from '@client/lib/api';
import { NullableBunkerRecord } from '@client/screens/estimates/details/helpers/types';
import RobTable from './RobTable';

type Props = {
	eventType: Values<typeof CrewReportTypes> | Values<typeof SofActions> | null;
	fixtureCurrency: Values<typeof Currencies>;
	newRobs: NullableBunkerRecord[];
	onDeleteRob: (rob: boolean, record: NullableBunkerRecord) => void;
	setNewRobs: (records: NullableBunkerRecord[]) => void;
	onSaveRob: () => void;
	setBunkersReceived: (records: NullableBunkerRecord[]) => void;
	bunkersReceived: NullableBunkerRecord[];
	selectedDate: Moment | null;
	acceptableFuels: Array<Values<typeof FuelTypes>>;
	voyageDetails: GetVoyageDetailsResponse | undefined;
}

const RobEntryFormItems = ({
	eventType,
	fixtureCurrency,
	newRobs,
	onDeleteRob,
	setNewRobs,
	onSaveRob,
	setBunkersReceived,
	bunkersReceived,
	selectedDate,
	acceptableFuels,
	voyageDetails,
}: Props) => {
	const [ports] = useFetchedState(getPorts);

	const canSetPricePerTon = eventType === CrewReportTypes.DELIVERY ||
	eventType === CrewReportTypes.REEVALUATION ||
	eventType === CrewReportTypes.COMMENCEMENT;

	const canEdit = (
		eventType === CrewReportTypes.BUNKERING ||
		eventType === CrewReportTypes.BUNKERS_ROB ||
		eventType === CrewReportTypes.REDELIVERY ||
		eventType === CrewReportTypes.REEVALUATION
	);

	let fuelOptions = Object.keys(FuelTypes).map((key) => ({
		label: FuelTypes[key],
		value: key,
	}));

	if (voyageDetails?.linkedTcInVoyageId != null) {
		const uniqueFuels = Array.from(new Set([
			...(acceptableFuels ?? []),
			...(bunkersReceived ?? []).map((b) => b.fuelGrade),
		]));

		fuelOptions = uniqueFuels.map((f) => ({
			value: f,
			label: f,
		}));
	}

	return (
		<Row gutter={[16, 16]}>
			{eventType !== CrewReportTypes.COMMENCEMENT && (
				<>
					<Col span={12}>
						<Form.Item
							name="event"
							shouldUpdate
							label="Event"
							rules={[rules.required]}
						>
							<Select
								disabled={eventType != null && !canEdit}
								showSearch
								options={[
									{ label: CrewReportTypes.BUNKERING, value: CrewReportTypes.BUNKERING },
									{ label: CrewReportTypes.BUNKERS_ROB, value: CrewReportTypes.BUNKERS_ROB },
								]}
								placeholder="Select Event"
							/>
						</Form.Item>
					</Col>
					{eventType !== CrewReportTypes.BUNKERS_ROB && (
						<Col span={12}>
							<Form.Item
								name="port"
								shouldUpdate
								label="Port"
								rules={[rules.required]}
							>
								<Select
									disabled={!canEdit}
									showSearch
									options={ports == null ? [] : getPortOptions(ports)}
									placeholder="Select Port"
								/>
							</Form.Item>
						</Col>
					)}
					<Col span={12}>
						<Form.Item
							name="date"
							label="Date"
							valuePropName="date"
							rules={[rules.required]}
						>
							<DatePicker
								time
								placeholder="Select date"
								defaultPickerValue={selectedDate ?? undefined}
								value={selectedDate}
								disabled={!canEdit}
							/>
						</Form.Item>
					</Col>
				</>
			)}
			<Col span={24}>
				{eventType == null ? (
					<>
						<p>* Select event type to enter ROB&apos;s</p>
						<Skeleton />
					</>
				) : (
					<>
						{(
							eventType === CrewReportTypes.BUNKERING ||
							eventType === CrewReportTypes.REDELIVERY
						) && (
							<RobTable
								showPricePerTon
								label={eventType === CrewReportTypes.BUNKERING ? 'Bunkers Received' : 'Bunkers Sold'}
								dataSource={bunkersReceived}
								onChange={setBunkersReceived}
								onDelete={(record) => onDeleteRob(false, record)}
								fuelOptions={fuelOptions}
								fixtureCurrency={fixtureCurrency}
							/>
						)}
						<RobTable
							showPricePerTon={canSetPricePerTon}
							label={`${eventType === CrewReportTypes.BUNKERING ? 'ROB (including bunkered quantities)' : 'ROB'}`}
							dataSource={newRobs}
							onChange={setNewRobs}
							onDelete={(record) => onDeleteRob(true, record)}
							fuelOptions={fuelOptions}
							fixtureCurrency={fixtureCurrency}
						/>
					</>
				)}
			</Col>
			<Col span={24}>
				<Button
					type="primary"
					onClick={onSaveRob}
				>
					Save & close
				</Button>
			</Col>
		</Row>
	);
};

export default RobEntryFormItems;
