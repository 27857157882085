import React from 'react';
import classNames from 'classnames';
import styles from './styles/Legend.module.css';

type Props = {
	items: Array<{
		key?: string;
		label: string;
		fill?: string;
	}>;
	position?: 'center' | 'end' | 'flex-end' | 'flex-start' | 'start';
	bordered?: boolean;
}

const ChartLegend = ({
	items,
	position = 'center',
	bordered = false,
}: Props) => (
	<div
		className={styles.chartLegend}
		style={{ justifyContent: position === 'center' ? 'center' : 'flex-start' }}
	>
		{items.map((item) => (
			<div
				key={item.key || item.label}
				className={styles.legendItem}
			>
				<div
					style={{ background: item.fill }}
					className={classNames(styles.legendSquare, { [styles.bordered]: bordered })}
				/>
				{item.label}
			</div>
		))}
	</div>
);

export default ChartLegend;
