import React, {
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Drawer,
	Flex,
	Form,
	Row,
	Space,
} from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGasPump } from '@fortawesome/pro-light-svg-icons';
import {
	CrewReportTypes,
	DATE_AND_TIME,
	PortActionTypes,
} from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import type { GetBunkerStemsResponse } from '@api/features/voyages/bunker-stems/getBunkerStems';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import Button from '@client/components/Button';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import { formatDate } from '@client/utils/formatDate';
import Select from '@client/components/Select';
import { useVoyage } from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageProvider/VoyageProvider';
import {
	deletePortCallAction,
	getBunkerStems,
	getSuppliers,
} from '@client/lib/api';
import CreateBunkerStemForm from '@client/components/CreateBunkerStemForm/CreateBunkerStemForm';
import { useAuth } from '@client/lib/auth';
import { useItinerary } from '../ItineraryProvider';
import { sortByFuelGrade } from '../utils/helpers';
import PortCallDetails from './PortCallDetails';
import ActivityWithRobsForm from './ActivityWithRobsForm';
import { getBunkerStemTableColumns } from './getColumns';

const PortCallSummaryTab = () => {
	const auth = useAuth();
	const [showActivityModal, setShowActivityModal] = useState(false);
	const [showBunkerDrawer, setShowBunkerDrawer] = useState(false);

	const [editingActionId, setEditingActionId] = useState<number | null>(null);
	const [editingStem, setEditingStem] = useState<
		GetBunkerStemsResponse[number] |
		undefined |
		null
	>();

	const {
		expandedEntry,
		refreshItinerary,
		setExpandedPortCallAction,
	} = useItinerary();

	const {
		cargos,
		voyageDetails,
		refreshDetails,
	} = useVoyage();

	const organizationName = auth?.userInfo?.organizationName;

	const selectedPortCall = expandedEntry as ItineraryPortCallDto;

	const {
		data: suppliers,
		isLoading: suppliersLoading,
		refetch: refreshSuppliers,
	} = useQuery('suppliers', getSuppliers, {
		refetchOnWindowFocus: false,
		retry: false,
	});

	const {
		data: bunkerStems,
		isLoading: bunkerStemsLoading,
		refetch: refreshStems,
	} = useQuery(
		['bunkerStems', voyageDetails?.id, selectedPortCall?.id],
		async () => {
			if (!voyageDetails?.id) {
				return [];
			}

			return await getBunkerStems({
				voyageId: voyageDetails.id,
				portCallId: selectedPortCall?.id,
			});
		},
		{
			refetchOnWindowFocus: false,
			retry: false,
			initialData: [],
		},
	);

	const [form] = Form.useForm();

	const { actions } = selectedPortCall;

	const editingAction = useMemo(() => {
		if (editingActionId == null) {
			return null;
		}

		const action = selectedPortCall.actions.find((a) => a.id === editingActionId);

		if (action == null) {
			return null;
		}

		return action;
	}, [selectedPortCall, editingActionId]);

	const onDelete = async (id: number) => {
		await deletePortCallAction(selectedPortCall.vesselId, selectedPortCall.id, id);
		await refreshItinerary();
		await refreshStems();
	};

	const onClose = async () => {
		setEditingActionId(null);
		await form.resetFields();
		setShowActivityModal(false);
	};

	const unDeletableActions = [
		...Object.values(PortActionTypes),
	];

	const { userInfo } = useAuth();

	const portActivityTypes = useMemo(() => (userInfo as UserData)?.portActivityTypes, [userInfo]);

	const sortedActions = useMemo(() => {
		const newestFirst = false;

		const sorted = actions.sort((a, b) => {
			if (a.actionDate === null && b.actionDate === null) {
				return 0;
			}

			if (a.actionDate === null) {
				return -1;
			}

			if (b.actionDate === null) {
				return 1;
			}

			// diff returns positive if a is after b.
			const diff = a.actionDate.diff(b.actionDate);

			return newestFirst ? -diff : diff;
		});

		return sorted;
	}, [actions]);

	return (
		<>
			<Row gutter={[8, 8]}>
				<Col span={12}>
					<Card styles={{ body: { overflowY: 'scroll' } }}>
						<PortCallDetails />
					</Card>
				</Col>
				<Col span={12}>
					<Card
						slim
						title="Activities & Stems"
						extra={(
							<Space>
								<Button
									loading={suppliersLoading || bunkerStemsLoading}
									onClick={() => setShowBunkerDrawer(true)}
									icon={(<FontAwesomeIcon icon={faGasPump as IconProp} />)}
								>
									Add Bunker Stem
								</Button>
								<Button
									onClick={() => setShowActivityModal(true)}
									icon={(<PlusOutlined />)}
									type="primary"
								>
									New Port Activity
								</Button>
							</Space>
						)}
					>
						<Table
							locale={{ emptyText: 'No activites registrered' }}
							size="small"
							rowKey="id"
							expandable={{
								onExpand(expanded, record) {
									if (expanded) {
										setExpandedPortCallAction(record);
									} else {
										setExpandedPortCallAction(null);
									}
								},
								expandedRowRender: (record) => {
									const rob = record.Robs[0];

									return (
										<Flex vertical gap={16}>
											{record.action === CrewReportTypes.BUNKER_STEM && (
												<Table
													pagination={false}
													columns={getBunkerStemTableColumns(
														suppliers,
														voyageDetails?.tcInContract,
														organizationName,
													)}
													dataSource={bunkerStems ?? []}
												/>
											)}
											<Table
												size="small"
												pagination={false}
												rowKey="id"
												dataSource={sortByFuelGrade(rob?.RobBunkers ?? [])}
												columns={[
													{
														title: 'Fuel Grade',
														dataIndex: 'fuelGrade',
													},
													{
														title: 'Quantity',
														dataIndex: 'adjustedQuantity',
														render: (v) => v.toFixed(3),
													},
												]}
											/>
										</Flex>
									);
								},
								rowExpandable: (record) => {
									const rob = record.Robs[0];

									if (rob == null) {
										return false;
									}

									if (rob.RobBunkers.length === 0) {
										return false;
									}

									return true;
								},
							}}
							columns={[
								{
									title: 'Activity',
									dataIndex: 'action',
									render: (a) => capitalize(a),
								},
								{
									title: 'Date',
									dataIndex: 'actionDate',
									render: (d) => (d == null ? 'N/A' : formatDate(d, DATE_AND_TIME)),
								},
								{
									title: 'Cargo',
									dataIndex: 'cargoId',
									render: (cargoId) => {
										if (cargoId == null) {
											return ' - ';
										}

										const cargo = (cargos ?? []).find((cg) => cg.id === cargoId);

										if (cargo == null) {
											return ' - ';
										}

										return cargo.type;
									},
								},
								{
									title: '',
									dataIndex: 'id',
									align: 'right' as const,
									render: (id, row) => id > 0 && (
										<Space>
											<Button
												onClick={() => {
													if (row.action === CrewReportTypes.BUNKER_STEM) {
														form.resetFields();

														const stem = (bunkerStems ?? []).find((bs) => (
															bs.portCallActionId === id
														));

														if (stem != null) {
															setEditingStem(stem);
															setShowBunkerDrawer(true);
														}
													} else {
														setEditingActionId(id);
													}
												}}
												type="link"
												icon={(<EditOutlined />)}
											/>
											<Button
												disabled={unDeletableActions.some((a) => a === row.action)}
												onClick={() => onDelete(id)}
												danger
												confirmTitle="Are you sure you want to delete this activity and its associated ROB's?"
												type="link"
												icon={(<DeleteOutlined />)}
											/>
										</Space>
									),
								},
							]}
							pagination={false}
							dataSource={sortedActions}
						/>
					</Card>
				</Col>
			</Row>
			<Drawer
				width={500}
				title={`${editingAction ? 'Update' : 'Create'} activity in ${selectedPortCall?.port?.name}`}
				open={showActivityModal || editingAction != null}
				onClose={onClose}
			>
				<Form
					layout="vertical"
					form={form}
				>
					<Form.Item label="Activity" name="action">
						<Select
							showSearch
							disabled={editingAction != null}
							placeholder="Select activity"
							options={
								(portActivityTypes ?? []).map((a) => ({
									label: a,
									value: a,
								}))
							}
						/>
					</Form.Item>
					<ActivityWithRobsForm
						key={editingActionId}
						afterOnSave={onClose}
						editingAction={editingAction}
						form={form}
					/>
				</Form>
			</Drawer>
			<CreateBunkerStemForm
				voyageId={voyageDetails?.id}
				refreshDetails={() => {
					refreshStems();
					refreshDetails();
					refreshItinerary();
				}}
				selectedPortCall={selectedPortCall}
				open={showBunkerDrawer}
				onClose={() => {
					setShowBunkerDrawer(false);
					refreshSuppliers();
					form.resetFields();
				}}
				tcInContract={voyageDetails?.tcInContract}
				editingStem={editingStem}
				setEditingStem={setEditingStem}
				fixtureCurrency={voyageDetails.bankAccount.currency}
			/>
		</>
	);
};

export default PortCallSummaryTab;
