import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
} from 'react';
import { Error } from 'sequelize';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	PortActionTypes,
} from '@shared/utils/constants';
import { SomePartial } from '@shared/utils/generics';
import { PortRotationEntryGeneratorTypes } from '@shared/utils/port-rotation-entry-generator-types';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { EstimatePortRotationEntryProps } from '@api/models/estimate-port-rotation-entry';
import type { SeaPassageSettings } from '@api/utils/sequelize/calculateVoyageItineraryDetails/helpers/itineraryConstants';
import type { Port } from '@api/utils/ports';
import {
	updateEstimate,
	updatePortRotation,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';

export type PartialPortRotationEntry = SomePartial<EstimatePortRotationEntryProps, ('estimateId' | 'id')> & {
	linerCostPricePerUnit: number | null;
	linerCostCurrency: Values<typeof Currencies>;
	linerCostExchangeRate: number | null;
}

export type NewPortRotationEntryFormValues = {
	type: Values<typeof PortActionTypes>;
	portId: number;
	daysInPort: number | null;
	portCost: number | null;
	linerCostPricePerUnit: number | null;
}

type NewPortRotationEntry = {
	id: number;
	type: Values<typeof PortActionTypes>;
	estimateId: number;
	portCost: number | null;
	linerCostPricePerUnit: number | null;
	generatedBy: null;
	port: Port;
	idleDays: number | null;
	speed: number;
}

export type LocalPortRotation = (EstimatePortRotationEntryProps | PartialPortRotationEntry) & {
	linerCostPricePerUnit: number | null;
	linerCostCurrency: Values<typeof Currencies>;
	linerCostExchangeRate: number | null;
};

export type LocalPortRotationWithKey = LocalPortRotation & { key: string };

/*
* Fields that should cause an immediate update, likely because other fields depends on them.
* Otherwise, the update will be delayed by 1 second. Feel free to add fields that should
* trigger updates.
*/
export const PortRotationFieldsToImmediatelyUpdate: Array<keyof EstimatePortRotationEntryProps> = [
	'port',
	'departureDate',
	'consumptionSetId',
];

type Props = {
	estimate: GetEstimateDetailsResponse | null | undefined;
	portRotationChanged: boolean;
	setPortRotationChanged: React.Dispatch<React.SetStateAction<boolean>>;
	centralPortRotation: PartialPortRotationEntry[];
	syncPortRotation: 'partial' | 'full' | undefined;
	setSyncPortRotation: React.Dispatch<React.SetStateAction<'partial' | 'full' | undefined>>;
	selectedEstimateId: number | null;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const usePortRotationHandlers = ({
	portRotationChanged,
	setPortRotationChanged,
	centralPortRotation,
	syncPortRotation,
	setSyncPortRotation,
	selectedEstimateId,
	setLoading,
}: Props) => {
	const lastCallRef = useRef<number>(0);
	const [
		localPortRotation,
		setLocalPortRotation,
	] = useState<LocalPortRotation[] | null>(null);

	const [
		pendingPortRotationChanges,
		setPendingPortRotationChanges,
	] = useState<LocalPortRotation[]>([]);

	const [
		forceRenderKey,
		setForceRenderKey,
	] = useState<number>(0);

	const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);

	const syncCalculatedFields = useCallback((sync: 'partial' | 'full') => {
		if (sync === 'full') {
			setLocalPortRotation(centralPortRotation);
			setForceRenderKey((prevKey) => prevKey + 1); // Force re-render

			return;
		}

		const updated = centralPortRotation.map((serverEntry, index) => {
			const localEntry = localPortRotation?.[index];

			if (localEntry == null) {
				return serverEntry;
			}

			if (localEntry?.port?.id !== serverEntry?.port?.id) {
				return serverEntry;
			}

			const userInputFields = {
				loadDischargeRate: localEntry.loadDischargeRate,
				seaMargin: localEntry.seaMargin,
				idleDays: localEntry.idleDays,
				turnDays: localEntry.turnDays,
				portCost: localEntry.portCost,
				draft: localEntry.draft,
			};

			return {
				...serverEntry,
				...userInputFields,
			};
		});

		setLocalPortRotation(updated.filter((u) => u != null) as LocalPortRotation[]);
	}, [centralPortRotation, localPortRotation]);

	useEffect(() => {
		if (
			(localPortRotation == null && centralPortRotation != null) ||
			(localPortRotation?.length !== centralPortRotation?.length)
		) {
			setLocalPortRotation(centralPortRotation);
			setSyncPortRotation(undefined);
		}
	}, [
		centralPortRotation,
		localPortRotation,
		syncPortRotation,
		setSyncPortRotation,
		portRotationChanged,
	]);

	useEffect(() => {
		if (syncPortRotation && !portRotationChanged) {
			syncCalculatedFields(syncPortRotation);
			setSyncPortRotation(undefined);
		}
	}, [
		centralPortRotation,
		localPortRotation,
		syncPortRotation,
		setSyncPortRotation,
		portRotationChanged,
		syncCalculatedFields,
	]);

	useEffect(() => {
		const handler = () => {
			if (pendingPortRotationChanges.length > 0) {
				setLoading(true);
				updateEstimate(selectedEstimateId!, {
					portRotationEntries: pendingPortRotationChanges,
				}, false, false)
					.then()
					.catch(() => {
						showErrorNotification('Something went wrong when updating a the port rotation');
					}).finally(() => {
						setPortRotationChanged(true);
						setPendingPortRotationChanges([]);
					});
			}
		};

		if (timeoutRef.current != null) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(handler, 1000);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [pendingPortRotationChanges, selectedEstimateId, setPortRotationChanged, setLoading]);

	const onPortRotationChange = useCallback(async (
		entries: PartialPortRotationEntry[],
		immediate?: boolean,
	) => {
		// To avoid the function being called both with and without "immediate"
		// which will cause an infinite loop
		const now = Date.now();

		if (now - lastCallRef.current < 200) {
			return;
		}

		lastCallRef.current = now;

		if (selectedEstimateId == null) {
			return;
		}

		if (immediate) {
			setLoading(true);

			try {
				await updateEstimate(selectedEstimateId, {
					portRotationEntries: entries,
				}, false, false);
			} catch (e) {
				showErrorNotification('Could not set draft', e as Error);
			}

			setPortRotationChanged(true);

			return;
		}

		setLocalPortRotation(entries);
		setPendingPortRotationChanges(entries);
	}, [selectedEstimateId, setPortRotationChanged, setLoading]);

	const onOrderChange = useCallback(async (entries: LocalPortRotation[]) => {
		if (selectedEstimateId == null) {
			return;
		}

		setLoading(true);
		setLocalPortRotation(entries);

		try {
			await updatePortRotation(entries as PartialPortRotationEntry[], selectedEstimateId);
		} catch (e) {
			showErrorNotification('Could not update Port Rotation', e as Error);
		}

		setPortRotationChanged(true);
	}, [selectedEstimateId, setPortRotationChanged, setLoading]);

	const onPortRotationRowChange = useCallback((
		id: number | undefined,
		attributes: PartialPortRotationEntry,
	) => {
		const portRotationCopy = [...localPortRotation ?? []];
		const entry = portRotationCopy.find((e) => e.id === id);

		if (entry == null) {
			return;
		}

		const diff = Object.keys(attributes).find((key) => attributes[key] !== entry[key]);
		const shouldTriggerImmediateUpdate = diff != null &&
			PortRotationFieldsToImmediatelyUpdate.includes(diff as any);

		Object.assign(entry, attributes);
		onPortRotationChange(portRotationCopy, shouldTriggerImmediateUpdate);
	}, [localPortRotation, onPortRotationChange]);

	const onConfigUpdate = useCallback(async (
		id: number | undefined,
		routeOptions: SeaPassageSettings,
	) => {
		if (localPortRotation == null) {
			return;
		}

		const entry = localPortRotation.find((e) => e.id === id);

		if (entry == null) {
			return;
		}

		entry.routeOptions = routeOptions;
		onPortRotationChange(localPortRotation, true);
	}, [onPortRotationChange, localPortRotation]);

	const onDeletePortRotationEntry = useCallback((entryToDelete: PartialPortRotationEntry) => {
		if (localPortRotation == null) {
			return;
		}

		const updateRotation = localPortRotation.filter((e) => e.id !== entryToDelete.id);
		onOrderChange(updateRotation);
	}, [onOrderChange, localPortRotation]);

	const onAddPortRotationEntry = useCallback((
		finalFormValues: NewPortRotationEntryFormValues,
		onAdd: (editing: boolean) => void,
	) => {
		if (selectedEstimateId == null || localPortRotation == null) {
			return;
		}

		const newEntry: NewPortRotationEntry = {
			id: -1,
			type: finalFormValues.type!,
			estimateId: selectedEstimateId,
			portCost: finalFormValues.portCost ?? null,
			linerCostPricePerUnit: finalFormValues.linerCostPricePerUnit ?? null,
			generatedBy: null,
			port: { id: finalFormValues.portId } as Port,
			idleDays: finalFormValues.daysInPort ?? 0,
			speed: 12,
		};

		onAdd(false);
		// @ts-ignore it's ok
		onOrderChange([...localPortRotation, newEntry]);
	}, [selectedEstimateId, onOrderChange, localPortRotation]);

	const portRotationWithKeys = useMemo(() => {
		const arrayToUse = localPortRotation ?? [];

		return arrayToUse.map((p) => {
			const disabled = p.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ||
				p.generatedBy === PortRotationEntryGeneratorTypes.CANAL_TRANSIT;

			return ({
				...p,
				key: `${p?.id},${p?.port?.id},${p.type},${disabled}` ?? -1,
			});
		});
	}, [localPortRotation]);

	return {
		portRotation: portRotationWithKeys,
		onPortRotationChange,
		onPortRotationRowChange,
		onOrderChange,
		onConfigUpdate,
		onAddPortRotationEntry,
		onDeletePortRotationEntry,
		setPortRotationChanged,
		forceRenderKey,
	};
};
