import React from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { Router } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import {
	QueryClient,
	QueryClientProvider,
} from 'react-query';
import { Crisp } from 'crisp-sdk-web';
import overrideMomentToJson from '@shared/utils/overrideMomentToJson';
import { timeTravel } from '@shared/utils/timetravel';
import '@shared/hireInvoice';
import { setDev } from '@shared/utils/isDev';
import {
	MOCK_DATE,
	PRODUCTION,
} from '@client/utils/constants';
import { Auth0Provider } from './lib/auth';
import { ImpersonationProvider } from './lib/impersonation';
import { NavigationBlockProvider } from './lib/navigationBlock';
import { getVersionInfo } from './utils/versionInfo';
import history from './utils/history';
import './index.css';
import App from './App';
import { GlobalStateProvider } from './lib/globalState';
import { ToursProvider } from './lib/tours';
/*
 * This fixes an issue with react-map-gl in production
 * Source: https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
 */
/* eslint-disable import/order,import/no-extraneous-dependencies,import/no-webpack-loader-syntax */
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';

// @ts-ignore - webpack shenanigans
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
/* eslint-enable import/order,import/no-extraneous-dependencies,import/no-webpack-loader-syntax */

setDev(!PRODUCTION);

// Include timezone info in moment conversion to JSON
overrideMomentToJson(moment);

// eslint-disable-next-line no-console
console.info('Version:', getVersionInfo());

// We use Moment overrides for the Antd +5, as they've switched to Dayjs -
// they also default to Chinese, so setting the locale is necessary
moment.locale('en_GB');

if (process.env.NODE_ENV !== 'development') {
	Crisp.configure('96b6430e-59b0-4518-adaf-7830e639f3d1');
}

const antdConfig = {
	// If trigger element is child of our main container, use that
	// If not, use body element
	// Used to fix popup placement when scrolling
	getPopupContainer: (trigger: HTMLElement | undefined) => {
		const mainContainer = document.querySelector('main');

		if (mainContainer != null && mainContainer.contains(trigger ?? null)) {
			return mainContainer;
		}

		return document.body;
	},
};

if (MOCK_DATE) {
	timeTravel();
}

// Set up react-pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const queryClient = new QueryClient();
const container = document.getElementById('root');

if (container != null) {
	const root = createRoot(container);

	root.render(
		(
			<Router history={history}>
				<Auth0Provider>
					<QueryClientProvider client={queryClient}>
						<GlobalStateProvider>
							<ImpersonationProvider>
								<DndProvider options={HTML5toTouch}>
									<NavigationBlockProvider>
										<ToursProvider>
											<ConfigProvider
												{...antdConfig}
											>
												<App />
											</ConfigProvider>
										</ToursProvider>
									</NavigationBlockProvider>
								</DndProvider>
							</ImpersonationProvider>
						</GlobalStateProvider>
					</QueryClientProvider>
				</Auth0Provider>
			</Router>
		),
	);
}

