import React, { useMemo } from 'react';
import { Alert } from 'antd';
import { ActualAccrualFields } from '@shared/utils/constants';
import type { GetConnectionAndMappingResponse } from '@api/features/accounting-system/getConnectionAndMapping';
import type { AccrualMappingEntry } from '@api/lib/integrations/navision/navisionIntegration';
import Card from '@client/components/Card/Card';
import EditableTable from '@client/components/EditableTable';
import {
	createMappingEntry,
	getCompanyAccounts,
	getUserData,
} from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';

const AccrualTypeMappingTab = ({
	connection,
	refreshConnection,
}: {
	connection: GetConnectionAndMappingResponse | undefined;
	refreshConnection: () => void;
}) => {
	const [userData, refreshUserData] = useFetchedState(getUserData);
	const [accountingCodes] = useFetchedState(async () => (
		connection == null ? [] : getCompanyAccounts(connection.id)
	), [connection]);

	const onSave = async (
		field: string,
		result: {accountingCode: string | undefined },
	) => {
		const value = result.accountingCode;

		if (value != null && field != null && connection != null) {
			try {
				await createMappingEntry(connection.id, 'accrual', field, value);
				await refreshConnection();
				await refreshUserData();
				showSuccessNotification('Successfully mapped entry');
			} catch (e) {
				showErrorNotification('Could not create mapping', e as Error);
			}
		}

		refreshConnection();
	};

	const accrualsWithCode = useMemo(() => {
		if (connection == null) {
			return [];
		}

		let entries: AccrualMappingEntry[] = [];

		if (userData?.globalAccrualMapping) {
			entries = userData.globalMappingEntries;
		} else {
			entries = connection.AccountingMappingEntries;
		}

		const formatted = Object.keys(ActualAccrualFields).map((key) => {
			const mappingEntry = entries.find((mapping) => (
				mapping.type === 'accrual' &&
				mapping.cvIdentifier === String(key)
			));

			return {
				key,
				field: ActualAccrualFields[key],
				accountingCode: mappingEntry?.code ?? undefined,
			};
		});

		return formatted;
	}, [connection, userData]);

	const globalMessage = 'Your company is configured for global accrual mapping. Changes made here, will apply to all instances of your accounting system';
	const localMessage = 'Your company is not configured for global accrual mapping. Changes made here, will not apply to any other instances of your accounting system';

	return (
		<Card slim>
			<Alert type="info" showIcon message={userData?.globalAccrualMapping ? globalMessage : localMessage} />
			<EditableTable
				actionsTitle=""
				iconButtons
				pagination={false}
				keyDataIndex="key"
				onSave={onSave}
				dataSource={accrualsWithCode ?? []}
				showSorterTooltip
				columns={[
					{
						key: 'fieldRow',
						dataIndex: 'field',
						title: 'Field',
					},
					{
						dataIndex: 'accountingCode',
						title: 'Accounting System Entry',
						editingProps: {
							type: 'select',
							options: (accountingCodes ?? []).map((c) => ({
								label: `(${c.code}) ${c.name}`,
								value: c.code,
							})),

							inputProps: {
								placeholder: 'Select code',
								showSearch: true,
							},
						},
						editable: true,
						render: (v: string | null) => {
							const entry = accountingCodes?.find((c) => c.code === v);

							if (entry == null) {
								return (<i>Empty</i>);
							}

							return `(${entry.code}) ${entry.name}`;
						},
					},
				]}
			/>
		</Card>
	);
};

export default AccrualTypeMappingTab;
