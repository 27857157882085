import React, { useState } from 'react';
import {
	Col,
	Divider,
	Empty,
	Flex,
	Row,
} from 'antd';
import {
	CrewReportTypes,
	PortCallTypes,
	VcContractCompletionTypes,
} from '@shared/utils/constants';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetBunkerStemsResponse } from '@api/features/voyages/bunker-stems/getBunkerStems';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	deleteBunkerStem,
	deleteRobBunker,
	getBunkerStems,
	getRobsFromPortCall,
	getSuppliers,
	getVoyageCompletionType,
	updateRob,
} from '@client/lib/api';
import EditableTable from '@client/components/EditableTable';
import CreateBunkerStemForm from '@client/components/CreateBunkerStemForm/CreateBunkerStemForm';
import AddButton from '@client/components/AddButton';
import { useAuth } from '@client/lib/auth';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	CommencementRobBunker,
	getRelevantBunkerTables,
	NullableItineraryBunkerRecord,
} from '../utils/bunkerTabHelpers';
import styles from './styles/PortCallBunkersTab.module.css';
import { getBunkerStemTableColumns } from './getColumns';

export const PortCallBunkersSection = ({
	selectedEntry,
	voyageDetails,
	refreshDetails,
}: {
	selectedEntry: ItineraryPortCallDto;
	voyageDetails: GetVoyageDetailsResponse | null | undefined;
	refreshDetails: () => void;
}) => {
	const auth = useAuth();
	const organizationName = auth?.userInfo?.organizationName;
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [editingStem, setEditingStem] = useState<
		GetBunkerStemsResponse[number] |
		undefined |
		null
	>();

	const [suppliers, refreshSuppliers] = useFetchedState(getSuppliers);

	const [fetchedRobs, refreshFetchedRobs] = useFetchedState(
		() => getRobsFromPortCall(selectedEntry.vesselId, selectedEntry.id), [selectedEntry],
	);

	const [bunkerStems, refreshStems] = useFetchedState(async () => {
		if (voyageDetails?.id == null) {
			return [];
		}

		return await getBunkerStems({
			voyageId: voyageDetails.id,
			portCallId: selectedEntry.id,
		});
	}, [voyageDetails, selectedEntry]);

	const [voyageCompletion] = useFetchedState(
		async () => {
			if (selectedEntry.voyageId != null) {
				return await getVoyageCompletionType(selectedEntry.voyageId);
			}

			return null;
		}, [selectedEntry.voyageId],
	);

	const fixtureCurrency = voyageDetails?.bankAccount.currency ?? 'USD';

	const isEstimated = selectedEntry.arrivalDate == null;
	const isCommencement = selectedEntry.type === PortCallTypes.COMMENCEMENT;
	const isTcInDelivery = selectedEntry.type === PortCallTypes.COMPLETION &&
		voyageCompletion?.completionType === VcContractCompletionTypes.TC_IN_DELIVERY;

	const onAddNew = async (
		robId: number | null | undefined,
		values: NullableItineraryBunkerRecord,
	) => {
		if (voyageDetails == null || robId == null) {
			return;
		}

		if (values.quantity < 0) {
			showErrorNotification('Bunker quantity cannot be less than 0');

			return;
		}

		await updateRob({
			vesselId: selectedEntry.vesselId,
			voyageId: voyageDetails.id,
			robId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: [{
					fuelGrade: values.fuelGrade,
					quantity: values.quantity,
					pricePerTon: values.pricePerTon ?? 0,
				}],
			},
			deleteMissingEntries: false,
		});

		refreshFetchedRobs();
		refreshDetails();
	};

	const onSave = async (
		id: number | string | undefined,
		robId: number | null | undefined,
		rob: NullableItineraryBunkerRecord,
	) => {
		if (voyageDetails == null || robId == null) {
			return;
		}

		if (rob.quantity < 0) {
			showErrorNotification('Bunker quantity cannot be less than 0');

			return;
		}

		await updateRob({
			vesselId: selectedEntry.vesselId,
			voyageId: voyageDetails.id,
			robId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: {
					id: Number(id),
					quantity: rob.quantity,
					fuelGrade: rob.fuelGrade,
					pricePerTon: rob.pricePerTon ?? 0,
				},
			},
		});

		refreshFetchedRobs();
		refreshDetails();
	};

	const onDelete = async (id: number | string | undefined) => {
		if (id == null || voyageDetails?.vesselId == null) {
			return;
		}

		await deleteRobBunker(Number(id), voyageDetails?.vesselId);
		refreshFetchedRobs();
		refreshDetails();
	};

	// Only relevant for commencement where users can add multiple of the same fuel type
	const onRemoveFuelQueueItem = async (
		row: CommencementRobBunker,
	) => {
		if (row == null || voyageDetails == null) {
			return;
		}

		const relevantRob = fetchedRobs?.commencement?.RobBunkers
			.find((rb) => rb.id === row.robBunkerId);

		if (relevantRob == null) {
			showErrorNotification('Could not remove bunker');

			return;
		}

		const newFuelQueue = relevantRob?.fuelQueue
			.filter((fq) => fq.pricePerTon !== row.pricePerTon && fq.quantity !== row.quantity);

		await updateRob({
			vesselId: selectedEntry.vesselId,
			voyageId: voyageDetails.id,
			robId: row.robId,
			robBunkerId: row.robBunkerId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: newFuelQueue,
			},
			resetQueue: true,
			event: CrewReportTypes.COMMENCEMENT,
		});

		refreshFetchedRobs();
		refreshDetails();
	};

	// Only relevant for commencement where users can add multiple of the same fuel type
	const onSaveFuelQueueItem = async (
		robId: number | string | undefined | null,
		robBunkerId: number,
		fuelQueue: Array<CommencementRobBunker>,
	) => {
		if (fuelQueue == null || voyageDetails == null || robId == null) {
			return;
		}

		await updateRob({
			vesselId: selectedEntry.vesselId,
			voyageId: voyageDetails.id,
			robId: Number(robId),
			robBunkerId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: fuelQueue,
			},
			resetQueue: true,
			event: CrewReportTypes.COMMENCEMENT,
		});

		refreshFetchedRobs();
		refreshDetails();
	};

	if (voyageDetails == null || fetchedRobs == null) {
		return (<Empty />);
	}

	return (
		<Row gutter={[16, 8]}>
			{getRelevantBunkerTables({
				voyageDetails,
				robs: fetchedRobs,
				selectedEntry,
				allowAddNew: true,
				onAddNew,
				onSave,
				onDelete,
				onRemoveFuelQueueItem,
				onSaveFuelQueueItem,
				tcInCompletionBunkers: isTcInDelivery ? (voyageCompletion.linkedTcInVoyageBunkers ?? []).filter((r) => r.type === 'redelivery')
					.map((b) => ({
						quantity: b.quantity,
						fuelGrade: b.fuelGrade,
						pricePerTon: b.pricePerTon,
					})) : null,
			})}
			<CreateBunkerStemForm
				voyageId={selectedEntry.voyageId!}
				refreshDetails={() => {
					refreshStems();
					refreshDetails();
				}}
				selectedPortCall={selectedEntry}
				open={drawerVisible}
				onClose={() => {
					setDrawerVisible(false);
					refreshSuppliers();
				}}
				tcInContract={voyageDetails?.tcInContract}
				editingStem={editingStem}
				setEditingStem={setEditingStem}
				fixtureCurrency={voyageDetails.bankAccount.currency}
			/>
			{(!isCommencement && !isEstimated) && (
				<Col span={24}>
					<Divider>Bunker Stems</Divider>
					<Flex
						vertical
						gap={10}
					>
						<AddButton
							type="primary"
							onClick={() => setDrawerVisible(true)}
							className={styles.addStemBtn}
						>
							Add bunker stem
						</AddButton>
						<EditableTable<GetBunkerStemsResponse[number], 'id'>
							enableDelete={() => true}
							enableEdit={() => true}
							iconButtons
							editingRow={null}
							onEditingRowChange={(_i, e) => {
								if (e != null) {
									setEditingStem(e);
									setDrawerVisible(true);
								}
							}}
							keyDataIndex="id"
							onSave={() => null}
							columns={getBunkerStemTableColumns(
								suppliers,
								voyageDetails?.tcInContract,
								organizationName,
							)}
							onDelete={async (id: number) => {
								await deleteBunkerStem(id);
								await refreshStems();
								await refreshDetails();
							}}
							dataSource={bunkerStems ?? []}
							className={styles.stemTable}
							pagination={false}
						/>
					</Flex>
				</Col>
			)}
		</Row>
	);
};
