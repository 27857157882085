import React from 'react';
import {
	Row,
	Col,
} from 'antd';
import HireInvoicesTable from './InvoiceTables/HireInvoicesTable';
import PaymentsTable from './PaymentsTable';

const InvoicesTab = () => {
	return (
		<Row gutter={[16, 16]}>
			<Col xl={12} xxl={8}>
				<PaymentsTable />
			</Col>
			<Col xl={24} xxl={16}>
				<HireInvoicesTable />
			</Col>
		</Row>
	);
};

export default InvoicesTab;
