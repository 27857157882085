// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FGHCNb4OaUdekV8QajFA {\n\tborder-right-width: 4px !important;\n}\n\n._K4CjIQKxp4ph0BYc6Ub .ant-table-cell {\n\tfont-size: 13px !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/dashboards/OperationsDashboard.module.css"],"names":[],"mappings":"AAAA;CACC,kCAAkC;AACnC;;AAEA;CACC,0BAA0B;AAC3B","sourcesContent":[".header {\n\tborder-right-width: 4px !important;\n}\n\n.table :global(.ant-table-cell) {\n\tfont-size: 13px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "FGHCNb4OaUdekV8QajFA",
	"table": "_K4CjIQKxp4ph0BYc6Ub"
};
export default ___CSS_LOADER_EXPORT___;
