import React, { useState } from 'react';
import {
	Card,
	Tabs,
} from 'antd';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import OrgBankAccounts from '@client/screens/management/banking/OrgBankAccounts';
import ApprovalSettings from '@client/screens/management/approvals/ApprovalSettings';
import UserManagement from '@client/screens/management/users/UserManagement';
import ReportGroups from '@client/screens/management/report-groups/ReportGroups';
import AccrualSettings from '../accruals/AccrualSettings';
import OffHireTypes from '../off-hire-types/OffHireTypes';
import EstimatorSettings from '../estimator/EstimatorSettings';
import GeneralSettings from '../general/GeneralSettings';
import Invoicing from '../invoicing/Invoicing';
import PortActivityTypes from '../port-activity-types/PortActivityTypes';
import styles from './ManagementSettingsScreen.module.css';

const ManagementSettingsScreen = () => {
	const [activeTab, setActiveTab] = useState('approvals');

	return (
		<SimpleScreen
			title="Company Settings"
			rootPageTitle="Company Settings"
		>
			<Card>
				<Tabs
					onChange={setActiveTab}
					activeKey={activeTab?.toString()}
					items={[
						{
							key: 'approvals',
							label: 'Approvals',
							children: (<ApprovalSettings />),
						},
						{
							key: 'users',
							label: 'Team Members',
							children: (<UserManagement />),
						},
						{
							key: 'banking',
							label: 'Banking',
							children: (
								<div className={styles.bankingContainer}>
									<OrgBankAccounts />
								</div>
							),
						},
						{
							key: 'accruals',
							label: 'Accruals',
							children: (<AccrualSettings />),
						},
						{
							key: 'reportGroups',
							label: 'Report Groups',
							children: (<ReportGroups />),
						},
						{
							key: 'offHireTypes',
							label: 'Off-hire Types',
							children: (<OffHireTypes />),
						},
						{
							key: 'estimatorSettings',
							label: 'Estimator',
							children: (<EstimatorSettings />),
						},
						{
							key: 'generalSettings',
							label: 'General',
							children: (<GeneralSettings />),
						},
						{
							key: 'invoicing',
							label: 'Invoicing',
							children: (
								<div className={styles.invoicingContainer}>
									<Invoicing />
								</div>
							),
						},
						{
							key: 'portActivites',
							label: 'Port Activity Types',
							children: (<PortActivityTypes />),
						},
					]}
				/>
			</Card>
		</SimpleScreen>
	);
};

export default ManagementSettingsScreen;
