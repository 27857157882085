import React, {
	createContext,
	useContext,
	ReactNode,
	useState,
	useMemo,
	useEffect,
	useCallback,
	SetStateAction,
} from 'react';
import { Moment } from 'moment';
import { arrayMove } from '@dnd-kit/sortable';
import { useQuery } from 'react-query';
import { asyncDebounce } from '@shared/utils/asyncDebounce';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { Values } from '@shared/utils/objectEnums';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
	PortCallTypes,
	VcContractCompletionTypes,
} from '@shared/utils/constants';
import { filterUnique } from '@shared/utils/array';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetVoyageItineraryResponse } from '@api/features/voyages/getVoyageItinerary';
import type { Port } from '@api/utils/ports';
import type { GetRobsFromPortCallResponse } from '@api/features/vessels/getRobsFromPortCall';
import type { CreateItineraryEntryRequest } from '@api/features/ops/createItineraryEntry';
import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@client/screens/estimates/details/helpers/types';
import {
	createItineraryEntry,
	deleteItineraryEntry,
	deleteRobBunker,
	getPorts,
	getRobsFromPortCall,
	getVoyageItinerary,
	reorderPortCall,
	updatePortCall,
	updateRob,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import getPortAndRangeOptions from '@client/utils/getPortAndRangeOptions';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import getItineraryColumns from './utils/getItineraryColumns';
import {
	CommencementRobBunker,
	NullableItineraryBunkerRecord,
} from './utils/bunkerTabHelpers';

type ItineraryContextType = {
	itinerary: GetVoyageItineraryResponse;
	refreshItinerary: () => void;
	itineraryError: unknown;
	itineraryLoading: boolean;
	deletePortCall: (portCall: ItineraryPortCallDto) => Promise<void>;
	portCalls: (ItineraryPortCallDto & { key: string })[];
	seaPassages: ItinerarySeaPassageDto[];
	localItinerary: (ItineraryPortCallDto | ItinerarySeaPassageDto)[];
	setLocalItinerary: (itinerary: (ItineraryPortCallDto | ItinerarySeaPassageDto)[]) => void;
	portOptions: ReturnType<typeof getPortAndRangeOptions>;
	viewMode: 'map' | 'bunker' | 'default';
	setViewMode: (type: 'map' | 'bunker' | 'default') => void;
	ports: Port[] | undefined;
	selectedTimeFormat: 'utc' | 'localTime';
	setSelectedTimeFormat: (format: 'utc' | 'localTime') => void;
	addItineraryEntry: ({
		portId,
		estimatedDepartureDate,
		actualDepartureDate,
		robs,
	}: {
		portId: number;
		estimatedDepartureDate?: Moment;
		actualDepartureDate?: Moment;
		robs: CreateItineraryEntryRequest['robs'];
	}) => Promise<void>;
	expandedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined;
	onSelectEntry: (entry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined) => void;
	voyageDetails: GetVoyageDetailsResponse;
	onUpdateRob: (key: string, portCallId: number, newQuantity: number | null) => Promise<void>;
	onUpdatePortCall: (field: string, value: any, newRow: ItineraryPortCallDto) => Promise<void>;
	movePortCall: (pcId: number, overId: number) => Promise<void>;
	columns: ReturnType<typeof getItineraryColumns>;
	refreshVoyageDetails: () => void;
	showCommencementLinking: boolean;
	setShowCommencementLinking: (value: boolean) => void;
	onSaveRob: (
		id: number | string | undefined,
		robId: number | null | undefined,
		rob: NullableItineraryBunkerRecord
	) => Promise<void>;
	fetchedRobs: GetRobsFromPortCallResponse | undefined;
	refreshFetchedRobs: () => void;
	onDeleteRob: (id: number | string | undefined) => Promise<void>;
	onSaveFuelQueueItem: (
		robId: number | string | undefined | null,
		robBunkerId: number,
		fuelQueue: Array<CommencementRobBunker>,
	) => Promise<void>;
	onRemoveFuelQueueItem: (row: CommencementRobBunker) => Promise<void>;
	onAddNewRob: (
		robId: number | null | undefined,
		values: NullableItineraryBunkerRecord
	) => Promise<void>;
	tcInCompletionBunkers: {
		quantity: number;
		fuelGrade: Values<typeof FuelTypes>;
		pricePerTon: number;
	}[] | null;
	setEditingCargoId: React.Dispatch<React.SetStateAction<number | null>>;
	setEditingCargoPortId: React.Dispatch<React.SetStateAction<number | null>>;
	editingCargoPortId: number | null;
	editingCargoId: number | null;
	renderKey: number;
	setItineraryShouldUpdate: React.Dispatch<SetStateAction<boolean>>;
}

interface ItineraryProviderProps {
	children: ReactNode;
}

export const ItineraryProvider = ({
	children,
}: ItineraryProviderProps) => {
	const {
		voyageDetails,
		refreshVoyageDetails,
		fixtureCurrency,
		voyageCompletion,
		refreshDetails,
	} = useVoyage();

	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

	const [viewMode, setViewMode] = useState<'default' | 'map' | 'bunker'>('default');
	const [selectedTimeFormat, setSelectedTimeFormat] = useState<'utc' | 'localTime'>(voyageDetails.itineraryUseUTC ? 'utc' : 'localTime');
	const [showCommencementLinking, setShowCommencementLinking] = useState(false);
	const [renderKey, setRenderKey] = useState(0);
	const [itineraryShouldUpdate, setItineraryShouldUpdate] = useState(false);
	const [editingCargoId, setEditingCargoId] = useState<number | null>(null);
	const [editingCargoPortId, setEditingCargoPortId] = useState<number | null>(null);

	const forceRender = () => setRenderKey((prev) => prev + 1);

	const {
		data: serverItinerary,
		refetch: refreshItinerary,
		error: itineraryError,
		isLoading: itineraryLoading,
	} = useQuery(
		['itinerary', voyageDetails.id],
		() => getVoyageItinerary(voyageDetails.id),
		{
			enabled: voyageDetails != null,
			refetchOnWindowFocus: false,
			retry: false,
			initialData: [],
		},
	);

	const itinerary = serverItinerary as GetVoyageItineraryResponse;

	const { data: ports } = useQuery('ports', getPorts);
	const portOptions = useMemo(() => getPortAndRangeOptions(ports ?? []), [ports]);

	const [
		localItinerary,
		setLocalItinerary,
	] = useState<(ItineraryPortCallDto | ItinerarySeaPassageDto)[]>(itinerary);

	const rawPortCalls = useMemo(
		() => localItinerary.filter((e): e is ItineraryPortCallDto => isItemPortCall(e)),
		[localItinerary],
	);

	const seaPassages = useMemo(
		() => localItinerary.filter((e): e is ItinerarySeaPassageDto => !isItemPortCall(e)),
		[localItinerary],
	);

	// Creates columns like VLSFO-arrival & VLSFO-departure for each unique fuel type
	const portCalls = useMemo(() => rawPortCalls.map((pc, index) => {
		const transformed = pc.Robs.reduce((acc: Record<string, number>, item) => {
			let eventKey = item.event.toLowerCase();

			// This allows us to see (but not edit) commencement and completion bunkers in table
			if (eventKey === 'commencement') {
				eventKey = 'departure';
			}

			if (eventKey === 'completion') {
				eventKey = 'departure';
			}

			item.RobBunkers.forEach((bunker) => {
				const fuelKey = bunker.fuelGrade;
				const key = `${fuelKey}-${eventKey}`;

				if (acc[key]) {
					acc[key] += bunker.totalQuantity;
				} else {
					acc[key] = bunker.totalQuantity;
				}
			});

			return acc;
		}, {});

		return {
			...transformed,
			...pc,
			key: `${pc.port.id}-${JSON.stringify(pc.actions.map((a) => a.action))}-${index}?isCanalTransit=${pc.isCanalTransit}&id=${pc.id}&isActualDate=${pc.arrivalDate != null}`,
		};
	}), [rawPortCalls]);

	const uniqueFuelTypes = useMemo(() => filterUnique(
		portCalls.flatMap((event) => (
			event.Robs.flatMap((rob) => rob.RobBunkers.map((bunker) => bunker.fuelGrade))
		)),
		(fuelGrade) => fuelGrade,
	).sort(), [portCalls]);

	const expandedEntry = useMemo(() => {
		if (selectedIndex == null) {
			return undefined;
		}

		const selectedEntry = itinerary[selectedIndex];

		if (selectedEntry == null) {
			setItineraryShouldUpdate(true);

			return undefined;
		}

		return selectedEntry;
	}, [selectedIndex, itinerary]);

	const {
		data: fetchedRobs,
		refetch: refreshFetchedRobs,
	} = useQuery(
		['fetchedRobs', expandedEntry?.id],
		() => getRobsFromPortCall(voyageDetails.vesselId, expandedEntry?.id!),
		{
			enabled: expandedEntry != null && isItemPortCall(expandedEntry),
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	useEffect(() => {
		if (itinerary != null && itineraryShouldUpdate) {
			setLocalItinerary(itinerary);
			setItineraryShouldUpdate(false);
			forceRender();
		}
	}, [itinerary, localItinerary, itineraryShouldUpdate]);

	useEffect(() => {
		if (itinerary != null && itinerary.length > 0 && localItinerary.length === 0) {
			setLocalItinerary(itinerary);
			forceRender();
		}
	}, [itinerary, localItinerary.length]);

	const deletePortCall = useCallback(async (portCall: ItineraryPortCallDto) => {
		try {
			await deleteItineraryEntry(portCall.vesselId, portCall.id);
			await refreshItinerary();
			setItineraryShouldUpdate(true);
		} catch (e) {
			showErrorNotification('Could not delete port call', e as Error);
		}
	}, [refreshItinerary]);

	const addItineraryEntry = async ({
		portId,
		estimatedDepartureDate,
		actualDepartureDate,
		robs,
	}: {
		portId: number;
		estimatedDepartureDate?: Moment;
		actualDepartureDate?: Moment;
		robs: CreateItineraryEntryRequest['robs'];
	}) => {
		await createItineraryEntry({
			vesselId: Number(voyageDetails.vesselId),
			portId,
			savingIndicator: true,
			voyageId: voyageDetails.id,
			estimatedDepartureDate,
			actualDepartureDate,
			robs,
		});

		await refreshItinerary();
		setItineraryShouldUpdate(true);
	};

	const onAddNewRob = async (
		robId: number | null | undefined,
		values: NullableItineraryBunkerRecord,
	) => {
		if (voyageDetails == null || robId == null) {
			return;
		}

		if (values.quantity < 0) {
			showErrorNotification('Bunker quantity cannot be less than 0');

			return;
		}

		await updateRob({
			vesselId: voyageDetails.vesselId,
			voyageId: voyageDetails.id,
			robId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: [{
					fuelGrade: values.fuelGrade,
					quantity: values.quantity,
					pricePerTon: values.pricePerTon ?? 0,
				}],
			},
			deleteMissingEntries: false,
		});

		refreshFetchedRobs();
		refreshDetails();
		setItineraryShouldUpdate(true);
	};

	const onUpdateRob = useCallback(async (
		key: string,
		portCallId: number,
		newQuantity: number | null,
	) => {
		const [fuelType, event] = key.split('-');

		const portCall = portCalls.find((pc) => pc.id === portCallId);

		if (portCall == null) {
			return;
		}

		const rob = portCall.Robs.find((r) => r.event.toLowerCase() === event);

		if (rob == null) {
			return;
		}

		const robBunker = rob.RobBunkers.find((b) => b.fuelGrade === fuelType);

		if (robBunker?.totalQuantity === newQuantity || newQuantity == null) {
			return;
		}

		// Find an update the relevant RobBunker to the updated quantity, then update localItinerary
		const updatedLocalItinerary = localItinerary.map((entry) => {
			if (entry.id !== portCallId || !isItemPortCall(entry)) {
				return entry;
			}

			const updatedRobs = entry.Robs.map((robItem) => {
				if (robItem.id !== rob!.id) {
					return robItem;
				}

				const updatedRobBunkers = robItem.RobBunkers.map((bunker) => {
					if (bunker.id !== robBunker?.id) {
						return bunker;
					}

					return {
						...bunker,
						totalQuantity: newQuantity ?? 0,
					};
				});

				return {
					...robItem,
					RobBunkers: updatedRobBunkers,
				};
			});

			// Return the updated entry with updated Robs
			return {
				...entry,
				Robs: updatedRobs,
			};
		});

		// @ts-ignore, this works
		setLocalItinerary(updatedLocalItinerary);

		if (portCall.voyageId == null) {
			return;
		}

		if (robBunker == null && fuelType != null) {
			await updateRob({
				vesselId: portCall.vesselId,
				voyageId: portCall.voyageId,
				robId: rob.id,
				currency: voyageDetails?.bankAccount?.currency ?? Currencies.USD,
				attributes: {
					remainingOnBoard: [{
						fuelGrade: fuelType as Values<typeof FuelTypes>,
						quantity: newQuantity ?? 0,
						pricePerTon: 0,
					}],
				},
				deleteMissingEntries: false,
			});
		} else if (robBunker != null) {
			await updateRob({
				vesselId: portCall.vesselId,
				voyageId: portCall.voyageId,
				robId: rob.id,
				currency: voyageDetails?.bankAccount?.currency ?? Currencies.USD,
				attributes: {
					remainingOnBoard: {
						id: robBunker.id,
						quantity: newQuantity ?? 0,
						fuelGrade: robBunker.fuelGrade,
					},
				},
			});
		}

		await refreshItinerary();
	}, [portCalls, localItinerary, refreshItinerary, voyageDetails]);

	const debounceUpdateRob = asyncDebounce(onUpdateRob, 300);

	const onUpdatePortCall = useCallback(async (
		field: string,
		value: any,
		newRow: ItineraryPortCallDto,
	) => {
		// update port call in localItinerary
		const newLocalItinerary = localItinerary.map((entry) => {
			if (entry.id === newRow.id && isItemPortCall(entry)) {
				return {
					...entry,
					[field]: value,
				};
			}

			return entry;
		});
		setLocalItinerary(newLocalItinerary);

		try {
			await updatePortCall(newRow.vesselId, newRow.id, { [field]: value }, true);
		} catch (e: any) {
			showErrorNotification(e.toString());
		}

		await refreshItinerary();
		setItineraryShouldUpdate(true);
	}, [refreshItinerary, localItinerary]);

	const onDeleteRob = async (id: number | string | undefined) => {
		if (id == null || voyageDetails?.vesselId == null) {
			return;
		}

		await deleteRobBunker(Number(id), voyageDetails?.vesselId);
		refreshFetchedRobs();
		refreshDetails();
		setItineraryShouldUpdate(true);
	};

	const movePortCall = useCallback(async (pcId: number, overId: number) => {
		const overEntry = portCalls.find((p) => p.id === overId);
		const activeEntry = portCalls.find((p) => p.id === pcId);
		const voyageId = activeEntry?.voyageId;

		if (pcId !== overId && overEntry != null && activeEntry != null && voyageId != null) {
			const activeIndex = portCalls.findIndex((i) => i.id === pcId);
			const overIndex = portCalls.findIndex((i) => i.id === overId);

			const oldItinerary = [...localItinerary];
			const updated = arrayMove(portCalls, activeIndex, overIndex);
			setLocalItinerary(updated);
			forceRender();

			try {
				await reorderPortCall({
					vesselId: Number(activeEntry?.vesselId),
					draggedId: activeEntry.id,
					droppedOverId: overEntry.id,
					voyageId,
				});
				await refreshItinerary();
			} catch (e) {
				setLocalItinerary(oldItinerary);
				showErrorNotification('Could not move port call', e as Error);
			}
		}

		setItineraryShouldUpdate(true);
	}, [localItinerary, portCalls, refreshItinerary]);

	const onSelectEntry = useCallback((
		entry: ItineraryPortCallDto | ItinerarySeaPassageDto | undefined,
	) => {
		if (entry == null) {
			setSelectedIndex(undefined);
			setItineraryShouldUpdate(true);

			return;
		}

		const isPortCall = isItemPortCall(entry);
		const index = itinerary.findIndex((e) => (
			e.id === entry.id && isItemPortCall(e) === isPortCall
		));
		setSelectedIndex(index);
	}, [itinerary]);

	const onSaveRob = async (
		id: number | string | undefined,
		robId: number | null | undefined,
		rob: NullableItineraryBunkerRecord,
	) => {
		if (voyageDetails == null || robId == null) {
			return;
		}

		if (rob.quantity < 0) {
			showErrorNotification('Bunker quantity cannot be less than 0');

			return;
		}

		await updateRob({
			vesselId: voyageDetails.vesselId,
			voyageId: voyageDetails.id,
			robId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: {
					id: Number(id),
					quantity: rob.quantity,
					fuelGrade: rob.fuelGrade,
					pricePerTon: rob.pricePerTon ?? 0,
				},
			},
		});

		await refreshItinerary();
		setItineraryShouldUpdate(true);
	};

	// Only relevant for commencement where users can add multiple of the same fuel type
	const onRemoveFuelQueueItem = async (
		row: CommencementRobBunker,
	) => {
		if (row == null || voyageDetails == null) {
			return;
		}

		const relevantRob = fetchedRobs?.commencement?.RobBunkers
			.find((rb) => rb.id === row.robBunkerId);

		if (relevantRob == null) {
			showErrorNotification('Could not remove bunker');

			return;
		}

		const newFuelQueue = relevantRob?.fuelQueue
			.filter((fq) => fq.pricePerTon !== row.pricePerTon && fq.quantity !== row.quantity);

		await updateRob({
			vesselId: voyageDetails.vesselId,
			voyageId: voyageDetails.id,
			robId: row.robId,
			robBunkerId: row.robBunkerId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: newFuelQueue,
			},
			resetQueue: true,
			event: CrewReportTypes.COMMENCEMENT,
		});

		await refreshFetchedRobs();
		await refreshDetails();
		setItineraryShouldUpdate(true);
	};

	// Only relevant for commencement where users can add multiple of the same fuel type
	const onSaveFuelQueueItem = async (
		robId: number | string | undefined | null,
		robBunkerId: number,
		fuelQueue: Array<CommencementRobBunker>,
	) => {
		if (fuelQueue == null || voyageDetails == null || robId == null) {
			return;
		}

		await updateRob({
			vesselId: voyageDetails.vesselId,
			voyageId: voyageDetails.id,
			robId: Number(robId),
			robBunkerId,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: fuelQueue,
			},
			resetQueue: true,
			event: CrewReportTypes.COMMENCEMENT,
		});

		await refreshFetchedRobs();
		await refreshDetails();

		setItineraryShouldUpdate(true);
	};

	const tcInCompletionBunkers = useMemo(() => {
		if (expandedEntry == null || !isItemPortCall(expandedEntry) || voyageCompletion == null) {
			return [];
		}

		const isTcInDelivery = expandedEntry.type === PortCallTypes.COMPLETION &&
			voyageCompletion.completionType === VcContractCompletionTypes.TC_IN_DELIVERY;

		const result = isTcInDelivery ? (voyageCompletion.linkedTcInVoyageBunkers ?? []).filter((r) => r.type === 'redelivery')
			.map((b) => ({
				quantity: b.quantity,
				fuelGrade: b.fuelGrade,
				pricePerTon: b.pricePerTon,
			})) : null;

		return result;
	}, [expandedEntry, voyageCompletion]);

	const columns = useMemo(() => getItineraryColumns({
		deletePortCall,
		onSelectEntry,
		portCalls,
		seaPassages,
		onUpdatePortCall,
		selectedTimeFormat,
		viewMode,
		setShowCommencementLinking,
		setEditingCargoId,
		setEditingCargoPortId,
		uniqueFuelTypes,
	}), [
		deletePortCall,
		onSelectEntry,
		portCalls,
		seaPassages,
		onUpdatePortCall,
		selectedTimeFormat,
		viewMode,
		uniqueFuelTypes,
	]);

	return (
		<ItineraryContext.Provider value={{
			tcInCompletionBunkers,
			itinerary,
			localItinerary,
			refreshItinerary,
			itineraryError,
			itineraryLoading,
			deletePortCall,
			portCalls,
			seaPassages,
			setLocalItinerary,
			portOptions,
			setViewMode,
			viewMode,
			ports,
			selectedTimeFormat,
			showCommencementLinking,
			setShowCommencementLinking,
			setSelectedTimeFormat,
			setItineraryShouldUpdate,
			addItineraryEntry,
			expandedEntry,
			voyageDetails,
			onSelectEntry,
			onUpdateRob: debounceUpdateRob,
			onUpdatePortCall,
			movePortCall,
			columns,
			refreshVoyageDetails,
			onSaveRob,
			fetchedRobs,
			refreshFetchedRobs,
			onDeleteRob,
			onAddNewRob,
			onRemoveFuelQueueItem,
			onSaveFuelQueueItem,
			editingCargoId,
			setEditingCargoId,
			setEditingCargoPortId,
			editingCargoPortId,
			renderKey,
		}}
		>
			{children}
		</ItineraryContext.Provider>
	);
};

const ItineraryContext = createContext<ItineraryContextType>(undefined!);
export const useItinerary = () => useContext(ItineraryContext);

