import React, { useRef } from 'react';
import { Typography } from 'antd';
import {
	faBalanceScale,
	faBooks,
	faChartPieAlt,
	faDollar,
	faDonate,
	faFileAlt,
	faFileInvoiceDollar,
	faGasPump,
	faLeaf,
	faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EditOutlined } from '@ant-design/icons';
import {
	AccountTypes,
	AttachmentTypes,
	ContractNamingConventions,
	FixtureTypes,
} from '@shared/utils/constants';
import { fixtureTypeToPascalCase } from '@shared/utils/string';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import {
	getUserData,
	renameIdentifier,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import VesselDescription from '@client/components/VesselDescription';
import { Links } from '@client/utils/links';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import FormPopover from '@client/components/FormPopover';
import Button from '@client/components/Button';
import HeaderAction from './components/HeaderAction';
import TcExpensesTab from './components/TcExpensesTab';
import InvoicesTab from './components/InvoicesTab';
import CumulativeComparison from './components/CumulativeComparison';
import Recap from './components/tabs/Recap';
import TcSummaryTab from './components/TcSummaryTab';
import CommissionsTab from './components/CommissionsTab/CommissionsTab';
import BunkerExpenditureTab from './tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import { ItineraryProvider } from './components/ItineraryTab/ItineraryProvider';
import TcPnlTab from './components/ProfitAndLossTab/TcPnlTab';
import { useVoyage } from './components/VoyageProvider/VoyageProvider';
import EuEtsTab from './components/EuEtsTab/EuEtsTab';

const TcContractDetailsScreen = () => {
	const tabRef = useRef<HTMLDivElement>(null);
	const [userInfo] = useFetchedState(getUserData);

	const {
		voyageDetails,
		fixtureCurrency,
	} = useVoyage();

	const {
		tcFixtureDetails: fixtureDetails,
		refreshDetails,
	} = useVoyage();

	const updateIdentifier = async (identifier?: string) => {
		try {
			await renameIdentifier(fixtureDetails.id, identifier);
			refreshDetails();
			showSuccessNotification('Identifier updated');
		} catch (e) {
			showErrorNotification('Could not update identifier', e as Error);
		}
	};

	const isTcIn = voyageDetails?.fixture?.type === FixtureTypes.TC_IN;

	const tabs = [
		{
			key: 'summary',
			title: 'Summary',
			icon: faChartPieAlt as IconProp,
			render: () => (
				<ItineraryProvider>
					<TcSummaryTab />
				</ItineraryProvider>
			),
		},
		{
			key: 'recap',
			title: 'Recap',
			icon: faFileAlt as IconProp,
			render: () => (
				<Recap />
			),
		},
		{
			key: 'expenses',
			title: 'Expenses',
			forceRender: true,
			icon: faDonate as IconProp,
			render: () => (
				<TcExpensesTab />
			),
		},
		{
			key: 'invoices',
			title: 'Invoices',
			icon: faFileInvoiceDollar as IconProp,
			render: () => (
				<InvoicesTab />
			),
		},
		...(fixtureDetails.brokers?.some((b) => {
			if (fixtureDetails.type === FixtureTypes.TC_OUT ||
				fixtureDetails.type === FixtureTypes.BB_OUT) {
				return b.paidBy === AccountTypes.OWNER;
			}

			if (fixtureDetails.type === FixtureTypes.TC_IN) {
				return b.paidBy === AccountTypes.CHARTERER;
			}

			return false;
		}) ? [{
				key: 'commissions',
				title: 'Commissions',
				icon: faUserTie as IconProp,
				render: () => (
					<CommissionsTab />
				),
			}] : []),
		...(fixtureDetails.type === FixtureTypes.TC_OUT ? [{
			key: 'pnl',
			title: 'P&L',
			icon: faDollar as IconProp,
			render: () => (
				<TcPnlTab
					currency={fixtureCurrency}
					id={fixtureDetails.voyageId}
					vesselOwnershipType={fixtureDetails.vessel.ownershipType}
				/>
			),
		}] : []),
		{
			key: 'reconciliation',
			title: 'SoA',
			icon: faBalanceScale as IconProp,
			render: ({ active }: {active: boolean}) => (
				<CumulativeComparison
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					focused={active}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		...(isTcIn ? [] : [
			{
				key: 'bunkers',
				title: 'Bunkers',
				icon: faGasPump as IconProp,
				render: () => (
					<BunkerExpenditureTab />
				),
			},
		]),
		{
			key: 'documents',
			title: 'Documents',
			icon: faBooks as IconProp,
			render: () => (
				<AttachmentLibrary
					attachments={voyageDetails.attachments}
					refreshAttachments={refreshDetails}
					type={AttachmentTypes.VOYAGE}
					attachToId={voyageDetails.id}
				/>
			),
		},
		...((
			fixtureDetails.type === FixtureTypes.TC_OUT ||
			fixtureDetails.type === FixtureTypes.BB_OUT
		) ? [{
				key: 'eu-ets',
				title: 'EU ETS',
				icon: faLeaf as IconProp,
				render: () => (
					<EuEtsTab />
				),
			}] : []),
	];

	return (
		<DetailsTabScreen
			rootPageTitle="Contract - TC"
			tabContainerRef={tabRef}
			defaultTabKey="summary"
			canGoBack
			tabsProps={{ destroyInactiveTabPane: true }}
			tabs={tabs}
			breadcrumbs={
				[[
					`Contracts - ${fixtureDetails == null ? 'N/A' : fixtureTypeToPascalCase(fixtureDetails.type)}`,
					Links.Voyages.get(),
				]]
			}
			title={(
				<Typography.Title level={4}>
					<VesselDescription
						name={voyageDetails?.vesselName}
						flag={voyageDetails?.vesselFlag}
					/>
					{' | '}
					{voyageDetails?.identifier}
					{userInfo?.contractNamingConvention === ContractNamingConventions.FREE && (
						<FormPopover
							title="Contract identifier"
							onSubmit={(values) => updateIdentifier(
								values.identifier ?? 'Unnamed',
							)}
							content="Hi"
							fields={[
								{
									label: 'Identifier',
									name: 'identifier',
									type: 'text',
									required: false,
								},
							]}
						>
							<Button type="link" icon={(<EditOutlined />)} />
						</FormPopover>
					)}
				</Typography.Title>
			)}
			extra={(
				<HeaderAction />
			)}
		/>
	);
};

export default TcContractDetailsScreen;

