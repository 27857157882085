import React, { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Table } from 'antd';
import {
	Currencies,
	FreightEstimatingMethod,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { formatNumber } from '@shared/utils/formatNumber';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import { useEstimate } from '@client/screens/estimates/details/context/EstimatorContext';
import styles from '../CommissionsCard/BrokerCommissionDetails.module.css';
import getIntakeFDDNumbers, { IntakeFDDCalculation } from './getIntakeFDDNumbers';

type Props = {
	cargoDetails: GetCargoDetailsResponse | undefined;
	refreshCargoDetails: () => void;
};

const IntakeCalculatorFreightDetails = ({
	cargoDetails,
}: Props) => {
	const { estimate } = useEstimate();

	const intakeFDDCalculation: IntakeFDDCalculation | null = useMemo(() => {
		if (!estimate || estimate.vesselMaximumIntake == null) {
			return null;
		}

		return getIntakeFDDNumbers(cargoDetails, estimate.vesselMaximumIntake);
	}, [cargoDetails, estimate]);

	const freightData = useMemo(() => {
		if (
			!cargoDetails ||
      !intakeFDDCalculation ||
      estimate?.freightEstimatingMethod === FreightEstimatingMethod.CP_QUANTITY
		) {
			return [];
		}

		return [
			{
				key: 'freight',
				type: 'Freight',
				rate: cargoDetails.freightRate ?? 0,
				quantity: intakeFDDCalculation.freightQuantity,
				gross: intakeFDDCalculation.freightAmount,
			},
			{
				key: 'overage',
				type: 'Overage',
				rate: cargoDetails.overageAmount ?? 0,
				quantity: intakeFDDCalculation.overageQuantity,
				gross: intakeFDDCalculation.overageAmount,
			},
			{
				key: 'deadfreight',
				type: 'Deadfreight',
				rate: cargoDetails.deadfreightAmount ?? 0,
				quantity: intakeFDDCalculation.deadfreightQuantity,
				gross: intakeFDDCalculation.deadfreightAmount,
			},
		];
	}, [cargoDetails, estimate?.freightEstimatingMethod, intakeFDDCalculation]);

	const totals = useMemo(() => {
		const totalQuantity = freightData.reduce((acc, row) => acc + row.quantity, 0);
		const totalRevenue = freightData.reduce((acc, row) => acc + row.gross, 0);

		return { totalQuantity, totalRevenue };
	}, [freightData]);

	return (
		<div className={styles.container}>
			<div className={styles.freightSection}>
				<Table
					columns={[
						{
							title: 'Type',
							dataIndex: 'type',
							width: 200,
						},
						{
							title: 'Quantity',
							dataIndex: 'quantity',
							width: 100,
							render(value: number) {
								return `${formatNumber(value, { separateThousands: true }, 2)} MT`;
							},
						},
						{
							title: 'Rate',
							dataIndex: 'rate',
							width: 100,
							render(value: number) {
								return formatCurrency(value, estimate?.currency ?? Currencies.USD);
							},
						},
						{
							title: 'Gross',
							dataIndex: 'gross',
							width: 100,
							render(value: number) {
								return formatCurrency(value, estimate?.currency ?? Currencies.USD);
							},
						},
					]}
					dataSource={freightData}
					size="small"
					pagination={false}
					summary={() => (
						<Table.Summary.Row>
							<Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
							<Table.Summary.Cell index={1}>
								{`${formatNumber(totals.totalQuantity, { separateThousands: true }, 2)} MT`}
							</Table.Summary.Cell>
							<Table.Summary.Cell index={2} />
							<Table.Summary.Cell index={3}>
								{formatCurrency(totals.totalRevenue, estimate?.currency ?? Currencies.USD)}
							</Table.Summary.Cell>
						</Table.Summary.Row>
					)}
				/>
			</div>
		</div>
	);
};

export default IntakeCalculatorFreightDetails;
