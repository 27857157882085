import React, {
	useState,
	useEffect,
	useRef,
	ReactNode,
} from 'react';
import {
	useMutation,
	UseMutationResult,
} from 'react-query';
import { asyncForEach } from '@shared/utils/array';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { PortActionTypes } from '@shared/utils/constants';
import { getCargoPortLaytimeEstimatedValues } from '@shared/utils/getCargoPortLaytimeEstimatedValues';
import type { CargoProps } from '@api/models/cargo';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type {
	CargoDetails,
	GetCargoDetailsResponse,
} from '@api/features/cargos/getCargoDetails';
import type {
	UpdateCargoRequest,
	UpdateCargoResponse,
} from '@api/features/cargos/updateCargo';
import type { CargoPortProps } from '@api/models/cargo-port';
import type { CargoPortWithEstimatedValues } from '@api/features/cargos/transformCargos';
import type { Port } from '@api/utils/ports';
import {
	updateCargo,
	addCargoToEstimate,
	createCargo,
	deleteCargo,
	updateCargoPort,
	getCargos,
	getCargoDetails,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';

// eslint-disable-next-line no-undef
export type ReturnResult<Fn extends (...args: any) => any> = Awaited<ReturnType<Fn>>

export type OnUpdateCargo = (
	cargoId: number,
	attributes: UpdateCargoRequest['attributes'],
) => void;

type CargoChanges = { cargoId: number; attributes: Partial<UpdateCargoRequest['attributes']> };
type CargoPortChanges = { cargoId: number; cargoPortId: number; attributes: Partial<CargoProps> };

export type OnImportCargoMutator = UseMutationResult<
	ReturnResult<typeof addCargoToEstimate>,
	Error,
	number
>

export type OnCreateNewCargoMutator = UseMutationResult<
	ReturnResult<typeof createCargo>,
	Error,
	void
>

export type OnDeleteCargoMutator = UseMutationResult<
	ReturnResult<typeof deleteCargo>,
	Error,
	number
>

export type UpdateCargoPort = {
	cargoId: number;
	cargoPortId: number;
	attributes: CargoPortProps;
}

type Props = {
	cargoId?: number | null;
	estimate?: GetEstimateDetailsResponse | null | undefined;
	selectedEstimateId: number | null;
	centralCargos?: CargoDetails[];
	cargosChanged?: boolean;
	setCargosChanged?: React.Dispatch<React.SetStateAction<boolean>>;
	syncCargos?: boolean;
	setSyncCargos?: React.Dispatch<React.SetStateAction<boolean>>;
	refreshEverything?: () => Promise<void>;
	refreshFreightRate?: () => Promise<void>;
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
* Handlers for cargos. If cargoId is specified, it's being used on CargoDetailsScreen,
* if estimateId is specified its on the estimate
*/
export const useCargoHandlers = ({
	cargoId,
	estimate,
	centralCargos,
	setCargosChanged,
	syncCargos,
	setSyncCargos,
	refreshEverything,
	selectedEstimateId,
	refreshFreightRate,
	setLoading,
}: Props) => {
	const inEstimate = estimate != null;
	const [localCargoData, setLocalCargoData] = useState<Array<CargoDetails> | null>(null);
	const [
		localCargoPortData,
		setLocalCargoPortData,
	] = useState<Array<CargoPortWithEstimatedValues> | null | undefined>(null);
	const [pendingCargoChanges, setPendingCargoChanges] = useState<Array<CargoChanges>>([]);
	const [
		pendingCargoPortChanges,
		setPendingCargoPortChanges,
	] = useState<Array<CargoPortChanges>>([]);

	const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
	const [allCargos, refreshAllCargos] = useFetchedState(getCargos);

	const [isReadyToFix, setIsReadyToFix] = useState<boolean>(true);
	const [isReadyToFixMessage, setIsReadyToFixMessage] = useState<
		string | ReactNode | undefined
	>(undefined);

	const [
		_cargo,
		refreshCargo,
	] = useFetchedState(async () => {
		if (cargoId != null) {
			const cargoDetails = await getCargoDetails(cargoId);
			setLocalCargoData([cargoDetails]);

			const cargoPorts = Object.values(cargoDetails.cargoPorts);
			setLocalCargoPortData(cargoPorts);
		}

		return null;
	}, [cargoId]);

	useEffect(() => {
		if (
			((localCargoData == null && centralCargos != null) ||
				(localCargoData?.length !== centralCargos?.length) ||
				syncCargos) && (cargoId == null)
		) {
			setLocalCargoData(centralCargos ?? []);

			const cargoPorts = centralCargos?.map((c) => c.cargoPorts).flat();
			const transformed = cargoPorts?.map((c) => Object.values(c));
			setLocalCargoPortData(transformed?.flat());
			setSyncCargos?.(false);
		}
	}, [cargoId, centralCargos, estimate, inEstimate, localCargoData, setSyncCargos, syncCargos]);

	useEffect(() => {
		const handler = () => {
			if (Object.keys(pendingCargoChanges).length > 0) {
				setLoading?.(true);

				const data: CargoProps[] = [];
				asyncForEach(pendingCargoChanges, async (c) => {
					const updatedCargo = await updateCargo(c, false);
					data.push(updatedCargo);
				}, true)
					.then()
					.catch(() => {
						showErrorNotification('Something went wrong when updating a cargo');
					})
					.finally(async () => {
						setCargosChanged?.(true);
						setPendingCargoChanges([]);
						if (estimate?.isTceLocked) {
							const updatedCargo = data[0];
							setLocalCargoData((prev) => {
								const currentData = prev?.[0];

								if (currentData == null) {
									return [];
								}

								return [{
									...currentData,
									freightRate: updatedCargo.freightRate,
								}];
							});
						}
					});
			}

			if (pendingCargoPortChanges.length > 0) {
				setLoading?.(true);
				asyncForEach(
					pendingCargoPortChanges,
					async (c) => await updateCargoPort(c, false),
					true,
				)
					.then()
					.catch(() => {
						showErrorNotification('Something went wrong when updating a cargo port');
					})
					.finally(() => {
						setCargosChanged?.(true);
						setPendingCargoPortChanges([]);
						refreshEverything?.();
					});
			}
		};

		if (timeoutRef.current != null) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(handler, 1000);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [
		setCargosChanged,
		estimate?.cargos,
		estimate?.isTceLocked,
		pendingCargoChanges,
		pendingCargoPortChanges,
		localCargoData,
		refreshCargo,
		refreshFreightRate,
		refreshEverything,
		setLoading,
	]);

	const onImportCargoMutator: OnImportCargoMutator = useMutation(
		{
			mutationFn: (id: number) => addCargoToEstimate(selectedEstimateId!, id),
			onMutate: () => setLoading?.(true),
			onSuccess: async () => {
				refreshEverything?.();
			},
		},
	);

	const onCreateNewCargoMutator: OnCreateNewCargoMutator = useMutation(
		{
			mutationFn: () => createCargo({
				estimateId: selectedEstimateId!,
				type: 'new cargo',
			}, false),
			onMutate: () => setLoading?.(true),
			onSuccess: async () => {
				refreshEverything?.();
				setIsReadyToFix(false);
			},
		},
	);

	const onDeleteCargoMutator: OnDeleteCargoMutator = useMutation(
		{
			mutationFn: (id: number) => deleteCargo(id, selectedEstimateId!, false),
			onMutate: () => setLoading?.(true),
			onSuccess: async () => {
				if (selectedEstimateId != null) {
					setCargosChanged?.(true);

					// TODO: Should disable Create button since we have an
					// invalid cargo entry ie. no fix can be created
					const { ready } = isReadyForFix(estimate?.cargos!);

					setIsReadyToFix(ready);

					refreshEverything?.();
				}

				showSuccessNotification('Cargo removed');
			},
			onError: (e) => {
				showErrorNotification('Failed to remove cargo', e as Error);
			},
		},
	);

	const onUpdateCargo: OnUpdateCargo = async (id, attributes) => {
		const relevantCargo = localCargoData?.find((c) => c.id === id);

		if (relevantCargo == null) {
			return;
		}

		let cargosToValidate = estimate?.cargos;
		const cargoToUpdate = cargosToValidate?.find(((c) => c.id === id));
		const updatedCargoEntry = {
			...cargoToUpdate,
			...attributes,
		};

		cargosToValidate = cargosToValidate?.filter((x) => x.id !== id);
		cargosToValidate?.push(updatedCargoEntry as CargoDetails);

		const { ready, message } = isReadyForFix(cargosToValidate!);

		setIsReadyToFix(ready);
		setIsReadyToFixMessage(message);

		if ('loadingPorts' in attributes || 'dischargePorts' in attributes) {
			setLoading?.(true);

			let cargo: UpdateCargoResponse | undefined;

			try {
				const cargoResult = await updateCargo({ cargoId: id, attributes }, false);
				cargo = cargoResult;
			} catch (e) {
				showErrorNotification('Could not set port', e as Error);
			}

			setCargosChanged?.(true);

			if (cargo) {
				setLocalCargoPortData(cargo.CargoPorts.map((cp) => {
					const calculatedValues = getCargoPortLaytimeEstimatedValues(cargo as CargoProps, cp);

					return {
						...cp,
						...calculatedValues,
						totalTimeInPort: calculatedValues.totalTimeInPort ?? 0,
						timeToCount: calculatedValues.timeToCount ?? 0,
						estimatedDemurrage: calculatedValues.estimatedDemurrage ?? 0,
						estimatedDespatch: calculatedValues.estimatedDespatch ?? 0,
					};
				}));
			}

			return;
		}

		setPendingCargoChanges((prev) => {
			const existingPendingChange = prev.find((c) => c.cargoId === id);
			let attributesToUpdate = attributes;

			if (existingPendingChange != null) {
				attributesToUpdate = {
					...existingPendingChange.attributes,
					...attributes,
				};
			}

			const otherCargos = prev.filter((c) => c.cargoId !== id);
			const updatedArr = [
				...otherCargos,
				{
					cargoId: id,
					attributes: attributesToUpdate,
				},
			];

			return updatedArr;
		});

		const cargoPortsToUpdate: CargoPortWithEstimatedValues[] = [];

		const cargoPorts = localCargoPortData?.reduce((acc, cp) => {
			const {
				totalTimeInPort,
				timeToCount,
				estimatedDemurrage,
				estimatedDespatch,
				estimatedTimeAllowed,
				idleDays,
				laytimeLostOrGained,
				workingDays,
			} = getCargoPortLaytimeEstimatedValues(
				{
					...relevantCargo,
					...attributes,
				} as CargoProps,
				cp,
			);

			const values: CargoPortWithEstimatedValues = {
				...cp,
				totalTimeInPort: totalTimeInPort ?? 0,
				timeToCount: timeToCount ?? 0,
				estimatedDemurrage: Math.abs(estimatedDemurrage ?? 0),
				estimatedDespatch: estimatedDespatch ?? 0,
				estimatedTimeAllowed,
				idleDays,
				laytimeLostOrGained,
				workingDays,
			};

			cargoPortsToUpdate.push(values);

			return {
				...acc,
				[cp.portAndActionKey]: values,
			};
		}, {});

		setLocalCargoData((prev) => {
			if (prev == null) {
				return [];
			}

			const updatedCargo = {
				...relevantCargo,
				...attributes,
				cargoPorts,
				key: relevantCargo.id,
			};

			return prev.map((c) => {
				return (c.id === relevantCargo.id ? updatedCargo : c);
			}) as Array<GetCargoDetailsResponse> | GetCargosResponse;
		});

		setLocalCargoPortData((prev) => prev?.map((cp) => {
			const updated = cargoPortsToUpdate.find((c) => c.id === cp.id);

			return updated ?? cp;
		}));
	};

	const onUpdateCargoPort = (values: UpdateCargoPort) => {
		const toUpdate = {
			cargoId: values.cargoId,
			cargoPortId: values.cargoPortId,
			attributes: values.attributes,
		};

		const relevantCargo = localCargoData?.find((c) => c.id === values.cargoId);
		const relevantCargoPort = localCargoPortData?.find((cp) => cp.id === values.cargoPortId);

		if (relevantCargo == null || relevantCargoPort == null) {
			return;
		}

		setPendingCargoPortChanges((prev) => {
			if (prev.length === 0) {
				return [toUpdate];
			}

			return prev.map((p) => {
				if (p.cargoPortId === values.cargoPortId) {
					return {
						...toUpdate,
						attributes: {
							...p.attributes,
							...toUpdate.attributes,
						},
					};
				}

				return p;
			});
		});

		setLocalCargoPortData((prev) => {
			if (prev == null) {
				return [];
			}

			const updatedCargoPort = {
				...prev.find((cp) => cp.id === values.cargoPortId),
				...values.attributes,
			};

			const calculatedValues = getCargoPortLaytimeEstimatedValues(relevantCargo, updatedCargoPort);

			const cpTimeAllowed = (updatedCargoPort.cpQuantity ?? 0) /
				(updatedCargoPort.loadingRate ?? updatedCargoPort.estimatedLoadingRate ?? 0);

			let blTimeAllowed = (updatedCargoPort.blQuantity ?? 0) /
				(updatedCargoPort.loadingRate ?? updatedCargoPort.estimatedLoadingRate ?? 0);

			// Failsafe since we split up timeAllowed into cp and bl
			// If bl has no value we ensure to return the cp instead - just as before the split
			blTimeAllowed = blTimeAllowed ?? cpTimeAllowed;

			return prev.map((cp) => {
				return (cp.id === values.cargoPortId ? {
					...updatedCargoPort,
					...calculatedValues,
					cpTimeAllowed,
					blTimeAllowed,
					estimatedDemurrage: Math.abs(calculatedValues.estimatedDemurrage ?? 0),
					totalTimeInPort: calculatedValues.totalTimeInPort ?? 0,
					timeToCount: calculatedValues.timeToCount ?? 0,
					estimatedDespatch: calculatedValues.estimatedDespatch ?? 0,
				} : cp);
			});
		});

		if (toUpdate.attributes?.port != null) {
			setLocalCargoData((prev) => {
				if (prev == null) {
					return [];
				}

				const updatedCargoPorts = {
					...relevantCargo?.cargoPorts,
					[relevantCargoPort.portAndActionKey]: {
						...relevantCargoPort,
						port: toUpdate.attributes.port,
					} as CargoPortWithEstimatedValues,
				};

				const { action, portId } = splitActionKey(relevantCargoPort.portAndActionKey);
				const isLoading = action === PortActionTypes.LOADING;
				const updatePort = (ports: Port[]) => ports
					.map((p) => (p.id === Number(portId) ? toUpdate.attributes.port : p));

				const updatedCargo = {
					...relevantCargo,
					cargoPorts: updatedCargoPorts,
					loadingPorts: isLoading ?
						updatePort(relevantCargo.loadingPorts) :
						relevantCargo.loadingPorts,
					dischargePorts: isLoading ?
						relevantCargo.dischargePorts :
						updatePort(relevantCargo.dischargePorts),
				};

				return prev.map((c) => (c.id === values.cargoId ? updatedCargo : c));
			});
		}
	};

	const isReadyForFix = (cargos: CargoDetails[] | null) => {
		let ready = true;
		const messages = [];

		// Since we can create the fix, without any cargo entries
		if (!cargos) {
			ready = true;

			return { ready, messages: null };
		}

		if (!estimate) {
			ready = false;
			messages.push('Estimate is missing.');
		}

		if (cargos.some((cargo) => !cargo.charterer)) {
			ready = false;
			messages.push('Some cargos are missing a charterer.');
		}

		if (cargos.some((cargo) => (
			cargo.loadingPorts.length === 0 || cargo.dischargePorts.length === 0
		))) {
			ready = false;
			messages.push('Some cargos are missing loading or discharging ports.');
		}

		if (cargos.some((cargo) => !cargo.quantity)) {
			ready = false;
			messages.push('Some cargos are missing a quantity.');
		}

		const messageJSX = (
			<ul>
				{messages.map((msg, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={index}>{msg}</li>
				))}
			</ul>
		);

		return {
			ready,
			message: messageJSX,
		};
	};

	return {
		cargos: (localCargoData ?? []).map((c) => ({ ...c, key: c.id })),
		cargoPorts: localCargoPortData,
		cargo: localCargoData?.[0] as GetCargoDetailsResponse,
		allCargos: allCargos ?? [],
		refreshAllCargos,
		onImportCargoMutator,
		onCreateNewCargoMutator,
		onUpdateCargo,
		onUpdateCargoPort,
		onDeleteCargoMutator,
		setCargosChanged,
		pendingChanges: pendingCargoChanges.length > 0 || pendingCargoPortChanges.length > 0,
		isReadyToFix,
		isReadyToFixMessage,
	};
};
