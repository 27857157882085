import React, {
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Col,
	Empty,
	Row,
} from 'antd';
import { useQuery } from 'react-query';
import {
	formatDate,
	toMoment,
} from '@shared/utils/date';
import {
	DATE_AND_TIME,
	TsReportTypes,
} from '@shared/utils/constants';
import isItemPortCall from '@shared/utils/isItemPortCall';
import Table from '@client/components/Table/Table';
import {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@client/screens/estimates/details/helpers/types';
import { getThirdPartyNoonReports } from '@client/lib/api';
import TsReport from './TsReport';

const TheShipReportsTab = ({
	selectedEntry,
}: {
	selectedEntry: ItineraryPortCallDto | ItinerarySeaPassageDto;
}) => {
	const isPortCall = isItemPortCall(selectedEntry);
	const isActual = selectedEntry.arrivalDate != null && selectedEntry.departureDate != null;
	const [selectedReportId, setSelectedReportId] = useState<string | undefined>();

	const from = isPortCall ? selectedEntry.arrivalDate : selectedEntry.departureDate;
	const to = isPortCall ? selectedEntry.departureDate : selectedEntry.arrivalDate;

	const {
		data: tsLogReports,
		isLoading: reportsLoading,
	} = useQuery(
		['tsLogReports', selectedEntry?.id],
		() => getThirdPartyNoonReports(
			selectedEntry.vesselId,
			{
				from: toMoment(from!).toISOString(),
				to: toMoment(to!).toISOString(),
				portCallId: isPortCall ? (selectedEntry.externalId ?? undefined) : undefined,
			},
		),
		{
			enabled: from != null && to != null && isActual,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const selectedReport = useMemo(() => {
		if (selectedReportId == null) {
			return undefined;
		}

		const report = tsLogReports?.find((r) => r.id === selectedReportId);

		if (report == null) {
			return undefined;
		}

		return report;
	}, [selectedReportId, tsLogReports]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={8}>
				<Table
					loading={reportsLoading}
					pagination={false}
					size="small"
					dataSource={tsLogReports}
					onRowClick={(row) => setSelectedReportId(row.id)}
					locale={{ emptyText: 'No reports found' }}
					columns={[
						{
							title: 'Report',
							dataIndex: 'type',
							render: (type) => (type == null ? 'Unknown' : TsReportTypes[type]),
						},
						{
							title: 'Title',
							dataIndex: 'dateAndTime',
							render: (d) => (d == null ? 'Unknown' : formatDate(d, DATE_AND_TIME)),
						},
					]}
				/>
			</Col>
			<Col span={16}>
				<Card size="small">
					{selectedReport == null ? (
						<Empty description="Select a report to see it" />
					) : (
						<TsReport report={selectedReport} />
					)}
				</Card>
			</Col>
		</Row>
	);
};

export default TheShipReportsTab;
