import React, {
	useEffect,
	useState,
} from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import {
	BankAccountTypes,
	CustomFieldParentTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { BankAccountProps } from '@api/models/bank-account';
import type { UpdateBankAccountRequest } from '@api/features/bank-accounts/updateBankAccount';
import { BankAccountTabs } from '@client/screens/fleet/VesselDetailsScreen/components/BankAccountTabs';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	createBankAccount,
	deleteBankAccount,
	updateBankAccount,
} from '@client/lib/api';
import styles from '@client/screens/fleet/VesselDetailsScreen/VesselDetailsScreen.module.css';
import CustomFields from '@client/screens/management/banking/CustomFields';

type Props = {
	vessel: GetVesselDetailsResponse;
	refreshVessel: () => void;
};

export const VesselBankingTab = ({ vessel, refreshVessel }: Props) => {
	const [activeTcInTab, setActiveTcInTab] = useState<number | undefined>(undefined);
	const [activeOurTab, setActiveOurTab] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!vessel) {
			return;
		}

		if (vessel.ownershipType === VesselOwnershipTypes.TC_IN) {
			if (
				!activeTcInTab ||
			!vessel.tcInBankAccounts.some((account) => account.id === activeTcInTab)
			) {
				setActiveTcInTab(vessel.tcInBankAccounts.length > 0 ?
					vessel.tcInBankAccounts[0].id :
					undefined);
			}
		}

		if (
			!activeOurTab ||
			!vessel.ourBankAccounts.some((account) => account.id === activeOurTab)
		) {
			setActiveOurTab(vessel.ourBankAccounts.length > 0 ? vessel.ourBankAccounts[0].id : undefined);
		}
	}, [activeOurTab, activeTcInTab, vessel]);

	const onAddTcInBankAccount = async () => {
		const newAccount = await createBankAccount(BankAccountTypes.TC_IN, vessel.id);
		await refreshVessel();

		return newAccount;
	};

	const onAddOurBankAccount = async () => {
		const newAccount = await createBankAccount(BankAccountTypes.OURS, vessel.id);
		await refreshVessel();

		return newAccount;
	};

	const onSaveBankAccount = async (
		account: BankAccountProps,
		changes: UpdateBankAccountRequest['attributes'],
	) => {
		await updateBankAccount(changes, account.id, vessel.id);
		await refreshVessel();
	};

	const onDeleteBankAccount = async (accountId: number) => {
		try {
			await deleteBankAccount(accountId, vessel.id);
			showSuccessNotification('Successfully deleted bank account.');
		} catch (error) {
			showErrorNotification('Could not delete bank account', error as Error);

			return;
		}

		await refreshVessel();
	};

	return (
		<Row gutter={[16, 16]}>
			{vessel.ownershipType === VesselOwnershipTypes.TC_IN && (
				<Col span={12}>
					<Card
						bordered
						title="Vessel Owner's Banking Details"
						className={styles.vesselDetailsCard}
					>
						{vessel.tcInBankAccounts?.length > 0 && (
							<BankAccountTabs
								activeBankAccountTab={activeTcInTab}
								setActiveBankAccountTab={setActiveTcInTab}
								vesselOwner
								bankAccounts={vessel.tcInBankAccounts}
								deleteBankAccount={onDeleteBankAccount}
								addBankAccount={onAddTcInBankAccount}
								saveBankAccount={onSaveBankAccount}
								allowCreate={vessel.ownershipType !== VesselOwnershipTypes.TC_IN}
							/>
						)}
						{activeTcInTab && (
							<CustomFields
								parentId={Number(activeTcInTab)}
								parentType={CustomFieldParentTypes.BANK}
							/>
						)}
					</Card>
				</Col>
			)}
			<Col span={12}>
				<Card
					bordered
					title="Your Banking Details"
					className={styles.vesselDetailsCard}
				>
					{vessel.ourBankAccounts?.length > 0 && (
						<BankAccountTabs
							activeBankAccountTab={activeOurTab}
							setActiveBankAccountTab={setActiveOurTab}
							bankAccounts={vessel.ourBankAccounts}
							deleteBankAccount={onDeleteBankAccount}
							addBankAccount={onAddOurBankAccount}
							saveBankAccount={onSaveBankAccount}
							allowCreate
						/>
					)}
					{activeOurTab && (
						<CustomFields
							parentId={Number(activeOurTab)}
							parentType={CustomFieldParentTypes.BANK}
						/>
					)}
				</Card>
			</Col>
		</Row>
	);
};
